export const msalConfig = {
  auth: {
    clientId: '13399021-8746-4999-b477-b65da35a6768',
    authority: 'https://login.microsoftonline.com/f30ac191-b8b4-45f2-9a9b-e5466cb90c2f',
    redirectUri: 'https://stark.digital-modis.com/',
    postLogoutRedirectUri: 'https://www.google.com/',
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['User.Read'],
  sid: 'ctry',
  sid2: ['ctry'],
  loginHint: 'preferred_username',
  loginHint2: ['preferred_username'],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com',
};
