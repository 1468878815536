/* eslint-disable no-restricted-globals */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Formik, Form, ErrorMessage,
} from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
import moment from 'moment';
// import AddIcon from '@material-ui/icons/Add';
import { api } from '../../../api/api';
import { ButtonCustomFormik, ButtonSimple } from '../../../commonComponents/ButtonCustom';
import TextFieldFormik from '../../../commonComponents/Fields/TextFieldFormik';
import DatePickerFormik from '../../../commonComponents/Fields/DatePickerFormik';

const useStyles = makeStyles(() => ({
  modalCreateWPTimeAndMaterial: {
    height: 'auto',
    backgroundColor: 'white',
    borderRadius: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textFields: {
    width: '261px',
    '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      display: 'none',
    },
  },
  gridItem: {
    margin: '5px',
  },
}));

// Async validation function
const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const CustomInput = (props) => {
  const {
    children, name, label, mandatory,
  } = props;
  return (
    <Box>
      <Box display="flex" className="formFieldLabel" gridGap="5px">
        <label htmlFor={name} className="labelsFormCreate">{label}</label>
        {mandatory && (
          <span className="mandatoryInputs">*</span>
        )}
      </Box>
      {children}
      {mandatory && (
      <ErrorMessage
        name={name}
        component="div"
        className="errorMessageCreateCollaborator"
      />
      )}
    </Box>
  );
};
const CreateWPContent = (props) => {
  const {
    ProjectId, setOpen, change, setChange, budgetType,
  } = props;

  const classes = useStyles();

  const [projectDate, setProjectDate] = useState();

  useEffect(() => {
    api
      .get(`api/projects/${ProjectId}`)
      .then((res) => setProjectDate(res.data.start_date));
  }, []);

  const validateAsync = (values) => sleep(200).then(() => {
    const errors = {};
    Object.keys(values).forEach((key) => {
      if (values[key] === '') { errors[key] = 'Required field.'; }
    });
    if (budgetType === 'Work Package') {
      if (isNaN(values.totalPriceWP)) {
        errors.totalPriceWP = 'Value must be a valid integer';
      }
      if (!Number.isInteger(Number(values.numberSprintsPlanned))) {
        errors.numberSprintsPlanned = 'Value must be a valid integer';
      }
      if (!Number.isInteger(Number(values.baseline))) {
        errors.baseline = 'Value must be a valid integer';
      }
      if (!Number.isInteger(Number(values.sprintStart))) {
        errors.sprintStart = 'Value must be a valid integer';
      }
      if (!Number.isInteger(Number(values.sprintLength))) {
        errors.sprintLength = 'Value must be a valid integer';
      }
      if (values.sprintStart?.length < 1) {
        errors.sprintStart = 'Required field';
      }
      if (isNaN(values.baseline)) {
        errors.baseline = 'Value must be a valid integer';
      }
      if (isNaN(values.sprintLength)) {
        errors.sprintLength = 'Value must be a valid integer';
      }
      if (isNaN(values.sprintLength)) {
        errors.sprintLength = 'Value must be an integer';
      }
      if (moment(values.startingDate).isBefore(moment(projectDate))) {
        errors.startingDate = 'This WorkPackage cannot start before the date of the project';
      }
      if (values.workPackageName?.length > 20) {
        errors.workPackageName = 'Cannot be longer than 20 characters';
      }
      if (values.workPackageName?.length > 0 && values.workPackageName?.length < 2) {
        errors.workPackageName = 'Cannot be shorter than 2 characters.';
      }
    }
    if (budgetType === 'Time and material') {
      if (!Number.isInteger(Number(values.numberSprintsPlanned))) {
        errors.numberSprintsPlanned = 'Value must be a valid integer';
      }
      if (isNaN(values.totalPriceWP)) {
        errors.totalPriceWP = 'Value must be a valid integer';
      }
      if (isNaN(values.numberSprintsPlanned)) {
        errors.numberSprintsPlanned = 'Value must be a valid integer';
      }
    }
    return errors;
  });

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setOpen(false);
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, []);

  const submitting = (values) => {
    let data = {
      projectId: ProjectId,
      budgetType,
      ...values,
    };
    if (budgetType === 'Time and material') {
      data = {
        ...data,
        sprintStart: 1, // set sprintStart to 1 for 'Time and Material'
      };
    }
    api.post('api/snapshots', data)
      .then((response) => (response.status === 201 ? setOpen(false) : undefined))
      .then(() => setChange(!change));
  };

  return (
    <div className={classes.modalCreateWPTimeAndMaterial}>
      <Formik
        initialValues={budgetType === 'Work Package' ? {
          baseline: '', totalPriceWP: '', workPackageName: '', numberSprintsPlanned: '', sprintStart: '', startingDate: '', sprintLength: '',
        }
          : { totalPriceWP: '', numberSprintsPlanned: '' }}
        onSubmit={submitting}
        validate={validateAsync}
      >
        {({ errors }) => {
          console.log(errors);
          return (
            <Form>
              <div>
                {budgetType === 'Work Package' ? (
                  <Grid alignItems="stretch" justifyContent="flex-start" container spacing={2}>
                    <Grid className={classes.gridItem} item xs={4}>
                      <CustomInput name="workPackageName" label="Work Package Title" mandatory>
                        <TextFieldFormik
                          name="workPackageName"
                          placeholder="Title"
                        />
                      </CustomInput>
                    </Grid>

                    <Grid item xs={2} />

                    <Grid className={classes.gridItem} item xs={4}>
                      <CustomInput name="totalPriceWP" label={budgetType === 'Time and material' ? 'Project Price' : 'Work Package Price'} mandatory>
                        <TextFieldFormik
                          name="totalPriceWP"
                          placeholder="Price"
                        />
                      </CustomInput>
                    </Grid>

                    <Grid item xs={1} />

                    <Grid item xs={5} className={classes.gridItem}>
                      <CustomInput name="numberSprintsPlanned" label="Numbers of Sprints" mandatory>
                        <TextFieldFormik
                          type="number"
                          name="numberSprintsPlanned"
                          placeholder="Number of Sprints"
                        />
                      </CustomInput>
                    </Grid>

                    <Grid item xs={1} />

                    <Grid item xs={5} className={classes.gridItem}>
                      <CustomInput name="baseline" label="Time Budget (in days)" mandatory>
                        <TextFieldFormik
                          name="baseline"
                          placeholder="Time in days"
                          value="50"
                        />
                      </CustomInput>
                    </Grid>

                    <Grid item className={classes.gridItem} xs={5}>
                      <CustomInput name="startingDate" label="Starting Date" mandatory>
                        <DatePickerFormik
                          name="startingDate"
                          className="fieldCreateCollaborator"
                          placeholder="Select"
                        />
                      </CustomInput>
                    </Grid>

                    <Grid item xs={1} />

                    <Grid item xs={5} className={classes.gridItem}>
                      <CustomInput name="sprintLength" label="Length of Sprint (days)" mandatory>
                        <TextFieldFormik
                          name="sprintLength"
                          placeholder="Type n length of sprint"
                        />
                      </CustomInput>
                    </Grid>

                    <Grid className={classes.gridItem} item xs={11} mt={5}>
                      <CustomInput name="sprintStart" label="From which sprint starts the workpackage ?" mandatory>
                        <TextFieldFormik
                          type="number"
                          name="sprintStart"
                          placeholder="Select"
                        />
                      </CustomInput>
                    </Grid>
                  </Grid>

                )
                  : (
                    <Grid alignItems="stretch" justifyContent="space-between" container>
                      <Grid item xs={4}>
                        <CustomInput name="numberSprintsPlanned" label="Number of sprints" mandatory>
                          <TextFieldFormik
                            name="numberSprintsPlanned"
                          />
                        </CustomInput>
                      </Grid>
                      <Grid item xs={4}>
                        <CustomInput name="totalPriceWP" label="Project price" mandatory>
                          <TextFieldFormik
                            name="totalPriceWP"
                          />
                        </CustomInput>
                      </Grid>
                    </Grid>
                  )}
              </div>

              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{ paddingTop: '40px' }}
                spacing={1}
              >
                <ButtonSimple event={() => setOpen(false)} variant="large" text="Cancel" />
                <Grid item xs={1} />
                <ButtonCustomFormik
                  variant="large"
                  text="Create"
                />
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

CreateWPContent.defaultProps = {
  change: false,
};

CreateWPContent.propTypes = {
  ProjectId: PropTypes.number.isRequired,
  setOpen: PropTypes.func.isRequired,
  setChange: PropTypes.func.isRequired,
  change: PropTypes.bool,
  budgetType: PropTypes.string.isRequired,
};

export default CreateWPContent;
