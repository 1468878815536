import React from 'react';
import { Paper } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ReactComponent as Oops } from '../ressources/oops.svg';
import { ButtonCustom } from '../commonComponents/ButtonCustom';

const NotFound = () => (
  <>
    <Paper style={{
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
    }}
    >
      <p>
        Ooops!
      </p>
      <Oops />
      <p>
        The page you requested does not exist.
      </p>
      <br />
      <Link style={{ textDecoration: 'none' }} to="/">
        <ButtonCustom
          variant="small"
          text="Go back"
        />
      </Link>
    </Paper>
  </>
);
export default NotFound;
