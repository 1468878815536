/* eslint-disable no-unused-vars */
import React from 'react';
import { api } from '../api/api';

// let user;

// const getUser = async () => {
//   user = await api.get('/api/users/2').data;
// };

export default React.createContext({
  user: {},
  setUser: (name) => {},
});
