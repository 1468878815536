/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import ForecastingsApi from '../api/ForecastingsApi';
// eslint-disable-next-line no-unused-vars
import datesRangeToForecastings, { groupforecastingByDateRangeIntensity } from '../utils/datesRangeToFprecastings';

const ForecastingsSlice = createSlice({
  name: 'forecastings',
  initialState: { forecastings: [], forecastingsByUserAndProjectId: [] },
  reducers: {
    forecastingsAdded: (state, action) => {
      state.forecastings = action.payload;
    },
    forecastingsByUserAndProjectId: (state, action) => {
      state.forecastingsByUserAndProjectId = action.payload;
    },
  },
});

const { actions, reducer } = ForecastingsSlice;

export const { forecastingsAdded, forecastingsByUserAndProjectId } = actions;

export const fetchForecastings = (params) => async (dispatch) => {
  const forecastingsApi = new ForecastingsApi();
  const response = await forecastingsApi.fetchAll(params);
  if (response.length > 0) {
    dispatch(forecastingsAdded(datesRangeToForecastings(response)));
  } else {
    dispatch(forecastingsAdded(response));
  }
};

export const fetchForecastingsByUserAndProjectId = (params) => async (dispatch) => {
  const forecastingsApi = new ForecastingsApi();
  const response = await forecastingsApi.fetchByUserAndProjectId(params);
  const dateRangeWithoutWE = response.length > 0 && datesRangeToForecastings(response);
  dispatch(
    forecastingsByUserAndProjectId(groupforecastingByDateRangeIntensity(dateRangeWithoutWE)),
  );
};

export default reducer;
