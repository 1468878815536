import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { api } from '../../api/api';
import FormikAutocomplete from '../formik/FormikAutocomplete';

const ContactPerson = (props) => {
  const { status } = props;

  const [employeesList, setEmployeesList] = useState([]);

  useEffect(() => {
    let mounted = true;
    api
      .get('api/users')
      .then((res) => {
        if (mounted) {
          setEmployeesList(res.data);
        }
      });
    return () => {
      mounted = false;
    };
  }, []);

  return (
    <FormikAutocomplete
      options={employeesList}
      name="Contact"
      getOptionLabel={(option) => (option ? `${option.first_name} ${option.last_name}` : '')}
      cleardisabled="true"
      placehold="Select the contact person"
      status={status}
    />
  );
};

ContactPerson.propTypes = {
  status: PropTypes.string.isRequired,
};

export default ContactPerson;
