/* eslint-disable react/prop-types */
import React from 'react';
import {
  Box, Button, Divider, makeStyles, TextField,
} from '@material-ui/core';
import TodayIcon from '@material-ui/icons/Today';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import SelectTime from '../../../commonComponents/SelectTime';
import TimeAxis from './TimeAxis';
import { reset } from '../../../redux/ForecastingsUserSlice';

const useStyles = makeStyles(() => ({
  button: {
    backgroundColor: 'white',
    height: '40px',
    boxShadow: 'none',
    textTransform: 'none',
    width: '120px',
    border: '1px solid rgba(0, 0, 0, 0.15)',
  },
}));

const Filters = (props) => {
  const {
    timeUnit, setTimeUnit, startDate, setStartDate, endDate, setEndDate, edit,
  } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const backToToday = () => {
    setStartDate(moment(new Date()).subtract(6, `${timeUnit}s`).startOf(timeUnit));
    setEndDate(moment(new Date()).add(6, `${timeUnit}s`).endOf(timeUnit));
  };

  const handleDatesChange = (e) => {
    const selectedDate = e.target.value !== '' ? e.target.value : moment();
    setStartDate(moment(selectedDate).subtract(6, `${timeUnit}s`).startOf(`${timeUnit}s`));
    setEndDate(moment(selectedDate).add(6, `${timeUnit}s`).endOf(`${timeUnit}s`));
  };

  const onChangeTimeUnit = (newTimeUnit) => {
    dispatch(reset());
    setTimeUnit(newTimeUnit);
    setStartDate(moment().subtract(6, `${newTimeUnit}s`).startOf(`${newTimeUnit}s`));
    setEndDate(moment().add(6, `${newTimeUnit}s`).endOf(`${newTimeUnit}s`));
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" marginTop="24px" marginBottom="20px" paddingLeft="32px" paddingRight="32px">
        <SelectTime
          timeUnit={timeUnit}
          setTimeUnit={onChangeTimeUnit}
        />
        <Box display="flex" gridGap="1rem">
          <Button variant="contained" className={classes.button} startIcon={<TodayIcon />} onClick={backToToday}>
            Today
          </Button>
          <TextField
            inputProps={{ style: { height: '40px', width: '130px', padding: '0px 16px' } }}
            variant="outlined"
            type="date"
            onChange={handleDatesChange}
            id="dateSelectorFilterForecastings"
          />
        </Box>
      </Box>
      <Divider style={{ marginLeft: '32px', marginRight: '32px' }} />
      {/* <TimeAxis /> */}
      <TimeAxis
        startDate={startDate}
        endDate={endDate}
        timeUnit={timeUnit}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        edit={edit}
      />

    </Box>
  );
};

Filters.defaultProps = {
  edit: false,
};

Filters.propTypes = {
  timeUnit: PropTypes.oneOf(['day', 'week', 'month']).isRequired,
  startDate: PropTypes.shape().isRequired, // Moment object
  endDate: PropTypes.shape().isRequired, // Moment object
  setStartDate: PropTypes.func.isRequired,
  setEndDate: PropTypes.func.isRequired,
  edit: PropTypes.bool,
};

export default Filters;
