import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import CheckBoxFilterSelf from '../ListEmployees/CheckBoxFilterSelf';
import StatusActivities from './StatusActivities';

const useStyles = makeStyles(() => ({
  icon: {
    marginLeft: '10px',
  },
}));

const CompletedActivities = (props) => {
  const classes = useStyles();

  const {
    setValueNameActivity, setProjectsFilters, projectsFilters, projectsNames, setStatus, status,
  } = props;

  return (
    <div id="filtersTitles">
      <TextField
        id="fieldNameFiltersCollList"
        onChange={(event) => setValueNameActivity(event.target.value)}
        placeholder="Search"
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon className={classes.icon} />
            </InputAdornment>
          ),
        }}
      />
      <CheckBoxFilterSelf
        data={projectsNames}
        itemsFilters={projectsFilters}
        setItemsFilters={setProjectsFilters}
        word="Project"
      />
      <StatusActivities status={status} setStatus={setStatus} />
    </div>
  );
};

CompletedActivities.propTypes = {
  setStatus: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  setProjectsFilters: PropTypes.func.isRequired,
  setValueNameActivity: PropTypes.func.isRequired,
  projectsNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  projectsFilters: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default CompletedActivities;
