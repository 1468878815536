/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import Slider from '@material-ui/core/Slider';

const FormikSlider = ({
  values,
  name, setFieldValue,
  colorBar, defaultValue, ...props
}) => (
  <Slider
    style={{ color: colorBar, width: '95%' }}
    name={name}
    value={values[name] ?? defaultValue} // we take values.name OR defaultvalue
    onChange={(e, v) => setFieldValue(name, v)}
    defaultValue={defaultValue}
    {...props}
  />
);

FormikSlider.defaultProps = {
  defaultValue: 50,
  colorBar: 'grey',
};

FormikSlider.propTypes = {
  defaultValue: PropTypes.number,
  setFieldValue: PropTypes.func.isRequired,
  colorBar: PropTypes.string,
  name: PropTypes.string.isRequired,
};

export default FormikSlider;
