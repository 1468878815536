/* eslint-disable react/prop-types */
import React from 'react';
import { TextField } from '@material-ui/core';
import { Field } from 'formik';
import PropTypes from 'prop-types';

const CustomNumberField = ({
  field,
  ...props
}) => (
  <>
    <TextField
      {...field}
      {...props}
      variant="outlined"
      size="small"
      fullWidth
      type="number"
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        inputProps: {
          type: 'number',
          min: 1,
        },
      }}
    />
  </>
);

const NumberFieldFormik = ({
  name, validate,
}) => (
  <Field
    name={name}
    component={CustomNumberField}
    validate={validate}
  />
);

NumberFieldFormik.defaultProps = {
  validate: () => {},
};

NumberFieldFormik.propTypes = {
  name: PropTypes.string.isRequired,
  validate: PropTypes.func,
};

export default NumberFieldFormik;
