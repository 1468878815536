/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import groupBy from 'lodash/groupBy';
import uniq from 'lodash/uniq';
import moment from 'moment';
import Avatar from '@material-ui/core/Avatar';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Box } from '@material-ui/core';
import HorizontalBarTotalIntensity from './HorizontalBarTotalIntensity';
import TimeAxis from './TimeAxis';
import GridPositioner from './GridPositioner';
import HorizontalBarProject from './HorizontalBarProject';
import LoadingAnimation from '../loaders/LoadingAnimation';
import { api } from '../../api/api';
import profileIcon from '../../ressources/profile-icon.png';
import ProjectNameBox from '../ProfilePage/Workload/ProjectNameBox';

const useStyles = makeStyles(() => ({
  root: {
    width: '75%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  panelSummary: {
    flexDirection: 'row-reverse',
  },
  goodToDisplayComponent: {
    position: 'relative',
    zIndex: 2,
  },
  accordion: {
    width: '100%',
    height: '100%',
    padding: '0px 16px',
    border: 'none',
    boxShadow: 'none',
  },
  rootAccordion: {
    paddingLeft: 0,
    margin: '0px 0px 16px 0px',
  },
  rootDetailsProjects: {
    marginTop: '0px',
    paddingTop: '0px',
  },
  verticalLine: {
    width: '2px',
    backgroundColor: 'red',
    height: '100%',
  },
}));

const GridForecasting = (props) => {
  const {
    forecastings,
    all,
    projectOneManager,
    searchUser,
    workers,
    setAll,
    startDate, endDate, timeUnit, setStartDate, setEndDate, setUpdateForecasting,
  } = props;
  const classes = useStyles();
  const [collaborators, setCollaborators] = useState([]);
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);
  useEffect(() => {
    setAll(true);
  }, []);
  // We cluster the entries for each user in only one array
  const groupedByUserId = groupBy(forecastings, 'UserId');
  useEffect(() => {
    // We create an array with all the UserId we have in the original request of forecastings
    const userIds = Object.keys(groupedByUserId);
    if (userIds.length > 0) {
      api
        .get(`api/users?UsersIds=${userIds.join(',')}`)
        .then((res) => res.data)
        .then((data) => setCollaborators(data));
    }
  }, [forecastings]);

  useEffect(() => {
    const filtered = workers.filter((user) => user.first_name.toLowerCase().includes(searchUser.toLowerCase())
      || user.last_name.toLowerCase().includes(searchUser.toLowerCase()));
    setFilteredUsers(filtered);
  }, [searchUser, workers]);

  useEffect(() => {
    // Lodash "uniq" removes duplicates from an array
    const projectIds = forecastings
    && uniq(forecastings.map((item) => item.ProjectId)).filter((el) => el != null);

    if (projectIds.length > 0) {
      setIsLoading(true);
      api.get(`api/projects?ProjectsIds=${projectIds.join(',')}`)
        .then((res) => setProjects(res.data));
      setIsLoading(false);
    }
  }, [forecastings]);

  let goodToDisplay;
  if (isLoading) {
    goodToDisplay = <LoadingAnimation />;
  } else if (forecastings.length === 0) {
    goodToDisplay = (
      <Box display="flex" justifyContent="center">
        <Typography variant="h4" style={{ marginTop: '2rem' }}>No forecastings for this period</Typography>
      </Box>
    );
  } else if (searchUser.length > 1) {
    goodToDisplay = (
      filteredUsers.map((user) => {
        const forecastingsForUser = groupedByUserId[user.id];
        const groupedByProject = groupBy(forecastingsForUser, 'ProjectId');
        let goodFormat;
        if (timeUnit === 'day') {
          goodFormat = 'YYYY-MM-DD';
        } else if (timeUnit === 'week') {
          goodFormat = 'W';
        } else if (timeUnit === 'month') {
          goodFormat = 'MM';
        }

        const groupedByTimeUnit = groupBy(forecastingsForUser,
          (f) => moment(f.date).format(goodFormat));

        const totalIntensitiesUser = Object.values(groupedByTimeUnit)
          .map((value) => value.reduce((acc, currentValue) => ({
            intensity: acc.intensity + currentValue.intensity,
            date: currentValue.date,
            UserId: currentValue.UserId,
            ProjectId: currentValue.ProjectId,
            ManagerId: currentValue.ManagerId,
            absence: currentValue.absence,
          }), { intensity: 0 }));
        // const forecastingsForUser = groupedByUserId[user.id];
        return (
          <div key={user.id} style={{ position: 'relative' }}>
            <div id="userForecasting">
              <Accordion className={classes.accordion} defaultExpanded>
                <AccordionSummary
                  className={classes.panelSummary}
                  expandIcon={<ExpandMoreIcon />}
                  classes={{ root: classes.rootAccordion }}
                >
                  <div id="userIdentityForecastings">
                    <Link to={`/employees/${user.id}`}>
                      <Avatar alt="Remy Sharp" src={user.profileImagePath ? `${process.env.REACT_APP_BACK_URL}/api/users/profileImage/${user.profileImagePath}` : profileIcon} />
                    </Link>
                    <div>
                      <p>
                        {user.first_name}
                        {' '}
                        {user.last_name}
                      </p>
                      <p>{user.position}</p>
                    </div>
                  </div>
                  <div style={{ gridTemplateColumns: `repeat(${endDate.diff(startDate, `${timeUnit}s`) + 1}, 1fr)`, zIndex: 2 }}>
                    {totalIntensitiesUser.map((f, i, a) => {
                      let isDouble = false;
                      let absent = false;
                      if (f.absence === 1 && timeUnit === 'day') {
                        absent = true;
                        if (i > 0) {
                          const dateVeille = a[i - 1].date ? moment(a[i - 1].date) : undefined;
                          const dateJour = moment(f.date);
                          if (f.absence === a[i - 1].absence && (dateJour.diff(dateVeille, `${timeUnit}s`) === 1)) {
                            isDouble = true;
                          }
                        }
                        return (
                          <div style={{ display: 'flex', alignItems: 'center', zIndex: 1 }}>
                            <GridPositioner
                              key={f.date}
                              startDate={moment(f.date)}
                              row={1}
                              gridStartDate={startDate}
                              timeUnit={timeUnit}
                              className={classes.goodToDisplayComponent}
                            >
                              <div style={{ position: 'relative', zIndex: 2 }}>
                                <HorizontalBarTotalIntensity word="Vaca" absent={absent} isDouble={isDouble} />
                              </div>
                            </GridPositioner>
                          </div>
                        );
                      }
                      if (f.absence === 2 && timeUnit === 'day') {
                        absent = true;
                        if (i > 0) {
                          const dateVeille = a[i - 1].date ? moment(a[i - 1].date) : undefined;
                          const dateJour = moment(f.date);
                          if (f.absence === a[i - 1].absence && (dateJour.diff(dateVeille, `${timeUnit}s`) === 1)) {
                            isDouble = true;
                          }
                        }
                        return (
                          <div style={{ display: 'flex', alignItems: 'center', zIndex: 1 }}>
                            <GridPositioner
                              key={f.date}
                              startDate={moment(f.date)}
                              row={1}
                              gridStartDate={startDate}
                              timeUnit={timeUnit}
                              className={classes.goodToDisplayComponent}
                            >
                              <div style={{ position: 'relative', zIndex: 2 }}>
                                <HorizontalBarTotalIntensity word="Sick" absent={absent} isDouble={isDouble} />
                              </div>
                            </GridPositioner>
                          </div>
                        );
                      }
                      if (i > 0) {
                        const dateVeille = a[i - 1].date ? moment(a[i - 1].date) : undefined;
                        const dayBefore = moment(f.date).subtract(1, 'days').format('DD-MMM-yyyy');
                        const searchPreviousDate = a.find((item) => moment(item.date).format('DD-MMM-yyyy') === dayBefore);
                        const dateJour = moment(f.date);

                        if (typeof searchPreviousDate !== 'undefined' && (dateJour.diff(dateVeille, `${timeUnit}s`) === 1) && f.intensity === a[i - 1].intensity) {
                          isDouble = true;
                        }
                      }
                      return (
                        <>
                          <GridPositioner
                            key={f.date}
                            startDate={moment(f.date)}
                            row={1}
                            gridStartDate={startDate}
                            timeUnit={timeUnit}
                            className={classes.goodToDisplayComponent}
                          >
                            <div style={{ position: 'relative', zIndex: 2 }}>
                              <HorizontalBarTotalIntensity
                                totalIntensity={f.intensity}
                                isDouble={isDouble}
                              />
                            </div>
                          </GridPositioner>
                        </>
                      );
                    })}
                  </div>

                </AccordionSummary>
                <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr' }}>
                  <div style={{ width: '250px', display: 'grid', gridTemplateRows: 'grid' }}>
                    {Object.values(groupedByProject).map((fcs, indexProject) => {
                      const projectName = projects.find((p) => p.id === fcs[0].ProjectId)?.project_name;
                      if (projectName) {
                        return (
                          <div
                            style={{
                              color: 'grey', display: 'flex', justifyContent: 'flex-end', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '100%',
                            }}
                            key={projectName}
                          >
                            {projectName}
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                  <div>
                    <AccordionDetails classes={{ root: classes.rootDetailsProjects }}>
                      <div style={{
                        gridTemplateColumns: `repeat(${endDate.diff(startDate, `${timeUnit}s`) + 1}, 1fr)`,
                        width: '100%',
                        display: 'grid',
                        gridGap: '4px',
                        gridAutoRows: 'minmax(2rem, auto)',
                        alignItems: 'center',
                        zIndex: 2,
                      }}
                      >
                        { Object.values(groupedByProject)
                          .map((fcs, indexProject) => {
                            const projectName = projects.find((p) => p.id === fcs[0].ProjectId)?.project_name;
                            return (
                              <>
                                {fcs.map((f, i, a) => {
                                  let isDoubleProject = false;

                                  if (i > 0) {
                                    const dateVeille = a[i - 1].date ? moment(a[i - 1].date) : undefined;
                                    const dateJour = moment(f.date);
                                    if (f.intensity === a[i - 1].intensity && (dateJour.diff(dateVeille, `${timeUnit}s`) === 1)) {
                                      isDoubleProject = true;
                                    }
                                  }

                                  return f.absence === 0 ? (
                                    <GridPositioner
                                      key={f.id}
                                      startDate={moment(f.date)}
                                      row={indexProject + 1}
                                      gridStartDate={startDate}
                                      timeUnit={timeUnit}
                                      className={classes.goodToDisplayComponent}
                                    >
                                      <HorizontalBarProject
                                        idProject={f.ProjectId}
                                        idManager={f.ManagerId}
                                        idUser={f.UserId}
                                        intensity={f.intensity}
                                        isDoubleProject={isDoubleProject}
                                        colorBorder={projects.find((proj) => proj.id === f.ProjectId)?.color}
                                        setUpdateForecasting={setUpdateForecasting}
                                      />
                                    </GridPositioner>
                                  ) : '';
                                })}
                              </>
                            );
                          })}
                      </div>
                    </AccordionDetails>
                  </div>
                </div>
              </Accordion>

            </div>
            <hr />
          </div>
        );
      })
    );
  } else if (all === true) {
    goodToDisplay = (
      workers.map((user) => {
        const forecastingsForUser = groupedByUserId[user.id];
        const groupedByProject = groupBy(forecastingsForUser, 'ProjectId');
        let goodFormat;
        if (timeUnit === 'day') {
          goodFormat = 'YYYY-MM-DD';
        } else if (timeUnit === 'week') {
          goodFormat = 'W';
        } else if (timeUnit === 'month') {
          goodFormat = 'MM';
        }

        const groupedByTimeUnit = groupBy(forecastingsForUser,
          (f) => moment(f.date).format(goodFormat));

        const totalIntensitiesUser = Object.values(groupedByTimeUnit)
          .map((value) => value.reduce((acc, currentValue) => ({
            intensity: acc.intensity + currentValue.intensity,
            date: currentValue.date,
            UserId: currentValue.UserId,
            ProjectId: currentValue.ProjectId,
            ManagerId: currentValue.ManagerId,
            absence: currentValue.absence,
          }), { intensity: 0 }));
        // const forecastingsForUser = groupedByUserId[user.id];
        return (
          <div key={user.id} style={{ position: 'relative' }}>
            <div id="userForecasting">
              <Accordion className={classes.accordion} defaultExpanded>
                <AccordionSummary
                  className={classes.panelSummary}
                  expandIcon={<ExpandMoreIcon />}
                  classes={{ root: classes.rootAccordion }}
                >
                  <div id="userIdentityForecastings">
                    <Link to={`/employees/${user.id}`}>
                      <Avatar alt="Remy Sharp" src={user.profileImagePath ? `${process.env.REACT_APP_BACK_URL}/api/users/profileImage/${user.profileImagePath}` : profileIcon} />
                    </Link>
                    <div>
                      <p>
                        {user.first_name}
                        {' '}
                        {user.last_name}
                      </p>
                      <p>{user.position}</p>
                    </div>
                  </div>
                  <div style={{ gridTemplateColumns: `repeat(${endDate.diff(startDate, `${timeUnit}s`) + 1}, 1fr)`, zIndex: 2 }}>
                    {totalIntensitiesUser.map((f, i, a) => {
                      let isDouble = false;
                      let absent = false;
                      if (f.absence === 1 && timeUnit === 'day') {
                        absent = true;
                        if (i > 0) {
                          const dateVeille = a[i - 1].date ? moment(a[i - 1].date) : undefined;
                          const dateJour = moment(f.date);
                          if (f.absence === a[i - 1].absence && (dateJour.diff(dateVeille, `${timeUnit}s`) === 1)) {
                            isDouble = true;
                          }
                        }
                        return (
                          <div style={{ display: 'flex', alignItems: 'center', zIndex: 1 }}>
                            <GridPositioner
                              key={f.date}
                              startDate={moment(f.date)}
                              row={1}
                              gridStartDate={startDate}
                              timeUnit={timeUnit}
                              className={classes.goodToDisplayComponent}
                            >
                              <div style={{ position: 'relative', zIndex: 2 }}>
                                <HorizontalBarTotalIntensity word="Vaca" absent={absent} isDouble={isDouble} />
                              </div>
                            </GridPositioner>
                          </div>
                        );
                      }
                      if (f.absence === 2 && timeUnit === 'day') {
                        absent = true;
                        if (i > 0) {
                          const dateVeille = a[i - 1].date ? moment(a[i - 1].date) : undefined;
                          const dateJour = moment(f.date);
                          if (f.absence === a[i - 1].absence && (dateJour.diff(dateVeille, `${timeUnit}s`) === 1)) {
                            isDouble = true;
                          }
                        }
                        return (
                          <div style={{ display: 'flex', alignItems: 'center', zIndex: 1 }}>
                            <GridPositioner
                              key={f.date}
                              startDate={moment(f.date)}
                              row={1}
                              gridStartDate={startDate}
                              timeUnit={timeUnit}
                              className={classes.goodToDisplayComponent}
                            >
                              <div style={{ position: 'relative', zIndex: 2 }}>
                                <HorizontalBarTotalIntensity word="Sick" absent={absent} isDouble={isDouble} />
                              </div>
                            </GridPositioner>
                          </div>
                        );
                      }
                      if (i > 0) {
                        const dateVeille = a[i - 1].date ? moment(a[i - 1].date) : undefined;
                        const dayBefore = moment(f.date).subtract(1, 'days').format('DD-MMM-yyyy');
                        const searchPreviousDate = a.find((item) => moment(item.date).format('DD-MMM-yyyy') === dayBefore);
                        const dateJour = moment(f.date);

                        if (typeof searchPreviousDate !== 'undefined' && (dateJour.diff(dateVeille, `${timeUnit}s`) === 1) && f.intensity === a[i - 1].intensity) {
                          isDouble = true;
                        }
                      }
                      return (
                        <>
                          <GridPositioner
                            key={f.date}
                            startDate={moment(f.date)}
                            row={1}
                            gridStartDate={startDate}
                            timeUnit={timeUnit}
                            className={classes.goodToDisplayComponent}
                          >
                            <div style={{ position: 'relative', zIndex: 2 }}>
                              <HorizontalBarTotalIntensity
                                totalIntensity={f.intensity}
                                isDouble={isDouble}
                              />
                            </div>
                          </GridPositioner>
                        </>
                      );
                    })}
                  </div>

                </AccordionSummary>
                <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr' }}>
                  <div style={{ width: '250px', display: 'grid', gridTemplateRows: 'grid' }}>
                    {Object.values(groupedByProject).map((fcs, indexProject) => {
                      const projectName = projects.find((p) => p.id === fcs[0].ProjectId)?.project_name;
                      if (projectName) {
                        return (
                          <div
                            style={{
                              color: 'grey', display: 'flex', justifyContent: 'flex-end', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '100%',
                            }}
                            key={projectName}
                          >
                            {projectName}
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                  <div>
                    <AccordionDetails classes={{ root: classes.rootDetailsProjects }}>
                      <div style={{
                        gridTemplateColumns: `repeat(${endDate.diff(startDate, `${timeUnit}s`) + 1}, 1fr)`,
                        width: '100%',
                        display: 'grid',
                        gridGap: '4px',
                        gridAutoRows: 'minmax(2rem, auto)',
                        alignItems: 'center',
                        zIndex: 2,
                      }}
                      >
                        { Object.values(groupedByProject)
                          .map((fcs, indexProject) => {
                            const projectName = projects.find((p) => p.id === fcs[0].ProjectId)?.project_name;
                            return (
                              <>
                                {fcs.map((f, i, a) => {
                                  let isDoubleProject = false;

                                  if (i > 0) {
                                    const dateVeille = a[i - 1].date ? moment(a[i - 1].date) : undefined;
                                    const dateJour = moment(f.date);
                                    if (f.intensity === a[i - 1].intensity && (dateJour.diff(dateVeille, `${timeUnit}s`) === 1)) {
                                      isDoubleProject = true;
                                    }
                                  }

                                  return f.absence === 0 ? (
                                    <GridPositioner
                                      key={f.id}
                                      startDate={moment(f.date)}
                                      row={indexProject + 1}
                                      gridStartDate={startDate}
                                      timeUnit={timeUnit}
                                      className={classes.goodToDisplayComponent}
                                    >
                                      <HorizontalBarProject
                                        idProject={f.ProjectId}
                                        idManager={f.ManagerId}
                                        idUser={f.UserId}
                                        intensity={f.intensity}
                                        isDoubleProject={isDoubleProject}
                                        colorBorder={projects.find((proj) => proj.id === f.ProjectId)?.color}
                                        setUpdateForecasting={setUpdateForecasting}
                                      />
                                    </GridPositioner>
                                  ) : '';
                                })}
                              </>
                            );
                          })}
                      </div>
                    </AccordionDetails>
                  </div>
                </div>
              </Accordion>

            </div>
            <hr />
          </div>
        );
      })
    );
  } else if (forecastings.length === 0) {
    goodToDisplay = (
      <Box display="flex" justifyContent="center">
        <Typography variant="h4" style={{ marginTop: '2rem' }}>No forecastings for this period</Typography>
      </Box>
    );
  } else if ((forecastings.length > 0)) {
    goodToDisplay = (
      forecastings.map((user) => {
        const forecastingsForUser = groupedByUserId[user.id];
        const groupedByProject = groupBy(forecastingsForUser, 'ProjectId');

        let goodFormat;
        if (timeUnit === 'day') {
          goodFormat = 'YYYY-MM-DD';
        } else if (timeUnit === 'week') {
          goodFormat = 'W';
        } else if (timeUnit === 'month') {
          goodFormat = 'MM';
        }

        const groupedByTimeUnit = groupBy(forecastingsForUser,
          (f) => moment(f.date).format(goodFormat));

        const totalIntensitiesUser = Object.values(groupedByTimeUnit)
          .map((value) => value.reduce((acc, currentValue) => ({
            intensity: acc.intensity + currentValue.intensity,
            date: currentValue.date,
            UserId: currentValue.UserId,
            ProjectId: currentValue.ProjectId,
            ManagerId: currentValue.ManagerId,
            absence: currentValue.absence,
          }), { intensity: 0 }));
        // const forecastingsForUser = groupedByUserId[user.id];
        // const groupedByProject = groupBy(forecastingsForUser, 'ProjectId');

        return (
          <div key={user.id}>
            <div id="userForecasting">

              <Accordion className={classes.accordion} defaultExpanded>
                <AccordionSummary
                  className={classes.panelSummary}
                  expandIcon={<ExpandMoreIcon />}
                  classes={{ root: classes.rootAccordion }}
                >
                  <div id="userIdentityForecastings">
                    <Link to={`/employees/${user.id}`}>
                      <Avatar alt="Remy Sharp" src={user.profileImagePath ? `${process.env.REACT_APP_BACK_URL}/api/users/profileImage/${user.profileImagePath}` : profileIcon} />
                    </Link>
                    <div>
                      <p>
                        {user.first_name}
                        {' '}
                        {user.last_name}
                      </p>
                      <p>{user.position}</p>
                    </div>
                  </div>
                  <div style={{ gridTemplateColumns: `repeat(${endDate.diff(startDate, `${timeUnit}s`) + 1}, 1fr)`, zIndex: 2 }}>
                    {totalIntensitiesUser.map((f, i, a) => {
                      let isDouble = false;

                      let absent = false;

                      if (f.absence === 1 && timeUnit === 'day') {
                        absent = true;
                        if (i > 0) {
                          const dateVeille = a[i - 1].date ? moment(a[i - 1].date) : undefined;
                          const dateJour = moment(f.date);
                          if (f.absence === a[i - 1].absence && (dateJour.diff(dateVeille, `${timeUnit}s`) === 1)) {
                            isDouble = true;
                          }
                        }
                        return (
                          <GridPositioner
                            key={f.date}
                            startDate={moment(f.date)}
                            row={1}
                            gridStartDate={startDate}
                            timeUnit={timeUnit}
                          >
                            <HorizontalBarTotalIntensity word="Vaca" absent={absent} isDouble={isDouble} />
                          </GridPositioner>
                        );
                      }

                      if (f.absence === 2 && timeUnit === 'day') {
                        absent = true;
                        if (i > 0) {
                          const dateVeille = a[i - 1].date ? moment(a[i - 1].date) : undefined;
                          const dateJour = moment(f.date);
                          if (f.absence === a[i - 1].absence && (dateJour.diff(dateVeille, `${timeUnit}s`) === 1)) {
                            isDouble = true;
                          }
                        }
                        return (
                          <GridPositioner
                            key={f.date}
                            startDate={moment(f.date)}
                            row={1}
                            gridStartDate={startDate}
                            timeUnit={timeUnit}
                          >
                            <HorizontalBarTotalIntensity word="Sick" absent={absent} isDouble={isDouble} />
                          </GridPositioner>
                        );
                      }

                      if (i > 0) {
                        const dateVeille = a[i - 1].date ? moment(a[i - 1].date) : undefined;
                        const dayBefore = moment(f.date).subtract(1, 'days').format('DD-MMM-yyyy');
                        const searchPreviousDate = a.find((item) => moment(item.date).format('DD-MMM-yyyy') === dayBefore);
                        const dateJour = moment(f.date);

                        if (typeof searchPreviousDate !== 'undefined' && (dateJour.diff(dateVeille, `${timeUnit}s`) === 1) && f.intensity === a[i - 1].intensity) {
                          isDouble = true;
                        }
                      }
                      return (
                        <GridPositioner
                          key={f.date}
                          startDate={moment(f.date)}
                          row={1}
                          gridStartDate={startDate}
                          timeUnit={timeUnit}
                        >
                          <HorizontalBarTotalIntensity
                            totalIntensity={f.intensity}
                            isDouble={isDouble}
                          />
                        </GridPositioner>
                      );
                    })}

                  </div>

                </AccordionSummary>
                <AccordionDetails classes={{ root: classes.rootDetailsProjects }}>

                  <div style={{ width: '24%' }} />

                  <div style={{
                    gridTemplateColumns: `repeat(${endDate.diff(startDate, `${timeUnit}s`) + 1}, 1fr)`, width: '100%', display: 'grid', gridGap: '4px', gridAutoRows: 'minmax(2rem, auto)', alignItems: 'center', zIndex: 2,
                  }}
                  >
                    { Object.values(groupedByProject)
                      .map((fcs, indexProject) => fcs.map((f, i, a) => {
                        let isDoubleProject = false;

                        if (i > 0) {
                          const dateVeille = a[i - 1].date ? moment(a[i - 1].date) : undefined;
                          const dateJour = moment(f.date);
                          if (f.intensity === a[i - 1].intensity && (dateJour.diff(dateVeille, `${timeUnit}s`) === 1)) {
                            isDoubleProject = true;
                          }
                        }

                        return f.absence === 0 ? (
                          <GridPositioner
                            key={f.id}
                            startDate={moment(f.date)}
                            row={indexProject + 1}
                            gridStartDate={startDate}
                            timeUnit={timeUnit}
                          >
                            <HorizontalBarProject
                              idProject={f.ProjectId}
                              idManager={f.ManagerId}
                              idUser={f.UserId}
                              intensity={f.intensity}
                              isDoubleProject={isDoubleProject}
                              colorBorder={projects.find((proj) => proj.id === f.ProjectId)?.color}
                              setUpdateForecasting={setUpdateForecasting}
                            />
                          </GridPositioner>
                        ) : '';
                      }))}

                  </div>
                </AccordionDetails>
              </Accordion>

            </div>
            <hr />
          </div>
        );
      })
    );
  }

  return (
    <>
      <div>
        <TimeAxis
          startDate={startDate}
          endDate={endDate}
          timeUnit={timeUnit}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      </div>
      <div style={{ position: 'relative' }}>
        {goodToDisplay}
      </div>
    </>
  );
};

GridForecasting.propTypes = {
  setAll: PropTypes.func.isRequired,
  searchUser: PropTypes.string.isRequired,
  all: PropTypes.bool.isRequired,
  forecastings: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  timeUnit: PropTypes.oneOf(['day', 'week', 'month']).isRequired,
  startDate: PropTypes.shape().isRequired, // Moment object
  endDate: PropTypes.shape().isRequired, // Moment object
  setStartDate: PropTypes.func.isRequired,
  setEndDate: PropTypes.func.isRequired,
  setUpdateForecasting: PropTypes.func.isRequired,
  workers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  projectOneManager: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default GridForecasting;
