import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import NotesForm from './NotesForm';
import NotesBoard from './NotesBoard';
import { api } from '../../../api/api';

const useStyles = makeStyles(() => ({
  titleText: {
    border: 'none',
    fontFamily: 'Roboto',
    width: '100%',
    paddingBottom: '10px',
    fontSize: '20px !important',
    fontWeight: '700 !important',
    '&:focus': {
      border: 'none',
      outline: 'none',
    },
  },
  descriptionProject: {
    width: '100%',
    height: '168px',
    border: 'none',
    borderRadius: '8px',
    fontFamily: 'Roboto',
    resize: 'none',
    fontSize: '16px',
    paddingBottom: '10px',
    '&:focus': {
      border: 'none',
      outline: 'none',
    },
  },
  noteBox: {
    overflowY: 'hidden',
    border: '1px solid rgba(0, 0, 0, 0.05)',
    boxShadow: '2px 4px 7px 1px rgba(0, 0, 0, 0.05)',
    borderRadius: '12px',
    padding: '24px 40px 16px 40px',
  },
}));

const Notes = ({ ProjectId }) => {
  const [notes, setNotes] = useState([]);
  const [trigger, setTrigger] = useState(false);
  const [open, setOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    api
      .get(`api/comments?project=${ProjectId}`)
      .then((res) => setNotes(res.data));
    setTrigger(false);
  }, [ProjectId, trigger, reload]);
  return (
    <>
      <div id="leftSection3" className="sections">
        <div>
          <NotesForm
            open={open}
            setOpen={setOpen}
            setTrigger={setTrigger}
            ProjectId={ProjectId}
            useStyles={useStyles}
            reload={reload}
            setReload={setReload}
            setComments={setNotes}
            numberNotes={notes.length}
            notes={notes}
            setNotes={setNotes}
            setSearchTerm={setSearchTerm}
          />
        </div>
        <div style={{
          overflowY: 'hidden', display: 'flex', flexDirection: 'column', gap: '24px', paddingBottom: '24px',
        }}
        >
          {notes.filter((n) => (searchTerm
            ? n.title.toLowerCase().includes(searchTerm.toLowerCase())
          || n.comment.toLowerCase().includes(searchTerm.toLowerCase())
          || n.User.first_name.toLowerCase().includes(searchTerm.toLowerCase())
          || n.User.last_name.toLowerCase().includes(searchTerm.toLowerCase())
            : n))
            .map((note) => (
              <NotesBoard
                key={note.id}
                note={note}
                useStyles={useStyles}
                notes={notes}
                setNotes={setNotes}
              />
            ))}

        </div>
      </div>
    </>
  );
};

Notes.propTypes = {
  ProjectId: PropTypes.number.isRequired,
};

export default Notes;
