import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { api } from '../../api/api';
import FormikAutocomplete from '../formik/FormikAutocomplete';

const DeliveryLead = (props) => {
  const { status, label } = props;

  const [deliveryLeadsList, setdeliveryLeadssList] = useState([]);

  useEffect(() => {
    let mounted = true;
    api
      .get('api/users?RolesIds=1,2,3')
      .then((res) => {
        if (mounted) {
          setdeliveryLeadssList(res.data);
        }
      });
    return () => {
      mounted = false;
    };
  }, []);

  return (
    <Box>
      {status === 'createProject' ? (
        <Box display="flex" className="formFieldLabel" gridGap="5px">
          <label htmlFor="clientDemand" className="labelsFormCreate">Delivery Lead</label>
        </Box>
      ) : ''}
      <FormikAutocomplete
        options={deliveryLeadsList}
        name="DeliveryLead"
        getOptionLabel={(option) => (option ? `${option.first_name} ${option.last_name}` : '')}
        disableClearable
        placehold="Select a delivery lead"
        status={status}
        label={label}
      />
    </Box>
  );
};

DeliveryLead.propTypes = {
  status: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default DeliveryLead;
