/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import moment from 'moment';
import MobileStepper from '@material-ui/core/MobileStepper';
import { Box } from '@material-ui/core';
import { ButtonCustom, ButtonCustomFormik } from '../../commonComponents/ButtonCustom';

const useStyles = makeStyles(() => ({
  steps: {
    flexGrow: 1,
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '32px',
  },
  buttonStep: {
    width: '98px',
    height: '46px',
    backgroundColor: '#373A36',
    color: 'white',
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: '500',
    '&:hover': {
      backgroundColor: 'rgb(255, 184, 28)',
      color: '#373A36',
    },
  },
  buttonStepBack: {
    color: 'rgba(112, 121, 135, 1)',
    fontSize: '16px',
    fontWeight: '400',
    textTransform: 'none',
  },
}));

const renderButtons = (activeStep, classes, handleNext, handleBack, setShallNotPass,
  setValidationContact, values) => {
  const buttonToDisplay = (
    <ButtonCustomFormik variant="large" text="Save" />
    // <ButtonCustom variant="large" text="Save" event={() => setValidationContact(false)} />
  );
  // }

  const backButton = <Button startIcon={<ArrowBackIosIcon />} size="small" className={classes.buttonStepBack} onClick={handleBack}>Back</Button>;
  const nextButton = <ButtonCustom variant="large" text="Next" event={handleNext} />;
  const createButton = buttonToDisplay;

  switch (activeStep) {
    case 0:
      return (
        <div style={{ textAlign: 'center' }}>{nextButton}</div>);
    case 4:
      return (
        // row-reverse on purpose to fix a bug where the click event
        // on "next" would trigger the form submit
        <Box display="flex" flexDirection="row-reverse" justifyContent="flex-end">
          <Box marginLeft="188px">
            {createButton}
          </Box>
          {backButton}
        </Box>
      );
    default:
      return (
        <Box display="flex">
          {backButton}
          <Box marginLeft="188px">
            {nextButton}
          </Box>
        </Box>
      );
  }
};

const StepperCreationProject = (props) => {
  const {
    activeStep, setActiveStep, validation, setShallNotPass, setValidationContact,
  } = props;
  const classes = useStyles();

  const { values } = useFormikContext();

  const ValideDate = () => moment(values.start_date).diff(values.end_date, 'days') < 1;

  const handleNext = () => {
    if (!validation) {
      setActiveStep(activeStep + 1);
      setShallNotPass(false);
    } else {
      setShallNotPass(true);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const buttons = renderButtons(activeStep, classes, handleNext, handleBack,
    setValidationContact, values);

  return (
    <>
      <MobileStepper
        variant="dots"
        steps={5}
        position="static"
        activeStep={activeStep}
        className={classes.steps}
      />
      {buttons}
    </>
  );
};

StepperCreationProject.propTypes = {
  activeStep: PropTypes.number.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  setValidationContact: PropTypes.func.isRequired,
  validation: PropTypes.bool.isRequired,
  setShallNotPass: PropTypes.func.isRequired,
};

export default StepperCreationProject;
