import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles(() => ({
  root: {
    color: 'black',
  },
  rootSecondary: {
    color: '#FDB71A',
  },
  select: {
    '&:focus': {
      background: 'none',
    },
  },
  selectCustom: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    borderRadius: '8px',
    // width: '194px',
    height: '36px',
  },
}));

const names = [
  { label: '> 100%', val: [101, Infinity] },
  { label: '100%', val: [100, 100] },
  { label: '80% - 99%', val: [80, 99] },
  { label: '50% - 79%', val: [50, 79] },
  { label: '< 50%', val: [0, 49] },
];

const CheckBoxFilterIntensity = ({ intensityFilters, setIntensityFilters }) => {
  const classes = useStyles();
  const [selectNone, setSelectNone] = useState(false);

  const handleChange = (event) => {
    const item = event.target.value;
    if (item.includes('Select All')) {
      setSelectNone(true);
      setIntensityFilters(intensityFilters.length === names.length
        ? [] : names.map((element) => element.val));
    } else if (item.includes('Select None')) {
      setIntensityFilters([]);
    } else {
      setIntensityFilters(event.target.value);
      setSelectNone(false);
    }
  };

  const isAllSelected = intensityFilters.length > 0 && intensityFilters.length === names.length;
  const isNoneSelected = intensityFilters.length === 0;

  return (
    <FormControl fullWidth className={classes.formControl}>
      <Select
        id="filtersSelect"
        multiple
        displayEmpty
        fullWidth
        value={intensityFilters}
        onChange={handleChange}
        input={<Input placeholder="Intensity" />}
        classes={{ select: classes.select }}
        className={classes.selectCustom}
        renderValue={() => 'Intensity'}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: '350px',
            },
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        <MenuItem key="Select All" value="Select All">
          <Checkbox
            color="primary"
            name="Select All"
            checked={isAllSelected}
            classes={{
              root: classes.root,
            }}
          />
          <ListItemText primary="Select All" />
        </MenuItem>
        {selectNone
          ? (
            <MenuItem key="Select None" value="Select None">
              <Checkbox
                color="primary"
                name="Select None"
                indeterminate={isNoneSelected}
                classes={{
                  root: isNoneSelected ? classes.rootSecondary : classes.root,
                }}
              />
              <ListItemText primary="Select None" />
            </MenuItem>
          ) : null}
        {names.map((name) => (
          <MenuItem key={name.label} value={name.val}>
            <Checkbox
              color="primary"
              classes={{ root: classes.root }}
              checked={intensityFilters.indexOf(name.val) > -1}
            />
            <ListItemText primary={name.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

CheckBoxFilterIntensity.propTypes = {
  intensityFilters: PropTypes.arrayOf(PropTypes.arrayOf).isRequired,
  setIntensityFilters: PropTypes.func.isRequired,
};

export default CheckBoxFilterIntensity;
