/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import { Field } from 'formik';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Box } from '@material-ui/core';
import IconType from '../../IconType';
import { ButtonCustom, ButtonSimple } from '../../../commonComponents/ButtonCustom';
import ModalCustom from '../../../commonComponents/ModalCustom';

const useStyles = makeStyles((theme) => ({
  select: {
    display: 'flex',
    alignItems: 'center',
    width: '227px',
    marginLeft: 0,
    borderRadius: '8px',
    color: 'black',
    backgroundColor: 'white',
    background: '#FFFFFF',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.03)',
    '&:focus': {
      backgroundColor: 'white',
      borderRadius: '8px',
    },
  },
  outlined: {
    height: '40px',
  },
  selectSize: {
    height: '48px',
  },
  LabelType: {
    top: '-3px',
  },
}));

const ProjectBudget = (props) => {
  const {
    originalBudget, edit, setFieldValue, setDeleteSnapshots, handleSubmit,
  } = props;

  const [budgetType, setBudgetType] = useState(originalBudget);
  const [openModal, setOpenModal] = useState(false);

  const [tempBudgetType, setTempBudgetType] = useState(originalBudget);
  const classes = useStyles();

  const handleFieldChange = (event) => {
    setFieldValue('time_budget_type', event.target.value);
    setDeleteSnapshots(true);
    setOpenModal(true);
  };

  const handleCancelUpdate = () => {
    setFieldValue('time_budget_type', originalBudget);
    setDeleteSnapshots(false);
    setOpenModal(false);
  };

  return (
    <>
      {edit
        ? (
          <>
            <FormControl variant="outlined">
              <InputLabel classes={{ root: classes.LabelType }}>Budget Type</InputLabel>
              <Select
                className={classes.select}
                label="Budget Type"
                name="time_budget_type"
                value={budgetType}
                onChange={handleFieldChange}
                classes={{
                  select: classes.select,
                  outlined: classes.outlined,
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                  PaperProps: { elevation: 0 },
                }}
                {...props}
              >
                <MenuItem name="time_budget_type" value="Not defined">
                  <IconType type="Not defined" select="select" />
                  Not defined
                </MenuItem>
                <MenuItem value="Time and material">
                  {' '}
                  <IconType type="Time and material" select="select" />
                  Time and material
                </MenuItem>
                <MenuItem value="Work Package">
                  {' '}
                  <IconType type="Work Package" select="select" />
                  Work Package
                </MenuItem>
              </Select>
            </FormControl>
          </>
        )
        : (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconType type={originalBudget || 'Not defined'} />
            <p>{originalBudget || 'Budget not defined'}</p>
          </div>
        )}

      {/* <Dialog
        id="updateBudgetDialog"
        fullWidth
        maxWidth="sm"
        open={openModal}
        onClose={() => setOpenModal(false)}
        PaperProps={{ elevation: 0 }}
      >
        <DialogContent>

        </DialogContent>
      </Dialog> */}

      <ModalCustom
        handleClose={() => setOpenModal(false)}
        open={openModal}
        width="676px"
        title="Budget type change"
        child={(
          <>
            <p>
              <strong>Note: </strong>
              in order to update, the budget type of the project, we would need to delete,
              the existing records, related to the &apos;Time Budget&apos; information
              <br />
              <br />
              No worries - the data will remain in the application (it will just not be shown,
              under the &apos;Time Budget&apos; section - so, if you ever decide, to change
              the budget type, back to its original value, then, that information, will, again
              be visible, under the &apos;Time Budget&apos; tab)
              <br />
              <br />
              That being said - would you like, to proceed, with the budget type change?
            </p>
            <Box display="flex" justifyContent="center" gridGap="1.5rem" marginTop="40px">
              <ButtonCustom
                event={() => handleSubmit()}
                variant="large"
                text="Proceed"
              />
              <ButtonSimple event={() => handleCancelUpdate()} variant="large" text="Cancel" />
            </Box>
          </>
          )}
      />

    </>
  );
};

ProjectBudget.defaultProps = {
  budget: undefined,
};

ProjectBudget.propTypes = {
  edit: PropTypes.bool.isRequired,
  budget: PropTypes.string,
};

export default ProjectBudget;
