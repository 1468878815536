/* eslint-disable react/self-closing-comp */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import { Box, Grid } from '@material-ui/core';
import { api } from '../../api/api';
import { ButtonCustomFormik, ButtonSimple } from '../../commonComponents/ButtonCustom';
import { SelectFormik } from '../../commonComponents/Fields/SelectFormik';
import TextFieldFormik from '../../commonComponents/Fields/TextFieldFormik';

const useStyles = makeStyles((theme) => ({
  collaborators: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '8px',
    boxShadow: theme.shadows[5],
  },
  gridItem: {
    margin: '5px',
  },
}));

// Async validation function
const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// eslint-disable-next-line consistent-return
const validateAsync = (values) => sleep(200).then(() => {
  const errors = {};
  Object.keys(values).forEach((key) => {
    if (values[key] === '') { errors[key] = 'Required field.'; }
  });
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values?.email)) {
    errors.email = 'Invalid email address';
  }
  if (values.email.substr(-'@de.adecco.net'.length) !== '@de.adecco.net') {
    errors.email = 'Email must end with @de.adecco.net';
  }

  // Custom validation for first_name, last_name, and position fields
  ['first_name', 'last_name', 'position'].forEach((field) => {
    if (values[field].length === 20) {
      errors[field] = 'Should be less than 20 characters.';
    }
  });
  return errors;
});

const CustomInput = (props) => {
  const {
    children, name, label, mandatory,
  } = props;
  return (
    <Box>
      <Box display="flex" className="formFieldLabel" gridGap="5px">
        <label htmlFor={name} className="labelsFormCreate">{label}</label>
        {mandatory && (
          <span className="mandatoryInputs">*</span>
        )}
      </Box>
      {children}
      {mandatory && (
      <ErrorMessage
        name={name}
        component="div"
        className="errorMessageCreateCollaborator"
      />
      )}
    </Box>
  );
};

const BodyModalListEmployees = (props) => {
  const { handleClose, setUserUpdated, leadsNames } = props;
  const classes = useStyles();
  const [emailExist, setEmailExist] = useState(false);

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        // eslint-disable-next-line react/destructuring-assignment
        props.handleClose();
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, []);

  const submitting = (values) => {
    const data = { ...values };
    api.post('api/users', data)
      .then(() => handleClose())
      .then(() => setUserUpdated(true))
      .catch((err) => {
      // eslint-disable-next-line no-console
        console.error('Error:', err);
        if (err.response.status === 409) {
          setEmailExist(true);
        }
      });
  };

  return (
    <div className={classes.collaborators}>
      <Formik
        initialValues={{
          first_name: '', last_name: '', roleId: 3, position: '', email: '@de.adecco.net', location: 'Not Defined', leadId: null,
        }}
        validate={validateAsync}
        onSubmit={submitting}
      >
        {() => (
          <Form className="formCreateCollaborator">
            <Grid container>
              <Grid item className={classes.gridItem} spacing={1} container xs={12}>
                <Grid item xs={6}>
                  <CustomInput name="first_name" label="First Name" mandatory>
                    <TextFieldFormik
                      name="first_name"
                      placeholder="First Name"
                      maxLength={20}
                    />
                  </CustomInput>
                </Grid>
                <Grid item xs={6}>
                  <CustomInput name="last_name" label="Last Name" mandatory>
                    <TextFieldFormik
                      name="last_name"
                      placeholder="Last Name"
                      maxLength={20}
                    />
                  </CustomInput>
                </Grid>
              </Grid>
              <Grid item className={classes.gridItem} spacing={1} container xs={12}>
                <Grid item xs={6}>
                  <CustomInput name="email" label="Email" mandatory>
                    <TextFieldFormik
                      name="email"
                      type="email"
                    />
                  </CustomInput>
                  {emailExist ? (
                    <div
                      style={{ marginBottom: '10px' }}
                      className="errorMessageCreateCollaborator"
                    >
                      Email already exists.
                    </div>
                  ) : null}
                </Grid>
                <Grid item xs={6}>
                  <CustomInput name="location" label="Location">
                    <SelectFormik
                      name="location"
                      elements={['Not Defined', 'Hamburg', 'Berlin', 'Hannover', 'Düsseldorf', 'Munich', 'Stuttgart', 'Francfort']
                        .map((el) => ({ value: el, name: el }))}
                    />
                  </CustomInput>
                </Grid>
              </Grid>
              <Grid item className={classes.gridItem} spacing={1} container xs={12}>
                <Grid item xs={6}>
                  <CustomInput name="position" label="Position" mandatory>
                    <TextFieldFormik
                      name="position"
                      placeholder="Position in company"
                      maxLength={20}
                    />
                  </CustomInput>
                </Grid>
                <Grid item xs={6}>
                  <CustomInput name="roleId" label="Role">
                    <SelectFormik
                      name="roleId"
                      placeholder="Role"
                      elements={[{ value: 1, name: 'Director' }, { value: 2, name: 'Manager' }, { value: 3, name: 'Collaborator' }]
                        .map((el) => ({ value: el.value, name: el.name }))}
                    />
                  </CustomInput>
                </Grid>
              </Grid>
              <Grid item className={classes.gridItem} spacing={1} container xs={12}>
                <Grid item xs={12}>
                  <CustomInput name="leadId" label="Team Lead">
                    <SelectFormik
                      name="leadId"
                      elements={[{ value: null, name: 'Not Defined' }, ...leadsNames
                        .map((el) => ({ value: el.id, name: el.name }))]}
                    />
                  </CustomInput>
                </Grid>
              </Grid>
            </Grid>

            <Box display="flex" justifyContent="flex-end" gridGap="0.5rem" marginTop="40px">
              <ButtonSimple variant="large" text="Cancel" event={handleClose} />
              <ButtonCustomFormik variant="large" text="Save" />
            </Box>
          </Form>
        )}
      </Formik>

    </div>
  );
};

BodyModalListEmployees.propTypes = {
  handleClose: PropTypes.func.isRequired,
  setUserUpdated: PropTypes.func.isRequired,
  leadsNames: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default BodyModalListEmployees;
