import React from 'react';
import PropTypes from 'prop-types';
import {
  Avatar, Chip,
} from '@material-ui/core';

const LeadNamePart = (props) => {
  const { leadName } = props;
  function getOwnerInitials(fullName) {
    const initials = fullName.split(' ').map((elem) => elem.charAt(0)).join('');
    return initials;
  }

  return (
    leadName
      ? (
        <div id="collTeamLead">
          <Chip
            style={{
              background: 'white',
              border: '1px solid #9B9B9C',
              fontSize: '12px',
              fontWeight: '400',
              lineHeight: '2.66',
              letterSpacing: '0.07333em',
            }}
            avatar={(
              <Avatar style={{ color: 'white', background: '#89ABE3' }}>
                {leadName && getOwnerInitials(leadName.toUpperCase())}
              </Avatar>
)}
            label={leadName.toUpperCase()}
          />
        </div>
      ) : <div id="collTeamLead" />
  );
};

LeadNamePart.defaultProps = {
  leadName: '',
};

LeadNamePart.propTypes = {
  leadName: PropTypes.string,
};

export default LeadNamePart;
