import { TablePagination } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { api } from '../../api/api';
import { ButtonCustom } from '../../commonComponents/ButtonCustom';
import ModalCustom from '../../commonComponents/ModalCustom';
import { fetchProjects } from '../../redux/ProjectSlice';
import ShowOnRoles from '../../utils/ShowOnRoles';
import datesRangeToForecastings from '../../utils/datesRangeToFprecastings';
import CollaboratorInList from '../loaders/CollaboratorInListLoader';
import BodyModalListEmployees from './BodyModalListEmployees';
import Collaborator from './Collaborator';
import FiltersListColl from './FiltersListColl';

/**
 * Predicate that returns true if the passed
 * project is current. Otherwise false
 *
 * @param {Object} project
 */

/**
 * Predicate that returns true if the passed collaborator
 * has a total intensity in the passed range
 * @param {Object} collaborator
 * @param {Number} min >= 0
 * @param {Numer} max  >= 0
 */
const collIntInRange = (collaborator, min, max) => {
  const totalCurrentIntensity = collaborator.totalWeeklyIntensity ?? 0;
  return min <= totalCurrentIntensity && totalCurrentIntensity <= max;
};

const EmployeesList = () => {
  const [collaborators, setCollaborators] = useState([]);
  const [valueName, setValueName] = useState('');
  const [technosFilters, setTechnosFilters] = useState([]);
  const [toolsFilters, setToolsFilters] = useState([]);
  const [skillsFilters, setSkillsFilters] = useState([]);
  const [locationsFilters, setLocationsFilters] = useState([]);
  const [positionsFilters, setPositionsFilters] = useState([]);
  const [projectsFilters, setProjectsFilters] = useState([]);
  const [intensityFilters, setIntensityFilters] = useState([]);
  const [leadsFilters, setLeadsFilters] = useState([]);
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [startDate, setStartDate] = useState(moment());
  const [userUpdated, setUserUpdated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [technos, setTechnos] = useState([]);
  const [skills, setSkills] = useState([]);
  const [tools, setTools] = useState([]);
  const [leads, setLeads] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const tableRef = React.createRef();

  useEffect(async () => {
    api.get('api/technos').then((res) => setTechnos(res.data));
    api.get('api/skills').then((res) => setSkills(res.data));
    api.get('api/tools').then((res) => setTools(res.data));
    api.get('api/users?RolesIds=1,2').then((res) => setLeads(res.data));
  }, []);

  const dispatch = useDispatch();
  const projectsWithForecastings = useSelector((state) => state.projectsState.projects ?? []);
  const newListColl = collaborators.map((col) => ({
    ...col,
    Skills: col.Skills.map((el) => {
      const findSkill = skills.find((s) => s.id === el.skillId);
      return { ...el, skill_name: findSkill?.skill_name };
    }),
    Tools: col.Tools.map((el) => {
      const findTool = tools.find((t) => t.id === el.toolId);
      return { ...el, tool_name: findTool?.tool_name };
    }),
    Technos: col.Technos.map((el) => {
      const findTechno = technos.find((s) => s.id === el.technoId);
      return { ...el, techno_name: findTechno?.techno_name };
    }),
  }));

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setIsLoading(true);
    api.get('api/users').then((res) => setCollaborators(res.data.sort((a, b) => a.first_name.localeCompare(b.first_name))));
    setIsLoading(false);
    dispatch(fetchProjects());
  }, [userUpdated]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (tableRef.current) { tableRef.current.scrollTop = 0; }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    if (tableRef.current) { tableRef.current.scrollTop = 0; }
    setPage(0);
  };

  const userForecastingsByProject = projectsWithForecastings.reduce((acc, project) => {
    const userForecast = project?.ForecastingIntensities?.length > 0
    && datesRangeToForecastings(project.ForecastingIntensities)
      .reduce((accu, cur) => {
        accu.push({
          userId: cur.userId,
          roleInProject: cur.roleInProject,
          intensity: cur.intensity,
          date: cur.date,
          projectName: project.project_name,
          projectId: cur.projectId,
          end_date: project.end_date,
          start_date: project.start_date,
          status: project.status,
        });
        return accu;
      }, []).filter((forecasting) => (
        moment(forecasting.date)
          .isBetween(startDate.clone()
            .startOf('isoWeek'), startDate.clone()
            .endOf('isoWeek'), undefined, '[]')));
    if (userForecast) {
      acc.push({
        userForecast,
      });
    }
    return acc;
  }, []).flatMap((val) => val);

  const userIntensityByProject = userForecastingsByProject
  && userForecastingsByProject.map((val) => val?.userForecast.reduce((acc, cur) => {
    const user = acc.find(({ userId }) => userId === cur.userId);
    if (user) {
      user.intensity += cur.intensity;
    } else {
      acc.push({
        userId: cur.userId,
        roleInProject: cur.roleInProject,
        intensity: cur.intensity,
        date: cur.date,
        projectName: cur.projectName,
        projectId: cur.projectId,
        end_date: cur.end_date,
        start_date: cur.start_date,
        status: cur.status,
      });
    }
    return acc;
  }, [])).flatMap((data) => data);

  const totalIntensityByUser = userIntensityByProject && userIntensityByProject
    .reduce((acc, cur) => {
      const user = acc.find(({ userId }) => userId === cur.userId);
      if (user) {
        user.intensity += cur.intensity / 5;
      } else {
        acc.push({
          userId: cur.userId,
          intensity: cur.intensity / 5,
        });
      }
      return acc;
    }, []);

  const locations = newListColl.reduce((acc, cur) => {
    if (acc.indexOf(cur.location) === -1) {
      acc.push(cur.location);
    }
    return acc;
  }, []);

  const positions = newListColl.reduce((acc, cur) => {
    if (acc.indexOf(cur.position) === -1) {
      acc.push(cur.position);
    }
    return acc;
  }, []);

  const teamsLead = newListColl.reduce((acc, cur) => {
    if (cur.TeamLead && acc.indexOf(`${cur?.TeamLead.first_name} ${cur?.TeamLead.last_name}`) === -1) {
      acc.push(`${cur?.TeamLead.first_name} ${cur?.TeamLead.last_name}`);
    }
    return acc;
  }, []);

  const projects = projectsWithForecastings.reduce((acc, project) => {
    if (acc.indexOf(project.project_name === -1)) {
      acc.push(project.project_name);
    }
    return acc;
  }, []);

  const leadsNames = leads.reduce((acc, cur) => {
    acc.push({ id: cur.id, name: `${cur.first_name} ${cur.last_name}` });
    return acc;
  }, []);

  // HERE add currentProjects to USER to be able to Filter by Project
  userIntensityByProject.map((user) => {
    const item = newListColl.find((coll) => coll.id === user.userId);
    const currentUserProjects = userIntensityByProject
      .filter((current) => current.userId === user.userId);
    if (item) {
      return Object.assign(item, { currentProject: currentUserProjects });
    } return undefined;
  });

  // HERE add totalWeeklyIntensity to USER to be able to Filter by Intensity
  totalIntensityByUser.map((user) => {
    const item = collaborators.find((coll) => coll.id === user.userId);
    if (item) {
      return Object.assign(item, { totalWeeklyIntensity: user.intensity });
    } return undefined;
  });

  const newListCollFiltered = newListColl
    .filter((element) => element.first_name.toLocaleLowerCase()
      .includes(valueName.toLocaleLowerCase())
              || element.last_name.toLocaleLowerCase().includes(valueName.toLocaleLowerCase())
              || (`${element.first_name.toLocaleLowerCase()} ${element.last_name.toLocaleLowerCase()}`)
                .includes(valueName.toLocaleLowerCase()))
    .filter((element) => technosFilters.length === 0 || element.Technos
      .find((item) => technosFilters
        .find((e) => e === item.techno_name)))
    .filter((element) => toolsFilters.length === 0 || element.Tools
      .find((item) => toolsFilters
        .find((e) => e === item.tool_name)))
    .filter((element) => skillsFilters.length === 0 || element.Skills
      .find((item) => skillsFilters
        .find((e) => e === item.skill_name)))
    .filter((element) => locationsFilters.length === 0 || locationsFilters
      .find((item) => item === element.location))
    .filter((element) => positionsFilters.length === 0 || positionsFilters
      .find((item) => item === element.position))
    .filter((element) => projectsFilters.length === 0
              || (element.currentProject && element.currentProject
                .find((item) => projectsFilters
                  .find((project) => project === item.projectName))))
    .filter((collab) => intensityFilters.length === 0
              || intensityFilters.some((f) => collIntInRange(collab, f[0], f[1])))
    .filter((element) => leadsFilters.length === 0
              || (element.TeamLead && leadsFilters
                .includes(`${element.TeamLead.first_name} ${element.TeamLead.last_name}`)));

  return (
    <div id="boxEmployeesList">
      <div className="titleAndAddCollaborator">
        <h1>Collaborators</h1>
        <ShowOnRoles allowedRoleIds={[1, 2, 4]}>
          <ModalCustom
            title="Add collaborator"
            height="auto"
            width="676px"
            open={open}
            handleClose={handleClose}
            childButtonOpenModal={(
              <ButtonCustom variant="medium" event={handleOpen} text="Add" icon={<AddIcon />} />
            )}
            child={(
              <BodyModalListEmployees
                handleClose={handleClose}
                setUserUpdated={setUserUpdated}
                leadsNames={leadsNames}
              />
            )}
          />

        </ShowOnRoles>
      </div>
      <p>
        {newListColl.length}
        {' '}
        collaborators
      </p>
      <FiltersListColl
        collaborators={newListColl}
        valueName={valueName}
        setValueName={setValueName}
        technosFilters={technosFilters}
        setTechnosFilters={setTechnosFilters}
        toolsFilters={toolsFilters}
        setToolsFilters={setToolsFilters}
        skillsFilters={skillsFilters}
        setSkillsFilters={setSkillsFilters}
        locationsFilters={locationsFilters}
        setLocationsFilters={setLocationsFilters}
        locations={locations}
        positionsFilters={positionsFilters}
        setPositionsFilters={setPositionsFilters}
        positions={positions}
        projectsFilters={projectsFilters}
        setProjectsFilters={setProjectsFilters}
        projects={projects}
        setIntensityFilters={setIntensityFilters}
        intensityFilters={intensityFilters}
        setLeadsFilters={setLeadsFilters}
        leadsFilters={leadsFilters}
        teamsLead={teamsLead}
        leadsNames={leadsNames}
      />
      <div>
        {isLoading ? (
          <>
            <CollaboratorInList />
            <CollaboratorInList />
            <CollaboratorInList />
            <CollaboratorInList />
            <CollaboratorInList />
            <CollaboratorInList />
            <CollaboratorInList />
          </>
        )
          : newListCollFiltered
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((coll) => (
              <Link to={{ pathname: `/employees/${coll.id}` }} style={{ textDecoration: 'none', color: 'inherit' }} key={coll.id}>
                {' '}
                <Collaborator
                  key={coll.id}
                  coll={coll}
                  totalIntensityByUser={totalIntensityByUser}
                  forecasting={userIntensityByProject}
                  id={coll.id}
                  leadsNames={leadsNames}
                />
                {' '}
              </Link>
            ))}
        <TablePagination
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 20, 50, 100]}
          rowsPerPage={rowsPerPage}
          page={page}
          count={newListCollFiltered.length}
        />
      </div>
    </div>
  );
};

export default EmployeesList;
