/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import groupBy from 'lodash/groupBy';
import { Box } from '@material-ui/core';
import GridPositioner from '../../Forecasting/GridPositioner';
import GreyBarTime from '../../Forecasting/GreyBarTime';
import generateArrayTimeUnit from '../../../utils/generateArrayTimeUnit';
import { forecastingsUserUpdate, timeStampToggled } from '../../../redux/ForecastingsUserSlice';

const useStyles = makeStyles(() => ({
  rootButton: {
    padding: '0 0 0 0',
    marginTop: '34px',
    '&:hover': {
      backgroundColor: '#FFF',
    },
  },
  rightArrow: {
    transform: 'translateX(-10px)',
  },
  leftArrow: {
    transform: 'translateX(10px)',
  },
  icons: {
    width: '75%',
  },
}));

const TimeAxis = (props) => {
  const {
    startDate, endDate, timeUnit, setStartDate, setEndDate, edit,
  } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const timeStampSelectedUpdate = useSelector((state) => state
    .forecastingsUserState.timeStampSelected);

  const forecastings = useSelector((state) => state
    .forecastingsUserState.forecastingsUserUpdated
   ?? []);

  const arrayMoment = generateArrayTimeUnit(startDate, endDate, timeUnit);
  let goodFormatDate;

  if (timeUnit === 'day') {
    goodFormatDate = 'D';
  } else if (timeUnit === 'week') {
    goodFormatDate = 'W';
  } else if (timeUnit === 'month') {
    goodFormatDate = 'MMM';
  }
  // console.log('POPOLE', timeStampSelectedUpdate);
  const groupByMonthsAndYears = groupBy(arrayMoment, (element) => element.format('MM:yyyy'));
  const groupByYears = groupBy(arrayMoment, (element) => element.format('yyyy'));

  const returnInPast = () => {
    setStartDate(moment(startDate).subtract(5, `${timeUnit}s`));
    setEndDate(moment(endDate).subtract(5, `${timeUnit}s`));
  };
  const goToFuture = () => {
    setStartDate(moment(startDate).add(5, `${timeUnit}s`));
    setEndDate(moment(endDate).add(5, `${timeUnit}s`));
  };

  const returnInPastPlus = () => {
    setStartDate(moment(startDate).subtract(10, `${timeUnit}s`));
    setEndDate(moment(endDate).subtract(10, `${timeUnit}s`));
  };
  const goToFuturePlus = () => {
    setStartDate(moment(startDate).add(10, `${timeUnit}s`));
    setEndDate(moment(endDate).add(10, `${timeUnit}s`));
  };

  const onTimeStampToggled = (date) => {
    dispatch(timeStampToggled({ date, timeUnit }));

    // const arrayMomentSelected = timeStampSelectedUpdate.map((el) => moment(el));
    // const findDate = arrayMomentSelected.find((el) => moment(el).isSame(moment(date)));

    // const findTimeStampForecasting = forecastings.find((f)
    //  => moment(f.date).startOf('day').isSame(date.startOf('day')));
    // if (findDate) {
    //   dispatch(timeStampToggled(timeStampSelectedUpdate
    //     .filter((el) => !moment(el).isSame(findDate))));
    //   // dispatch(forecastingsUserUpdate(forecastings
    //   //   .map((f) => ({ ...f, calendarSelected: false }))));
    // } else {
    //   dispatch(timeStampToggled([...timeStampSelectedUpdate, date]));
    /*
         Si la date n'est pas dans la liste des Timestamps
         On veut mettre à jour tous les f de forecastingsUpdate de la time unit de la timeStamp
         concerné en calendarSelected=true

      */
    // if (timeUnit === 'day') {
    //   dispatch(forecastingsUserUpdate([...forecastings
    //     .filter((f) => f.id !== findTimeStampForecasting.id),
    //   {
    //     ...findTimeStampForecasting,
    //     calendarSelected: !findTimeStampForecasting.calendarSelected,
    //   }], timeUnit));
    // }
    // let forecastingsToChangeCalendarSelected = [];
    // forecastings.forEach((f) => {
    //   if (moment(f.date).startOf('day').isSame(date.startOf('day'))) {
    //     forecastingsToChangeCalendarSelected = {
    //       ...forecastingsToChangeCalendarSelected,
    //       calendarSelected: !f.calendarSelected,
    //     };
    //   }
    // });
    // dispatch(forecastingsUserUpdate(forecastingsToChangeCalendarSelected));
    // dispatch(forecastingsUserUpdate(forecastings
    //   .map((f) => {

    //   })));
    // }
    // console.log('POPOLE', forecastings);
    // console.log('timeStampSelectedUpdate', timeStampSelectedUpdate);
    // console.log('date', date);
    // console.log('COMPARAISON', forecastings.find((f) => moment(f.
    // date).startOf('day').isSame(date.startOf('day'))));
  };

  console.log('forecastings', forecastings);
  // console.log('timeStampToggled', timeStampSelectedUpdate);

  const horizontalBarTimeMonthYears = Object.values(timeUnit === 'month' ? groupByYears : groupByMonthsAndYears).map((item, index) => (
    <GridPositioner
      key={item[0].format('MMM:yyyy')}
      row={1}
      startDate={item[0]}
      endDate={item[item.length - 1]}
      gridStartDate={startDate}
      timeUnit={timeUnit}
    >
      <GreyBarTime
        timeUnit={timeUnit}
        date={item[0]}
        isFirst={index === 0}
        isLast={index === Object.values(timeUnit === 'month' ? groupByYears : groupByMonthsAndYears).length - 1}
        returnInPastPlus={returnInPastPlus}
        goToFuturePlus={goToFuturePlus}
      />
    </GridPositioner>
  ));

  return (
    <>
      <Box display="flex" marginTop="20px">
        <Box width="calc(12%)" />
        <IconButton
          classes={{ root: classes.rootButton }}
          className={classes.leftArrow}
          onClick={returnInPast}
        >
          <ArrowBackIosIcon className={classes.icons} />
        </IconButton>
        <Box
          width="88%"
          display="grid"
          gridGap="4px"
          alignItems="center"
          gridAutoRows="2rem"
          textAlign="center"
          marginRight="10px"
          gridTemplateColumns={`repeat(${endDate.diff(startDate, `${timeUnit}s`) + 1}, 1fr)`}
        >
          {horizontalBarTimeMonthYears}
          {arrayMoment.map((date) => (
            <GridPositioner
              key={date}
              row={2}
              startDate={date}
              gridStartDate={startDate}
              timeUnit={timeUnit}
            >
              {edit ? (
                <button type="button" onClick={() => onTimeStampToggled(date)} style={timeStampSelectedUpdate.some((el) => moment(el).isSame(date)) ? { color: 'yellow', backgroundColor: 'pink', width: '100%' } : { width: '100%' }}>
                  { (timeUnit === 'week' ? 'W' : '') + date.format(goodFormatDate)}
                </button>
              ) : <p>{ (timeUnit === 'week' ? 'W' : '') + date.format(goodFormatDate)}</p> }

            </GridPositioner>
          ))}
        </Box>
        <IconButton
          classes={{ root: classes.rootButton }}
          className={classes.rightArrow}
          onClick={goToFuture}
        >
          <ArrowForwardIosIcon className={classes.icons} />
        </IconButton>
      </Box>
      {/* <hr /> */}
    </>
  );
};

TimeAxis.defaultProps = {
  edit: false,
};

TimeAxis.propTypes = {
  timeUnit: PropTypes.oneOf(['day', 'week', 'month']).isRequired,
  startDate: PropTypes.shape().isRequired, // Moment object
  endDate: PropTypes.shape().isRequired, // Moment object
  setStartDate: PropTypes.func.isRequired,
  setEndDate: PropTypes.func.isRequired,
  edit: PropTypes.bool,
};

export default TimeAxis;
