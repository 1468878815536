/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import TodayIcon from '@material-ui/icons/Today';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { IconButton } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  icon: {
    background: 'none',
    '&:hover': {
      background: 'none',
    },
    '&:active': {
      background: 'none',
    },
  },
  datetimecontainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}));
const ForecastingDates = (props) => {
  const { start, end, setStartDate } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  // const getPrevious = () => {
  // };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container>
        <Grid item xs="auto">
          <div className={classes.datetimecontainer}>
            <IconButton
              onClick={() => setOpen(true)}
              className={classes.icon}
              size="small"
              aria-label="delete"
            >
              <TodayIcon fontSize="small" />
              <ExpandMoreIcon fontSize="small" />
            </IconButton>
            <DatePicker
              variant="dialog"
              value={start}
              open={open}
              onChange={setStartDate}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              TextFieldComponent={() => null}
            />

          </div>

        </Grid>
        <Grid item xs={8}>
          <Grid
            direction="row"
            justifyContent="center"
            container
            alignItems="center"
            spacing={1}
          >
            <Grid item xs="auto">
              <IconButton onClick={() => setStartDate(moment(start).subtract(1, 'week'))} size="small" aria-label="delete">
                <KeyboardArrowLeftIcon fontSize="small" />
              </IconButton>
            </Grid>
            <Grid item xs="auto">
              {start.format('MMM DD')}
              -
              {end.format('MMM DD')}
              {' '}
              (KW
              {start.format('WW')}
              )
            </Grid>
            <Grid item xs="auto">
              <IconButton onClick={() => setStartDate(moment(start).add(1, 'week'))} size="small" aria-label="delete">
                <KeyboardArrowRightIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

    </Box>
  );
};

export default ForecastingDates;
