/* eslint-disable no-nested-ternary */
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import RecentActorsIcon from '@material-ui/icons/RecentActors';

const GeneralInfosCard = (props) => {
  const {
    endDate, startDate, stepSale, budgettype, clientName,
  } = props;

  let cardInfo;
  const iconBudget = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.59 2.59C14.21 2.21 13.7 2 13.17 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8.83C20 8.3 19.79 7.79 19.41 7.42L14.59 2.59ZM15 18H9C8.45 18 8 17.55 8 17C8 16.45 8.45 16 9 16H15C15.55 16 16 16.45 16 17C16 17.55 15.55 18 15 18ZM15 14H9C8.45 14 8 13.55 8 13C8 12.45 8.45 12 9 12H15C15.55 12 16 12.45 16 13C16 13.55 15.55 14 15 14ZM13 8V3.5L18.5 9H14C13.45 9 13 8.55 13 8Z" fill="#373A36" fillOpacity="0.5" />
    </svg>

  );
  const iconDelivery = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19 3H18V2C18 1.45 17.55 1 17 1C16.45 1 16 1.45 16 2V3H8V2C8 1.45 7.55 1 7 1C6.45 1 6 1.45 6 2V3H5C3.89 3 3.01 3.9 3.01 5L3 19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM18 19H6C5.45 19 5 18.55 5 18V8H19V18C19 18.55 18.55 19 18 19ZM8 10H11C11.55 10 12 10.45 12 11V14C12 14.55 11.55 15 11 15H8C7.45 15 7 14.55 7 14V11C7 10.45 7.45 10 8 10Z" fill="#373A36" fillOpacity="0.5" />
    </svg>
  );

  const todayDate = moment(Date.now());
  const endDateFormat = moment(endDate);
  const startDateFormat = moment(startDate);
  const weeksDiff = endDateFormat.diff(todayDate, 'weeks');
  const endDateFormatDisplay = endDateFormat.format('DD.MM.yyyy');
  const startDateFormatDisplay = startDateFormat.format('DD.MM.yyyy');

  const onGoing = moment(startDate).isBefore(todayDate) && moment(todayDate).isBefore(endDate);
  const finished = (moment(endDate).isBefore(todayDate));

  const saleSteps = ['Identify', 'Propose', 'Submission', 'Negotiating', 'Won', 'Lost', 'Cancelled'];

  if (onGoing) {
    cardInfo = (
      <>
        <div style={{
          display: 'flex', marginLeft: '3px', marginTop: '5px', alignItems: 'center',
        }}
        >
          <RecentActorsIcon color="action" size="20px" />
          <p style={{ marginLeft: '8px', color: 'rgba(55, 58, 54, 0.8)' }}>{clientName}</p>
        </div>
        <div style={{
          display: 'flex', marginTop: '4px', marginBottom: '8px', alignItems: 'center',
        }}
        >
          {iconBudget}
          <p style={{ marginLeft: '8px', color: 'rgba(55, 58, 54, 0.8)' }}>{budgettype}</p>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', color: 'rgba(55, 58, 54, 0.8)' }}>
          {iconDelivery}
          <p style={{ marginLeft: '8px' }}>
            Delivery:
            {' '}
            {' '}
            <span style={{ fontWeight: 500 }}>{endDateFormatDisplay}</span>
          </p>
          <p style={{
            height: '24px', marginLeft: '8px', borderRadius: '6px', backgroundColor: '#F0F0F0', color: '#707987', display: 'flex', alignItems: 'center', padding: '0 8px', fontWeight: '400',
          }}
          >
            {weeksDiff}
            w left
          </p>
        </div>
      </>
    );
  } else if (finished) {
    cardInfo = (
      <>
        <div style={{
          display: 'flex', marginTop: '4px', marginLeft: '3px', alignItems: 'center',
        }}
        >
          <RecentActorsIcon color="action" size="20px" />
          <p style={{ marginLeft: '8px', color: 'rgba(55, 58, 54, 0.8)' }}>{clientName}</p>
        </div>
        <div style={{
          display: 'flex', marginTop: '5px', marginBottom: '8px', alignItems: 'center',
        }}
        >
          {iconBudget}
          <p style={{ marginLeft: '8px', color: 'rgba(55, 58, 54, 0.8)' }}>{budgettype}</p>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', color: 'rgba(55, 58, 54, 0.8)' }}>
          {iconDelivery}
          <p style={{ marginLeft: '8px' }}>
            Delivered:
            {' '}
            {' '}
            <span style={{ fontWeight: 500 }}>{endDateFormatDisplay}</span>
          </p>
        </div>
      </>
    );
  } else if ((moment(todayDate).isBefore(startDateFormat)) || startDate === null) {
    cardInfo = (
      <>
        <div style={{
          display: 'flex', marginTop: '5px', marginLeft: '3px', alignItems: 'center',
        }}
        >
          <RecentActorsIcon color="action" size="20px" />
          <p style={{ marginLeft: '8px', color: 'rgba(55, 58, 54, 0.8)' }}>{clientName}</p>
        </div>
        <div style={{
          display: 'flex', marginTop: '4px', marginBottom: '8px', alignItems: 'center',
        }}
        >
          <p style={{
            height: '24px', borderRadius: '6px', backgroundColor: '#F0F0F0', color: '#707987', display: 'flex', alignItems: 'center', padding: '0 8px', fontWeight: '400',
          }}
          >
            {stepSale === 'Cancelled' ? 'Cancelled' : stepSale === 'Lost' ? 'Lost'
              : `Step ${saleSteps.findIndex((step) => step === stepSale) + 1}`}
          </p>
          <p style={{ marginLeft: '9px' }}>{stepSale === 'Lost' || stepSale === 'Cancelled' ? undefined : stepSale}</p>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', color: 'rgba(55, 58, 54, 0.8)' }}>
          {iconDelivery}
          <div style={{ marginLeft: '8px', display: 'flex' }}>
            {'Start Date: '}
            {startDate ? (
              <p style={{ fontWeight: 500, marginLeft: '4px' }}>{startDateFormatDisplay}</p>
            ) : (
              <p style={{ fontWeight: 500, marginLeft: '4px' }}>
                --
              </p>
            )}
          </div>
        </div>
      </>
    );
  }

  return (
    <div>
      {cardInfo}
    </div>
  );
};

GeneralInfosCard.defaultProps = {
  startDate: null,
  endDate: null,
  stepSale: 'Lorem',
  budgettype: null,
  clientName: null,
};

GeneralInfosCard.propTypes = {
  stepSale: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  budgettype: PropTypes.string,
  clientName: PropTypes.string,
};

export default GeneralInfosCard;
