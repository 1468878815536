import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  pBox: {
    borderLeftColor: (props) => props.color,
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
    borderStyle: 'solid',
    borderLeftWidth: '6px',
    borderColor: 'rgb(255, 255, 255)',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    paddingLeft: '14px',

  },
}));

export default function ProjectNameBox({ color, cle, projectName }) {
  const classes = useStyles({ color });

  return (
    <Box className={classes.pBox}>
      <Typography style={{ marginTop: `${cle > 0 ? '4px' : undefined} `, fontWeight: 700, fontSize: '16px' }}>{projectName}</Typography>
    </Box>
  );
}

ProjectNameBox.defaultProps = {
  color: 'grey',
  cle: 5,
};

ProjectNameBox.propTypes = {
  color: PropTypes.string,
  cle: PropTypes.number,
  projectName: PropTypes.string.isRequired,
};
