/* eslint-disable no-unused-vars */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Box } from '@material-ui/core';
import CheckBoxFilterApi from './CheckBoxFilterApi';
import CheckBoxFilterSelf from './CheckBoxFilterSelf';
import CheckBoxFilterIntensity from './CheckBoxFilterIntensity';

const useStyles = makeStyles(() => ({
  icon: {
    marginLeft: '10px',
  },
}));

const FiltersListColl = (props) => {
  const {
    setValueName, technosFilters, setTechnosFilters, toolsFilters,
    setToolsFilters, skillsFilters, setSkillsFilters, locations,
    locationsFilters, setLocationsFilters, positions, setPositionsFilters,
    positionsFilters, setProjectsFilters, projectsFilters, projects,
    intensityFilters, setIntensityFilters, leadsNames, setLeadsFilters, leadsFilters, teamsLead,
  } = props;

  const classes = useStyles();

  return (
    <>
      <div id="filtersCollList">
        <TextField
          id="fieldNameFiltersCollList"
          onChange={(event) => setValueName(event.target.value)}
          placeholder="Search a name"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon className={classes.icon} />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <hr />
      <div id="othersFiltersCollList">
        {' '}
        <Box width="calc(13.55% - 22px)" paddingRight="22px">
          <CheckBoxFilterSelf
            data={positions}
            itemsFilters={positionsFilters}
            setItemsFilters={setPositionsFilters}
            word="Position"
          />
        </Box>
        <Box width="calc(10.16% - 22px)" paddingRight="22px">
          <CheckBoxFilterSelf
            data={locations}
            itemsFilters={locationsFilters}
            setItemsFilters={setLocationsFilters}
            word="Location"
          />
        </Box>
        <Box width="calc(13.55% - 22px)" paddingRight="22px">
          <CheckBoxFilterApi
            itemsFilters={skillsFilters}
            setItemsFilters={setSkillsFilters}
            word="skills"
            fieldToUse="skill_name"
          />
        </Box>
        <Box width="calc(13.55% - 22px)" paddingRight="22px">
          <CheckBoxFilterApi
            itemsFilters={toolsFilters}
            setItemsFilters={setToolsFilters}
            word="tools"
            fieldToUse="tool_name"
          />
        </Box>
        <Box width="calc(13.55% - 22px)" paddingRight="22px">
          <CheckBoxFilterApi
            itemsFilters={technosFilters}
            setItemsFilters={setTechnosFilters}
            word="technos"
            fieldToUse="techno_name"
          />
        </Box>
        <Box width="calc(8.81% - 22px)" paddingRight="22px">
          <CheckBoxFilterIntensity
            intensityFilters={intensityFilters}
            setIntensityFilters={setIntensityFilters}
          />
        </Box>
        <Box width="calc(13.55% - 22px)" paddingRight="22px">
          <CheckBoxFilterSelf
            data={projects}
            itemsFilters={projectsFilters}
            setItemsFilters={setProjectsFilters}
            word="Project"
          />
        </Box>
        <Box width="calc(13.28% - 22px)">
          <CheckBoxFilterSelf
            data={teamsLead}
            itemsFilters={leadsFilters}
            setItemsFilters={setLeadsFilters}
            word="Team Lead"
          />
        </Box>
      </div>
    </>
  );
};

FiltersListColl.propTypes = {
  setValueName: PropTypes.func.isRequired,
  setLocationsFilters: PropTypes.func.isRequired,
  setProjectsFilters: PropTypes.func.isRequired,
  setIntensityFilters: PropTypes.func.isRequired,
  setPositionsFilters: PropTypes.func.isRequired,
  setSkillsFilters: PropTypes.func.isRequired,
  setTechnosFilters: PropTypes.func.isRequired,
  setToolsFilters: PropTypes.func.isRequired,
  technosFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
  toolsFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
  skillsFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
  locations: PropTypes.arrayOf(PropTypes.string).isRequired,
  positions: PropTypes.arrayOf(PropTypes.string).isRequired,
  locationsFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
  positionsFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
  intensityFilters: PropTypes.arrayOf(PropTypes.arrayOf).isRequired,
  projectsFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
  projects: PropTypes.arrayOf(PropTypes.string).isRequired,
  leadsNames: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setLeadsFilters: PropTypes.func.isRequired,
  leadsFilters: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  teamsLead: PropTypes.arrayOf(PropTypes.string).isRequired,

};

export default FiltersListColl;
