import React from 'react';
import {
  Document, Page, StyleSheet, Text, View,
} from '@react-pdf/renderer';
import moment from 'moment/moment';
import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFF',
    margin: 20,
    flexWrap: 'wrap',
  },
  section: {
    margin: 10,
    padding: 30,
    height: 250,
    border: 0.5,
    borderRadius: 8,
  },
  sectionActivities: {
    margin: 10,
    padding: 30,
    height: 250,
    width: 300,
    border: 0.5,
    borderRadius: 8,
  },
  sectionSkills: {
    margin: 10,
    padding: 30,
    height: 250,
    width: 290,
    border: 0.5,
    borderRadius: 8,
  },
  text: {
    fontSize: 10,
    marginRight: 10,
  },
  textActivity: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  circle: {
    width: 80,
    height: 80,
    backgroundColor: '#d5e746',
    borderRadius: 50,
    marginBottom: 10,
  },
});

const renderUser = (user) => (
  <View style={styles.section}>
    <View style={styles.circle} />
    <Text>
      {`${user.first_name} ${user.last_name}`}
    </Text>
    <Text style={styles.text}>
      {user.position}
    </Text>
    <Text style={styles.text}>
      {user.email}
    </Text>
    <Text style={styles.text}>
      {user.location}
    </Text>
  </View>
);

const renderTechnos = (technos) => (
  <View>
    <Text>
      Technologies
    </Text>
    {technos && technos.map((techno) => (
      <View key={techno.id} style={{ flexDirection: 'row' }}>
        <Text style={styles.text}>
          {techno.techno_name}
        </Text>
      </View>
    ))}
  </View>
);

const renderSkills = (skills) => (
  <View>
    <Text style={{ marginTop: '10px' }}>
      Skills
    </Text>
    {skills && skills.map((skill) => (
      <Text key={skill.id} style={styles.text}>
        {skill.skill_name}
      </Text>
    ))}
  </View>
);

// eslint-disable-next-line no-unused-vars
const renderTools = (tools) => (
  <View>
    <Text style={{ marginTop: '10px' }}>
      Tools
    </Text>
    {tools && tools.map((tool) => (
      <Text key={tool.id} style={styles.text}>
        {tool.tool_name}
      </Text>
    ))}
  </View>
);

const ProfilePdf = (props) => {
  const {
    user, skills, tools, technos, userActivityProjects, projectsNames, activitiesList,
  } = props;

  return (
    <Document>
      <Page style={styles.page}>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          {renderUser(user)}
          <View style={styles.sectionSkills}>
            {renderTechnos(technos)}
            {renderSkills(skills)}
            {renderTools(tools)}
          </View>
        </View>
        <View style={styles.sectionActivities}>
          <Text>
            Activities
          </Text>
          {userActivityProjects
          && userActivityProjects.length > 0 && userActivityProjects.map((activity) => (
            <View key={activity.id} style={styles.textActivity}>
              <Text style={styles.text}>
                {moment(activity.startDate).format('DD-MM-YYYY')}
              </Text>
              <Text style={styles.text}>
                {projectsNames && projectsNames.length > 0 && projectsNames.find((
                  projectName,
                ) => activity?.projectId === projectName?.projectId)?.projectName}
              </Text>
              <Text style={styles.text}>
                {activitiesList.length > 0 && activitiesList.find(
                  (activityName) => activityName.id === activity.activityId,
                ).activity_name}
              </Text>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

ProfilePdf.defaultProps = {
  projectsNames: [],
};

ProfilePdf.propTypes = {
  user: PropTypes.shape({}).isRequired,
  skills: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  tools: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  technos: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  userActivityProjects: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  projectsNames: PropTypes.arrayOf(PropTypes.shape()),
  activitiesList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default ProfilePdf;
