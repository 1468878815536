import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ArchiveIcon from '@material-ui/icons/Archive';
import { ButtonSimple } from './ButtonCustom';

const ConfirmationComponent = (props) => {
  const {
    eventOne, eventTwo, child, archived,
  } = props;
  return (
    <Box>
      {child}
      <Box display="flex" gridGap="1.5rem" justifyContent="center">
        <ButtonSimple variant="large" text={archived ? 'Archive' : 'Delete'} border="1px solid #DA291C" event={eventOne} icon={archived ? <ArchiveIcon /> : <DeleteIcon />} color="#DA291C" />
        <ButtonSimple variant="large" text="Cancel" event={eventTwo} />
      </Box>
    </Box>
  );
};

export default ConfirmationComponent;

ConfirmationComponent.defaultProps = {
  // eslint-disable-next-line no-console
  eventOne: () => console.error(''),
  // eslint-disable-next-line no-console
  eventTwo: () => console.error(''),
  child: '',
  archived: false,
};

ConfirmationComponent.propTypes = {
  eventOne: PropTypes.func,
  eventTwo: PropTypes.func,
  archived: PropTypes.bool,
  child: PropTypes.element,
};
