/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
// import UsersApi from '../../api/UsersApi';

const RiskSlice = createSlice({
  name: 'risks',
  initialState: { risks: [] },
  reducers: {
    risksAdded: (state, action) => {
      // state.push(action.payload);
      state.risks = action.payload;
    },
  },
});

const { actions, reducer } = RiskSlice;

export const { risksAdded } = actions;

export default reducer;
