/* eslint-disable react/require-default-props */
import {
  Avatar, Box, Chip, TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import PropTypes from 'prop-types';
import React from 'react';

const TeamSelection = (props) => {
  const {
    usersSelected, setUsersSelected, users, edit,
  } = props;

  function handleSelectUser(id) {
    if (id && !usersSelected.includes(id)) {
      setUsersSelected([...usersSelected, id]);
    }
  }

  function handleDeleteUser(idToDelete) {
    setUsersSelected(usersSelected.filter((el) => el.id !== idToDelete));
  }

  return (
    <Box>
      <Box display="flex" className="formFieldLabel" gridGap="5px">
        {edit ? (
          <label
            htmlFor="teams"
            style={{
              fontSize: '16px',
              fontWeight: 700,
              marginBottom: '10px',
            }}
          >
            Team

          </label>
        ) : (

          <label htmlFor="teams" className="labelsFormCreate">Team</label>
        )}
      </Box>
      <Autocomplete
        id="autocompleteSelectAddElement"
        options={users}
        getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
        onChange={(event, newValue) => {
          handleSelectUser(newValue);
        }}
        style={{ width: '100%', marginBottom: usersSelected.length > 0 ? '0.5em' : '0em' }}
        renderInput={(params) => (
          <TextField placeholder="Type to search a user" {...params} variant="outlined" />
        )}
        clearOnBlur
        clearOnEscape
        value={null}
      />
      {usersSelected.map((owner) => (
        <Chip
          key={owner.id}
          style={{ background: 'rgba(0, 0, 0, 0.07)', marginBottom: '10px' }}
          avatar={(
            <Avatar style={{ color: 'white', background: '#89ABE3' }}>
              {owner.first_name.charAt(0) + owner.last_name.charAt(0)}
            </Avatar>
                          )}
          label={`${owner.first_name} ${owner.last_name}`}
          onDelete={() => handleDeleteUser(owner.id)}
        />
      ))}
    </Box>
  );
};

export default TeamSelection;

TeamSelection.propTypes = {
  usersSelected: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  users: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setUsersSelected: PropTypes.func.isRequired,
  edit: PropTypes.bool,
};
