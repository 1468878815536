/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Field } from 'formik';
import InfoIcon from '@material-ui/icons/Info';
import Delete from '@material-ui/icons/Delete';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableContainer,
  TableFooter,
  TablePagination,
  TextareaAutosize,
  Tooltip,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ModalCustom from '../../../../commonComponents/ModalCustom';
import DeleteCapex from './DeleteCapex';
import { api } from '../../../../api/api';
import { convertCurrency } from '../../../../utils/convertCurrency';
import ConfirmationComponent from '../../../../commonComponents/ConfirmationComponent';

const useRowStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'rgba(39, 130, 237, 0.05)',
    },
  },
  fieldDate: {
    height: '38px',
    borderRadius: '8px',
    border: '1px solid #B1B1B1',
    padding: '0',
    textIndent: '0px',
    paddingRight: '16px',
  },
  tableCell: {
    border: '1px solid #CCCCCC',
    paddingTop: '7.5px',
    paddingBottom: '7.5px',
  },
  tableCellHead: {
    paddingTop: '8px',
    paddingBottom: '8px',
    color: 'rgba(0, 0, 0, 0.5)',
    border: '1px solid #CCCCCC',
  },
  tableItem: {
    fontSize: '12px',
    lineHeight: '1rem',
    padding: '15px',
  },
  tableLargeHeader: {
    background: 'none',
    fontSize: '13px',
    fontWeight: 'bold',
    lineHeight: '1rem',
    letterSpacing: '0.08333em',
    textTransform: 'uppercase',
    borderBottom: 'none',
  },
});

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))(Tooltip);

const CapexTable = (props) => {
  const {
    // eslint-disable-next-line no-unused-vars
    rows, edit, updateExpenses, setUpdateExpenses,
    currency, convertCurrencyValue, ProjectId,
  } = props;
  const formatDates = (rowsData) => rowsData.map((row) => {
    row.date = row.date.substring(0, 10);
    return row;
  });

  const [rowsToDisplay, setRowsToDisplay] = useState(formatDates(rows));
  const [showMoreIcon, setShowMoreIcon] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [capexToBeDeleted, setCapexToBeDeleted] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [capexIds, setCapexIds] = useState(rows.map((expense) => expense.id));
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const tableRef = React.createRef();
  const [page, setPage] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (tableRef.current) { tableRef.current.scrollTop = 0; }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    if (tableRef.current) { tableRef.current.scrollTop = 0; }
    setPage(0);
  };
  const classes = useRowStyles();

  useEffect(() => {
    setRowsToDisplay(rows);
  }, [showMoreIcon]);

  const handleDeleteRow = (capexId) => {
    setCapexToBeDeleted(capexId);
    setOpenDeleteDialog(true);
  };

  const handleDeleteExpenses = () => {
    api.delete(`api/expenses?projectId=${ProjectId}`)
      .then(() => setUpdateExpenses(!updateExpenses))
      .then(() => setOpenDelete(false));
  };
  const onChangeQuantity = (row, value) => {
    row.quantity = value;
    api.put(`api/expenses/${row.id}`, row).then(() => setUpdateExpenses(!updateExpenses));
  };

  const onChangeCategory = (row, value) => {
    row.category = value;
    api.put(`api/expenses/${row.id}`, row).then(() => setUpdateExpenses(!updateExpenses));
  };

  const onChangeUnitPrice = (row, value) => {
    row.unitPrice = value;
    api.put(`api/expenses/${row.id}`, row).then(() => setUpdateExpenses(!updateExpenses));
  };

  const onChangeNotes = (row, value) => {
    row.extraInfos = value;
    api.put(`api/expenses/${row.id}`, row).then(() => setUpdateExpenses(!updateExpenses));
  };

  const onChangeRebill = (row, value) => {
    row.rebillCustomer = value;
    api.put(`api/expenses/${row.id}`, row).then(() => setUpdateExpenses(!updateExpenses));
  };

  const onChangeDate = (row, value) => {
    row.date = value;
    api.put(`api/expenses/${row.id}`, row).then(() => setUpdateExpenses(!updateExpenses));
  };

  useEffect(() => {
    setCapexIds(rows.map((expense) => expense.id));
  }, [rows]);

  return (
    <>
      <TableContainer
        style={{ width: ((edit && showMoreIcon) ? '99%' : '100%'), display: 'inline-block', overflowX: 'hidden' }}
        component={Paper}
      >
        <TableRow>
          <TableCell className={classes.tableLargeHeader} align="left" colSpan={edit ? 8 : 11}>
            <Typography variant="overline" className={classes.tableLargeHeader} align="left">
              CAPEX
            </Typography>
          </TableCell>
          {edit && (
          <TableCell className={classes.tableLargeHeader} align="left" colSpan={1}>
            <IconButton aria-label="expand row" size="small">
              <LightTooltip title="Delete Table" placement="bottom-start">
                <MoreHorizIcon
                  className={classes.moreHorizIcon}
                  onClick={() => setOpenDelete(true)}
                />
              </LightTooltip>
            </IconButton>
          </TableCell>
          )}
        </TableRow>
        <Table className={classes.tableCell} id="capexTable" aria-label="collapsible table">
          <TableHead>
            <TableRow style={{ backgroundColor: 'rgba(0, 0, 0, 0.03)' }}>
              <TableCell className={classes.tableCellHead} align="left">Name</TableCell>
              <TableCell className={classes.tableCellHead} align="left">Notes</TableCell>
              <TableCell className={classes.tableCellHead} align="left">Date</TableCell>
              <TableCell className={classes.tableCellHead} align="left">Category</TableCell>
              <TableCell className={classes.tableCellHead} align="left">Rebill</TableCell>
              <TableCell className={classes.tableCellHead} align="right">
                Quantity
              </TableCell>
              <TableCell className={classes.tableCellHead} align="right">
                Unit Price (
                {convertCurrency(currency)}
                )
              </TableCell>
              <TableCell className={classes.tableCellHead} align="right">
                Total (
                {convertCurrency(currency)}
                )
              </TableCell>
              {edit && (
                <TableCell className={classes.tableCellHead} />
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <TableRow
                key={row.id}
                className={classes.root}
                onMouseEnter={() => { setShowMoreIcon(true); row.showMoreIcon = true; }}
                onMouseLeave={() => { setShowMoreIcon(false); row.showMoreIcon = false; }}
              >
                <TableCell className={classes.tableCell} component="th" scope="row" align="left">
                  {row.expenseName}
                </TableCell>
                <TableCell className={classes.tableCell} component="th" scope="row" align="center">
                  {edit ? (
                    <TextareaAutosize
                      value={row.extraInfos}
                      onChange={(e) => onChangeNotes(row, e.target.value)}
                      minRows={2}
                      style={{ padding: '5px' }}
                      className="fieldFormik"
                    />
                  ) : (
                    row.extraInfos ? (
                      <>
                        <Tooltip title={row.extraInfos} style={{ fontSize: '12' }} arrow>
                          <InfoIcon style={{ color: 'rgba(0, 0, 0, 0.2)' }} />
                        </Tooltip>
                      </>
                    ) : '')}
                </TableCell>
                <TableCell className={classes.tableCell} component="th" scope="row" align="left">
                  {edit ? (
                    <div className={classes.itemsForm}>
                      <Field
                        style={{ width: '70px' }}
                        id="date"
                        value={row.date}
                        type="date"
                        variant="outlined"
                        className={classes.fieldDate}
                        onChange={(e) => onChangeDate(row, e.target.value)}
                        inputlabelprops={{
                          shrink: true,
                        }}
                      />
                    </div>
                  ) : moment(row.date).format('DD.MM.YYYY')}
                </TableCell>
                <TableCell className={classes.tableCell} component="th" scope="rw" align="left">
                  {edit ? (
                    <Select
                      name="category"
                      value={row.category}
                      id="category"
                      variant="outlined"
                      style={{ height: '38px', width: '70px' }}
                      onChange={(e) => onChangeCategory(row, e.target.value)}
                      classes={{
                        root: classes.rootSelect,
                        select: classes.select,
                        outlined: classes.outlined,
                        selectMenu: classes.selectMenu,
                      }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      <MenuItem value="Other">Other</MenuItem>
                      <MenuItem value="IT">IT</MenuItem>
                      <MenuItem value="Material">Material</MenuItem>
                      <MenuItem value="Building">Building</MenuItem>
                      <MenuItem value="Travel">Travel</MenuItem>
                      <MenuItem value="ByPass">ByPass</MenuItem>
                    </Select>
                  ) : row.category}
                </TableCell>
                <TableCell className={classes.tableCell} component="th" scope="row" align="center">
                  {edit ? (
                    <Select
                      id="rebillCustomer"
                      value={row.rebillCustomer}
                      variant="outlined"
                      style={{ height: '38px' }}
                      onChange={(e) => onChangeRebill(row, e.target.value)}
                      classes={{
                        root: classes.rootSelect,
                        select: classes.select,
                        outlined: classes.outlined,
                        selectMenu: classes.selectMenu,
                      }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      <MenuItem value={false}><CancelIcon style={{ color: '#DA291C' }} /></MenuItem>
                      <MenuItem value><CheckCircleIcon style={{ color: '#68D47E' }} /></MenuItem>
                    </Select>
                  ) : (row.rebillCustomer ? <CheckCircleIcon style={{ color: '#68D47E' }} /> : <CancelIcon style={{ color: '#DA291C' }} />)}
                </TableCell>
                <TableCell className={classes.tableCell} component="th" scope="row" align="right">
                  {edit ? (
                    <div className={classes.itemsForm}>
                      <Field
                        style={{ width: '70px' }}
                        type="number"
                        id="quantity"
                        value={row.quantity}
                        min={1}
                        className="fieldFormik"
                        onChange={(e) => onChangeQuantity(row, e.target.value)}
                      />
                    </div>
                  ) : row.quantity}
                </TableCell>
                <TableCell className={classes.tableCell} style={{ border: '1xp solid #CCCCC' }} component="th" scope="row" align="right">
                  {edit ? (
                    <div className={classes.itemsForm}>
                      <Field
                        style={{ width: '70px' }}
                        type="number"
                        id="unitPrice"
                        value={row.unitPrice}
                        className="fieldFormik"
                        onChange={(e) => onChangeUnitPrice(row, e.target.value)}
                      />
                    </div>
                  ) : convertCurrencyValue(row.unitPrice).toFixed(0)}
                </TableCell>
                <TableCell className={classes.tableCell} component="th" scope="row" align="right">{row.quantity * convertCurrencyValue(row.unitPrice).toFixed(0)}</TableCell>
                {edit && (
                <TableCell>
                  <Delete
                    fontSize="small"
                    color="error"
                    style={{ cursor: 'pointer' }}
                    onMouseEnter={() => { setShowMoreIcon(true); row.showMoreIcon = true; }}
                    onMouseLeave={() => { setShowMoreIcon(false); row.showMoreIcon = false; }}
                    onClick={() => handleDeleteRow(row.id)}
                  />
                </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
          {rows.length > 10 && (
          <TableFooter>
            <TableRow>
              <TableCell style={{ border: 'none' }} align="right" colSpan={11}>
                <TablePagination
                  rowsPerPageOptions={[10, 15, 25]}
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableCell>
            </TableRow>
          </TableFooter>
          )}
        </Table>
      </TableContainer>

      <DeleteCapex
        capexId={capexToBeDeleted}
        updateExpenses={updateExpenses}
        setUpdateExpenses={setUpdateExpenses}
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
      />
      <ModalCustom
        handleClose={() => setOpenDelete(false)}
        open={openDelete}
        width="676px"
        title="Delete Capex table?"
        child={(
          <ConfirmationComponent
            eventOne={() => handleDeleteExpenses(capexIds)}
            eventTwo={() => setOpenDelete(false)}
          />
        )}
      />
    </>
  );
};

CapexTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setUpdateExpenses: PropTypes.func.isRequired,
  updateExpenses: PropTypes.bool.isRequired,
  edit: PropTypes.bool.isRequired,
  currency: PropTypes.string.isRequired,
  ProjectId: PropTypes.number.isRequired,
  convertCurrencyValue: PropTypes.func.isRequired,
};

export default CapexTable;
