/* eslint-disable import/prefer-default-export */

export const orderCriticityUtility = (values) => {
  switch (values) {
    case 'Negligible':
      return 1;
    case 'Significant':
      return 2;
    case 'Critical':
      return 3;
    case 'Essential':
      return 4;
    case 'Unacceptable':
      return 5;
    default:
      return 0;
  }
};
