/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import CurrenciesApi from '../api/CurrenciesApi';

const CurrencySlice = createSlice({
  name: 'currencies',
  initialState: { currencies: [] },
  reducers: {
    currenciesAdded: (state, action) => {
      state.currencies = action.payload;
    },
  },
});

const { actions, reducer } = CurrencySlice;

export const { currenciesAdded } = actions;

export const fetchCurrencies = (startDate, endDate) => async (dispatch) => {
  const currenciesApi = new CurrenciesApi();
  const response = await currenciesApi.fetchCurrencies(startDate, endDate);
  dispatch(currenciesAdded(response));
};
export default reducer;
