/* eslint-disable linebreak-style */
import { configureStore } from '@reduxjs/toolkit';
import ActivitiesListReducer from './redux/ActivitiesListSlice';
import ActivitySliceReducer from './redux/ActivitySlice';
import CurrencySliceReducer from './redux/CurrencySlice';
import ForecastingsSliceReducer from './redux/ForecastingsSlice';
import ForecastingsUserSliceReducer from './redux/ForecastingsUserSlice';
import ProjectSliceReducer from './redux/ProjectSlice';
import RiskSliceReducer from './redux/RiskSlice';
import UserSliceReducer from './redux/UserSlice';

export default configureStore({
  reducer: {
    projectsState: ProjectSliceReducer,
    usersState: UserSliceReducer,
    risksState: RiskSliceReducer,
    activitiesState: ActivitySliceReducer,
    activitiesListState: ActivitiesListReducer,
    currenciesState: CurrencySliceReducer,
    forecastingsUserState: ForecastingsUserSliceReducer,
    forecastingsState: ForecastingsSliceReducer,
  },
});
