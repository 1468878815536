/* eslint-disable no-nested-ternary */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Alert } from '@material-ui/lab';
import { convertCurrency } from '../../../utils/convertCurrency';

const useRowStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'rgba(39, 130, 237, 0.05)',
    },
  },
  tableCellHead: {
    paddingTop: '8px',
    paddingBottom: '8px',
    color: 'rgba(0, 0, 0, 0.5)',
    height: '2rem',
  },
  tableCell: {
    border: '1px solid #CCCCCC',
    paddingTop: '7.5px',
    paddingBottom: '7.5px',
  },
  tableLargeHeader: {
    background: 'none',
    fontSize: '13px',
    fontWeight: 'bold',
    lineHeight: '1rem',
    letterSpacing: '0.08333em',
    textTransform: 'uppercase',
    borderBottom: 'none',
  },
  gridTable: {
    padding: '10px',
    boxShadow: '0px 1px 1px -1px rgb(0 0 0 / 20%), 0px 0px 3px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%)',
    borderRadius: '4px',
    border: '1px solid rgba(224, 224, 224, 1)',
  },
  tableItem: {
    fontSize: '12px',
    lineHeight: '1rem',
    padding: '15px',
  },

  tableHeader: {
    textTransform: 'uppercase',
    lineHeight: '1rem',
    fontSize: '10px',
    fontWeight: 'bold',
    color: '#373A36',
  },
  tableRow: {
    '& td:nth-last-child(1)': {
      borderRight: '1px solid rgba(224, 224, 224, 1)',
    },
    '& td:first-child': {
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
    },
  },
  tableTitle: {
    marginBottom: '15px',
    float: 'left',
    background: 'none',
    fontSize: '13px',
    fontWeight: 'bold',
    lineHeight: '1rem',
    letterSpacing: '0.08333em',
    textTransform: 'uppercase',
    padding: '7px 3px 0px 3px',
  },
});

function Row(props) {
  const { row, convertCurrencyValue } = props;
  // const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell className={classes.tableItem} align="left">{row[0].workPackageName}</TableCell>
        <TableCell className={classes.tableItem} align="right">{convertCurrencyValue(Math.round(row[0].totalPriceWP)).toFixed(0)}</TableCell>
      </TableRow>
    </>
  );
}

const TotalIncomeTable = (props) => {
  const { rows, currency, convertCurrencyValue } = props;
  const classes = useRowStyles();

  if (rows.length === 0) {
    return (
      <Alert
        style={{
          marginBottom: '1rem',
          marginTop: '1rem',
        }}
        severity="info"
      >
        There is no income information for this project. You can add it in Time Budget.
      </Alert>
    );
  }
  return (
    <>
      <h4 className={classes.tableTitle}>Total</h4>
      <TableContainer
        style={{
          display: 'inline-block',
          overflowX: 'hidden',
          padding: '7px 3px 7px 3px',
          fontSize: '12px',
        }}
        component={Paper}
      >
        <Table className={classes.gridTable} id="totalIncomeTable" aria-label="collapsible table">
          <TableHead>
            <TableRow style={{ backgroundColor: 'rgba(0, 0, 0, 0.03)' }}>
              <TableCell className={classes.tableHeader} align="left">Income</TableCell>
              <TableCell className={classes.tableHeader} align="right">
                Total (
                {convertCurrency(currency)}
                )
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <Row key={row[0].id} row={row} convertCurrencyValue={convertCurrencyValue} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

    </>
  );
};

TotalIncomeTable.propTypes = {
  currency: PropTypes.string.isRequired,
  convertCurrencyValue: PropTypes.func.isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

Row.propTypes = {
  convertCurrencyValue: PropTypes.func.isRequired,
  row: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default TotalIncomeTable;
