/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import ChipSeniority from './ChipSeniority';
import SenioritySelection from './SenioritySelection';
import { api } from '../../api/api';

const filter = createFilterOptions();
const useStyles = makeStyles(() => ({
  tag: {
    display: 'none',
  },
  suppItem: {
    marginRight: '16px',
    color: 'rgba(17, 17, 17, 0.7)',
    height: '18px',
    padding: '3px 0',
    '&:hover': {
      backgroundColor: 'grey',
      color: 'white',
      boxShadow: 'none',
      borderRadius: '50%',
    },
  },
}));

const SelectAddElementProfile = (props) => {
  const {
    selected, setSelected, word, title, keyRequired, elements, setElements, options,
  } = props;
  const goodVariable = `${word.toLowerCase()}_name`;
  const [itemFocused, setItemFocused] = useState();
  const [level, setLevel] = useState(1);
  const { id } = useParams();
  const classes = useStyles();
  const name = `${keyRequired.slice(0, keyRequired.indexOf('_'))}Id`;
  const handleSelect = (event, newElements) => {
    const newSelected = { ...selected };

    const body = {};
    body[keyRequired] = newElements[newElements.length - 1][keyRequired];
    const addElement = async () => api
      .post(`api/${word.toLowerCase()}s`, body)
      .then((response) => {
        setElements([...elements, response.data]);
        setSelected([...selected, { ...response.data, seniority: 1, [name]: response.data.id }]);
      })
      .catch((err) => {
        if (err.response.status === 409) {
          console.error(err);
        }
      });

    const itemTechnosExist = elements.find((el) => el.techno_name === newElements[newElements.length - 1].techno_name);
    const itemToolsExist = elements.find((el) => el.tool_name === newElements[newElements.length - 1].tool_name);
    const itemSkillsExist = elements.find((el) => el.skill_name === newElements[newElements.length - 1].skill_name);
    // If element did not exist, create it in DB
    if (!itemTechnosExist || !itemToolsExist || !itemSkillsExist) {
      addElement();
    }

    if (typeof newValue === 'string') {
      newSelected[goodVariable] = newElements;
      setSelected(newSelected);
    } else if (newElements && newElements.inputValue) {
      newSelected[goodVariable] = newElements.inputValue;
      setSelected(newSelected);
    } else {
      const elementExist = selected.find((el) => el.id === newElements[newElements.length - 1].id);

      if (!elementExist) { // We don't allow duplicate in the list
        const varFromNewElement = newElements[newElements.length - 1][keyRequired];

        const elementName = elements.find((el) => {
          const varFromElement = el[keyRequired];
          return varFromElement === varFromNewElement;
        });

        const focused = {
          ...newElements[newElements.length - 1],
          [keyRequired]: elementName ? elementName[keyRequired]
            : newElements[newElements.length - 1][keyRequired],
          seniority: level,
          id: newElements[newElements.length - 1].id,
          userId: parseInt(id, 10),
        };
        focused[name] = newElements[newElements.length - 1].id;
        setSelected([...selected,
          focused]);
        setItemFocused(focused);
      }
    }
  };
  const removeElement = (idToRemove, e) => {
    e.stopPropagation(); // We dont want to activate the event from the parent
    let updateElementsSelected;

    if (!idToRemove) {
      updateElementsSelected = selected
        .filter((el) => el[keyRequired] !== itemFocused[keyRequired]);
    } else {
      updateElementsSelected = selected
        .filter((el) => el.id !== idToRemove);
    }

    setItemFocused(undefined);
    setSelected(updateElementsSelected);
  };

  const focusElementSeniority = (item) => {
    const findElement = selected.find((el) => el.id === item.id);
    if (findElement) {
      setItemFocused(item);
      setLevel(item.seniority);
    } else {
      setItemFocused(undefined);
    }
  };

  const onChangeSeniority = (seniority, itemId) => {
    const itemToUpdate = selected.find((el) => el?.id === itemId);
    if (itemToUpdate) {
      itemToUpdate.seniority = level;
      setSelected([...selected.filter((el) => el?.id !== itemId), itemToUpdate]);
      setItemFocused({ ...itemFocused, seniority: level, name: itemId });
    } else {
      setItemFocused(selected[selected.length - 1]);
    }
  };

  const listToDisplay = selected.map((i) => {
    const elementToFind = elements.find((el) => el.id === i[name]);
    return { ...i, [keyRequired]: elementToFind ? elementToFind[keyRequired] : undefined };
  });

  return (
    <div className="boxAutoCompletionTechnoTool">
      {title === 'no' ? '' : (
        <p className="labelsFormCreate">
          {word}
          s
          {' '}
          needed
        </p>
      ) }

      <Autocomplete
        id="autocompleteSelectAddElement"
        multiple
        classes={{ tag: classes.tag }}
        style={title === 'no' ? { width: '100%', marginBottom: '16px', padding: '8px 0' } : {
          width: '554px',
          marginBottom: '16px',
          padding: '8px 0',
        }}
        autoComplete
        autoHighlight
        value={selected}
        onChange={handleSelect}
        // eslint-disable-next-line no-shadow
        filterOptions={(options, params) => {
          // eslint-disable-next-line no-debugger
          // debugger;
          const filtered = filter(options, params);
          // Suggest the creation of a new value
          if (params.inputValue !== '' && !elements.some((item) => item[goodVariable] === params.inputValue)) {
            const body = {};
            body.title = `Add "${params.inputValue}"`;
            body[goodVariable] = params.inputValue;
            filtered.push(body);
          }
          return filtered;
        }}
        selectOnFocus
        handleHomeEndKeys
        options={options}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          return option[goodVariable];
        }}
        renderOption={(option) => option.title ?? option[goodVariable]}
        freeSolo
        renderInput={(params) => (
          <TextField placeholder={`Type to search or add a ${word.toLowerCase()}`} {...params} variant="outlined" />
        )}
      />
      {
        itemFocused ? (
          <SenioritySelection
            label={itemFocused[keyRequired]}
            itemId={itemFocused.id}
            onChangeSeniority={onChangeSeniority}
            removeElement={(e) => removeElement(itemFocused.id, e)}
            seniority={level}
            setLevel={setLevel}
          />
        ) : undefined
      }
      <div style={{
        display: 'flex', columnGap: '16px', rowGap: '16px', flexWrap: 'wrap',
      }}
      >

        {selected ? listToDisplay
          .map((item, i) => {
            const nameFocused = itemFocused ? itemFocused[keyRequired] : undefined;
            const nameItem = item[keyRequired];
            if (nameFocused === nameItem) return undefined;
            if (item.id !== itemFocused?.id) {
              return (
                <ChipSeniority
                  focusElementSeniority={() => focusElementSeniority(item)}
                  item={item}
                  key={i}
                  label={item[keyRequired]}
                  seniority={item.seniority}
                  edit
                  removeElement={(e) => removeElement(item.id, e)}
                />
              );
            } return undefined;
          }) : ''}
      </div>
    </div>
  );
};

SelectAddElementProfile.defaultProps = {
  title: 'yes',
  selected: [],
};

SelectAddElementProfile.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })),
  word: PropTypes.string.isRequired,
  setSelected: PropTypes.func.isRequired,
  title: PropTypes.string,
  keyRequired: PropTypes.string.isRequired,
  elements: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setElements: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default SelectAddElementProfile;
