import { React, useEffect } from 'react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Step4False from './Step4False';
import Step4True from './Step4True';

const Step4 = (props) => {
  const { values } = useFormikContext();
  const {
    setValidation,
    shallNotPass,
    touched,
  } = props;

  useEffect(() => {
    if (values.signed !== '0') {
      values.chance_winning = 100;
      values.step_sale = (undefined);
    }
  }, []);

  return (
    <Grid container style={{ minHeight: '400px', width: '85%', margin: 'auto' }} alignItems="flex-start">
      {values.signed === '0' || values.signed === undefined
        ? (
          <Step4False />
        )
        : (
          <Step4True shallNotPass={shallNotPass} setValidation={setValidation} touched={touched} />
        )}
    </Grid>
  );
};

Step4.propTypes = {
  setValidation: PropTypes.func.isRequired,
  shallNotPass: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  touched: PropTypes.object.isRequired,
};

export default Step4;
