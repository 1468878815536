import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { api } from '../../api/api';
import ConfirmationComponent from '../../commonComponents/ConfirmationComponent';
import ModalCustom from '../../commonComponents/ModalCustom';

const useStyles = makeStyles(() => ({
  deleteButton: {
    color: '#4B4E4A',
    width: '30px',
    height: '30px',
    padding: '24px',
    opacity: '70%',
    marginLeft: '1.5rem',
  },
}));

const DeleteCompletedActivity = (props) => {
  const { activityId, setActivityRemoved } = props;
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteCompletedActivity = () => {
    api.delete(`api/userActivityProject/${activityId}`)
      .then(() => setActivityRemoved(true))
      .then(() => setActivityRemoved(false))
      .then(() => setTimeout(() => {
        setOpen(false);
      }, 3000));
  };

  return (
    <ModalCustom
      handleClose={handleClose}
      open={open}
      width="676px"
      title="Delete the Activity?"
      childButtonOpenModal={(
        <IconButton disableFocusRipple disableRipple aria-label="delete" className={classes.deleteButton} onClick={handleOpen}>
          <DeleteIcon />
        </IconButton>
      )}
      child={(
        <ConfirmationComponent eventOne={deleteCompletedActivity} eventTwo={handleClose} />
      )}
    />
  );
};

DeleteCompletedActivity.propTypes = {
  activityId: PropTypes.number.isRequired,
  setActivityRemoved: PropTypes.func.isRequired,
};

export default DeleteCompletedActivity;
