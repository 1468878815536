/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import AddIcon from '@material-ui/icons/Add';
import { Grid } from '@material-ui/core';
import FiltersActivities from './FiltersActivities';
import BodyModalAddActivity from './BodyModalAddActivity';
import DeleteCompletedActivities from './DeleteCompletedActivities';
import ModalCustom from '../../commonComponents/ModalCustom';
import { ButtonCustom } from '../../commonComponents/ButtonCustom';

const CompletedActivities = (props) => {
  const [valueNameActivity, setValueNameActivity] = useState('');
  const [projectsFilters, setProjectsFilters] = useState([]);
  const [open, setOpen] = useState(false);
  const [editCompletedActivity, setEditCompletedActivity] = useState(false);
  const [editActivity, setEditActivity] = useState('');
  const [projectName, setProjectName] = useState('');
  const [activityName, setActivityName] = useState('');
  const [status, setStatus] = useState('Ongoing');
  const [activity, setActivity] = useState([]);

  const {
    completedActivities,
    userId,
    currentProjects,
    setActivityAdded,
    setActivityRemoved,
    activitiesList,
    setActivitiesList,
    setNewActivityAdded,
  } = props;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditCompletedActivity(false);
  };

  const handelEditProject = (id) => completedActivities.filter(
    (act) => act.id === id,
  );

  const handelEditActivity = (id) => activitiesList.filter(
    (act) => act.id === id,
  );

  // eslint-disable-next-line no-use-before-define
  const handleWorkedOnProjectsByUser = (id) => workedOnProjectsByUser.filter(
    (user) => user.userId === id,
  );

  const projectsNames = [];
  currentProjects && currentProjects.filter(
    (project) => moment(project.start_date).isBefore(moment(Date.now())),
  ).forEach((proj) => projectsNames.push(proj?.project_name));

  // FILTER PROJECT BY USER
  const workedOnProjectsByUser = currentProjects && currentProjects.filter(
    (project) => moment(project.start_date).isBefore(moment(Date.now())),
  )
    .map((elem) => elem.ForecastingIntensities).flatMap((data) => data)
    .reduce((acc, cur) => {
      acc.push({
        projectId: [cur.projectId],
        userId: cur.userId,
      });
      return acc;
    }, [])
    .reduce((acc, cur) => {
      // eslint-disable-next-line no-shadow
      const user = acc.find(({ userId }) => userId === cur.userId);
      if (user) {
        user.projectId = [...user.projectId, ...cur.projectId];
      } else {
        acc.push({
          projectId: cur.projectId,
          userId: cur.userId,
        });
      }
      return acc;
    }, [])
    .map((user) => ({
      userId: user.userId,
      projectId: user.projectId.filter((x, i) => user.projectId.indexOf(x) === i),
    }));

  const handleOpenEdit = (id, activityId) => {
    setOpen(true);
    setEditCompletedActivity(true);
    setEditActivity(handelEditProject(id));
    setActivity(handelEditActivity(activityId));
  };

  useEffect(() => {
    // no-unused-expressions
    editActivity && setProjectName(
      currentProjects && currentProjects.length > 0 && currentProjects.find(
        (proj) => proj.id === editActivity[0]
          .projectId,
      ).project_name,
    );

    editActivity && activitiesList.length > 0 && setActivityName(
      activitiesList.find((elem) => elem.id === editActivity[0]
        .activityId).activity_name,
    );
  }, [editActivity]);

  const renderActivity = completedActivities && completedActivities.length > 0 ? completedActivities
    .filter((element) => activitiesList.length > 0 && activitiesList.find(
      (elem) => elem.id === element.activityId,
    ).activity_name.toLocaleLowerCase()
      .includes(valueNameActivity.toLocaleLowerCase()))
    .filter((element) => projectsFilters.length === 0
      || projectsFilters.includes(
        currentProjects && currentProjects.find((p) => p.id === element.projectId).project_name,
      ))
    .filter((item) => (status === 'Ongoing' ? !item.endDate : typeof item.endDate === 'string'))
    .map((activity, index) => (
      <div className={index % 2 ? 'itemActivitiesPair' : 'itemActivities'} key={activity.id}>
        <Grid container>
          {status === 'Ongoing'
            ? (
              <Grid item xs={3}>
                <ButtonCustom variant="small" event={() => handleOpenEdit(activity.id, activity.activityId)} text="Edit" />
                <DeleteCompletedActivities
                  activityId={activity.id}
                  setActivityRemoved={setActivityRemoved}
                />
              </Grid>
            )
            : (
              <>
                <Grid item xs={1} />
                <Grid item xs={1}>
                  <DeleteCompletedActivities
                    activityId={activity.id}
                    setActivityRemoved={setActivityRemoved}
                  />
                </Grid>
                <Grid item xs={1} />
              </>
            )}
          {status === 'Ongoing'
            ? (
              <>
                <Grid item xs={3} style={{ margin: 'auto' }}>
                  {' '}
                  {(moment(activity.startDate).format('DD/MM/YYYY'))}
                </Grid>
              </>
            )
            : (
              <>
                <Grid item xs={3} style={{ margin: 'auto' }}>{`${(moment(activity.startDate).format('DD/MM/YYYY'))} - ${(moment(activity.endDate).format('DD/MM/YYYY'))}`}</Grid>
              </>
            )}
          <Grid item xs={3} style={{ margin: 'auto' }}>
            {currentProjects && currentProjects.length > 0 && currentProjects.find(
              (proj) => proj.id === activity?.projectId,
            )?.project_name}
          </Grid>
          <Grid item xs={3} style={{ margin: 'auto' }}>
            {activitiesList.length > 0 && activitiesList.find(
              (elem) => elem.id === activity.activityId,
            ).activity_name }
          </Grid>
        </Grid>
      </div>
    )) : (
      <p id="activityNotAvailable">
        No activities available
      </p>
  );

  return (
    <div id="completedActivities">
      <div className="headBoxProfile">
        <p>Activities</p>
        {status !== 'Ongoing'
          ? <ButtonCustom variant="small" event={() => setStatus('Ongoing')} text="See ongoing activities" />
          : (
            <ModalCustom
              handleClose={handleClose}
              open={open}
              width="676px"
              title={!editCompletedActivity ? 'New Activity' : 'Edit an Activity'}
              childButtonOpenModal={<ButtonCustom variant="small" event={handleOpen} text="New Activity" icon={<AddIcon />} />}
              child={(
                <BodyModalAddActivity
                  handleClose={handleClose}
                  userId={userId}
                  currentProjects={currentProjects}
                  setActivityAdded={setActivityAdded}
                  setEditCompletedActivity={setEditCompletedActivity}
                  editcompletedactivity={editCompletedActivity}
                  editActivity={editActivity}
                  projectName={projectName}
                  activityName={activityName}
                  setActivityName={setActivityName}
                  activitystartdate={editActivity && editActivity[0].startDate}
                  projectId={editActivity && editActivity[0].projectId}
                  activityId={editActivity && editActivity[0].activityId}
                  setActivitiesList={setActivitiesList}
                  activitiesList={activitiesList}
                  completedActivityId={editActivity && editActivity[0].id}
                  activity={activity}
                  workedOnProjectsByUser={handleWorkedOnProjectsByUser(userId)}
                  setNewActivityAdded={setNewActivityAdded}
                />
              )}
            />
          )}
      </div>
      <FiltersActivities
        setValueNameActivity={setValueNameActivity}
        projectsNames={projectsNames}
        projectsFilters={projectsFilters}
        setProjectsFilters={setProjectsFilters}
        setStatus={setStatus}
        status={status}
      />
      <hr />
      <Grid container id="titlesTableActivities">
        <Grid item xs={3} />
        <Grid item xs={3} style={{ textAlign: 'center' }}>Date</Grid>
        <Grid item xs={3} style={{ textAlign: 'center' }}>Project</Grid>
        <Grid item xs={3} style={{ textAlign: 'center' }}>Activity</Grid>
      </Grid>
      <div>
        {renderActivity}
      </div>
    </div>
  );
};

CompletedActivities.defaultProps = {
  userId: undefined,
  currentProjects: [],
  activitiesList: [],
};

CompletedActivities.propTypes = {
  completedActivities: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  // projects: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  userId: PropTypes.number,
  currentProjects: PropTypes.arrayOf(PropTypes.shape()),
  activitiesList: PropTypes.arrayOf(PropTypes.shape()),
  setActivityAdded: PropTypes.func.isRequired,
  setActivityRemoved: PropTypes.func.isRequired,
  setActivitiesList: PropTypes.func.isRequired,
  setNewActivityAdded: PropTypes.func.isRequired,
};

export default CompletedActivities;
