import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { api } from '../../api/api';
import FormikAutoCompleteCreate from '../formik/FormikAutoCompleteCreate';

const Client = (props) => {
  const { status, label } = props;

  const { values, setFieldValue } = useFormikContext();
  const [clientsList, setClientsList] = useState([]);

  useEffect(() => {
    let mounted = true;
    api
      .get('api/clients')
      .then((res) => {
        if (mounted) {
          setClientsList(res.data);
        }
      });
    return () => {
      mounted = false;
    };
  }, []);

  const handleCreate = (newValue) => api
    .post('api/clients', newValue)
    .then((response) => {
      setClientsList([...clientsList, response.data]);
      setFieldValue({ ...values, Client: response.data });
      return response.data;
    })
    .catch((err) => {
      throw new Error(err);
    });

  return (
    <>
      {status === 'createProject' ? (
        <>
          <label htmlFor="clientDemand" className="labelsFormCreate">
            Client
            <span className="mandatoryInputs">*</span>
          </label>
          <p className="helperText">If the client you are looking for does not exist, press enter to add a new one.</p>
        </>
      ) : ''}
      <FormikAutoCompleteCreate
        name="Client"
        options={clientsList}
        disableClearable
        status={status}
        placehold="Select a client"
        onCreate={handleCreate}
        label={label}
      />
    </>
  );
};

Client.propTypes = {
  status: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default Client;
