import React from 'react';
import PropTypes from 'prop-types';

const HorizontalBarTotalIntensity = (props) => {
  const {
    totalIntensity, isDouble, absent, word,
  } = props;

  let colorFill = '';

  if (totalIntensity >= 90 && totalIntensity <= 110) {
    colorFill = '#E3F7E7';
  } else if (totalIntensity <= 70 || totalIntensity >= 130) {
    colorFill = '#FCEAE9';
  } else {
    colorFill = '#FFF8E8';
  }

  let goodToDisplay;

  if (isDouble && absent) {
    goodToDisplay = (
      <div className="totalIntensityForecastingBarDouble totalIntensityForecastingBar" style={{ backgroundColor: 'rgba(242, 242, 255, 1)' }} />
    );
  } else if (isDouble) {
    goodToDisplay = (
      <div className="totalIntensityForecastingBarDouble totalIntensityForecastingBar" style={{ backgroundColor: colorFill }} />
    );
  } else if (absent) {
    goodToDisplay = (
      <div className="totalIntensityForecastingBarSimple totalIntensityForecastingBar" style={{ backgroundColor: 'rgba(242, 242, 255, 1)', color: 'rgba(156, 145, 189, 0.9)' }}>
        <p>
          {word}
        </p>
      </div>
    );
  } else {
    goodToDisplay = (
      <div className="totalIntensityForecastingBarSimple totalIntensityForecastingBar" style={{ backgroundColor: colorFill }}>
        <p>
          {Math.round(totalIntensity)}
          %
        </p>
      </div>
    );
  }

  return goodToDisplay;
};

HorizontalBarTotalIntensity.defaultProps = {
  absent: false,
  word: '',
  totalIntensity: 0,
};

HorizontalBarTotalIntensity.propTypes = {
  totalIntensity: PropTypes.number,
  word: PropTypes.string,
  isDouble: PropTypes.bool.isRequired,
  absent: PropTypes.bool,
};

export default HorizontalBarTotalIntensity;
