/* eslint-disable react/prop-types */
import React from 'react';
import { TextField } from '@material-ui/core';
import { Field } from 'formik';
import PropTypes from 'prop-types';

const CustomTextArea = ({
  field,
  ...props
}) => (
  <TextField
    {...field}
    {...props}
    multiline
    rows={props.rows}
    fullWidth
    placeholder={props.placeholder}
    inputProps={{
      style: {
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: '8px',
        paddingLeft: '16px',
        paddingTop: '12px',
      },
    }}
  />
);

const TextAreaFormik = ({ name, placeholder, rows }) => (
  <Field
    name={name}
    component={CustomTextArea}
    placeholder={placeholder}
    rows={rows}
  />
);

TextAreaFormik.defaultProps = {
  placeholder: '',
  rows: 5,
};

TextAreaFormik.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  rows: PropTypes.number,
};

export default TextAreaFormik;
