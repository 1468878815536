import { Box, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles({
  element: {
    margin: '1rem 0 1rem 0',
    borderRadius: '10px',
  },
});

const TableLoader = ({ height }) => {
  const classes = useStyles();
  return (
    <Box>
      <Skeleton variant="rect" width={840} height={height} className={classes.element} />
    </Box>
  );
};

TableLoader.defaultProps = {
  height: 50,
};

TableLoader.propTypes = {
  height: PropTypes.number,
};

export default TableLoader;
