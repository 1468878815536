/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { ThemeProvider, createTheme, makeStyles } from '@material-ui/core/styles';
import TodayIcon from '@material-ui/icons/Today';
import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Box } from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  dateLabel: {
    display: 'flex',
    gap: '4px',
  },
  error: {
    marginTop: '4px',
    color: 'red',
    fontSize: '0.8rem',
  },
  errorInput: {
    borderColor: 'red !important',
  },
  errorLabel: {
    color: 'red !important',
  },
  smallerInput: {
    '& .MuiOutlinedInput-input': {
      padding: '1px 8px',
      fontSize: '2rem',
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(1px, 8px) scale(1)',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(1px, -6px) scale(0.75)',
    },
  },
}));

const CustomDateStart = ({
  field,
  form: { touched, errors },
  ...props
}) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const classes = useStyles();
  const [startDate, setStartDate] = useState(null);
  const [invalidDateWarning, setInvalidDateWarning] = useState(false);
  const {
    label, mandatory, handleStartDateChange, title,
  } = props;

  const customTheme = createTheme({
    overrides: {
      MuiOutlinedInput: {
        root: {
          '&$error': {
            '& $notchedOutline': {
              borderColor: 'red',
            },
          },
          '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
            borderColor: '#FFB81C',
          },
          '&$focused $notchedOutline': {
            borderColor: '#FFB81C',
          },
        },
        notchedOutline: {
          borderRadius: '8px',
        },
        input: {
          padding: '1px 8px',
          fontSize: '1rem',
        },
      },
      MuiInputLabel: {
        outlined: {
          '&$focused': {
            color: '#FFB81C',
          },
        },
      },
    },
  });

  const isValidDate = (date) => {
    const dateFormat = 'DD-MM-yyyy';
    return moment(date, dateFormat, true).isValid();
  };

  const handleStartDChange = (date) => {
    setFieldTouched(field.name, true);
    setStartDate(date);

    if (date) {
      const isValid = isValidDate(date);
      setInvalidDateWarning(!isValid);

      if (isValid) {
        setFieldValue(field.name, date.toISOString());
      } else {
        setFieldValue(field.name, null);
      }
    } else {
      setInvalidDateWarning(false);
      setFieldValue(field.name, null);
    }
  };
  return (
    <Box>
      {title
        ? (
          <div className={classes.dateLabel}>
            <p className="labelsFormCreate">{title}</p>
            { mandatory && <span className="mandatoryInputs">*</span>}
          </div>
        ) : null}
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <ThemeProvider theme={customTheme}>
          <KeyboardDatePicker
            {...field}
            {...props}
            id="dateCreationStartUdpate"
            name={field.name}
            value={field.value}
            placeholder="dd-mm-yyyy"
            autoOk
            fullWidth
            format="DD-MM-yyyy"
            size="small"
            inputVariant="outlined"
            InputAdornmentProps={{ position: 'start' }}
            onChange={handleStartDateChange || handleStartDChange}
            keyboardIcon={<TodayIcon className={classes.iconCalendar} />}
            className={`${invalidDateWarning ? classes.errorInput : ''} ${classes.smallerInput}`}
            InputLabelProps={{
              shrink: true,
              className: invalidDateWarning ? classes.errorLabel : '',
            }}
            error={invalidDateWarning}
          />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
      {invalidDateWarning && (
      <div className={classes.error}>Please enter a valid date</div>
      )}
    </Box>
  );
};

const StartDateSelection = ({
  title, label, mandatory, handleStartDateChange, name,
}) => (
  <Field
    name={name}
    component={CustomDateStart}
    label={label}
    title={title}
    mandatory={mandatory}
    handleStartDateChange={handleStartDateChange}
  />
);

StartDateSelection.defaultProps = {
  label: '',
  mandatory: false,
};

export default StartDateSelection;
