import React, { useState } from 'react';
import {
  BrowserRouter as Router, Switch, Route,
} from 'react-router-dom';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import moment from 'moment/min/moment-with-locales';
import Navbar from './components/Navbar';
import Dashboard from './components/Dashboard';
import EmployeesList from './components/ListEmployees/EmployeesList';
import Project from './components/projectDetailed/Project';
import ProfilePage from './components/ProfilePage/ProfilePage';
import GeneralFinance from './components/generalFinance/GeneralFinance';
import 'moment/locale/en-gb';
import Forecasting from './components/Forecasting/Forecasting';
import './scss/main.scss';
import Authenticator from './auth/Authenticator';
import AccountInitializer from './auth/AccountInitializer';
import ShowOnRoles from './utils/ShowOnRoles';
import NotFound from './components/NotFound';

moment.locale('en-gb');
moment.updateLocale('en-gb', {
  relativeTime: {
    future: 'in %s',
    past: '%s',
    s: 'a few seconds ago',
    ss: '%d seconds ago',
    m: 'a minute ago',
    mm: '%d minutes ago',
    h: 'an hour ago',
    hh: '%d hours ago',
    d: 'yesterday',
    dd: '%d days ago',
    M: 'a month ago',
    MM: '%d months ago',
    y: 'a year ago',
    yy: '%d years ago',
  },
});
const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(255, 184, 28)',
    },
    // secondary: {
    //   main: 'rgb(255, 184, 28)',
    // },
  },
  shadows: ['none'],
  shape: {
    borderRadius: '8px',
  },
  overrides: {
    MuiAutocomplete: {
      input: {
        '&:first-child': {
          paddingLeft: '0px !important',
        },
      },
    },
    // MuiLinearProgress: {
    //   barColorPrimary: {
    //     backgroundColor: undefined,
    //   },
    // },
    // MuiFormLabel: {
    //   root: {
    //     top: '-3px',
    //   },
    // },
    MuiInputBase: {
      root: {
        paddingLeft: '0px !important',
      },
    },
    MuiSelect: {
      root: {

      },
      select: {
        // display: 'flex',
        // alignItems: 'center'
        '&:focus': {
          backgroundColor: 'white',
          // borderRadius: '8px',
        },
        // '&:hover': {
        //   textDecoration: 'none',
        //   border: 'none',
        //   borderBottom: 'none',
        // },
      },

    },
    MuiInput: {
      underline: {
        '&:after': {
          borderBottom: 'none',
        },
        '&:before': {
          borderBottom: 'none',
        },
        '&:hover': {
          borderBottom: 'none',
          '&:not(.Mui-disabled)': {
            '&:before': {
              borderBottom: 'none',

            },
          },
        },

      },
      // focused: {
      //   backgroundColor: 'yellow',
      // },
    },

    // MuiInput: {
    //   root: {
    //     textDecoration: 'none',
    //   },
    //   '&:before': {
    //     textDecoration: 'none',
    //   },
    // },

    MuiOutlinedInput: {
      // select: {
      // },
      input: {
        // marginLeft: '16px',
        // paddingLeft: '16px',
        '&::placeholder': {
          fontSize: '16px',
          color: '#707987',
          opacity: '1',
        },
      },

      root: {
        // paddingLeft: '0px',
        height: '40px',
        width: '100%',
        // '& $notchedOutline': {
        //   borderColor: 'green',
        // },
        '&:hover $notchedOutline': {
          borderColor: 'rgb(255, 184, 28)',
        },
        // '&$focused $notchedOutline': {
        //   borderColor: 'purple',
        // },
        '&&& $input': {
          // padding: '0px 1px',

          // borderRadius: '8px',
          // height: '40px',
        },
      },
    },
  },
});

const App = () => {
  const [keywordSearch, setKeywordSearch] = useState('');

  return (
    <Authenticator>
      <AccountInitializer>
        <ThemeProvider theme={theme}>
          <Router>
            <Navbar setKeywordSearch={setKeywordSearch} />
            <Switch>
              <Route exact path="/">
                <Dashboard keywordSearch={keywordSearch} setKeywordSearch={setKeywordSearch} />
              </Route>
              <Route path="/forecasting">
                <ShowOnRoles allowedRoleIds={[1, 2, 4]} page>
                  <Forecasting />
                </ShowOnRoles>
              </Route>
              <Route path="/projects/:id">
                <Project />
              </Route>
              <Route path="/employeesList">
                <EmployeesList />
              </Route>
              <Route path="/employees/:id">
                <ProfilePage />
              </Route>
              <Route path="/generalFinance">
                <ShowOnRoles allowedRoleIds={[1, 2, 4]} page>
                  <GeneralFinance />
                </ShowOnRoles>
              </Route>
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </Router>
        </ThemeProvider>
      </AccountInitializer>
    </Authenticator>
  );
};

export default App;
