import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { api } from '../../api/api';
import FormikAutocomplete from '../formik/FormikAutocomplete';

const Manager = (props) => {
  const { status, label } = props;

  const [managersList, setManagersList] = useState([]);

  useEffect(() => {
    let mounted = true;
    api
      .get('api/users?RolesIds=1,2')
      .then((res) => {
        if (mounted) {
          setManagersList(res.data);
        }
      });
    return () => {
      mounted = false;
    };
  }, []);

  return (
    <Box>
      {status === 'createProject' ? (
        <Box display="flex" className="formFieldLabel" gridGap="5px">
          <label htmlFor="clientDemand" className="labelsFormCreate">Manager</label>
          <span className="mandatoryInputs">*</span>
        </Box>
      ) : ''}
      <FormikAutocomplete
        options={managersList}
        name="Manager"
        getOptionLabel={(option) => (option ? `${option.first_name} ${option.last_name}` : '')}
        disableClearable
        placehold="Select a manager"
        status={status}
        label={label}
      />
    </Box>
  );
};

Manager.propTypes = {
  status: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default Manager;
