import { PublicClientApplication } from '@azure/msal-browser';
import axios from 'axios';
import moment from 'moment';
import { msalConfig } from './authConfig';

/*
Pour tout appel axios => on appel une grtosse fonction qui continet configAxiosBearerToker,
refreshTokenSilent

a la fin de la fonction on reconfigure axios bearer toekn

*/

const msalInstance = new PublicClientApplication(msalConfig);

const isAuthenticated = (instance) => instance.getAllAccounts().length > 0;

const getBearerToken = () => {
  const isAuth = isAuthenticated(msalInstance);

  if (isAuth) {
    // Find the auth token from localStorage
    let credentials;
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(localStorage)) {
      if (key.includes('idtoken')) {
        credentials = { key: JSON.parse(value) };
      }
    }
    return credentials.key.secret;
  }
  return undefined;
};

const refreshTokenSilent = async () => {
  const isAuth = isAuthenticated(msalInstance);
  if (isAuth) {
    const account = msalInstance.getActiveAccount();

    const silentRequest = {
      scopes: ['openid profile email User.Read'],
      account,
      forceRefresh: true,
    };

    const isExpired = moment.unix(account.idTokenClaims.exp).isBefore(moment());

    if (isExpired) {
      // eslint-disable-next-line max-len
      await msalInstance.acquireTokenSilent(silentRequest).catch(() => (msalInstance.loginRedirect()));
    }
  }
};

const api = axios.create({
  baseURL: process.env.REACT_APP_BACK_URL || 'http://localhost:5000/',
});

const injectAuthTokenInterceptor = async (config) => {
  await refreshTokenSilent();
  const bearerToken = getBearerToken();
  const result = { ...config };
  if (bearerToken) result.headers.common.Authorization = `Bearer ${bearerToken}`;
  return result;
};

api.interceptors.request.use(injectAuthTokenInterceptor);

export {
  api,
  msalInstance,
};
