import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import _ from 'lodash';
import { Alert } from '@material-ui/lab';
import { api } from '../../../api/api';
import TableBugetTotal from './TableBudgetTotal';
import BudgetTimeAndMaterial from './BudgetTimeAndMaterial';
import BudgetWorkPackage from './BudgetWorkPackage';
import CreateWPContent from './CreateWPContent';
import { ButtonSimple } from '../../../commonComponents/ButtonCustom';
import ModalCustom from '../../../commonComponents/ModalCustom';

const useStyles = makeStyles({
  boxMessage: {
    padding: '15px',
    boxShadow: '1px 1px 5px 1px rgb(240 176 21 / 32%)',
    borderRadius: '5px',
    fontSize: '15px',
    color: 'gray',
  },
  exportLink: {
    color: 'rgba(0, 0, 0, 0.87)',
    textDecoration: 'none',
    float: 'right',
  },
});

const TimeBudget = (props) => {
  const {
    ProjectId, budgetType, edit, currency, convertCurrencyValue,
  } = props;

  const [workPakagesData, setWorkPackagesData] = useState([]);
  const [change, setChange] = useState(false);
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    api
      .get(`api/snapshots?ProjectId=${ProjectId}`)
      .then((res) => res.data)
      .then((data) => setWorkPackagesData(data));
  }, [change]);

  const workPakagesSorted = Object.values(_.groupBy(workPakagesData, 'workPackageName'));
  const groupByTimeStamp = Object.values(_.groupBy(workPakagesData, 'timeStamp'));
  const headers = [
    {
      label: 'Sprint', key: 'timeStamp', colSpan: 2, type: 'tm', width: '10%',
    },
    {
      label: 'Baseline', key: 'baseline', colSpan: 1, type: 'global', width: '10%',
    },
    {
      label: 'Forecast', key: 'forecast', colSpan: 1, type: 'tm', width: '10%',
    },
    {
      label: 'Agg. Forecast', key: 'aggForecast', colSpan: 1, type: 'tm', width: '10%',
    },
    {
      label: 'Work', key: 'worked', colSpan: 1, type: 'tm', width: '10%',
    },
    {
      label: 'Agg. Work', key: 'aggWorked', colSpan: 1, type: 'tm', width: '10%',
    },
    {
      label: '% of Completion', key: 'poc', colSpan: 1, type: 'tm', width: '10%',
    },
    {
      label: 'Deviation', key: 'deviation', colSpan: 1, type: 'global', width: '10%',
    },
    {
      label: 'Start Date', key: 'startingDate', colSpan: 2, type: 'wp', width: '20%',
    },
  ];

  const arrayBuilt = groupByTimeStamp.map((ts) => ts.reduce((acc, val) => ({
    baseline: acc.baseline + val.baseline,
    forecast: acc.forecast + val.forecast,
    worked: acc.worked + val.worked,
    timeStamp: val.timeStamp,
    startingDate: val.startingDate,
  }), { baseline: 0, forecast: 0, worked: 0 }));
  let goodToDisplay;

  if (budgetType === 'Time and material') {
    goodToDisplay = workPakagesData.length === 0 && !edit
      ? (
        <Alert style={{ marginBottom: '1rem' }} severity="info">
          At the moment, there is no information, regarding the Time Budget of this project.
          <br />
          <br />
          If you would like to add some, you can easily do so, by clicking on
          &quot;Edit project&quot; button, on the top right part of this section.
        </Alert>
      ) : (
        <BudgetTimeAndMaterial
          workPakagesData={workPakagesData}
          change={change}
          setChange={setChange}
          budgetType={budgetType}
          ProjectId={ProjectId}
          edit={edit}
        />
      );
  } else if (budgetType === 'Work Package') {
    goodToDisplay = workPakagesData.length === 0 && !edit
      ? (
        <Alert style={{ marginBottom: '1rem' }} severity="info">
          At the moment, there is no information, regarding the Time Budget of this project.
          <br />
          <br />
          If you would like to add some, you can easily do so, by clicking on
          &quot;Edit project&quot; button, on the top right part of this section.
        </Alert>
      ) : (
        <BudgetWorkPackage
          workPakagesData={workPakagesData}
          change={change}
          setChange={setChange}
          budgetType={budgetType}
          ProjectId={ProjectId}
          edit={edit}
        />
      );
  } else {
    goodToDisplay = (
      <Box className={classes.boxMessage}>
        No budget type has been registered yet.
        <br />
        <br />
        In order to add information, related to the Time Budget of the project,
        you may want to define a budget type first (you can easily do so, by clicking on
        &quot;Edit the project&quot; button, on the top right part of this section)
      </Box>
    );
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box minHeight="calc(100vh - 370px)" margin="30px">

      {edit && budgetType === 'Work Package' && workPakagesData.length > 0 ? (
        <ModalCustom
          handleClose={handleClose}
          open={open}
          width="676px"
          title="Create workpackage"
          childButtonOpenModal={(
            <div className="addNewThingInProject">
              <ButtonSimple text="Work Package" border="1px solid black" event={handleOpen} icon={<AddIcon />} />
            </div>

          )}
          child={(
            <CreateWPContent
              ProjectId={ProjectId}
              setOpen={setOpen}
              change={change}
              setChange={setChange}
              budgetType={budgetType}
              workPackagesData={workPakagesData}
            />
          )}
        />
      ) : ''}
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {workPakagesData ? workPakagesSorted.map((item, ind) => {
              const key = item[0].ProjectId + ind;
              let lheaders = headers;
              let title = 'Workpackage';
              if (budgetType === 'Time and material') {
                lheaders = _.filter(headers, (o) => o.type === 'tm');
                title = 'TimeAndMaterial';
              }
              return (
                <div key={key}>
                  <TableBugetTotal
                    tableType={title}
                    headers={lheaders}
                    budgetType={budgetType}
                    key={item[0].ProjectId}
                    data={item}
                    edit={edit}
                    change={change}
                    setChange={setChange}
                    ProjectId={ProjectId}
                    currency={currency}
                    convertCurrencyValue={convertCurrencyValue}
                  />
                </div>
              );
            }) : ''}
          </Grid>
        </Grid>

        {budgetType === 'Work Package' && workPakagesSorted.length > 0
          ? (
            <TableBugetTotal
              tableType="Total-Workpackage"
              headers={_.filter(headers, (o) => o.type === 'global' || o.type === 'tm')}
              budgetType={budgetType}
              data={arrayBuilt}
            />
          )
          : ''}
      </div>

      {goodToDisplay}

    </Box>
  );
};

TimeBudget.propTypes = {
  ProjectId: PropTypes.number.isRequired,
  budgetType: PropTypes.string.isRequired,
  edit: PropTypes.bool.isRequired,
  currency: PropTypes.string.isRequired,
  convertCurrencyValue: PropTypes.func.isRequired,
};

export default TimeBudget;
