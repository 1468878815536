import { React, useEffect } from 'react';
import { EventType } from '@azure/msal-browser';
import { useIsAuthenticated, AuthenticatedTemplate, useMsal } from '@azure/msal-react';

// eslint-disable-next-line react/prop-types
const Authenticator = ({ children }) => {
  const {
    instance,
  } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    const handleAuth = async () => {
      if (!isAuthenticated) {
        const accountss = instance.getAllAccounts();
        if (accountss.length > 0) {
          instance.setActiveAccount(accountss[0]);
        }

        instance.addEventCallback((event) => {
          // set active account after redirect
          if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
            const { account } = event.payload;
            instance.setActiveAccount(account);
          }
        }, (error) => {
          // eslint-disable-next-line no-console
          console.log('error', error);
        });

        await instance.handleRedirectPromise();
        const account = instance.getActiveAccount();

        if (!account) {
          // redirect anonymous user to login page
          instance.loginRedirect();
        }
      }
    };
    handleAuth();
  }, [instance, isAuthenticated]);

  return (
    <AuthenticatedTemplate>
      {children}
    </AuthenticatedTemplate>
  );
};

export default Authenticator;
