/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {
  useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { api } from '../api/api';
import ModalCustom from '../commonComponents/ModalCustom';
import { fetchForecastings } from '../redux/ForecastingsSlice';
import { projectsAdded } from '../redux/ProjectSlice';
import DashboardHat from './DashboardHat';
import CardProject from './cardDashboard/CardProject';
import ManageCollaborators from './creationproject/ManageCollaborators';
import CardDashboardLoader from './loaders/CardDashboardLoader';

const Dashboard = (props) => {
  const { keywordSearch, setKeywordSearch } = props;

  const dispatch = useDispatch();

  const [projects, setProjects] = useState([]);
  const [projectTypeFilter, setProjectTypeFilter] = useState('');
  const [budgetTypeFilter, setBudgetTypeFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [success, setSuccess] = useState(false);
  const [intensitiesAllProjects, setIntensitiesAllProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [projectTime, setProjectTime] = useState('?ProjectTime=current');
  const [openModalCollaborators, setOpenModalCollaborators] = useState(false);
  const [projectId, setProjectId] = useState();

  const startDate = moment().startOf('isoWeek').format('YYYY-MM-DD');
  const endDate = moment().endOf('isoWeek').format('YYYY-MM-DD');

  useEffect(() => () => {
    setKeywordSearch('');
  }, []);

  const forecastingsRedux = useSelector(
    (state) => state.forecastingsState?.forecastings
      .filter((forecasting) => moment(forecasting.date).isBetween(startDate, endDate, undefined, '[]')) ?? [],
  );
  const loadData = async () => {
    setIsLoading(true);
    const projectPromise = api
      .get(`api/projects${projectTime}`);

    // const forecastingsPromise = api.get('api/forecasting/weeklyIntensities');
    const params = {
      startDate: moment().startOf('isoWeek').format('YYYY-MM-DD'),
      endDate: moment().endOf('isoWeek').format('YYYY-MM-DD'),
    };
    dispatch(fetchForecastings());

    // const [resProject, resForecastings] = await Promise.all(
    // [projectPromise, forecastingsPromise]);
    const [resProject] = await Promise.all([projectPromise]);
    dispatch(projectsAdded(resProject.data));
    setProjects(resProject.data);
    setIntensitiesAllProjects(forecastingsRedux);
    setIsLoading(false);
  };

  useEffect(() => {
    loadData();
  }, [projectTime]);

  const projOverlapsDate = (project, date) => {
    const projStart = project.start_date ? moment(project.start_date) : moment('0001-01-01', 'YYYY-MM-DD');
    return (
      moment(date, 'YYYY-MM-DD').isSameOrBefore(projStart)
    );
  };

  const projEndInlapsDate = (project, date) => {
    const projEnd = project.end_date ? moment(project.end_date) : moment('9999-12-31', 'YYYY-MM-DD');
    return (
      moment(date, 'YYYY-MM-DD').isSameOrAfter(projEnd)
    );
  };

  const datesMatch = (proj) => (
    projOverlapsDate(proj, start) && projEndInlapsDate(proj, end))
    || (start === '' && projEndInlapsDate(proj, end))
    || (projOverlapsDate(proj, start) && end === '')
    || (start === '' && end === '');
  return (
    <div id="dashboard">
      <DashboardHat
        setProjectTime={setProjectTime}
        projectTime={projectTime}
        setProjectTypeFilter={setProjectTypeFilter}
        projectTypeFilter={projectTypeFilter}
        statusFilter={statusFilter}
        setStatusFilter={setStatusFilter}
        setStart={setStart}
        setEnd={setEnd}
        start={start}
        end={end}
        success={success}
        setSuccess={setSuccess}
        budgetTypeFilter={budgetTypeFilter}
        setBudgetTypeFilter={setBudgetTypeFilter}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        openModalCollaborators={openModalCollaborators}
        setOpenModalCollaborators={setOpenModalCollaborators}
        setProjectId={setProjectId}
        projectId={projectId}

      />
      <ModalCustom />
      <div className="listProjects" id="gridListProject">
        {isLoading ? (
          <>
            <CardDashboardLoader />
            <CardDashboardLoader />
            <CardDashboardLoader />
            <CardDashboardLoader />
            <CardDashboardLoader />
            <CardDashboardLoader />
            <CardDashboardLoader />
            <CardDashboardLoader />
            <CardDashboardLoader />
          </>
        )
          : projects
            .filter((item) => (projectTypeFilter ? item.project_type === projectTypeFilter : item))
            .filter((item) => (statusFilter ? item.status === statusFilter : item))
            .filter((item) => (budgetTypeFilter ? item.time_budget_type === budgetTypeFilter
              : item))
            .filter((item) => (keywordSearch
              ? item.project_name.toLocaleLowerCase().includes(keywordSearch.toLocaleLowerCase())
          || item.Client.client_name.toLocaleLowerCase().includes(keywordSearch.toLocaleLowerCase())
          || item?.Manager?.first_name.toLocaleLowerCase().includes(keywordSearch.toLocaleLowerCase())
          || item?.Manager?.last_name.toLocaleLowerCase().includes(keywordSearch.toLocaleLowerCase())
          || (`${item?.Manager?.first_name.toLocaleLowerCase()} ${item?.Manager?.last_name.toLocaleLowerCase()}`)
            .includes(keywordSearch.toLocaleLowerCase())
            // || item.Collaborators.map((el) => el.first_name)
            //   .some((name) => name.toLocaleLowerCase()
            // .includes(keywordSearch.toLocaleLowerCase()))
            // || item.Collaborators.map((el) => el.last_name)
            //   .some((name) => name.toLocaleLowerCase()
            // .includes(keywordSearch.toLocaleLowerCase()))
              : item))
            .filter((proj) => datesMatch(proj) && proj)
            .map((project) => (
              <Link
                key={project.project_name}
                to={`/projects/${project.id}`}
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                <CardProject
                  clientName={project.Client.client_name}
                  key={project.project_name}
                  projectName={project.project_name}
                  startDate={project.start_date}
                  endDate={project.end_date}
                  budgettype={project.time_budget_type}
                  progression={project.progression}
                  chanceWinning={project.chance_winning}
                  projectType={project.project_type}
                  status={project.status}
                  fullyStaff={project.fully_staffed.toString()}
                  collaborators={project.ForecastingIntensities}
                  manager={project.Manager}
                  projectTime={projectTime}
                  stepSale={project.step_sale}
                  project={project}
                  ManagerId={project.ManagerId}
                  ProjectId={project.id}
                  forecastings={intensitiesAllProjects}
                />
              </Link>
            ))}
        <ManageCollaborators
          openModalCollaborators={openModalCollaborators}
          setOpenModalCollaborators={setOpenModalCollaborators}
          projectId={projectId}
          success={success}
        />
      </div>
    </div>
  );
};

Dashboard.propTypes = {
  keywordSearch: PropTypes.string.isRequired,
  setKeywordSearch: PropTypes.func.isRequired,
};

export default Dashboard;
