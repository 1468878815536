import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import TodayIcon from '@material-ui/icons/Today';
import SelectTime from '../../commonComponents/SelectTime';

const useStyles = makeStyles(() => ({
  title: {
    top: '90%',
  },
  rightBoxFiltersForecastings: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    gap: '1rem',
  },
  filterForecasting: {
    marginBottom: '15px',
    marginLeft: '24px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  FormControl: {
    marginRight: '10px',
  },
  icon: {
    marginLeft: '10px',
  },
  button: {
    backgroundColor: 'white',
    border: '1px solid #B1B1B1',
    height: '36px',
    boxShadow: 'none',
    textTransform: 'none',
    color: '#707987',
    width: '140px',
  },
}));

const FiltersForecasting = (props) => {
  const {
    setSearchUser, setTimeUnit, timeUnit, setEndDate, setStartDate,
  } = props;
  const classes = useStyles();

  const handleDatesChange = (e) => {
    const selectedDate = e.target.value !== '' ? e.target.value : moment();
    setStartDate(moment(selectedDate).subtract(6, `${timeUnit}s`).startOf(`${timeUnit}s`));
    setEndDate(moment(selectedDate).add(6, `${timeUnit}s`).endOf(`${timeUnit}s`));
  };
  const backToToday = () => {
    setStartDate(moment(new Date()).subtract(6, `${timeUnit}s`).startOf(timeUnit));
    setEndDate(moment(new Date()).add(6, `${timeUnit}s`).endOf(timeUnit));
  };
  return (
    <>
      <div id="boxForecastingList">
        <div className="titleAndAddCollaborator">
          <h1>Forecasting</h1>
        </div>
        <div className={classes.filterForecasting}>
          <div style={{ display: 'flex' }}>
            <FormControl className={classes.FormControl}>
              <TextField
                id="nameFilterForecasting"
                onChange={(event) => setSearchUser(event.target.value)}
                placeholder="Search a name"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon className={classes.icon} />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <div>
              <SelectTime timeUnit={timeUnit} setTimeUnit={setTimeUnit} />
            </div>
          </div>
          <div className="rightBoxFiltersForecastings">

            <Button
              variant="contained"
              className={classes.button}
              startIcon={<TodayIcon />}
              onClick={backToToday}
            >
              Today
            </Button>
            <TextField
              style={{ marginRight: '0px', width: '140px' }}
              variant="outlined"
              type="date"
              onChange={handleDatesChange}
              id="dateSelectorFilterForecastings"
            />
          </div>
        </div>
        <hr style={{ display: 'flex', width: '100%' }} />
      </div>
    </>
  );
};

FiltersForecasting.propTypes = {
  setSearchUser: PropTypes.func.isRequired,
  setTimeUnit: PropTypes.func.isRequired,
  timeUnit: PropTypes.string.isRequired,
  setStartDate: PropTypes.func.isRequired,
  setEndDate: PropTypes.func.isRequired,
};

export default FiltersForecasting;
