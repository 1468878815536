/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import UserContext from '../contexts/UserContext';
import { api } from '../api/api';
import AccountInitLoader from '../components/loaders/AccountInitLoader';

/**
 * This component allow us to check if an account exists in the DB, if not, we create it
 */
const AccountInitializer = ({ children }) => {
  const { instance } = useMsal();

  const [user, setUser] = useState();

  const account = instance.getActiveAccount();

  useEffect(() => {
    const handleAccountInit = async () => {
      const res = await api.get(`api/users?AdeccoLocalAccount=${account.localAccountId}`);
      if (res.data) {
        setUser(res.data); // in 'development', we are not into this condition
      } else {
        try {
          const userPosted = await api.post('api/users', {});
          setUser(userPosted.data);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      }
    };
    handleAccountInit();
  }, []);

  return user ? (
    <UserContext.Provider value={user}>
      {children}
    </UserContext.Provider>
  ) : <AccountInitLoader />;
};

export default AccountInitializer;
