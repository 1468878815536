import React from 'react';
import PropTypes from 'prop-types';
import { Player } from '@lottiefiles/react-lottie-player';

import creationAnimation from './creationAnimation.json';

const AnimationCreated = (props) => {
  const { messageToDisplay } = props;

  return (
    <div id="lottieBox" style={{ width: '100%', height: '300px' }}>
      <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js" />

      <Player
        src={creationAnimation}
        background="transparent"
        speed="1"
        style={{
          width: '300px', height: '300px', marginLeft: 'auto', marginRight: 'auto', marginTop: '35px', marginBottom: '-20px',
        }}
        loop
        autoplay
      />
      <p style={{
        width: '40px', height: '40px', fontSize: '30px', fontStyle: 'oblique', marginRight: '70px',
      }}
      >
        {messageToDisplay}
      </p>
    </div>
  );
};

AnimationCreated.propTypes = {
  messageToDisplay: PropTypes.string.isRequired,
};

export default AnimationCreated;
