import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

/**
 * Must be in a CSS grid.
 * Places its children on the right column of the grid, based on the passed date.
 *
 * @param {Moment} startDate Date where to place the child.
 *  It's range is 1 column by default is no column is provided
 * @param {Moment} endDate Optional. Date where the child finished to span.
 * Is equel to startDate if not specified.
 * @param {Moment} gridStartDate The date where the grid starts
 *
 */
const GridPositioner = (props) => {
  const {
    // eslint-disable-next-line react/prop-types
    children, startDate, endDate, row, timeUnit, gridStartDate, style,
  } = props;

  // Computes the column index in the grid
  const startColumnIndex = startDate.diff(gridStartDate, timeUnit);

  const trueEndDate = endDate ?? moment(startDate); // endDate equals startDate if not specified
  const endColumnIndex = trueEndDate.diff(gridStartDate, timeUnit);

  return (
    <div style={{ gridColumn: `${startColumnIndex + 1} / ${endColumnIndex + 2}`, gridRow: row, ...style }}>
      {children}
    </div>
  );
};

GridPositioner.defaultProps = {
  endDate: undefined,
  style: {},
};

GridPositioner.propTypes = {
  startDate: PropTypes.shape().isRequired, // Moment object
  endDate: PropTypes.shape(), // Moment object
  row: PropTypes.number.isRequired,
  timeUnit: PropTypes.oneOf(['day', 'week', 'month']).isRequired,
  gridStartDate: PropTypes.shape().isRequired, // Moment object
  style: PropTypes.shape(),
};

export default GridPositioner;
