import React from 'react';
import {
  Box, IconButton, Modal, Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: (props) => props.widthModal,
    height: (props) => props.heightModal,
    overflowY: 'auto',
    scrollbarWidth: 'thin',
    scrollbarColor: '#4B4E4A #ffffff',
    backgroundColor: theme.palette.background.paper,
    border: '0px solid #000',
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)',
    borderRadius: '16px',
    padding: '32px',
  },
  title: {
    fontWeight: 600,
    fontSize: '20px',
  },
  iconButtonClear: {
    padding: '0px',
    width: '32px',
    height: '32px',
    color: '#666666',
  },
}));

const ModalCustom = (props) => {
  const {
    height, title, width, child, childButtonOpenModal, open, handleClose,
  } = props;

  const classes = useStyles({ heightModal: height, widthModal: width });

  return (
    <>
      {childButtonOpenModal}
      <Modal
        className={classes.modal}
        open={open}
      >
        <Box className={classes.paper}>
          <Box style={{
            display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '95%',
          }}
          >
            <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="20px">
              <Typography />
              <Typography className={classes.title}>{title}</Typography>
              <IconButton onClick={handleClose} size="small">
                <CloseIcon classes={{ root: classes.iconButtonClear }} />
              </IconButton>
            </Box>
            {child}
          </Box>
        </Box>
      </Modal>
    </>
  );
};

ModalCustom.defaultProps = {
  height: 'auto',
  width: '676px',
  title: 'Create the project',
  child: <Typography>No content, sorry</Typography>,
  childButtonOpenModal: '',
};

ModalCustom.propTypes = {
  open: PropTypes.element.isRequired,
  handleClose: PropTypes.func.isRequired,
  height: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.string,
  child: PropTypes.element,
  childButtonOpenModal: PropTypes.element,
};

export default ModalCustom;
