/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import _debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { ClickAwayListener, Typography } from '@material-ui/core';
import { api } from '../../../api/api';

const useStyles = makeStyles(() => ({
  hover: {
    padding: '9px',
    fontWeight: 'bold',
    border: '1px dashed',
    borderRadius: '4px',
    '&:hover': {
      background: '#FFFFFF',
      border: 'none !important',
      boxSizing: 'border-box',
      boxShadow: '0px 5px 10px 2px rgba(0, 0, 0, 0.13)',
      borderRadius: '4px',
      padding: '12px',
    },
  },
  tableName: {
    fontSize: '15px',
    fontWeight: 'bold',
    lineHeight: '1rem',
    letterSpacing: '0.08333em',
    textTransform: 'uppercase',
  },
}));

const WorkPackageName = (props) => {
  const {
    edit, workPackageName, snapshotsIds, change, setChange,
  } = props;

  const [name, setName] = React.useState(workPackageName);
  const [flag, updateFlag] = React.useState(false);
  // The ref here will prevent the rerender of the component
  const open = React.useRef(false);

  const classes = useStyles();

  const handleClick = () => {
    open.current = !open.current;
    updateFlag(!flag);
  };

  const handleClickAway = () => {
    open.current = false;
    updateFlag(!flag);
  };

  function handleDebounceFn(inputValue) {
    api.put(`api/snapshots/property?ids=${snapshotsIds}`, {
      key: 'workPackageName',
      value: inputValue,
    }).then(() => {
      setChange(!change);
    });
  }

  const debounceFn = useCallback(_debounce(handleDebounceFn, 1000), []);

  const handleChangeName = (event) => {
    if (event.target.value.length <= 20) {
      setName(event.target.value);
      debounceFn(event.target.value);
    } else {
      setName(event.target.value.slice(0, 20));
    }
  };

  return (
    <div id={edit ? 'workPackageNameEdit' : 'workPackageName'}>
      {edit
        ? (
          <>
            <ClickAwayListener onClickAway={handleClickAway}>
              {!open.current ? (
                <Typography onClick={handleClick} className={`${classes.hover} ${classes.tableName}`}>
                  {name}
                </Typography>
              ) : (

                <TextField
                  id="workPackageName"
                  value={name}
                  onChange={handleChangeName}
                  style={{
                    background: '#FFFFFF',
                    border: '1px solid rgba(0, 0, 0, 0.1)',
                    boxSizing: 'border-box',
                    boxShadow: '0px 5px 10px 2px rgba(0, 0, 0, 0.13)',
                    borderRadius: '4px',
                    paddingLeft: '10px',
                    marginBottom: '10px',
                  }}
                  className={classes.tableName}
                />
              )}
            </ClickAwayListener>

          </>

        )
        : <p className={classes.tableName}>{workPackageName}</p>}
    </div>

  );
};

WorkPackageName.propTypes = {
  edit: PropTypes.bool.isRequired,
  workPackageName: PropTypes.string.isRequired,
};

export default WorkPackageName;
