/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import UserContext from '../contexts/UserContext';
import NotFound from '../components/NotFound';
import 'dotenv/config';

/**
 * This component renders its children only if the user's role
 * is in the ones passed.
 */

const ShowOnRoles = ({ allowedRoleIds, children, page }) => {
  const user = useContext(UserContext);

  // Not connected, while the authentication is enabled.
  if (!user) {
    return page ? <NotFound /> : null;
  }

  const currentRole = user?.roleId;
  if (allowedRoleIds.includes(currentRole)) {
    return children;
  }

  return page ? <NotFound /> : null;
};

export default ShowOnRoles;
