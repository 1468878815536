import { Box, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

const useStyles = makeStyles({
  box: {
    width: '328px',
    height: '264px',
    backgroundColor: 'white',
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.08)',
    borderRadius: '13px',
    padding: '32px',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  element: {
    marginRight: '1rem',
    borderRadius: '10px',
  },
});

const CardDashboardLoader = () => {
  const classes = useStyles();

  return (
    <Box className={classes.box}>
      <Box display="flex">
        <Skeleton variant="rect" width={40} height={40} className={classes.element} />
        <Skeleton variant="text" width={160} />
      </Box>

      <Skeleton variant="text" width={300} />
      <Skeleton variant="text" width={300} />
      <Skeleton variant="text" width={300} />

      <Skeleton variant="text" width={120} height={40} />

      <Box display="flex" justifyContent="space-between">
        <Skeleton variant="circle" width={48} height={48} />
        <Skeleton variant="circle" width={48} height={48} />
        <Skeleton variant="circle" width={48} height={48} />
        <Skeleton variant="circle" width={48} height={48} />
        <Skeleton variant="circle" width={48} height={48} />
      </Box>
    </Box>
  );
};

export default CardDashboardLoader;
