/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles, MenuItem, Select } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const useStyles = makeStyles(() => ({
  select: {
    display: 'flex',
    alignItems: 'center',
    width: '185px',
    marginLeft: 0,
    borderRadius: '8px',
    color: 'black',
    backgroundColor: 'white',
    background: '#FFFFFF',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.03)',
    '&:focus': {
      backgroundColor: 'white',
      borderRadius: '8px',
    },
  },
  outlined: {
    height: '40px',
  },
  selectSize: {
    height: '48px',
  },
  LabelType: {
    top: '-3px',
  },
  pointFilter: {
    width: '11px',
    height: '11px',
    marginRight: '8px',
    color: 'red',
  },
}));

const FormikSelect = ({
  field, form: { touched, errors },
  currentStatus, ...props
}) => {
  const classes = useStyles();

  return (
    <div style={{ marginBottom: '32px' }}>
      <Select
        variant="outlined"
        name={field.name}
        displayEmpty
        classes={{ select: classes.select, outlined: classes.outlined }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        {...field}
        {...props}
      >
        {props.items.map((item) => (
          <MenuItem value={item.item}>
            {field.name === 'status' ? <FiberManualRecordIcon className={classes.pointFilter} style={{ color: item.color }} />
              : null}
            {item.item.charAt(0).toUpperCase() + item.item.slice(1)}
          </MenuItem>
        ))}
      </Select>
      {touched[field.name]
        && errors[field.name] && <div className="error">{errors[field.name]}</div>}
    </div>
  );
};

export default FormikSelect;
