/* eslint-disable jsx-a11y/alt-text */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { AiOutlineCamera } from 'react-icons/ai';

const Picture = (props) => {
  const {
    edit, profileImagePath, setProfileImagePath, setProfileImageForm,
  } = props;
  const fileBrowseHandler = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setProfileImagePath(reader.result);
      });
      reader.readAsDataURL(selectedFile);
      reader.onloadend = () => {
        const base64data = reader.result.split(',')[1];
        const binaryData = atob(base64data);
        const arrayBuffer = new ArrayBuffer(binaryData.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < binaryData.length; i += 1) {
          uint8Array[i] = binaryData.charCodeAt(i);
        }
        const blob = new Blob([uint8Array], { type: selectedFile.type });
        const formData = new FormData();
        formData.append('file', blob, selectedFile.name);
        setProfileImageForm(formData);
      };
    }
  };

  const hiddenFileInput = useRef(null);
  const handleClick = async () => {
    hiddenFileInput.current.click();
  };

  const styleIcon = { color: '#FDB71A' };

  const modal = (
    <>
      {edit ? (
        <div className="App">
          <div>
            <input
              ref={hiddenFileInput}
              style={{ display: 'none', size: '40px' }}
              type="file"
              name="file"
              onChange={fileBrowseHandler}
            />
            <button style={{ display: 'contents' }} type="button" onClick={handleClick}>
              <AiOutlineCamera style={styleIcon} size={25} />
            </button>
            <img src={profileImagePath} alt="Encoded" />
          </div>
        </div>
      ) : (
        <img src={profileImagePath} />
      )}

    </>
  );

  return (
    <div>
      {modal}
    </div>
  );
};

Picture.propTypes = {
  edit: PropTypes.bool.isRequired,
  profileImagePath: PropTypes.string.isRequired,
  setProfileImagePath: PropTypes.func.isRequired,
  setProfileImageForm: PropTypes.func.isRequired,
};

export default Picture;
