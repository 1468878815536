import React from 'react';

const VerticalArrow = () => (
  <div
    style={{
      position: 'absolute',
      marginLeft: '37px',
      gridColumnStart: 1,
      gridColumnEnd: 2,
      gridRowStart: 1,
      gridRowEnd: 'span 100',
      marginTop: '86px',
      height: '100%',
      zIndex: '1',
    }}
  >
    <div
      style={{
        width: '1px',
        height: '100vh',
        backgroundColor: 'black',
      }}
    />
    <div
      style={{
        width: 0,
        height: 0,
        borderLeft: '7px solid transparent',
        borderRight: '7px solid transparent',
        borderBottom: '10.5px solid black',
        position: 'absolute',
        left: 'calc(50% - 7px)',
        top: '-1px',
      }}
    />
  </div>
);

export default VerticalArrow;
