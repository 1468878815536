/* eslint-disable react/require-default-props */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable no-return-assign */
import {
  Avatar, Chip, Grid, TextField, Typography,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useFormikContext } from 'formik';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { api } from '../../api/api';
import EndDateSelection from '../../commonComponents/EndDateCustom';
import TextAreaFormik from '../../commonComponents/Fields/TextAreaFormik';
import StartDateSelection from '../../commonComponents/StartDateCustom';
import SelectAddElement from '../SelectAddElement';
import Client from '../common/Client';
import ContactPerson from '../common/ContactPerson';
import DeliveryLead from '../common/DeliveryLead';
import Manager from '../common/Manager';
import LinksCreator from '../creationproject/LinksCreator';
import TeamSelection from '../creationproject/TeamSelection';
import DateSelection from '../../commonComponents/StartEndDateCustom';

const useStyles = makeStyles(() => ({
  button: {
    marginRight: '16px',
    marginBottom: '8px',
    backgroundColor: '#373A36',
    color: 'white',
    textDecoration: 'none',
    fontSize: '16px',
    '&:hover': {
      backgroundColor: 'rgb(255, 184, 28)',
      color: '#373A36',
    },
  },
  buttonRoot: {
    textTransform: 'none',
  },
  datePicker: {
    padding: '14.5px 0px',
  },
  contactPerson: {
    color: 'black',
    fontSize: '16px',
    fontFamily: 'Roboto',
    justifyContent: 'center',
    fontWeight: 'bold',
  },
  info_client: {
    width: '100% !important',
  },
  Typography: {
    color: 'rgba(218, 41, 28, 0.7)',
    marginTop: '8px',
    fontSize: '12px',
    marginLeft: '12px',
  },
}));

const OverviewProject = (props) => {
  const {
    project, edit, toolsSelected,
    setEditValidation,
    technosSelected, setToolsSelected, setTechnosSelected,
    colorSelected, setColorSelected, usersSelected, setUsersSelected, clientId,
    clientContacts, setClientContacts, titleError,
  } = props;
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const validatePhone = (phone) => (!/^[+\d]{1,20}$/.test(phone));

  useEffect(() => {
    api
      .get('api/users')
      .then((res) => setUsers(res.data));
  }, []);

  useEffect(() => {
    api
      .get(`api/clients?ClientId=${clientId}`)
      .then((res) => res.data)
      .then((data) => setClientContacts({
        id: data.ClientContacts[0]?.id,
        // eslint-disable-next-line object-shorthand
        clientId: clientId,
        full_name: data.ClientContacts[0]?.full_name,
        phone_number: data.ClientContacts[0]?.phone_number,
        email: data.ClientContacts[0]?.email,
      }));
  }, [clientId]);

  useEffect(() => {
    setColorSelected(project.color);
  }, []);

  const startDateFormat = project ? moment(project.start_date).format('DD.MM.yyyy') : <p>N/A</p>;
  const endDateFormat = project ? moment(project.end_date).format('DD.MM.yyyy') : <p>N/A</p>;
  const todayDate = moment(Date.now());
  const endDate = project ? moment(project.end_date) : '';

  const validateEmail = (email) => (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email));

  const filterUsers = users.length > 0 && usersSelected.length > 0 && users.reduce((acc, cur) => {
    const item = usersSelected.find((userSelected) => userSelected.id === cur.id);
    if (!item) {
      acc.push(cur);
    }
    return acc;
  }, []);

  const { values } = useFormikContext();
  const [dateError, setDateError] = useState(false);
  const [validation, setValidation] = useState(true);
  const [dateMessage, setDateMessage] = useState(false);
  const [signedError, setSignedError] = useState(false);
  const [title, setTitle] = useState(false);
  const [titleNameValidation, setTitleNameValidation] = useState(false);
  const [noEmptyAllow, setNoEmptyAllow] = useState(false);
  const [maxCharEmail, setMaxCharEmail] = useState(false);
  const [maxCharFullName, setMaxCharFullName] = useState(false);
  const [maxCharPhoneNumber, setMaxCharPhoneNumber] = useState(false);
  const [emailChecker, setEmailChecker] = useState(false);
  const [phoneChecker, setPhoneChecker] = useState(false);

  useEffect(() => {
    if (values.project_name.length > 19) {
      setEditValidation(true);
    } else if (values.project_name.length < 20) {
      setEditValidation(false);
    }
  }, [values.project_name]);

  useEffect(() => {
    if (typeof values.start_date !== 'undefined'
      && typeof values.end_date !== 'undefined'
      && moment(values.end_date).isAfter(values.start_date)) {
      // case in which the dates are correct
      setValidation(false);
      setDateError(false);
      setDateMessage(false);
      setEditValidation(false);
      setSignedError(false);
    } else if (typeof values.start_date !== 'undefined'
      && typeof values.end_date !== 'undefined'
      && moment(values.end_date).isBefore(values.start_date)) {
      setDateError(false);
      setDateMessage(true);
      setValidation(true);
      setEditValidation(true);
    } else if (project.signed === true && values.start_date === null) {
      setSignedError(true);
    } else {
      setDateError(false);
      setSignedError(false);
    }
  }, [values.start_date, values.end_date]);

  useEffect(() => {
    if (clientContacts.email?.length > 40) {
      setMaxCharEmail(true);
      setEditValidation(false);
    } else {
      if (clientContacts.email?.length === 0 || !validateEmail(clientContacts.email)) {
        setEmailChecker(true);
        setEditValidation(true);
      } else {
        setEmailChecker(false);
      }
      setMaxCharEmail(false);
      setEditValidation(false);
    }
    if (clientContacts.full_name?.length > 19) {
      setMaxCharFullName(true);
      setEditValidation(true);
    } else {
      setMaxCharFullName(false);
      setEditValidation(false);
    }
    if (clientContacts.phone_number?.length > 19) {
      setMaxCharPhoneNumber(true);
      setEditValidation(true);
    } else if (typeof clientContacts.phone_number !== 'undefined' && !validatePhone(clientContacts.phone_number)) {
      setPhoneChecker(false);
      setMaxCharPhoneNumber(false);
      setEditValidation(false);
    } else {
      setPhoneChecker(true);
      setMaxCharPhoneNumber(false);
      setEditValidation(false);
    }
    if (!clientContacts?.full_name && (clientContacts?.email || clientContacts?.phone_number)) {
      setTitleNameValidation(true);
    } else {
      setTitleNameValidation(false);
    }
    if (typeof values?.Client !== 'undefined' && clientContacts?.full_name === '') {
      setNoEmptyAllow(true);
      setEditValidation(true);
    } else {
      setNoEmptyAllow(false);
    }
  }, [clientContacts]);

  useEffect(() => {
    if (maxCharEmail
      || maxCharFullName
      || maxCharPhoneNumber
      || !emailChecker
      || phoneChecker
      || (noEmptyAllow && !titleNameValidation)) {
      setEditValidation(true);
    } else {
      setEditValidation(false);
    }
  }, [clientContacts,
    maxCharEmail,
    maxCharFullName,
    maxCharPhoneNumber,
    titleNameValidation,
    emailChecker,
    phoneChecker,
    noEmptyAllow]);

  useEffect(() => {
    if (noEmptyAllow && !titleNameValidation) {
      setValidation(true);
    }
  }, [noEmptyAllow]);

  const iconLink = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19 19H5V5H12V3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V12H19V19ZM14 3V5H17.59L7.76 14.83L9.17 16.24L19 6.41V10H21V3H14Z" fill="white" />
    </svg>
  );

  const colors = ['Red', 'Peru', 'Aqua', 'Aquamarine', 'RoyalBlue', 'Beige', 'SeaGreen', 'Black', 'SpringGreen', 'Blue', 'BlueViolet', 'Turquoise', 'Violet', 'CadetBlue', 'Chartreuse', 'Chocolate', 'Coral', 'CornflowerBlue', 'Yellow', 'Crimson'];
  return (
    <div id="leftSection3" className="sections">
      {edit
        ? (
          <>
            <div id="clientSection">
              <p>Client Information</p>
            </div>
            <Grid container spacing={2}>

              <Grid item xs={6}>
                <Client
                  currentClient={project.Client}
                  label="Client"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  className={classes.info_client}
                  label="Client Contact Person"
                  placeholder="Add a contact person"
                  defaultValue="Not defined"
                  value={typeof clientContacts === 'undefined' ? 'Not defined' : clientContacts?.full_name}
                  variant="outlined"
                  onChange={(e) => setClientContacts({
                    ...clientContacts,
                    full_name: e.target.value,
                  })}
                />
                <Typography variant="caption" className="errorMessageCreateCollaborator">
                  { titleNameValidation && ('You must enter contact name person if you have phone and/or email. ') }
                  { maxCharFullName && ('Client Contact must be less than 20 characters. ') }
                  { noEmptyAllow && !titleNameValidation && ('Replace a client contact with empty value is forbidden. ')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  className={classes.info_client}
                  label="E-mail"
                  placeholder="Add an E-mail"
                  value={typeof clientContacts === 'undefined' ? 'Not defined' : clientContacts.email}
                  defaultValue="Not defined"
                  variant="outlined"
                  onChange={(e) => setClientContacts({
                    ...clientContacts,
                    email: e.target.value,
                  })}
                />
                <Typography variant="caption" className="errorMessageCreateCollaborator">
                  { maxCharEmail && ('Email must be less than 30 characters. ') }
                  { !emailChecker && ('Invalide Email')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  className={classes.info_client}
                  label="Phone Number"
                  placeholder="Add a phone number"
                  value={typeof clientContacts === 'undefined' ? 'Not defined' : clientContacts.phone_number}
                  defaultValue="Not defined"
                  variant="outlined"
                  onChange={(e) => setClientContacts({
                    ...clientContacts,
                    phone_number: e.target.value,
                  })}
                />
                <Typography variant="caption" className="errorMessageCreateCollaborator">
                  { maxCharPhoneNumber && ('Phone number must be less than 20 characters. ') }
                  { phoneChecker && ('Invalid Phone number')}
                </Typography>
              </Grid>
            </Grid>

            <div id="clientSection" style={{ marginTop: '40px' }}>
              <p>Project Details</p>
            </div>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Manager label="Manager" />
              </Grid>
              <Grid item xs={6}>
                <DeliveryLead label="Delivery Lead" />
              </Grid>
              <Grid item xs={6}>
                <DateSelection signedError={project.signed} label="Start Date" name="start_date" type="Start" size="small" />
              </Grid>
              <Grid item xs={6}>
                <DateSelection label="End Date" name="end_date" type="End" size="small" />
              </Grid>
              <Typography className={classes.Typography} variant="caption">
                {dateMessage && ('End date must be later than Start date.')}
              </Typography>
              <Typography className={classes.Typography} variant="caption">
                {dateError && ('Start and End dates are required.')}
              </Typography>
              <Grid item xs={12}>
                <p className="labelsDetailedProject">Project Description</p>
                <TextAreaFormik
                  name="description"
                  rows={8}
                  placeholder="Short description of the project, what was sold, what are the expectations of the client?"
                />
              </Grid>
              <Grid item xs={12}>
                <LinksCreator links={project.Links} edit />
              </Grid>
              <Grid item xs={12}>
                <p className="labelsDetailedProject">Tools</p>
                <SelectAddElement selected={toolsSelected} setSelected={setToolsSelected} word="Tool" title="no" />
              </Grid>
              <Grid item xs={12}>
                <p className="labelsDetailedProject">Technologies</p>
                <SelectAddElement selected={technosSelected} setSelected={setTechnosSelected} word="Techno" title="no" />
              </Grid>
              <Grid item xs={12}>
                <TeamSelection
                  edit
                  usersSelected={usersSelected}
                  setUsersSelected={setUsersSelected}
                  users={usersSelected.length > 0 ? filterUsers : users}
                />
              </Grid>
            </Grid>
          </>
        )
        : (
          <>
            <div id="clientSection">
              <p>Client Information</p>
            </div>
            <div id="leftSection3-1">
              <div className="elementsLeftSection3-1">
                <p className="labelsDetailedProject">Client</p>
                <p>
                  {project.Client.client_name}
                </p>
              </div>
              <div className="elementsLeftSection3-1">
                <p className="labelsDetailedProject">Client Contact Person</p>
                <p>
                  { clientContacts?.full_name
                    ? `${clientContacts.full_name}` : 'Not Defined '}
                </p>
              </div>
              <div className="elementsLeftSection3-1">
                <p className="labelsDetailedProject">E-mail</p>
                <p>
                  { clientContacts?.email
                    ? `${clientContacts.email}` : 'Not Defined '}
                </p>
              </div>
              <div className="elementsLeftSection3-1">
                <p className="labelsDetailedProject">Phone number</p>
                <p>
                  { clientContacts?.phone_number
                    ? `${clientContacts.phone_number}` : 'Not Defined '}
                </p>
              </div>
            </div>

            <div id="clientSection">
              <p>Project Information</p>
            </div>
            <div id="leftSection3-1">
              <div className="elementsLeftSection3-1">
                <p className="labelsDetailedProject">Manager</p>
                <p>
                  {`${project.Manager.first_name} ${project.Manager.last_name}`}
                </p>
              </div>
              <div className="elementsLeftSection3-1">
                <p className="labelsDetailedProject">Delivery Lead</p>
                <p>
                  {project.DeliveryLead ? `${project.DeliveryLead.first_name} ${project.DeliveryLead.last_name}` : 'Not Defined '}
                </p>
              </div>
              <div className="elementsLeftSection3-1">
                <p className="labelsDetailedProject">Start date</p>
                <p>{startDateFormat !== 'Invalid date' ? startDateFormat : 'N/A' }</p>
              </div>
              <div className="elementsLeftSection3-1">
                <p className="labelsDetailedProject">End Date</p>
                <div style={{ display: 'flex' }}>
                  <p>{endDateFormat !== 'Invalid date' ? endDateFormat : 'N/A' }</p>
                  {endDateFormat !== 'Invalid date' ? (
                    <button
                      type="button"
                      style={{
                        height: 'auto',
                        width: 'auto',
                        padding: '4px 8px',
                        color: '#707987',
                        backgroundColor: '#F0F0F0',
                        border: 'none',
                        borderRadius: '6px',
                        fontSize: '14px',
                        fontWeight: 400,
                        fontFamily: 'Roboto',
                        marginTop: '-4px',
                        marginLeft: '16px',
                      }}
                    >
                      {endDate.diff(todayDate, 'weeks')}
                      {' '}
                      weeks remaining
                    </button>
                  ) : '' }

                </div>

              </div>
            </div>

            <div id="projectDescription">
              <p className="labelsDetailedProject">Project description</p>
              <p>
                {project.description}
              </p>
            </div>

            {project.deletedAt
              ? (
                <div id="projectDescription">
                  <p className="labelsDetailedProject">Reason for archiving</p>
                  <p>
                    {project.reasonArchived}
                  </p>
                </div>
              )
              : ''}

            <div>
              <p className="labelsDetailedProject">Links</p>
              <div style={{ marginBottom: '32px', marginRight: '48px' }}>
                { project.Links.map((link) => (
                  // we use target="_blank" in order to open the link in a new tab
                  <a href={`${link.url}`} target="_blank" rel="noreferrer noopener" key={link.url} style={{ textDecoration: 'none' }}>
                    <Button
                      key={link.id}
                      variant="contained"
                      color="default"
                      classes={{ root: classes.buttonRoot }}
                      className={classes.button}
                      endIcon={iconLink}
                    >
                      {link.title}
                    </Button>
                  </a>
                ))}
              </div>
            </div>

            <div className="elementsLeftSection3" id="boxToolsTechnos">
              <div style={{ marginBottom: '32px', marginRight: '48px' }}>
                <p className="labelsDetailedProject">Tools</p>
                {project.Tools.map((tool) => <button key={tool.id} type="button" className="buttonTechnoTool">{tool.tool_name}</button>)}
              </div>
              <div style={{ marginBottom: '32px', marginRight: '48px' }}>
                <p className="labelsDetailedProject">Technologies</p>
                {project.Technos.map((techno) => <button key={techno.id} type="button" className="buttonTechnoTool">{techno.techno_name}</button>)}
              </div>
              <div style={{ marginBottom: '32px', marginRight: '48px' }}>
                <p className="labelsDetailedProject">Team</p>
                {project.Team.map((user, i) => (
                  <Chip
                    key={user.id}
                    style={{ background: 'rgba(0, 0, 0, 0.07)', marginBottom: '10px', marginLeft: i > 0 ? '12px' : '0px' }}
                    avatar={(
                      <Avatar style={{ color: 'white', background: '#89ABE3' }}>
                        {user.first_name.charAt(0) + user.last_name.charAt(0)}
                      </Avatar>
                                  )}
                    label={`${user.first_name} ${user.last_name}`}
                  />
                ))}
              </div>
            </div>
          </>
        )}

      {edit
        ? (
          <>
            <div style={{
              display: 'flex', gap: '3rem', alignItems: 'center', marginTop: '40px',
            }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <p className="labelsDetailedProject">Team Contact Person</p>
                <ContactPerson name="Contact" component="select">
                  {users.map((user) => (
                    <option value={user.id}>
                      {user.first_name}
                      {' '}
                      {user.last_name}
                    </option>
                  ))}

                </ContactPerson>
              </div>
            </div>
            <div style={{ marginBottom: '8px', marginTop: '40px' }}>
              <label htmlFor="clientDemand" className={classes.contactPerson}>
                Choose a color for the project
              </label>
            </div>
            <div name="color" style={{ margin: '0px 50px 8px 0px' }}>
              {colors.map((color) => (
                <button
                  type="button"
                  key={color}
                  value={color.toLowerCase()}
                  onClick={() => setColorSelected(color)}
                  style={{
                    background: color,
                    fontSize: '30px',
                    color: 'white',
                    fontWeight: 'bold',
                    width: '7%',
                    height: '55px',
                    filter: 'brightness(85%)',
                    margin: '12px 20px 2px 2px',
                    borderRadius: '8px',
                    border: '0px',
                  }}
                >
                  {color === colorSelected
                    ? (
                      <span style={{
                        position: 'absolute', fontSize: '70px', margin: '-60px 0 0 -10px', color: 'white',
                      }}
                      >
                        .
                      </span>
                    )
                    : (
                      <span style={{ position: 'absolute', color: 'transparent' }}>
                        {' '}

                        {' '}
                      </span>
                    )}
                </button>
              ))}
            </div>

          </>
        )
        : (
          <div style={{ display: 'flex', gap: '3rem' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <p className="labelsDetailedProject">Team Contact Person</p>
              <p>{`${project.Contact ? project.Contact.first_name : ''} ${project.Contact ? project.Contact.last_name : ''}`}</p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <p className="labelsDetailedProject">Project Color</p>
              <div style={{ height: '1rem', width: '6rem', backgroundColor: project.color }} />
            </div>
          </div>
        )}

    </div>
  );
};

OverviewProject.propTypes = {
  setEditValidation: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
  technosSelected: PropTypes.arrayOf(PropTypes.shape()),
  toolsSelected: PropTypes.arrayOf(PropTypes.shape()),
  setToolsSelected: PropTypes.func.isRequired,
  setColorSelected: PropTypes.func.isRequired,
  colorSelected: PropTypes.string,
  setTechnosSelected: PropTypes.func.isRequired,
  project: PropTypes.shape({
    description: PropTypes.string,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    deletedAt: PropTypes.string.isRequired,
    reasonArchived: PropTypes.string.isRequired,
    color: PropTypes.string,
    Client: PropTypes.shape().isRequired,
    id: PropTypes.number.isRequired,
    Technos: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      techno_name: PropTypes.string.isRequired,
    })),
    Team: PropTypes.arrayOf(PropTypes.shape()),
    Tools: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      tool_name: PropTypes.string,
    })),
    Links: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      url: PropTypes.string.isRequired,
    })),
    Contact: PropTypes.shape().isRequired,
    Manager: PropTypes.shape().isRequired,
    DeliveryLead: PropTypes.shape().isRequired,
    ClientId: PropTypes.number,
  }),
  setUsersSelected: PropTypes.func.isRequired,
  usersSelected: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default OverviewProject;
