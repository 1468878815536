/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import UsersApi from '../api/UsersApi';

const UserSlice = createSlice({
  name: 'users',
  initialState: { users: [] },
  reducers: {
    usersAdded: (state, action) => {
      // state.push(action.payload);
      state.users = action.payload;
    },
  },
});

const { actions, reducer } = UserSlice;

export const { usersAdded } = actions;

export const fetchUsers = () => async (dispatch) => {
  const usersApi = new UsersApi();
  const response = await usersApi.fetchAll();
  dispatch(usersAdded(response));
};

export default reducer;
