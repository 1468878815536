/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { Button, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  totalRange: {
    width: '100%',
    backgroundColor: (props) => props.colorFill,
    height: '28px',
    border: 'none',
    borderRadius: '4px',
    textAlign: 'left',
    // paddingLeft: '24px',
    fontSize: '14px',
    color: '#707987',
    fontWeight: 700,
  },
  absence: {
    color: '#A59BC4',
    fontSize: '14px',
    fontWeight: 700,

  },
}));

const HorizontalBarTotalIntensity = (props) => {
  const { forecasting } = props;

  let colorFill = '';

  // console.log('forecasting', forecasting);

  if (forecasting.absence > 0) {
    colorFill = '#FFFFFF';
  } else if (forecasting.intensity >= 90 && forecasting.intensity <= 110) {
    colorFill = '#E3F7E7';
  } else if (forecasting.intensity <= 70 || forecasting.intensity >= 130) {
    colorFill = '#FCEAE9';
  } else {
    colorFill = '#FFF8E8';
  }
  const classes = useStyles({ colorFill });

  let goodToDisplay;

  if (forecasting.intensity > 0 && forecasting.absence === 0) {
    goodToDisplay = (
      <p>
        {Math.round(forecasting.intensity)}
        %
      </p>
    );
  } else if (forecasting.absence === 1) {
    goodToDisplay = (
      <p className={classes.absence}>
        Vaca
      </p>
    );
  } else if (forecasting.absence === 2) {
    goodToDisplay = (
      <p className={classes.absence}>
        Sick
      </p>
    );
  }

  return (
    <button
      type="button"
      className={classes.totalRange}
    >
      {goodToDisplay}
    </button>
  );
  // return <Button style={{ backgroundColor: 'red', width: '100%' }}>{value}</Button>;
};

export default HorizontalBarTotalIntensity;
