import moment from 'moment';
import assert from 'assert';

const VALID_TIME_UNITS = ['day', 'week', 'month'];

/**
 * This function will generate an array of as much as moment between the startDate and EndDate
 *
 * @param {Moment} startDate
 * @param {Moment} endDate
 * @param {String} timeUnit      "day" or "month" or "week"
 * @returns
 */
const generateArrayTimeUnit = (startDate, endDate, timeUnit) => {
  assert(typeof startDate !== 'undefined');
  assert(typeof endDate !== 'undefined');
  assert(typeof timeUnit !== 'undefined');
  assert(VALID_TIME_UNITS.includes(timeUnit), `Invalid argument timeUnit received: ${timeUnit}. Should be one of ${VALID_TIME_UNITS})`);
  assert(endDate.isAfter(startDate), 'Passed end date should not be before passed start date.');

  const arrayMoment = [startDate];

  let momentCurrent = moment(startDate).add(1, `${timeUnit}s`).startOf(`${timeUnit}`);

  while (momentCurrent.isBefore(endDate)) {
    arrayMoment.push(momentCurrent);
    momentCurrent = moment(momentCurrent).add(1, `${timeUnit}s`);
  }

  arrayMoment.push(endDate);

  return arrayMoment;
};

export default generateArrayTimeUnit;
