import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import groupBy from 'lodash/groupBy';
import Avatar from '@material-ui/core/Avatar';
import { mapUserRole } from '../../utils/mapUserRole';
import ChipStaff from './ChipStaff';

const useStyles = makeStyles({
  avatar: {
    width: '48px',
    height: '48px',
  },
  avatarLetter: {
    marginTop: '4px',
  },
  isRed: {
    backgroundColor: 'rgba(218, 41, 28, 0.1)',
    color: '#DA291C',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '6px',
    marginTop: '4px',
    height: '24px',
    fontSize: '14px',
    fontWeight: 500,
  },
  linkStyle: {
    color: '#bdbdbd',
  },
  isOk: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '6px',
    marginTop: '4px',
    height: '24px',
    fontSize: '14px',
    fontWeight: 500,
    color: '#707987',
  },
});

const Staffed = (props) => {
  const {
    // eslint-disable-next-line no-unused-vars
    ProjectId, ManagerId, startDate, endDate,
    fullyStaff, forecastings,
  } = props;

  const classes = useStyles();
  const todayDate = moment(Date.now());
  const onGoing = moment(startDate).isBefore(todayDate) && moment(todayDate).isBefore(endDate);

  const projectIntensities = forecastings.filter((f) => f.ProjectId === ProjectId);
  // We manage here the red background when total weekly of someone is above 130 or below 70%

  const totalIntensityUsers = Object.values(groupBy(forecastings, 'UserId')).map((user) => {
    const projectWeeklyUserIntensities = Object.values(groupBy(user, 'ProjectId')).map((proj) => proj.reduce(
      (acc, curr) => ({
        intensity: acc.intensity + (curr.intensity / 5),
        projectId: curr.projectId,
        userId: curr.UserId,
      }),
      { intensity: 0 },
    ));
    const numberProject = projectWeeklyUserIntensities?.length;
    return projectWeeklyUserIntensities.reduce((acc, curr) => ({
      intensity: acc.intensity + curr.intensity,
      projects: numberProject,
      userId: curr.userId,
    }),
    { intensity: 0 });
  }).map((el) => ({
    ...el,
    weeklyIntensity: el.intensity,
  }));

  const isRed = (userId) => {
    const user = totalIntensityUsers.find((el) => el.userId === userId);
    return (user.intensity <= 70 || user.intensity >= 130) && onGoing;
  };

  const groupedByUserId = Object.values(groupBy(projectIntensities, 'UserId')).flatMap((user) => user);
  const oneValuePerUser = groupedByUserId.reduce((acc, cur) => {
    const user = acc.find(({ userId }) => userId === cur.User?.id);
    if (user) {
      user.intensity += cur.intensity;
    } else {
      acc.push({
        userId: cur.UserId,
        roleInProject: cur.roleInProject,
        first_name: cur?.User?.first_name,
        last_name: cur?.User?.last_name,
        intensity: cur.intensity,
        project_name: cur.Project?.project_name,
      });
    }
    return acc;
  }, []);

  const POSM = oneValuePerUser.filter((user) => user.roleInProject === '1' || user.roleInProject === '2');
  const developers = oneValuePerUser.filter((user) => user.roleInProject !== '1' && user.roleInProject !== '2');

  const POSMToDisplay = ({ slice }) => POSM.slice(0, slice).map((user) => (
    <div key={user.userId} className="boxStaffed">
      <Link to={`/employees/${user.userId}`} className={classes.linkStyle}>
        <Avatar
          title={`${user.first_name} ${user.last_name} - ${mapUserRole(user.roleInProject)}`}
          alt={`${user.first_name} ${user.last_name}`}
          className={classes.avatar}
          key={user.first_name.charAt(0) + user.last_name.charAt(0)}
          src={user.image}
        >
          {`${user.first_name.charAt(0)}${user.last_name.charAt(0)}`}
        </Avatar>
      </Link>
      <div className={isRed(user.userId) ? classes.isRed : classes.isOk}>
        {(user.intensity / 5).toFixed(0)}
        %
      </div>
    </div>
  ));

  const DevelopersToDisplay = ({ slice }) => developers.slice(0, slice).map((user) => (
    <div className="boxStaffed" key={user.id}>
      <Link to={`/employees/${user.userId}`} className={classes.linkStyle}>
        <Avatar
          title={`${user.first_name} ${user.last_name}`}
          alt={`${user.first_name} ${user.last_name}`}
          className={classes.avatar}
          key={user.first_name?.charAt(0) + user.last_name?.charAt(0)}
          src={user.image}
        >
          {`${user.first_name?.charAt(0)}${user.last_name?.charAt(0)}`}
        </Avatar>
      </Link>
      <div className={isRed(user.userId) ? classes.isRed : classes.isOk}>
        {(user.intensity / 5).toFixed(0)}
        %
      </div>
    </div>
  ));

  // eslint-disable-next-line react/prop-types
  const AvatarPlus = ({ number }) => (<Avatar className={classes.avatarLetter}>{`+${number}`}</Avatar>);

  let goodToDisplay;
  if (POSM.length > 5) {
    goodToDisplay = (
      <div style={{ display: 'flex' }}>
        <POSMToDisplay slice={5} />
        <AvatarPlus number={POSM.length - 5} />
      </div>
    );
  } else {
    goodToDisplay = (
      <div style={{ display: 'flex' }}>
        <POSMToDisplay slice={POSM.length} />
        {POSM.length === 0 ? null : <p style={{ marginTop: '20px', marginRight: '8px', fontWeight: 700 }}>|</p> }
        <DevelopersToDisplay slice={5 - POSM.length} />
        {POSM.length + developers.length > 5
          ? <AvatarPlus number={POSM.length + developers.length - 5} /> : null}
      </div>
    );
  }

  return (
    <div>

      {onGoing ? (<ChipStaff fullyStaff={fullyStaff} />)
        : (
          <p style={{
            fontSize: '16px', width: '96px', height: '27px', marginTop: '8px', borderRadius: '6px', backgroundColor: '#F0F0F0', color: '#707987', display: 'flex', alignItems: 'center', padding: '0 8px', fontWeight: '400',
          }}
          >
            Collaborators
          </p>
        )}
      <div style={{ display: 'flex', marginTop: '1rem' }}>
        {goodToDisplay}
      </div>
    </div>
  );
};

Staffed.defaultProps = {
  ManagerId: undefined,
  fullyStaff: '0',
  startDate: '',
  endDate: '',
};

Staffed.propTypes = {
  ProjectId: PropTypes.number.isRequired,
  ManagerId: PropTypes.number,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  fullyStaff: PropTypes.string,
  forecastings: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
  })).isRequired,
};

export default Staffed;
