import React from 'react';
import PropTypes from 'prop-types';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { useFormikContext } from 'formik';
import FormikAutocomplete from './FormikAutocomplete';

/**
 * This components wraps Material UI's <Automcomplete>
 * in order to use it as a Formik field.
 */
const FormikAutoCompleteCreate = ({
  name, options, onCreate, ...props
}) => {
  const { values, setFieldValue } = useFormikContext();
  const goodVariable = `${name.toLowerCase()}_name`;
  const filter = createFilterOptions();

  const handleChange = async (event, newValue) => {
    let toSet;
    if (newValue.isNew) {
      // If element is new, create it in DB
      toSet = await onCreate(newValue);
    } else {
      toSet = newValue;
    }

    values[name] = toSet;
    setFieldValue(values);
  };

  return (
    <FormikAutocomplete
      size="small"
      name={name}
      onChange={handleChange}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        if (option.isNew) {
          return `Add "${option[goodVariable]}"`;
        } if (option[goodVariable]) {
          return option[goodVariable];
        }
        // Regular option
        return option.title;
      }}
      getOptionSelected={(option, value) => option[goodVariable] === value[goodVariable]}
      filterOptions={(opts, params) => {
        const filtered = filter(opts, params);
        // Suggest the creation of a new value
        if (params.inputValue !== '') {
          const body = {};
          body[goodVariable] = params.inputValue;
          body.isNew = true;
          filtered.push(body);
        }
        return filtered;
      }}
      options={options}
      {...props}
    />

  );
};

FormikAutoCompleteCreate.defaultProps = {
  options: [],
  onCreate: () => {},
};

FormikAutoCompleteCreate.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()),
  onCreate: PropTypes.func,
};

export default FormikAutoCompleteCreate;
