import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { FieldArray, Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Box, IconButton } from '@material-ui/core';
import { isEmpty } from 'lodash';
import AddIcon from '@material-ui/icons/Add';
import { api } from '../../api/api';
import EditOneRange from './EditOneRange';
import { ButtonCustomFormik, ButtonSimple } from '../../commonComponents/ButtonCustom';

const useStyles = makeStyles(() => ({
  iconButtonAdd: {
    backgroundColor: '#F0F0F0',
    borderRadius: '8px',
    margin: '1rem auto 37px auto',
    '&:hover': {
      backgroundColor: 'rgb(255, 184, 28)',
      color: 'black',
    },
  },
}));

const ForecastProjectEdit = (props) => {
  const {
    idProject, idUser,
    setUpdateForecasting, onClose, setList,
  } = props;
  const classes = useStyles();
  const groupedForecastings = useSelector(
    (state) => state.forecastingsState.forecastingsByUserAndProjectId ?? [],
  );

  const projectsOneManager = useSelector((state) => state.projectsState.projects ?? []);
  const [overlap, setOverlap] = useState(false);

  const initialValuesForecastings = [];

  groupedForecastings.forEach((el) => el.forEach((item) => initialValuesForecastings.push({
    ManagerId: item[0].ManagerId,
    UserId: item[0].UserId,
    ProjectId: item[0].ProjectId,
    startDate: item[0].startDate,
    endDate: item[0].endDate,
    intensity: item[0].intensity,
    absence: item[0].absence,
    roleInProject: item[0].roleInProject,
    uuid: uuidv4(),
  })));

  const initialValuesObject = { forecastings: initialValuesForecastings };

  const validateOneForecasting = (forecasting) => {
    const errors = {};

    if (forecasting?.absence === 0 && forecasting?.intensity < 5) {
      errors.intensity = 'Intensity must be over 5%';
    }
    if (forecasting?.absence === 0 && typeof forecasting?.intensity !== 'number') {
      errors.intensity = 'Intensity must be a number';
    }
    if (moment(forecasting?.endDate).isBefore(forecasting?.startDate)) {
      errors.startDate = 'End date must be equal or above start date';
    }

    const projectConcerned = projectsOneManager.find((p) => p.id === forecasting?.ProjectId);
    // // validate that the selected start date, for the forecasting, is greater (or equal)
    // // than the starting date of the selected project (if any)
    if ((typeof projectConcerned?.start_date !== 'undefined') && (typeof forecasting?.startDate !== 'undefined')
      && moment(forecasting?.startDate).isBefore(projectConcerned.start_date, 'day')) {
      const message = 'The start date of the forecasting must occur after (or the same day) as the starting date of the selected project';
      errors.startDate = errors.startDate ? `${errors.startDate} and ${message}` : message;
    }
    if ((typeof projectConcerned?.end_date !== 'undefined') && (forecasting?.endDate !== 'undefined')
        && moment(forecasting?.endDate).isAfter(projectConcerned.end_date, 'day')) {
      const message = 'The end date of the forecasting must occur before (or the same day) as the end date of the selected project';
      errors.startDate = errors.startDate ? `${errors.startDate} and ${message}` : message;
    }
    if (overlap) {
      const message = 'No overlapping allowed, please check the date range';
      errors.startDate = errors.startDate ? `${errors.startDate} and ${message}` : message;
    }

    return errors;
  };

  const validate = (values) => {
    const errors = {};
    errors.forecastings = values.forecastings
      .map(validateOneForecasting);
    if (errors.forecastings.every((f) => isEmpty(f))) {
      return {};
    } return errors;
  };

  const DEFAULT_FORECASTING = () => ({
    absence: 0,
    UserId: idUser,
    ProjectId: idProject,
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    intensity: 0,
    roleInProject: '0',
    uuid: uuidv4(),
  });

  const handleSubmit = (values) => {
    const data = values.forecastings.map((f) => ({
      ...f, UserId: idUser, edit: true,
    }));
    api.post('api/forecasting', data)
      .then(onClose)
      .then(() => setUpdateForecasting(true))
      .then(() => setUpdateForecasting(false));
  };

  return (
    <Formik initialValues={initialValuesObject} onSubmit={handleSubmit} validate={validate}>
      {({ values }) => {
        const dateRanges = values.forecastings.reduce((acc, cur) => {
          acc.push(
            cur.startDate,
            cur.endDate,
          );
          return acc;
        }, []);

        return (
          <Form>
            <Box id="forecastingsModal">
              <FieldArray name="forecastings">
                {({ remove, push }) => (
                  <Box>
                    {values.forecastings.length > 0
                    && values.forecastings.map((f, index) => (
                      <EditOneRange
                        key={f.uuid}
                        id={f.uuid}
                        index={index}
                        remove={() => {
                          remove(f.uuid);
                        }}
                        setList={setList}
                        setUpdateForecasting={setUpdateForecasting}
                        dateRanges={dateRanges}
                        setOverlap={setOverlap}
                      />
                    ))}
                    <Box display="flex" justifyContent="center">
                      <IconButton
                        onClick={() => push(DEFAULT_FORECASTING())}
                        size="small"
                        classes={{ root: classes.iconButtonAdd }}
                      >
                        <AddIcon />
                      </IconButton>
                    </Box>
                  </Box>
                )}
              </FieldArray>
            </Box>
            <Box display="flex" justifyContent="center" gridGap="1.5rem">
              <ButtonCustomFormik variant="large" text="Save" />
              <ButtonSimple variant="large" text="Cancel" event={onClose} />
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

ForecastProjectEdit.propTypes = {
  // groupedForecastings: PropTypes.arrayOf(PropTypes.arrayOf).isRequired,
  setUpdateForecasting: PropTypes.func.isRequired,
  setList: PropTypes.func.isRequired,
  idProject: PropTypes.number.isRequired,
  idUser: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ForecastProjectEdit;
