import moment from 'moment/moment';
import { extendMoment } from 'moment-range';
import groupBy from 'lodash/groupBy';
import { selectPublicHollidays } from './dateUtils';

const momentRange = extendMoment(moment);
const datesRangeToForecastings = (colabs) => colabs.length > 0 && colabs.reduce((acc, cur) => {
  const start = moment(cur.startDate, 'YYYY-MM-DD');
  const end = moment(cur.endDate, 'YYYY-MM-DD');
  const range = momentRange.range(start, end);
  const arrayRange = Array.from(range.by('days')).map((m) => m.format('YYYY-MM-DD')).filter((item) => {
    const date = moment(item).format('ddd');
    return date !== 'Sat' && date !== 'Sun';
  });
  const arrayRangeWithoutHollidays = arrayRange.filter(
    (value) => !selectPublicHollidays.includes(value),
  );
  // console.log('arrayRangeWithoutHollidays', arrayRangeWithoutHollidays);
  if (arrayRangeWithoutHollidays.length > 0) {
    for (let i = 0; i <= arrayRangeWithoutHollidays.length - 1; i += 1) {
      acc.push({
        ...cur,
        date: moment(arrayRangeWithoutHollidays[i]).format(),
      });
    }
  } else {
    acc.push({
      ...cur,
    });
  }
  return acc;
}, []);
export default datesRangeToForecastings;

/**
     * This function will clusterise the passed forectings so that the forecastings
     * in the following days with the same intensity
     * (for a project, a user and a manager) land in the same cluster. We want ranges of days.
     * Monday and wednesday with same intensity = 2 range,
     * Same intensity between friday and monday => 1 range
     *
     */
export const groupforecastingByDateRangeIntensity = (forecastings) => {
  const forecastingsSortedByDate = [...forecastings];
  forecastingsSortedByDate.sort();

  const groupByIntensity = groupBy(forecastingsSortedByDate, 'intensity');
  // console.log('groupByIntensity', groupByIntensity);
  const results = (Object.values(groupByIntensity)).map((items) => {
    const cluster = [];
    items.forEach((f, i, array) => {
      if (i === 0) {
        cluster.push([f]);
      } else {
        const previous = moment(array[i - 1].date);
        const current = moment(f.date);
        const difference = current.diff(previous, 'days');
        // console.log('difference', difference);

        const isPreviousFriday = previous.format('dddd') === 'Friday';
        // console.log('isPreviousFriday', isPreviousFriday);
        if (difference === 1 || (difference === 3 && isPreviousFriday) || difference === 5) {
          cluster[cluster.length - 1].push(f);
        } else {
          cluster.push([f]);
        }
      }
    });
    // console.log('cluster', cluster);

    return cluster;
  });
  return results;
};
