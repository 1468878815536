/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-fragments */
import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TableFooter, TablePagination, Typography } from '@material-ui/core';
import { convertCurrency } from '../../../utils/convertCurrency';
// import moment from 'moment';

const useRowStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'rgba(39, 130, 237, 0.05)',
    },
  },
  tableCellHead: {
    paddingTop: '8px',
    paddingBottom: '8px',
    color: 'rgba(0, 0, 0, 0.5)',
    height: '2rem',
  },
  tableCell: {
    border: '1px solid #CCCCCC',
    paddingTop: '7.5px',
    paddingBottom: '7.5px',
  },
  tableLargeHeader: {
    background: 'none',
    fontSize: '13px',
    fontWeight: 'bold',
    lineHeight: '1rem',
    letterSpacing: '0.08333em',
    textTransform: 'uppercase',
    borderBottom: 'none',
  },
  gridTable: {
    padding: '10px',
    boxShadow: '0px 1px 1px -1px rgb(0 0 0 / 20%), 0px 0px 3px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%)',
    borderRadius: '4px',
    border: '1px solid rgba(224, 224, 224, 1)',
  },
  tableItem: {
    fontSize: '12px',
    lineHeight: '1rem',
    padding: '15px',
  },

  tableHeader: {
    textTransform: 'uppercase',
    lineHeight: '1rem',
    fontSize: '10px',
    fontWeight: 'bold',
    background: '#373A36',
    color: 'white',
  },
  tableRow: {
    '& td:nth-last-child(1)': {
      borderRight: '1px solid rgba(224, 224, 224, 1)',
    },
    '& td:first-child': {
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
    },
  },
});

function Row(props) {
  const { row, convertCurrencyValue } = props;
  const classes = useRowStyles();

  return (
    <Fragment>
      <TableRow className={classes.tableRow}>
        <TableCell className={classes.tableItem} align="left">{row.fullName}</TableCell>
        <TableCell className={classes.tableItem} align="left">{row.daysWorked.toFixed(2)}</TableCell>
        <TableCell className={classes.tableItem} align="left">{convertCurrencyValue(row.hourlyRate).toFixed(0)}</TableCell>
        <TableCell className={classes.tableItem} align="left">{(row.daysWorked.toFixed(2) * 8 * convertCurrencyValue(row.hourlyRate).toFixed(0)).toFixed(2)}</TableCell>
      </TableRow>
    </Fragment>
  );
}

const OpexTable = (props) => {
  const { rows, currency, convertCurrencyValue } = props;
  const classes = useRowStyles();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const tableRef = React.createRef();
  const [page, setPage] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (tableRef.current) { tableRef.current.scrollTop = 0; }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    if (tableRef.current) { tableRef.current.scrollTop = 0; }
    setPage(0);
  };
  return (
    <TableContainer
      style={{
        padding: '7px 3px 7px 3px',
        fontSize: '12px',
      }}
    >
      <Table className={classes.gridTable} stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableLargeHeader} align="left" colSpan={11}>
              <Typography variant="overline" className={classes.tableLargeHeader} align="left" colSpan={8}>
                OPEX
              </Typography>
            </TableCell>

          </TableRow>
          <TableRow style={{ verticalAlign: 'middle' }}>
            <TableCell className={classes.tableHeader}>Name</TableCell>
            <TableCell className={classes.tableHeader}>Days worked</TableCell>
            <TableCell className={classes.tableHeader}>
              Hourly Rate (
              {convertCurrency(currency)}
              )

            </TableCell>
            <TableCell className={classes.tableHeader} align="left">
              Total (
              {convertCurrency(currency)}
              )

            </TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
            <Row key={row.id} row={row} convertCurrencyValue={convertCurrencyValue} />
          ))}
        </TableBody>
        {rows.length > 10 && (
        <TableFooter>
          <TableRow>
            <TableCell style={{ border: 'none' }} align="right" colSpan={11}>
              <TablePagination
                rowsPerPageOptions={[10, 15, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableCell>
          </TableRow>
        </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
};

export default OpexTable;
