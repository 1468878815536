import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles(() => ({
  root: {
    height: '26px',
    fontWeight: 700,
    color: '#707987',
    fontSize: '14px',
    marginRight: '8px',
    borderRadius: '4px',
  },
  label: {
    padding: '0 6px',
  },
}));

const ProjectCurrent = (props) => {
  const { forecasting, id } = props;

  const classes = useStyles();
  const userForecstingByProject = forecasting
    .filter((user) => user.userId === id);
  return (
    <div id="intensityProjectCollListColl">
      {userForecstingByProject.length > 0 && userForecstingByProject.map((item) => {
        const todayDate = moment(Date.now());
        const endDateFormat = moment(item.end_date);
        const startDateFormat = moment(item.start_date);
        let projectToDisplay;
        let colorChip;
        let colorChipBackGround;
        if (item.status === 'red') {
          colorChip = 'rgba(218, 41, 28, 0.7)';
          colorChipBackGround = 'rgba(218, 41, 28, 0.2)';
        } else if (item.status === 'orange') {
          colorChip = 'rgba(199, 142, 17, 1)';
          colorChipBackGround = 'rgba(255, 184, 28, 0.2)';
        } else {
          colorChip = 'rgba(104, 212, 126, 1)';
          colorChipBackGround = 'rgba(104, 212, 126, 0.2)';
        }

        if (moment(startDateFormat).isBefore(todayDate)
          && moment(todayDate).isBefore(endDateFormat)) {
          projectToDisplay = (
            <div key={item.projectId} style={{ display: 'flex', flexWrap: 'wrap', rowGap: '4px' }}>
              <Chip
                classes={{ root: classes.root, label: classes.label }}
                label={item.projectName}
                style={{ color: colorChip, backgroundColor: colorChipBackGround }}
              />
              <Chip
                classes={{ root: classes.root, label: classes.label }}
                label={`${item.intensity / 5}%`}
              />
            </div>
          );
        } else {
          projectToDisplay = '';
        }

        return projectToDisplay;
      })}
    </div>
  );
};

ProjectCurrent.propTypes = {
  id: PropTypes.number.isRequired,
  forecasting: PropTypes.arrayOf(PropTypes.shape({
    userId: PropTypes.number,
  })).isRequired,
  coll: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default ProjectCurrent;
