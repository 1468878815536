import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import loader from './loader.json';

const LoadingAnimation = () => (
  <div id="lottieBox" style={{ width: '100%', height: '100vh' }}>
    <Player
      src={loader}
      background="transparent"
      speed="1"
      style={{
        width: '300px',
        height: '300px',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '35px',
        marginBottom: '-20px',
      }}
      loop
      autoplay
    />
  </div>
);

export default LoadingAnimation;
