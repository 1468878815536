/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { Field, Formik, Form } from 'formik';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
// import IconType from '../../IconType';

const useStyles = makeStyles(() => ({
  select: {
    display: 'flex',
    alignItems: 'center',
    width: '185px',
    marginLeft: 0,
    borderRadius: '8px',
    color: 'black',
    backgroundColor: 'white',
    background: '#FFFFFF',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.03)',
    '&:focus': {
      backgroundColor: 'white',
      borderRadius: '8px',
    },
  },
  outlined: {
    height: '40px',

  },
  selectSize: {
    height: '48px',
  },
  selectCustom: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    borderRadius: '8px',
    width: '150px',
  },
  LabelType: {
    top: '-3px',
  },
}));

const ProjectTypeCustom = ({
  field, form: { touched, errors },
  ...props
}) => (
  <FormControl
    variant="outlined"
    {...field}
    {...props}
  >
    {touched[field.name]
      && errors[field.name] && <div className="error">{errors[field.name]}</div>}
  </FormControl>
);
const StatusActivities = (props) => {
  // eslint-disable-next-line no-shadow
  const classes = useStyles();
  const { setStatus, status } = props;
  // const [status, setStatus] = useState('Ongoing');
  return (
    <Formik
      initialValues={{
        activity_status: '',
      }}
    >
      {() => (
        <Form className="formCreateCollaborator">
          <Select
            label="activity_status"
            name="activity_status"
            id="filtersSelect"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            displayEmpty
            classes={{
              select: classes.select,
              outlined: classes.outlined,
              underline: classes.underline,
            }}
            className={classes.selectCustom}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
          >
            <MenuItem
              value="Ongoing"
              classes={{
                root: classes.root,
              }}
            >
              OnGoing
            </MenuItem>
            <MenuItem
              value="Completed"
              classes={{
                root: classes.root,
              }}
            >
              Completed
            </MenuItem>
          </Select>
        </Form>
      )}
    </Formik>
  );
};

StatusActivities.defaultProps = {
  projectType: undefined,
  budget: undefined,
};

StatusActivities.propTypes = {
  // edit: PropTypes.bool.isRequired,
  projectType: PropTypes.string,
  budget: PropTypes.string,
};

export default StatusActivities;
