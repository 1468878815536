/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';

const ProjectManager = (props) => {
  const {
    proj, setMembersOf, membersOf,
  } = props;

  const teamMembersNumber = proj?.Team?.length > 0
    ? proj?.Team?.length : 0;

  const itemSelected = membersOf === proj.id ? '#dbdbdb' : 'white';
  function memberssOf() {
    setMembersOf(proj?.id);
  }
  return (
    <div id="projManager" onClick={() => memberssOf()} style={{ backgroundColor: itemSelected, cursor: 'pointer' }}>
      <div style={{ backgroundColor: proj.color }} />
      <div>
        <p>{proj.project_name}</p>
        <p>
          {teamMembersNumber}
          {' '}
          teams members
        </p>
      </div>
    </div>

  );
};

ProjectManager.propTypes = {
  proj: PropTypes.shape().isRequired,
  id: PropTypes.number.isRequired,
  membersOf: PropTypes.number.isRequired,
  setMembersOf: PropTypes.func.isRequired,
};

export default ProjectManager;
