import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as AkkodisLogo } from '../../ressources/AKKODIS_Logo.svg';

const useStyles = makeStyles(() => ({
  bar: {
    height: '74px',
    width: '100%',
    left: '0px',
    top: '96px',
    display: 'flex',
    'justify-content': 'space-between',
    background: '#001F33',
    'box-shadow': '0px 4px 20px rgba(0, 0, 0, 0.05)',
  },
  first: {
    display: 'flex',
    'align-items': 'center',
    background: '#001F33',
    'background-color': '#001F33',
    borderBottom: '96px',
  },
  second: {
    display: 'flex',
    height: '100px',
  },
}));

const AccountInitLoader = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.bar}>
        <div className={classes.first}>
          <AkkodisLogo className={classes.logoModis} />
        </div>
      </div>
      <div className={classes.second} />
      <div className="lds-dual-ring" />
    </>
  );
};

export default AccountInitLoader;
