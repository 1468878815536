/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ShowOnRoles from '../utils/ShowOnRoles';
import UserContext from '../contexts/UserContext';

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    '&:hover': {
      opacity: 1,
    },
    '&$selected': {
      color: '#373A36',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#373A36',
    },
  },
  selected: {},
// eslint-disable-next-line react/jsx-props-no-spreading
}))((props) => <Tab disableRipple {...props} />);

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: '4px',
    '& > span': {
      maxWidth: '65%',
      width: '100%',
      backgroundColor: 'rgb(255, 184, 28)',
    },
  },
  flexContainer: {
    display: 'flex',
    // justifyContent: 'space-around',
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const useStyles = makeStyles(() => ({
  tabColor: {
    color: '#373A36',
    fontWeight: 400,
    fontSize: '16px',
  },
  tab: {
    minWidth: 0,
  },
  // elevation: {
  // boxShadow: 'none',
  // },
}));

const TabsProject = (props) => {
  const { valueTab, setValueTab } = props;
  const classes = useStyles();

  const user = useContext(UserContext);

  const handleChange = (event, newValue) => {
    setValueTab(newValue);
  };

  const tabs = [
    {
      tab:
  <AntTab
    key={0}
    label="Overview"
    className={classes.tabColor}
    classes={{
      root: classes.tab,
    }}
  />,
      allowedRoles: [1, 2, 3],
    },
    {
      tab:
  <AntTab
    key={1}
    label="Timeline"
    className={classes.tabColor}
    classes={{
      root: classes.tab,
    }}
  />,
      allowedRoles: [1, 2, 3],
    },
    {
      tab:
  <AntTab
    key={2}
    label="Finance"
    className={classes.tabColor}
    classes={{
      root: classes.tab,
    }}
  />,
      allowedRoles: [1, 2],
    },
    {
      tab: <AntTab
        key={3}
        label="Risks/Opportunities"
        className={classes.tabColor}
        classes={{
          root: classes.tab,
        }}
      />,
      allowedRoles: [1, 2],
    },
    {
      tab: <AntTab
        key={4}
        label="Time Budget"
        className={classes.tabColor}
        classes={{
          root: classes.tab,
        }}
      />,
      allowedRoles: [1, 2],
    }, {
      tab: <AntTab
        key={5}
        label="Notes"
        className={classes.tabColor}
        classes={{
          root: classes.tab,
        }}
      />,
      allowedRoles: [1, 2, 3],
    },
  ];

  return (
    <Paper>
      <StyledTabs
        value={valueTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label="tabs"
        style={{ marginLeft: '15px' }}
      >
        {tabs.map((tab) => {
          const currentRole = user.roleId;
          if (tab.allowedRoles.includes(currentRole)) {
            return tab.tab;
          }

          return null;
        })}
      </StyledTabs>
    </Paper>
  );
};

export default TabsProject;
