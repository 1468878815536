import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { MenuItem, Select } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';

const useStyles = makeStyles(() => ({
  pointFilter: {
    width: '18px',
    height: '18px',
    marginRight: '10px',
  },
  select: {
    display: 'flex',
    alignItems: 'center',
    width: '170px',
    padding: '9.5px 0px 9.5px 16px',
    borderRadius: '8px',
    color: 'black',
    backgroundColor: 'white',
    background: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.15)',
    boxSizing: 'border-box',
    boxShadow: 'none',
    '&:focus': {
      backgroundColor: 'white',
      borderRadius: '8px',
    },
  },
  outlined: {
    padding: '10.5px 0 10.5px 0px',
    height: '40px',
  },
}));

const BootstrapInput = withStyles(() => ({
  input: {
  },
}))(InputBase);

const SelectTime = (props) => {
  const { timeUnit, setTimeUnit } = props;
  const classes = useStyles();

  return (
    <Select
      style={{ width: '120px', display: 'flex' }}
      value={timeUnit}
      onChange={(e) => setTimeUnit(e.target.value)}
      displayEmpty
      defaultValue=""
      classes={{ select: classes.select, outlined: classes.outlined }}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      }}
      input={<BootstrapInput />}
    >
      <MenuItem value="day">
        <CalendarTodayOutlinedIcon className={classes.pointFilter} />
        Day
      </MenuItem>
      <MenuItem value="week">
        {' '}
        <CalendarTodayOutlinedIcon className={classes.pointFilter} />
        Week
      </MenuItem>
      <MenuItem value="month">
        {' '}
        <CalendarTodayOutlinedIcon className={classes.pointFilter} />
        Month
      </MenuItem>
    </Select>
  );
};

SelectTime.propTypes = {
  setTimeUnit: PropTypes.func.isRequired,
  timeUnit: PropTypes.string.isRequired,
};

export default SelectTime;
