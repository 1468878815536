/* eslint-disable react/destructuring-assignment */
import React, { useContext } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Link, useLocation } from 'react-router-dom';
import { alpha, makeStyles, withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';
import { Tooltip, IconButton } from '@material-ui/core';
import { AiTwotoneBank } from 'react-icons/ai';
import { BiExit } from 'react-icons/bi';
import PeopleIcon from '@material-ui/icons/People';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AppsIcon from '@material-ui/icons/Apps';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
// import { ReactComponent as ModisLogo } from '../ressources/modislogo.svg';
import { ReactComponent as AkkodisLogo } from '../ressources/AKKODIS_Logo.svg';
import ShowOnRoles from '../utils/ShowOnRoles';
import UserContext from '../contexts/UserContext';

const useStyles = makeStyles((theme) => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha('#929292', 0.25),
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
  },
  inputRoot: {
    width: 'auto',
  },
  inputInput: {
    color: 'white',
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: 'auto',
    [theme.breakpoints.up('md')]: {
      width: '60ch',
    },
  },
  logoModis: {
    marginLeft: '32px',
  },
  // (--------------Current Tab-----------------)
  links: {
    textDecoration: 'none',
  },
  button: {
    height: '42px',
    color: 'black',
    borderRadius: '13px',
    backgroundColor: 'rgb(255, 184, 28)',
    boxShadow: 'none',
    padding: '11px 12px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'rgb(255, 184, 28)',
      color: 'black',
    },
  },
  // (--------------Other tabs-------------)
  fabCustom: {
    width: '57px',
    height: '42px',
    borderRadius: '13px',
    backgroundColor: '#4e504d',
    boxShadow: 'none',
    display: 'flex',
    color: 'rgba(255, 255, 255, 0.6)',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: 'rgb(255, 184, 28)',
      color: 'black',
    },
  },
  logout: {
    width: '57px',
    height: '42px',
    borderRadius: '13px',
    backgroundColor: '#4e504d',
    boxShadow: 'none',
    display: 'flex',
    color: 'rgba(255, 255, 255, 0.6)',
    justifyContent: 'center',
    marginLeft: '-10px',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: 'rgb(255, 184, 28)',
      color: 'black',
    },
  },
}));

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))(Tooltip);

const Navbar = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const isAuthenticated = useIsAuthenticated();

  const { setKeywordSearch } = props;

  const { instance } = useMsal();
  const user = useContext(UserContext);

  let goodListNavigation;

  function handleLogout(inst) {
    const accounts = instance.getAllAccounts();
    const account = accounts[0];
    inst.logoutRedirect({
      account,
    }).catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e);
    });
  }

  const SignOutButton = () => (
    <IconButton onClick={() => handleLogout(instance)}>
      <div className={classes.logout}>
        <BiExit />
      </div>
    </IconButton>
  );
  const ProjectTabNonFocus = () => (
    <Link to="/">
      <CustomTooltip
        title="Projects"
      >
        <div className={classes.fabCustom}>
          <AppsIcon />
        </div>
      </CustomTooltip>
    </Link>
  );

  const ForecastingNonFocus = () => (
    <Link to="/forecasting">
      <CustomTooltip
        title="Forecasting"
      >
        <div className={classes.fabCustom}>
          <TrendingUpIcon />
        </div>
      </CustomTooltip>
    </Link>
  );

  const ListEmployeesNonFocus = () => (
    <Link to="/employeesList">
      <CustomTooltip
        title="Collaborators"
      >
        <div className={classes.fabCustom}>
          <PeopleIcon />
        </div>
      </CustomTooltip>
    </Link>
  );

  const GeneralFinanceNonFocus = () => (
    <></>
    // <Link to="/generalFinance">
    //   <CustomTooltip
    //     title="General Finance"
    //   >
    //     <div className={classes.fabCustom}>
    //       <AiFillBank size="25px" />
    //     </div>
    //   </CustomTooltip>
    // </Link>
  );

  const ProfileNonFocus = () => (
    <Link to={`/employees/${user.id || 3}`}>
      <CustomTooltip
        title="Profile"
      >
        <div className={classes.fabCustom}>
          <AccountCircleIcon />
        </div>
      </CustomTooltip>
    </Link>
  );

  if (location.pathname === '/forecasting') {
    goodListNavigation = (
      <ShowOnRoles allowedRoleIds={[1, 2, 4]}>
        <ProjectTabNonFocus />
        <Link to="/forecasting" className={classes.links}>
          <Button
            variant="contained"
            className={classes.button}
            startIcon={<TrendingUpIcon />}
          >
            Forecasting
          </Button>
        </Link>
        <GeneralFinanceNonFocus />
        <ListEmployeesNonFocus />
        <ProfileNonFocus />
        {isAuthenticated ? <SignOutButton /> : undefined }
      </ShowOnRoles>
    );
  } else if (location.pathname === '/employeesList') {
    goodListNavigation = (
      <>
        <ProjectTabNonFocus />
        <ShowOnRoles allowedRoleIds={[1, 2, 4]}>
          <ForecastingNonFocus />
          <GeneralFinanceNonFocus />
        </ShowOnRoles>
        <Link to="/employeesList" className={classes.links}>
          <Button
            variant="contained"
            className={classes.button}
            startIcon={<PeopleIcon />}
          >
            Collaborators
          </Button>
        </Link>
        <ProfileNonFocus />
        {isAuthenticated ? <SignOutButton /> : undefined }
      </>
    );
  } else if (location.pathname.includes('employees/') === true) {
    goodListNavigation = (
      <>
        <ProjectTabNonFocus />
        <ShowOnRoles allowedRoleIds={[1, 2, 4]}>
          <ForecastingNonFocus />
          <GeneralFinanceNonFocus />
        </ShowOnRoles>
        <ListEmployeesNonFocus />
        <Link to={`/employees/${user.id || 3}`} className={classes.links}>
          <Button
            variant="contained"
            className={classes.button}
            startIcon={<AccountCircleIcon />}
          >
            Profile
          </Button>
        </Link>
        {isAuthenticated ? <SignOutButton /> : undefined }
      </>
    );
  } else if (location.pathname.includes('/generalFinance')) {
    goodListNavigation = (
      <ShowOnRoles allowedRoleIds={[1, 2, 4]}>
        <ProjectTabNonFocus />
        <ForecastingNonFocus />
        <Link to="/generalFinance" className={classes.links}>
          <Button
            variant="contained"
            className={classes.button}
            startIcon={<AiTwotoneBank />}
          >
            General Finance
          </Button>
        </Link>
        <ListEmployeesNonFocus />
        <ProfileNonFocus />
        {isAuthenticated ? <SignOutButton /> : undefined }
      </ShowOnRoles>
    );
  } else {
    goodListNavigation = (
      <>
        <Link to="/" className={classes.links}>
          <Button
            variant="contained"
            className={classes.button}
            startIcon={<AppsIcon />}
          >
            Projects
          </Button>
        </Link>
        <ShowOnRoles allowedRoleIds={[1, 2, 4]}>
          <ForecastingNonFocus />
          <GeneralFinanceNonFocus />
        </ShowOnRoles>
        <ListEmployeesNonFocus />
        <ProfileNonFocus />
        {isAuthenticated ? <SignOutButton /> : undefined }
      </>
    );
  }

  return (
    <div id="navbar">
      <div id="first">
        <Link to="/"><AkkodisLogo className={classes.logoModis} onClick={() => setKeywordSearch('')} /></Link>
        {location.pathname !== '/' ? ''
          : (
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                onChange={(e) => setKeywordSearch(e.target.value)}
                placeholder="Select a project name, a client or a manager"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
              />
            </div>
          )}

      </div>

      <div id="second">
        {goodListNavigation}
      </div>

    </div>
  );
};

Navbar.propTypes = {
  setKeywordSearch: PropTypes.func.isRequired,
};

export default Navbar;
