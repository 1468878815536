/* eslint-disable react/no-array-index-key */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ChipSeniority from './ChipSeniority';

const useStyles = makeStyles(() => ({
  boxElement: {
    display: 'flex',
    gap: '16px',
    flexWrap: 'wrap',
    marginTop: '24px',
  },
  title: {
    fontSize: '20px',
    fontWeight: 700,
  },
}));

const SkillsProfile = (props) => {
  const {
    elements, keyRequired, word, listInfos,
  } = props;
  const classes = useStyles();

  const listToDisplay = elements.sort((a, b) => b.seniority - a.seniority).map((i) => {
    const name = `${keyRequired.slice(0, keyRequired.indexOf('_'))}Id`;
    const elementToFind = listInfos?.find((el) => el.id === i[name]);
    return { ...i, [keyRequired]: elementToFind ? elementToFind[keyRequired] : undefined };
  });
  return (
    <div>
      <p className={classes.title}>{word}</p>
      <div className={classes.boxElement}>
        {listToDisplay.map((item, i) => (
          <ChipSeniority
            key={i}
            label={item[keyRequired]}
            seniority={item.seniority}
          />
        ))}
      </div>
    </div>
  );
};

SkillsProfile.propTypes = {
  keyRequired: PropTypes.string.isRequired,
  listInfos: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  word: PropTypes.string.isRequired,
  elements: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default SkillsProfile;
