import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import { Box } from '@material-ui/core';
import ConfirmationComponent from '../../commonComponents/ConfirmationComponent';
import ModalCustom from '../../commonComponents/ModalCustom';

const useStyles = makeStyles(() => ({
  deleteButton: {
    color: '#4B4E4A',
    width: '30px',
    height: '30px',
    padding: '24px',
    opacity: '70%',
    marginLeft: '1.5rem',
  },
  buttonRemove: {
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'Roboto',
    margin: '16px 0 0 7px',
  },
}));

const DeleteForecasting = (props) => {
  const { removeForecastings, values } = props;
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ModalCustom
      handleClose={handleClose}
      open={open}
      width="676px"
      title="Delete the Forecasting?"
      childButtonOpenModal={(
        <Box display="flex" justifyContent="center">
          <IconButton
            disableFocusRipple
            disableRipple
            aria-label="delete"
            className={classes.buttonRemove}
            onClick={handleOpen}
            size="small"
          >
            <ClearIcon />
          </IconButton>
        </Box>

      )}
      child={(
        <ConfirmationComponent
          eventOne={() => {
            removeForecastings(values);
            return handleClose();
          }}
          eventTwo={handleClose}
        />
      )}
    />
  );
};

DeleteForecasting.propTypes = {
  removeForecastings: PropTypes.func.isRequired,
  values: PropTypes.shape().isRequired,
};

export default DeleteForecasting;
