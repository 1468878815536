import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: '32px',
    borderRadius: 5,
    marginBottom: '24px',
    width: '95%',
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 800],
    // color: (props) => props.colorBar,
  },
  bar: {
    borderRadius: 5,
  },
  barColorPrimary: {
    backgroundColor: (props) => props.colorBar,

  },
}))(LinearProgress);

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  bar: {
    backgroundColor: (props) => props.colorBar,

  },
});

export default function CustomizedProgressBars(props) {
  const { evolution, colorBar } = props;
  const classes = useStyles({ colorBar });

  return (
    <div className={classes.root}>
      <BorderLinearProgress classes={{ bar: classes.bar }} variant="determinate" value={evolution} />
    </div>
  );
}

CustomizedProgressBars.defaultProps = {
  evolution: 0,
};

CustomizedProgressBars.propTypes = {
  evolution: PropTypes.number,
  colorBar: PropTypes.string.isRequired,
};
