/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import _debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { TableCell, ClickAwayListener, Typography } from '@material-ui/core';
import { api } from '../../../api/api';

const useStyles = makeStyles(() => ({
  tableCell: {
    padding: '12px',
    '&:hover': {
      background: '#FFFFFF',
      boxSizing: 'border-box',
      boxShadow: '0px 5px 10px 2px rgba(0, 0, 0, 0.13)',
      borderRadius: '4px',
    },
  },
  tableCellEdit: {
    border: '1px solid #CCCCCC',
    padding: '0px',
  },
}));

const PriceWPEdit = (props) => {
  const {
    priceWP, snapshotsIds, change, setChange, colspan,
  } = props;

  const [price, setPrice] = React.useState(priceWP);
  const [flag, updateFlag] = React.useState(false);
  // The ref here will prevent the rerender of the component
  const open = React.useRef(false);

  const classes = useStyles();

  const handleClick = () => {
    open.current = !open.current;
    updateFlag(!flag);
  };

  const handleClickAway = () => {
    open.current = false;
    updateFlag(!flag);
  };

  function handleDebounceFn(inputValue) {
    api.put(`api/snapshots/property?ids=${snapshotsIds}`, {
      key: 'totalPriceWP',
      value: inputValue,
    }).then(() => {
      setChange(!change);
    });
  }

  const debounceFn = useCallback(_debounce(handleDebounceFn, 1000), []);

  const handleChangePrice = (event) => {
    if (event.target.value.length <= 20) {
      setPrice(event.target.value);
      debounceFn(event.target.value);
    } else {
      setPrice(event.target.value.slice(0, 20));
    }
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        {!open.current ? (
          <TableCell colSpan={colspan} classes={{ root: classes.tableCell }} style={{ fontWeight: 'bold' }} align="right">
            <Typography onClick={handleClick} style={{ fontWeight: 'bold' }} variant="overline">
              {price}
              €
            </Typography>
          </TableCell>
        ) : (
          <TableCell colSpan={1} classes={{ root: classes.tableCellEdit }} style={{ fontWeight: 'bold' }} component="th" scope="row" align="right">
            <TextField
              id="totalPriceWPField"
              value={price}
              onChange={handleChangePrice}
              style={{
                background: '#FFFFFF',
                boxSizing: 'border-box',
                boxShadow: '0px 5px 10px 2px rgba(0, 0, 0, 0.13)',
                borderRadius: '4px',
                padding: '5px',
              }}
              InputProps={{
                style: {
                  fontSize: '12px',
                  lineHeight: '2.66',
                  texTransform: 'uppercase',
                  padding: '5px',
                  fontWeight: 'bold',
                },
              }}
            />
          </TableCell>
        ) }

      </ClickAwayListener>
    </>

  );
};

PriceWPEdit.propTypes = {
  priceWP: PropTypes.number.isRequired,
};

export default PriceWPEdit;
