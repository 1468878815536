/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import groupBy from 'lodash/groupBy';
import GridPositioner from './GridPositioner';
import generateArrayTimeUnit from '../../utils/generateArrayTimeUnit';
import GreyBarTime from './GreyBarTime';
import VerticalArrow from './VerticalArrow';

const useStyles = makeStyles(() => ({
  rootButton: {
    padding: '0 0 0 0',
    marginTop: '34px',
    '&:hover': {
      backgroundColor: '#FFF',
    },
  },
  rightArrow: {
    transform: 'translateX(-10px)',
  },
  leftArrow: {
    transform: 'translateX(10px)',
  },
  icons: {
    width: '75%',
  },
}));

const TimeAxis = (props) => {
  const {
    startDate, endDate, timeUnit, setStartDate, setEndDate,
  } = props;

  const classes = useStyles();

  const arrayMoment = generateArrayTimeUnit(startDate, endDate, timeUnit);

  let goodFormatDate;

  if (timeUnit === 'day') {
    goodFormatDate = 'D';
  } else if (timeUnit === 'week') {
    goodFormatDate = 'W';
  } else if (timeUnit === 'month') {
    goodFormatDate = 'MMM';
  }

  const groupByMonthsAndYears = groupBy(arrayMoment, (element) => element.format('MM:yyyy'));
  const groupByYears = groupBy(arrayMoment, (element) => element.format('yyyy'));

  const returnInPast = () => {
    setStartDate(moment(startDate).subtract(5, `${timeUnit}s`));
    setEndDate(moment(endDate).subtract(5, `${timeUnit}s`));
  };
  const goToFuture = () => {
    setStartDate(moment(startDate).add(5, `${timeUnit}s`));
    setEndDate(moment(endDate).add(5, `${timeUnit}s`));
  };

  const returnInPastPlus = () => {
    setStartDate(moment(startDate).subtract(10, `${timeUnit}s`));
    setEndDate(moment(endDate).subtract(10, `${timeUnit}s`));
  };
  const goToFuturePlus = () => {
    setStartDate(moment(startDate).add(10, `${timeUnit}s`));
    setEndDate(moment(endDate).add(10, `${timeUnit}s`));
  };

  const horizontalBarTimeMonthYears = Object.values(timeUnit === 'month' ? groupByYears : groupByMonthsAndYears).map((item, index) => (
    <GridPositioner
      key={item[0].format('MMM:yyyy')}
      row={1}
      startDate={item[0]}
      endDate={item[item.length - 1]}
      gridStartDate={startDate}
      timeUnit={timeUnit}
    >
      <GreyBarTime
        timeUnit={timeUnit}
        date={item[0]}
        isFirst={index === 0}
        isLast={index === Object.values(timeUnit === 'month' ? groupByYears : groupByMonthsAndYears).length - 1}
        returnInPastPlus={returnInPastPlus}
        goToFuturePlus={goToFuturePlus}
      />
    </GridPositioner>
  ));

  const renderVerticalLine = () => {
    const isCurrentDayInRange = moment().isBetween(startDate, endDate, timeUnit, '[]');
    const currentDay = isCurrentDayInRange ? moment() : null;

    return currentDay && (
      <>
        <GridPositioner
          key={`${currentDay}_day`}
          row={3}
          startDate={currentDay}
          gridStartDate={startDate}
          timeUnit={timeUnit}
          // style={{ color: 'grey' }}
          isCurrentDay
        >
          {currentDay && currentDay.format('ddd')[0]}
        </GridPositioner>
        <GridPositioner
          key={`${currentDay}_date`}
          row={2}
          startDate={currentDay}
          gridStartDate={startDate}
          timeUnit={timeUnit}
          // style={{ color: 'grey' }}
          isCurrentDay
        >
          {currentDay && currentDay.format(goodFormatDate)}
        </GridPositioner>
        <GridPositioner
          key="today"
          startDate={currentDay}
          row={1}
          gridStartDate={startDate}
          timeUnit={timeUnit}
          // style={{ color: 'black' }}
        >
          <VerticalArrow />
        </GridPositioner>
      </>
    );
  };

  return (
    <>
      <div id="timeAxis">
        <div id="timeAxis1">
          <p>Name</p>
        </div>
        <IconButton
          classes={{ root: classes.rootButton }}
          className={classes.leftArrow}
          onClick={returnInPast}
        >
          <ArrowBackIosIcon className={classes.icons} />
        </IconButton>
        <div id="listDate" style={{ gridTemplateColumns: `repeat(${endDate.diff(startDate, `${timeUnit}s`) + 1}, 1fr)` }}>
          {horizontalBarTimeMonthYears}
          {arrayMoment.map((date) => (
            <>
              <GridPositioner
                key={date}
                row={2}
                startDate={date}
                gridStartDate={startDate}
                timeUnit={timeUnit}
                style={{ color: 'grey' }}
              >
                {date.format(goodFormatDate)}
              </GridPositioner>
              <GridPositioner
                key={`${date}_day`}
                row={3}
                startDate={date}
                gridStartDate={startDate}
                timeUnit={timeUnit}
                style={{ color: 'grey' }}
              >
                {date.format('ddd')[0]}
              </GridPositioner>
            </>
          ))}
          {renderVerticalLine()}
        </div>
        <IconButton
          classes={{ root: classes.rootButton }}
          className={classes.rightArrow}
          onClick={goToFuture}
        >
          <ArrowForwardIosIcon className={classes.icons} />
        </IconButton>
      </div>
      <hr />
    </>
  );
};

TimeAxis.propTypes = {
  timeUnit: PropTypes.oneOf(['day', 'week', 'month']).isRequired,
  startDate: PropTypes.shape().isRequired, // Moment object
  endDate: PropTypes.shape().isRequired, // Moment object
  setStartDate: PropTypes.func.isRequired,
  setEndDate: PropTypes.func.isRequired,
};

export default TimeAxis;
