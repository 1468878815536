import { React } from 'react';
import { Box, Grid } from '@material-ui/core';
import { SelectFormik } from '../../commonComponents/Fields/SelectFormik';

const Step4False = () => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Box>
        <Box display="flex" className="formFieldLabel" gridGap="5px">
          <label htmlFor="chance_winning" className="labelsFormCreate">Chance of Winning Project</label>
        </Box>
        <SelectFormik
          name="chance_winning"
          elements={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
            .map((el) => ({ value: el, name: `${el}%` }))}
        />
      </Box>

    </Grid>
    <Grid item xs={12}>
      <Box>
        <Box display="flex" className="formFieldLabel" gridGap="5px">
          <label htmlFor="step_sale" className="labelsFormCreate">Step in Sales Process</label>
        </Box>
        <SelectFormik
          name="step_sale"
          elements={['Identify', 'Propose', 'Submission', 'Negotiating', 'Won', 'Lost', 'Cancelled']
            .map((el) => ({ value: el, name: el }))}
        />
      </Box>
    </Grid>

  </Grid>
);

export default Step4False;
