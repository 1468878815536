import { Box, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

const useStyles = makeStyles({
  detailedProjectBox: {
    width: 'calc(100vw - 8rem)',
    maxWidth: '1648px',
    marginTop: '4%',
    marginBottom: '3.rem',
    display: 'flex',
    gridGap: '3.5rem',
    gap: '3.5rem',
  },
  leftSection: {
    flexGrow: '1',
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.08)',
    borderRadius: '13px',
    height: 'fit-content',
  },
  rightSection1: {
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.08)',
    backgroundColor: 'white',
    borderRadius: '13px',
    height: '172px',
    paddingLeft: '32px',
    paddingRight: '32px',
  },
  rightSection2: {
    maxHeight: 'calc(100% - 176px - 36px)',
    minHeight: 'calc(100% - 176px - 3.5rem)',
    paddingLeft: '32px',
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.08)',
    backgroundColor: 'white',
    borderRadius: '13px',
    paddingRight: '32px',
  },
  rightSection: {
    width: '28rem',
    flexShrink: '0',
    display: 'flex',
    flexDirection: 'column',
    gridGap: '3.5rem',
    gap: '3.5rem',
  },
  leftSection1: {
    height: '91.2px',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '32px',
    paddingRight: '32px',
  },
  leftSection2: {
    width: '858px',
    height: '28px',
    paddingLeft: '32px',
    paddingRight: '32px',
  },
  leftSection3: {
    overflowY: 'hidden',
    height: `calc(100% - ${236}px)`,
    paddingTop: '0px',
    marginBottom: '20px',
  },
  element: {
    marginRight: '1rem',
    borderRadius: '10px',
  },
  element2: {
    display: 'flex',
    alignItems: 'center',
    width: '133.54px',
    height: '34px',
  },
  elementsSection3: {
    width: '680.5px',
    height: '229.6px',
    marginTop: '24px',
    paddingLeft: '32px',
    paddingRight: '32px',
  },
  elementsSection4: {
    width: '680.5px',
    height: '65px',
    display: 'flex',
  },
  elements2Section3: {
    paddingLeft: '32px',
    paddingRight: '32px',
    marginBottom: '24px',
  },
  lastelements: {
    paddingLeft: '32px',
    paddingRight: '32px',
    display: 'flex',
    flexDirection: 'column',
  },
  elements2Section4: {
    marginBottom: '32px',
  },
});

const CardDashboardLoader = () => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.detailedProjectBox}>
        <Box className={classes.leftSection}>
          <Box className={classes.leftSection1}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Skeleton variant="text" width={250} height={68} />
              <div style={{
                display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '1.5rem',
              }}
              >
                <Skeleton variant="text" width={134.63} height={68} />
                <Skeleton variant="text" width={48} height={48} />
              </div>
            </div>
          </Box>
          <div style={{
            display: 'flex',
            marginBottom: '20px',
            gap: '1rem',
            justifyContent: 'space-between',
            paddingLeft: '32px',
            paddingRight: '32px',
          }}
          >
            <Box style={{ display: 'flex', gridGap: '1rem', gap: '1rem' }}>
              <Skeleton variant="text" className={classes.element2} />
              <Skeleton variant="text" className={classes.element2} />
              <Skeleton variant="text" className={classes.element2} />
            </Box>
          </div>
          <Box className={classes.leftSection2}>
            <div style={{
              display: 'flex', overflow: 'hidden', minHeight: '48px', gap: '1rem',
            }}
            >
              <Skeleton variant="text" width={93.35} height={28} />
              <Skeleton variant="text" width={93.35} height={28} />
              <Skeleton variant="text" width={93.35} height={28} />
              <Skeleton variant="text" width={93.35} height={28} />
              <Skeleton variant="text" width={93.35} height={28} />
              <Skeleton variant="text" width={93.35} height={28} />
            </div>
          </Box>
          <Skeleton variant="text" style={{ height: '2px' }} />
          <Skeleton variant="text" style={{ height: '2px' }} />
          <Box className={classes.leftSection3}>
            <Box className={classes.elements2Section3}>
              <Skeleton variant="text" width={680.5} height={126.8} />
            </Box>
            <Box className={classes.elements2Section3}>
              <Skeleton variant="text" width={680.5} height={19.2} />
            </Box>
            <Box className={classes.elements2Section3}>
              <Skeleton variant="text" width={680.5} height={19.2} />
            </Box>
            <Box className={classes.elementsSection3}>
              <Box className={classes.elements2Section4}>
                <Skeleton variant="text" width={80} height={19.2} />
                <Skeleton variant="text" width={80} height={25} />
              </Box>
              <Box className={classes.elements2Section4}>
                <Skeleton variant="text" width={80} height={19.2} />
                <Skeleton variant="text" width={80} height={25} />
              </Box>
              <Box className={classes.elements2Section4}>
                <Skeleton variant="text" width={80} height={19.2} />
                <Skeleton variant="text" width={80} height={25} />
              </Box>
            </Box>
            <Box className={classes.elementsSection4}>
              <div className={classes.lastelements}>
                <Skeleton className={classes.lastelements} width={123.97} height={42} />
                <Skeleton className={classes.lastelements} width={123.97} height={22} />
              </div>
              <div className={classes.lastelements}>
                <Skeleton className={classes.lastelements} width={96} height={42} />
                <Skeleton className={classes.lastelements} width={96} height={22} />
              </div>
            </Box>
          </Box>
        </Box>
        <div className={classes.rightSection}>
          <Box className={classes.rightSection1}>
            <div style={{ marginTop: '24px', marginBottom: '0px' }}>
              <Skeleton variant="text" width={134} height={40} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ marginRight: '14px' }}>
                <Skeleton variant="text" width={344.3} height={56} />
              </div>
              <Skeleton variant="text" width={29.7} height={47} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '24px' }}>
              <Skeleton variant="circle" width={24} height={24} />
              <div style={{ marginLeft: '12px' }}>
                <Skeleton variant="text" width={65.5} height={16} />
              </div>
            </div>
          </Box>
          <Box className={classes.rightSection2}>
            <div style={{
              display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '24px',
            }}
            >
              <Skeleton variant="text" width={121.38} height={20} />
              <div style={{ marginLeft: '32px', paddingRight: '32px', width: '80px' }}>
                <Skeleton variant="text" width={120} height={16} />
              </div>
            </div>
            <div style={{
              display: 'flex', justifyContent: 'start', alignItems: 'center', marginBottom: '20px', marginTop: '24px',
            }}
            >
              <div style={{ marginRight: '14px' }}>
                <Skeleton variant="square" width={46} height={26} />
              </div>
              <Skeleton variant="text" width={277.33} height={26} />
            </div>
            <div style={{
              display: 'flex', justifyContent: 'space-between', alignItems: 'center',
            }}
            >
              <Skeleton variant="circle" width={46} height={46} />
              <Skeleton variant="text" width={277.33} height={66} />
              <Skeleton variant="circle" width={46} height={46} />
            </div>
            <div style={{
              display: 'flex', justifyContent: 'space-between', alignItems: 'center',
            }}
            >
              <Skeleton variant="circle" width={46} height={46} />
              <Skeleton variant="text" width={277.33} height={66} />
              <Skeleton variant="circle" width={46} height={46} />
            </div>
            <div style={{
              display: 'flex', justifyContent: 'space-between', alignItems: 'center',
            }}
            >
              <Skeleton variant="circle" width={46} height={46} />
              <Skeleton variant="text" width={277.33} height={66} />
              <Skeleton variant="circle" width={46} height={46} />
            </div>
            <div style={{
              display: 'flex', justifyContent: 'space-between', alignItems: 'center',
            }}
            >
              <Skeleton variant="circle" width={46} height={46} />
              <Skeleton variant="text" width={277.33} height={66} />
              <Skeleton variant="circle" width={46} height={46} />
            </div>
          </Box>
        </div>
      </Box>
    </>
  );
};

export default CardDashboardLoader;
