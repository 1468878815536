/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
// import UsersApi from '../../api/UsersApi';

const ActivitiesListSlice = createSlice({
  name: 'activitiesList',
  initialState: { activitiesList: [] },
  reducers: {
    activitiesListAdded: (state, action) => {
      // state.push(action.payload);
      state.activitiesList = action.payload;
    },
  },
});

const { actions, reducer } = ActivitiesListSlice;

export const { activitiesListAdded } = actions;

export default reducer;
