import React, { useState } from 'react';
import {
  Box, Divider, Typography, Avatar, Button, TextField, TextareaAutosize,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import moment from 'moment';
import { api } from '../../../api/api';
import DeleteNote from './DeleteNote';
import { ButtonCustom, ButtonSimple } from '../../../commonComponents/ButtonCustom';

const NotesBoard = (props) => {
  const {
    note,
    useStyles,
    notes,
    setNotes,
  } = props;

  const [newTitle, setNewTitle] = useState(note.title);
  const [newComment, setNewComment] = useState(note.comment);
  const [isValidComment, setIsValidComment] = useState(true);
  const [isValidTitle, setIsValidTitle] = useState(true);
  const [hasBeenTouched, setHasBeenTouched] = useState(false);
  const classes = useStyles();
  const [edit, setEdit] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const bull = (
    <Box
      component="span"
      sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
      •
    </Box>
  );
  const lastUpdatedBy = () => {
    const fullname = ` ${note.User.first_name} ${note.User.last_name}`;
    const lastUpdated = moment(note.updatedAt).isAfter(moment(note.createdAt)) ? 'Last updated by \xa0' : 'Posted by \xa0';
    return (
      <Typography style={{ marginLeft: '10px', display: 'flex' }} variant="body2" color="textSecondary">
        {lastUpdated}
        <Typography style={{ fontWeight: 'bold' }} variant="body2" color="textSecondary">
          {fullname}
        </Typography>
        {bull}
        {moment(note.updatedAt).isAfter(moment(note.createdAt))
          ? moment(note.updatedAt).fromNow() : moment(note.createdAt).fromNow() }
      </Typography>
    );
  };

  const handleUpdateTitle = (e) => {
    setHasBeenTouched(true);
    const title = e.target.value;
    setNewTitle(title.length > 20 ? title.slice(0, 20) : e.target.value);
    if (title.length === 0 || title.trim().length === 0 || title.length < 2) {
      setIsValidTitle(false);
    } else {
      setIsValidTitle(true);
      setHasBeenTouched(false);
    }
  };

  const handleUpdateDescription = (e) => {
    setHasBeenTouched(true);
    const comment = e.target.value;
    setNewComment(comment);
    if (comment.length < 2 || comment.trim().length === 0 || comment.length > 5000) {
      setIsValidComment(false);
    } else {
      setIsValidComment(true);
      setHasBeenTouched(false);
    }
  };

  const handleUpdateNote = () => {
    const data = { title: newTitle, comment: newComment };
    if (newTitle.length >= 2 || newComment.length <= 2) {
      api.put(`api/comments/${note.id}`, data)
        .then((res) => {
          const notesFiltered = notes.filter((n) => n.id !== note.id);
          notesFiltered.push({
            ...note, title: newTitle, comment: newComment, updatedAt: res.data.updatedAt,
          });
          setNotes(notesFiltered);
        })
        .then(() => { setEdit(false); });
    }
  };

  const handleDeleteNote = () => {
    setOpenDeleteDialog(true);
  };

  const avatarBox = (
    <Box display="flex" alignItems="center">
      <Avatar
        title={`${note.User.first_name} ${note.User.last_name}`}
        alt={note.User.first_name.charAt(0) + note.User.last_name.charAt(0)}
        key={note.User.first_name.charAt(0) + note.User.last_name.charAt(0)}
        src="https://www.byrdie.com/thmb/Jjj54SaUGDzwW7h5MxQI8hsNXnw=/1000x1000/filters:fill(auto,1)/Stocksy_txp67ff3d0fWrz200_Medium_3514532-crop-29154df0578b40309acce7c668e12faa.jpg"
      />
      {lastUpdatedBy()}
    </Box>
  );

  return (
    <>

      <Card hidden={edit} className={classes.noteBox} elevation={0}>
        <Typography gutterBottom variant="h6" component="div" style={{ overflowWrap: 'break-word', wordBreak: 'break-word', marginBottom: '16px' }}>
          {note.title}
        </Typography>
        <Typography variant="body2" color="textSecondary" style={{ marginBottom: '24px', overflowWrap: 'break-word', wordBreak: 'break-word' }}>
          {note.comment}
        </Typography>
        <Divider />
        <Box display="flex" justifyContent="space-between" marginTop="16px">
          {avatarBox}
          <Box display="flex" gridGap="24px">
            <Button style={{ textTransform: 'capitalize' }} onClick={() => setEdit(true)}>
              <Typography variant="body2" color="textSecondary">
                Edit
              </Typography>
            </Button>

            <Button style={{ textTransform: 'capitalize' }} onClick={handleDeleteNote}>
              <Typography variant="body2" color="textSecondary">
                Delete
              </Typography>
            </Button>
          </Box>
        </Box>

      </Card>

      <Card
        hidden={!edit}
        className={classes.noteBox}
        elevation={0}
        style={{
          border: '1px solid rgba(0, 0, 0, 0.3)',
          boxShadow: '2px 4px 7px 1px rgba(0, 0, 0, 0.05)',
        }}
      >
        <TextField
          type="text"
          value={newTitle}
          className={classes.titleText}
          onChange={handleUpdateTitle}

        />
        <TextareaAutosize
          name="description"
          minRows={2}
          value={newComment}
          onChange={handleUpdateDescription}
          className={classes.descriptionProject}
        />

        {(hasBeenTouched && !isValidTitle) ? (<p style={{ color: 'rgba(218, 41, 28, 0.7)', marginBottom: '10px' }}>The title is mandatory with 2 characters minimum.</p>) : ''}
        {(hasBeenTouched && !isValidComment) ? <p style={{ color: 'rgba(218, 41, 28, 0.7)', marginBottom: '10px' }}>A description of the note is mandatory with 2 to 5000 chars minimum.</p> : ''}

        <Divider style={{
          marginTop: '8px', marginBottom: '24px',
        }}
        />

        <Box display="flex" justifyContent="space-between" marginTop="16px">
          {avatarBox}
          <Box display="flex" justifyContent="flex-end" gridGap="24px">
            <ButtonCustom variant="small" text="Save" event={handleUpdateNote} />
            <ButtonSimple variant="small" text="Cancel" event={() => setEdit(false)} />
          </Box>
        </Box>

      </Card>

      <DeleteNote
        noteId={note.id}
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        notes={notes}
        setNotes={setNotes}
      />
    </>

  );
};

NotesBoard.defaultProps = {
  notes: [],
};

NotesBoard.propTypes = {
  note: PropTypes.shape().isRequired,
  useStyles: PropTypes.func.isRequired,
  setNotes: PropTypes.func.isRequired,
  notes: PropTypes.arrayOf(PropTypes.shape()),
};

export default NotesBoard;
