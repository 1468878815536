/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Field, useFormikContext } from 'formik';

const useStyles = makeStyles(() => ({
  inputFocused: {
    marginLeft: '16px',
  },
  inputRoot: {
    height: '40px',
    // width: '100%',
  },
}));

/**
 * This components wraps Material UI's <Automcomplete>
 * in order to use it as a Formik field.
 */
const FormikAutocomplete = ({
  options, name, getOptionLabel, getOptionSelected, validate, onChange, label, ...props
}) => {
  const classes = useStyles();
  const { setFieldValue, values } = useFormikContext();

  let classAutocomplete;
  if (props.status === 'createProject') {
    classAutocomplete = 'autoCompleteCreateProject';
  } else {
    classAutocomplete = '';
  }

  return (
    <>
      <Autocomplete
        classes={{
          inputFocused: classes.inputFocused,
          inputRoot: props.status === 'createProject' ? classes.inputRoot : '',
        }}
        name={name}
        id={classAutocomplete}
        onChange={(e, v) => {
          const newValue = v.value ?? v;
          if (onChange) {
            onChange(e, newValue);
          } else {
            values[name] = newValue;
            setFieldValue(name, newValue);
          }
        }}
        size="small"
        // we know which data to display and dont need renderOption
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        options={options}
        fullWidth
        shrink
        value={values[name] ?? null}
        renderInput={(params) => (
          <Field
            placeholder={props.placehold}
            value={values[name]}
            {...params}
            name={name}
            variant="outlined"
            component={TextField}
            label={label}
            InputLabelProps={{ shrink: props.status !== 'createProject' }}
            // style={{ width: props.status !== 'createProject' ? '400px' : '' }}
            style={{ width: '100%' }}
            validate={validate}
          />
        )}
        {...props}
      />
    </>
  );
};

FormikAutocomplete.defaultProps = {
  options: [],
  validate: () => {},
  getOptionLabel: (option) => option.key ?? option.name ?? '',
  getOptionSelected: (option, val) => (option.key
    ? option.key === JSON.stringify(val)
    : JSON.stringify(option) === JSON.stringify(val)),
};

FormikAutocomplete.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape()),
  getOptionLabel: PropTypes.func,
  getOptionSelected: PropTypes.func,
  validate: PropTypes.func,
};

export default FormikAutocomplete;
