import React from 'react';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const ChartTimeBudget = (props) => {
  const { data, budgetType } = props;

  const stackedWorked = [];
  const stackedForecasted = [];

  const dataFormatted = [[], [], [], stackedWorked, stackedForecasted, [], [], []];

  data.forEach((sprint, i, a) => {
    dataFormatted[0].push(sprint.baseline);
    dataFormatted[1].push(sprint.forecast);
    dataFormatted[2].push(sprint.worked);
    stackedWorked.push(a[i - 1]
      ? sprint.worked + stackedWorked[stackedWorked.length - 1] : sprint.worked);
    stackedForecasted.push(a[i - 1]
      ? sprint.forecast + stackedForecasted[stackedForecasted.length - 1] : sprint.forecast);
    dataFormatted[5].push(
      dataFormatted[3][i] - dataFormatted[0][i] < 0 ? 0 : dataFormatted[3][i] - dataFormatted[0][i],
    ); // Deviation
    dataFormatted[7].push(sprint.timeStamp);
  });

  stackedWorked.forEach((el) => dataFormatted[6].push((el
    / stackedForecasted[stackedForecasted.length - 1]) * 100));

  const biggestNumber = Math.max.apply(null, dataFormatted
    .map((el) => Math.max.apply(null, el))
    .filter((item, i) => i < 6));

  // eslint-disable-next-line no-unused-vars
  const series = budgetType !== 'Work Package' ? [{
    name: 'Agg. Forecast (days)',
    type: 'line',
    color: '#D59ED7',
    data: dataFormatted[4],
  }, {
    name: 'Agg. work (days)',
    type: 'line',
    color: '#89ABE3',
    data: dataFormatted[3],
  }, {
    name: 'Forecast (days)',
    type: 'bar',
    color: '#D59ED7',
    data: dataFormatted[1],
  }, {
    name: 'Worked (days)',
    type: 'bar',
    color: '#89ABE3',
    data: dataFormatted[2],
  }, {
    name: '% of completion',
    color: 'rgba(255, 255, 255, 0.1)',
    data: dataFormatted[6].map((el) => el.toFixed(0)),
  }] : [{
    name: 'Agg. Forecast (days)',
    type: 'line',
    color: '#D59ED7',
    data: dataFormatted[4],
  }, {
    name: 'Agg. work (days)',
    type: 'line',
    color: '#89ABE3',
    data: dataFormatted[3],
  }, {
    name: 'Forecast (days)',
    type: 'bar',
    color: '#D59ED7',
    data: dataFormatted[1],
  }, {
    name: 'Worked (days)',
    type: 'bar',
    color: '#89ABE3',
    data: dataFormatted[2],
  }, {
    name: 'Baseline (days)',
    color: '#00558C',
    data: dataFormatted[0],
  }, {
    name: '% of completion',
    color: 'rgba(255, 255, 255, 0.1)',
    data: dataFormatted[6].map((el) => el.toFixed(0)),
  }, {
    name: 'Deviation (days)',
    color: 'rgba(255, 255, 255, 0.4)',
    data: dataFormatted[5],
  }];

  const options = {
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: budgetType === 'Time and material' ? [3, 3, 3, 1, 1.5] : [3, 3, 3, 1, 1.5, 0, 0],
      curve: 'smooth',
    },
    xaxis: {
      categories: dataFormatted[7],
      labels: {
        style: {
          colors: 'rgba(0, 0, 0, 0.3)',
          fontSize: '16px',
        },
      },
    },
    yaxis: {
      min: 0,
      max: biggestNumber + 10,
      labels: {
        style: {
          colors: 'rgba(0, 0, 0, 0.3)',
          fontSize: '16px',
        },
        formatter(value) {
          return `${value}d`;
        },
      },
    },
    legend: {
      show: false,
    },
  };

  return (
    <div style={{ marginTop: '10px', overflow: 'hidden', height: '410px' }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        {budgetType === 'Work Package' ? (
          <>
            <FiberManualRecordIcon style={{
              color: '#00558C', marginRight: '4px', width: '15px', height: '15px',
            }}
            />
            <p style={{ fontSize: '14px', fontWeight: 400 }}>Baseline</p>
          </>
        ) : ''}

        <FiberManualRecordIcon style={{
          color: '#89ABE3', marginRight: '4px', width: '15px', height: '15px', marginLeft: '10px',
        }}
        />
        <p style={{ fontSize: '14px', fontWeight: 400 }}>Forecast</p>
        <FiberManualRecordIcon style={{
          color: '#D59ED7', marginRight: '4px', width: '15px', height: '15px', marginLeft: '10px',
        }}
        />
        <p style={{ fontSize: '14px', fontWeight: 400 }}>Work</p>
      </div>
      <Chart
        id="chart"
        options={options}
        series={series}
        width="100%"
        height={400}
      />
    </div>
  );
};

ChartTimeBudget.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  budgetType: PropTypes.string.isRequired,
};

export default ChartTimeBudget;
