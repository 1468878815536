/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const DEFAULT_FORECASTING = {
  absence: 0,
  intensity: 0,
  roleInProject: '0',
  calendarSelected: false,
};

const ForecastingsUserSliceReducer = createSlice({
  name: 'forecastingsUser',
  initialState: { forecastingsUser: [], forecastingsUserUpdated: [], timeStampSelected: [] },
  reducers: {
    forecastingsUser: (state, action) => {
      state.forecastingsUser = action.payload;
      state.forecastingsUserUpdated = action.payload;
    },
    forecastingsUserUpdate: (state, action) => {
      const {
        timeUnit, ProjectId, intensity, start, end,
      } = action.payload;

      // console.log('state.forecastingsUserUpdated111111', state.forecastingsUserUpdated);
      const intensityToChange = state.forecastingsUserUpdated.map((f) => {
        // console.log('popole1');
        if (moment(f.date).isBetween(moment(start), moment(end), undefined, '[]')) {
          // console.log('{ ...f, intensity }', { ...f, intensity });
          return { ...f, intensity };
        } return undefined;

        // moment('2016-10-30').isBetween('2016-10-30', '2016-10-30', undefined, '[]'); // true
      }).filter((item) => !!item);
      // console.log('intensityToChange', intensityToChange);

      intensityToChange.forEach((f) => {
        state.forecastingsUserUpdated = [...state.forecastingsUserUpdated
          .filter((el) => el.id !== f.id), { ...f, intensity }];
      });
      // console.log(' state.forecastingsUserUpdated2', state.forecastingsUserUpdated);
      // console.log('intensityToChange', intensityToChange.map((el) => el));
      // console.log('state.forecastingsUserUpdated', state.forecastingsUserUpdated.map(el => el));

      // if (timeUnit === 'day') {
      //   // const truc = state.forecastingsUserUpdated;
      //   const forecastingToUpdate = state.forecastingsUserUpdated.find((el) => moment(el.date)
      //     .isSame(moment(date)));
      //   if (forecastingToUpdate) {
      //     state.forecastingsUserUpdated = [...state.forecastingsUserUpdated
      //       .filter((f) => forecastingToUpdate.id !== f.id), { ...forecastingToUpdate, intensity }];
      //   } else {
      //     state.forecastingsUserUpdated = [...state.forecastingsUserUpdated,
      //       {
      //         ...DEFAULT_FORECASTING, ProjectId, intensity, date,
      //       }];
      //   }
      // }

      // if (timeUnit === 'week') {

      // }
      // /*
      //   Si on donne une nouvelle intensity au forecasting semaine

      // */

      // if (timeUnit === 'week') {
      //   const forecastingsToUpdate = state.forecastingsUserUpdated((f) => {
      //     const numeroWeek = moment(f.date).isoWeek();
      //     if (moment(f.date).isoWeek() === moment(forecasting).isoWeek()) {
      //       console.log('lol');
      //     }
      //   });
      // }
    },
    timeStampToggled: (state, action) => {
      const { timeUnit, date } = action.payload;

      const startDate = moment(date).startOf(timeUnit);
      const endDate = moment(date).endOf(timeUnit);

      const arrayMomentSelected = state.timeStampSelected.map((el) => moment(el));
      const findDate = arrayMomentSelected.find((el) => el.isSame(date));
      if (findDate) {
        state.timeStampSelected = state.timeStampSelected.filter((t) => !moment(t).isSame(date));
        state.forecastingsUserUpdated = state.forecastingsUserUpdated.map((f) => {
          if (moment(f.date).isBetween(startDate, endDate, undefined, '[]')) {
            return { ...f, calendarSelected: false };
          }
          return f;
        });
      } else {
        state.timeStampSelected = [...state.timeStampSelected, date];
        state.forecastingsUserUpdated = state.forecastingsUserUpdated.map((f) => {
          if (moment(f.date).isBetween(startDate, endDate, undefined, '[]')) {
            return { ...f, calendarSelected: true };
          }
          return f;
        });
      }
    },
    reset: (state) => {
      state.timeStampSelected = [];
      state.forecastingsUserUpdated = [...state.forecastingsUser];
    },
  },
});

const { actions, reducer } = ForecastingsUserSliceReducer;

export const {
  forecastingsUser, forecastingsUserUpdate,
  timeStampToggled, reset,
} = actions;

export default reducer;
