/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
import {
  ClickAwayListener, TableCell, TextField, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { debounce } from 'lodash';
import React, { useCallback } from 'react';
import { api } from '../../../api/api';

const useStyles = makeStyles(() => ({
  tableCell: {
    border: '1px solid #CCCCCC',
    padding: '9px',
    '&:hover': {
      background: '#FFFFFF',
      border: '1px solid rgba(0, 0, 0, 0.1)',
      boxSizing: 'border-box',
      boxShadow: '0px 5px 10px 2px rgba(0, 0, 0, 0.13)',
      borderRadius: '4px',
      padding: '0px 16px 0px 0px',
    },
  },
  tableCellEdit: {
    border: '1px solid #CCCCCC',
    padding: '0px',
  },
  tableCellNoEdit: {
    border: '1px solid #CCCCCC',
    padding: '9px',
  },
  root: {
    background: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    boxSizing: 'border-box',
    boxShadow: '0px 5px 10px 2px rgba(0, 0, 0, 0.13)',
    borderRadius: '4px',
  },
}));

const CellRisk = (props) => {
  const {
    edit, change, setChange, item, rowId, word, type,
  } = props;

  const classes = useStyles();

  const [flag, updateFlag] = React.useState(false);
  // The ref here will prevent the rerender of the component
  const open = React.useRef(false);

  const handleClick = () => {
    open.current = !open.current;
    updateFlag(!flag);
  };

  const handleClickAway = () => {
    open.current = false;
    updateFlag(!flag);
  };

  const onChange = (id, e) => {
    api.put(`api/risks/${id}`, {
      [word]: e.target.value,
    }).then(() => {
      setChange(!change);
    });
  };

  const onChangeDebounced = useCallback(
    debounce(onChange, 1000),
    [change],
  );

  return edit
    ? (
      <ClickAwayListener onClickAway={handleClickAway}>
        {!open.current ? (
          <TableCell classes={{ root: classes.tableCell }} align="right">
            <Typography onClick={handleClick}>{item}</Typography>
          </TableCell>
        ) : (
          <TableCell classes={{ root: classes.tableCellEdit }} align="right">
            <TextField
              type={type ?? 'text'}
              inputProps={{
                min: 0,
                style: {
                  textAlign: 'right',
                  paddingRight: '16px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                },
              }}
              className={classes.root}
              defaultValue={item || 0}
              onChange={(e) => onChangeDebounced(rowId, e)}
              fullWidth
            />
          </TableCell>
        ) }

      </ClickAwayListener>
    )
    : (
      <TableCell className={classes.tableCellNoEdit} align="right">
        <Typography>{item}</Typography>
      </TableCell>
    );
};

export default CellRisk;
