import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ProjectManager from './ProjectManager';

const useStyles = makeStyles(() => ({
  rootButton: {
    backgroundColor: 'white',
    fontWeight: 400,
    color: 'rgba(112, 121, 135, 1)',
    fontSize: '14px',
    fontFamily: 'Roboto',
    textTransform: 'none',
    marginTop: '24px',
    paddingTop: '0px',
    '&:hover': {
      backgroundColor: '#FFF',
    },
  },
}));
const ProjectNamesForecasting = (props) => {
  const {
    projectsOneManager, setMembersOf, membersOf, setAll, all, pointer, setPointer,
  } = props;
  const classes = useStyles();
  return (
    <div id="projectNamesForecastingBox">
      <div>
        <h1>Projects</h1>
        <Button
          disableRipple
          onClick={() => {
            setAll(true);
            setMembersOf(undefined);
          }}
          classes={{ root: classes.rootButton }}
        >
          All
        </Button>
      </div>
      {projectsOneManager.map((proj) => (
        <ProjectManager
          setPointer={setPointer}
          pointer={pointer}
          id={proj.id}
          key={proj.project_name}
          proj={proj}
          membersOf={membersOf}
          setMembersOf={setMembersOf}
          all={all}
          setAll={setAll}
        />
      ))}

    </div>

  );
};

ProjectNamesForecasting.defaultProps = {
  setMembersOf: () => undefined,
  setAll: () => undefined,
  membersOf: undefined,
};

ProjectNamesForecasting.propTypes = {
  setPointer: PropTypes.func.isRequired,
  projectsOneManager: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setMembersOf: PropTypes.func,
  membersOf: PropTypes.number,
  setAll: PropTypes.func,
  all: PropTypes.bool.isRequired,
  pointer: PropTypes.number.isRequired,
};

export default ProjectNamesForecasting;
