import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const useStyles = makeStyles(() => ({
  rootButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

const GreyBarTime = (props) => {
  const {
    timeUnit, date, isFirst, isLast, returnInPastPlus, goToFuturePlus,
  } = props;

  const classes = useStyles();

  return (
    <div className="greyBarTime">
      <div>
        {isFirst ? (
          <IconButton
            classes={{ root: classes.rootButton }}
            size="small"
            onClick={returnInPastPlus}
          >
            <ArrowLeftIcon />
          </IconButton>
        ) : ''}
        <p>{timeUnit === 'month' ? date.format('yyyy') : date.format('MMM') }</p>
      </div>
      {isLast ? (
        <IconButton
          classes={{ root: classes.rootButton }}
          size="small"
          onClick={goToFuturePlus}
        >
          <ArrowRightIcon />
        </IconButton>
      ) : ''}
    </div>
  );
};

GreyBarTime.defaultProps = {
  timeUnit: 'day',
  isFirst: false,
  isLast: false,
};

GreyBarTime.propTypes = {
  timeUnit: PropTypes.oneOf(['day', 'week', 'month']),
  date: PropTypes.shape().isRequired, // Moment object
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  goToFuturePlus: PropTypes.func.isRequired,
  returnInPastPlus: PropTypes.func.isRequired,
};

export default GreyBarTime;
