/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { api } from '../../api/api';
import { ButtonCustom } from '../../commonComponents/ButtonCustom';
import ModalCustom from '../../commonComponents/ModalCustom';
import SelectAddElement from '../SelectAddElement';
import AnimationCreated from './AnimationCreated';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import StepperCreationProject from './StepperCreationProject';

const useStyles = makeStyles(() => ({
  iconButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
  },
  paperWidthFalse: {
    width: '682px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflowX: 'hidden',
  },
  dialogTitleRoot: {
    marginBottom: '24px',
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: '700',
    display: 'flex',
    margin: '5% 10% 5% 39%',
    justifyContent: 'space-between',
    '&:hover, &:focus': {
      textDecoration: 'underline',
      textDecorationColor: '#FDB71A',
    },
  },
  dialogContentRoot: {
    padding: '0px 64px 0px 64px',
  },
  titleForm: {
    textAlign: 'center',
    marginTop: '10px',
  },
}));

const CreateProject = (props) => {
  const {
    success, setSuccess, setOpenModalCollaborators, setProjectId, projectId,
  } = props;
  const classes = useStyles();

  const [toolsSelected, setToolsSelected] = useState([]);
  const [technosSelected, setTechnosSelected] = useState([]);
  const [usersSelected, setUsersSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [validation, setValidation] = useState(true);
  const [validationContact, setValidationContact] = useState(true);
  const [shallNotPass, setShallNotPass] = useState(false);
  const [colorSelected, setColorSelected] = useState('white');
  const [users, setUsers] = useState([]);
  const [title, setTitle] = useState('Create a Project');
  const [clientContacts, setClientContacts] = useState([]);

  const colors = ['Red', 'Peru', 'Aqua', 'Aquamarine', 'RoyalBlue', 'Beige', 'SeaGreen', 'Black', 'SpringGreen', 'Blue', 'BlueViolet', 'Turquoise', 'Violet', 'CadetBlue', 'Chartreuse', 'Chocolate', 'Coral', 'CornflowerBlue', 'Yellow', 'Crimson'];
  useEffect(() => {
    let mounted = true;
    api
      .get('api/users')
      .then((res) => {
        if (mounted) {
          setUsers(res.data);
        }
      });

    return () => {
      mounted = false;
    };
  }, []);

  const submitting = (values, actions) => {
    const data = { ...values };
    data.ClientId = values.Client.id;
    data.Tools = toolsSelected;
    data.Technos = technosSelected;
    data.ManagerId = values.Manager.id;
    data.ContactId = values.Contact ? values.Contact.id : null;
    data.project_type = values.project_type;
    data.color = (colors[Math.floor(Math.random() * colors.length + 1)]);
    data.deliveryLeadId = values.DeliveryLead ? values.DeliveryLead.id : null;
    data.Users = usersSelected;
    data.Client.ClientContacts = Array.isArray(values.Client.ClientContacts)
    && values.Client.ClientContacts.length > 0
      ? {
        ...clientContacts,
        id: values.Client.ClientContacts[0]?.id,
        clientId: values.Client.id,
      }
      : clientContacts.full_name
        ? {
          ...clientContacts,
          clientId: values.Client.id,
        } : null;
    // We keep Links only when we have a title OR a URL
    data.Links = values.Links.filter((l) => l.title || l.url);
    // in case the user chooses 'false', in the second step, for the question
    // 'Has the project been signed', then, the user, won't be given, with the option
    // to select a start / end date (that's why, in that case, we set them, here, to null)
    if (data.start_date === '') {
      data.start_date = null;
    }
    if (data.end_date === '') {
      data.end_date = null;
    }
    if (data.time_budget_type === '') {
      data.time_budget_type = null;
    }
    api({
      method: 'post',
      url: 'api/projects',
      data,
    }).then((res) => setProjectId(res.data.id))
      .then(() => setSuccess(true))
      .then(() => setTimeout(() => {
        setOpen(false);
        setSuccess(false);
        actions.setSubmitting(false);
        setUsersSelected([]);
        setColorSelected('white');
        setOpenModalCollaborators(true);
      }, 3000))
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error('Error while trying to create a new project. Error: ', err);
        setActiveStep(0);
      });
  };

  const handleOpen = () => {
    setActiveStep(0);
    setOpen(true);
    setValidation(true);
    setShallNotPass(false);
  };

  const handleClose = () => {
    setOpen(false);
    setValidation(true);
    setShallNotPass(false);
    setValidationContact(true);
    setUsersSelected([]);
    setColorSelected('white');
  };

  const filterUsers = users.length > 0 && usersSelected.length > 0 && users.reduce((acc, cur) => {
    const item = usersSelected.find((userSelected) => userSelected.id === cur.id);
    if (!item) {
      acc.push(cur);
    }
    return acc;
  }, []);

  const initialValues = {
    project_name: '',
    project_type: 'Not defined',
    start_date: null,
    end_date: null,
    step_sale: 'Identify',
    description: '',
    time_budget_type: 'Not defined',
    progression: 0,
    signed: '1',
    chance_winning: 0,
    Links: [],
    Client: undefined,
    Manager: undefined,
    ContactId: undefined,
    DeliveryLead: undefined,
    status: 'green',
  };
  return (

    <ModalCustom
      title={activeStep >= 1 ? title : 'Create a Project'}
      height="556px"
      width="676px"
      open={open}
      handleClose={handleClose}
      childButtonOpenModal={(
        <ButtonCustom variant="large" event={handleOpen} text="New Project" icon={<AddIcon />} />
        )}
      child={(
        <Formik
          enableReinitialize
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={submitting}
          initialValues={initialValues}
        >
          {({ touched }) => (
            <Form className="formStyle">
              {activeStep === 0 ? (
                <Step1
                  setTitle={setTitle}
                  setValidation={setValidation}
                  shallNotPass={shallNotPass}
                />
              ) : '' }
              {activeStep === 1 ? <Step2 shallNotPass={shallNotPass} setValidation={setValidation} clientContacts={clientContacts} setClientContacts={setClientContacts} /> : '' }
              {activeStep === 2 ? <Step3 shallNotPass={shallNotPass} setValidation={setValidation} touched={touched} /> : '' }
              {activeStep === 3 ? <Step4 shallNotPass={shallNotPass} setValidation={setValidation} touched={touched} /> : '' }

              {activeStep === 4 ? (
                success
                  ? (
                    <>
                      <AnimationCreated messageToDisplay="Project created" />
                    </>
                  )
                  : (
                    <div style={{ minHeight: '400px', width: '85%', margin: 'auto' }}>
                      <SelectAddElement selected={toolsSelected} setSelected={setToolsSelected} word="Tool" />
                      <SelectAddElement selected={technosSelected} setSelected={setTechnosSelected} word="Techno" />
                    </div>
                  )
              ) : '' }
              {success ? ''
                : (
                  <StepperCreationProject
                    setShallNotPass={setShallNotPass}
                    validation={validation}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    setValidationContact={setValidationContact}
                  />
                )}
            </Form>
          )}
        </Formik>
        )}
    />
  );
};

CreateProject.propTypes = {
  success: PropTypes.bool.isRequired,
  projectId: PropTypes.number.isRequired,
  setSuccess: PropTypes.func.isRequired,
  setProjectId: PropTypes.func.isRequired,
  setOpenModalCollaborators: PropTypes.bool.isRequired,
};

export default CreateProject;
