/* eslint-disable no-console */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import HistoryIcon from '@material-ui/icons/History';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { api } from '../../api/api';
import Period from './Period';
import ModalCustom from '../../commonComponents/ModalCustom';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  close: {
    marginRight: '19px',
    marginTop: '19px',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '16px',
    width: '682px',
  },
  edit: {
    backgroundColor: 'rgba(55, 58, 54, 1)',
    width: '98px',
    color: 'white',
    borderRadius: '8px',
    fontSize: '16px',
    padding: '4px 16px',
    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.05)',
    fontFamily: 'Roboto',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#FFB81C',
      color: 'black',
      boxShadow: 'none',
      textDecoration: 'none',
    },
  },
  cancel: {
    backgroundColor: '#F0F0F1',
    width: '98px',
    color: '#707987',
    borderRadius: '8px',
    fontSize: '16px',
    padding: '4px 16px',
    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.05)',
    fontFamily: 'Roboto',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#FFB81C',
      color: 'black',
      boxShadow: 'none',
      textDecoration: 'none',
    },
  },
  plusButton: {
    textAlign: 'center',
    backgroundColor: '#F0F0F0',
    color: '#707987',
    borderRadius: '8px',
    width: '21px',
    height: '21px',
    padding: '4.5px',
  },
  iconButton: {
    '&:hover': {
      background: 'none',
    },
  },
  dialogTitleRoot: {
    textAlign: 'center',
    margin: '-60px 0px 0px 241px',
    paddingBottom: '24px',
    fontSize: '24px',
    fontWeight: '700',
    display: 'flex',
    justifyContent: 'space-between',
    '&:hover, &:focus': {
      textDecoration: 'underline',
      textDecorationColor: '#FDB71A',
    },
  },
}));

const HourlyRate = (props) => {
  const {
    edit, user, setTrigger,
  } = props;
  const classes = useStyles();

  const [hourlyRate, setHourlyRate] = useState();
  const [open, setOpen] = useState(false);

  const getCall = () => api
    .get(`api/hourlyRatePeriod?UserId=${user}`)
    .then((res) => setHourlyRate(res.data.map((el) => ({
      id: el.id,
      hourlyRate: el.hourlyRate,
      userId: el.userId,
    }))));

  useEffect(() => {
    let mounted = true;
    api
      .get(`api/hourlyRatePeriod?UserId=${user}`)
      .then((res) => {
        if (mounted) {
          setHourlyRate(res.data.map((el) => ({
            id: el.id,
            hourlyRate: el.hourlyRate,
            userId: el.userId,
          })));
        }
      });
    return () => {
      mounted = false;
    };
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    getCall();
    setTrigger(false);
  };

  const handleSave = () => {
    api
      .post('api/hourlyRatePeriod', [hourlyRate])
      .then(() => setTrigger(true))
      .then(() => handleClose());
  };

  const handleChange = (newHR) => {
    setHourlyRate(newHR);
  };

  return (
    <ModalCustom
      handleClose={handleClose}
      open={open}
      width="676px"
      title={edit ? 'Edit Hourly Rates' : 'Hourly Rate History'}
      childButtonOpenModal={(
        <IconButton onClick={handleOpen} className={classes.iconButton} disableTouchRipple>
          <HistoryIcon />
          <p style={{
            fontSize: '16px', fontWeight: 400, color: '#000000', opacity: '50%', marginLeft: '10px',
          }}
          >
            {edit ? 'Edit' : 'History'}
          </p>
        </IconButton>
      )}
      child={(
        <div className={classes.paper}>

          {edit ? (
            <>
              <div style={{ marginLeft: '64px', marginRight: '140px' }}>
                <div style={{
                  display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '16px', fontWeight: 500,
                }}
                >
                  <p>Hourly Rate:</p>
                  <div>
                    {hourlyRate && (
                      <Period
                        onChange={handleChange}
                        userId={user}
                        hourlyRate={hourlyRate}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div style={{
                display: 'flex', justifyContent: 'center', gap: '24px',
              }}
              >
                <Button variant="contained" className={classes.edit} onClick={handleSave}>Save</Button>
                <Button variant="contained" className={classes.cancel} onClick={handleClose}>Cancel</Button>
              </div>
            </>
          ) : (
            <div style={{ marginLeft: '64px', marginRight: '64px' }}>
              {hourlyRate.length > 0 ? (
                <div style={{
                  display: 'flex', justifyContent: 'center', marginBottom: '16px', fontWeight: 500,
                }}
                >
                  {/* <p>Date</p> */}
                  <p>Hourly Rate</p>
                  {hourlyRate.map((period) => (
                    <div
                      key={period.id}
                    >
                      <p>
                        €
                        {period.hourlyRate}
                        /hr
                      </p>
                    </div>
                  ))}
                </div>
              ) : <h3 style={{ textAlign: 'center' }}>No Data: Go on edit mode to add periods.</h3> }
            </div>
          )}
        </div>
      )}
    />
  );
};

HourlyRate.propTypes = {
  edit: PropTypes.bool.isRequired,
  user: PropTypes.number.isRequired,
  setTrigger: PropTypes.func.isRequired,
};

export default HourlyRate;
