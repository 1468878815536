/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { Box, Grid } from '@material-ui/core';
import LinksCreator from './LinksCreator';
import RadioFormik from '../../commonComponents/Fields/RadioFormik';
import TextAreaFormik from '../../commonComponents/Fields/TextAreaFormik';

const Step3 = (props) => {
  const { values } = useFormikContext();
  const {
    setValidation,
    shallNotPass,
  } = props;

  useEffect(() => {
    if (typeof values.signed !== 'undefined') setValidation(false);
  }, [values.signed]);

  useEffect(() => {
    setValidation(true);
  }, []);

  return (
    <Grid container spacing={2} style={{ width: '85%', margin: 'auto', marginBottom: '10px' }}>
      <Grid item xs={12}>
        <Box>
          <Box display="flex" className="formFieldLabel" gridGap="5px">
            <label htmlFor="description" className="labelsFormCreate">Description</label>
          </Box>
          <TextAreaFormik
            rows={7}
            name="description"
            placeholder="Wtite a short description about the project"
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <LinksCreator
          shallNotPass={shallNotPass}
          setValidation={setValidation}
        />
      </Grid>
      <Grid container item xs={12} spacing={2} alignItems="baseline" justifyContent="stretch">
        <Grid item xs={12}>
          <Box>
            <Box display="flex" className="formFieldLabel" gridGap="5px">
              <label htmlFor="signed" className="labelsFormCreate">Has the project been signed?</label>
            </Box>
            <RadioFormik
              name="signed"
              elements={[{ value: '0', name: 'No' },
                { value: '1', name: 'Yes' }]}
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

Step3.propTypes = {
  shallNotPass: PropTypes.bool.isRequired,
  setValidation: PropTypes.func.isRequired,
};

export default Step3;
