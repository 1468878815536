import React from 'react';
import CodeIcon from '@material-ui/icons/Code';
import BrushIcon from '@material-ui/icons/Brush';
import CloudIcon from '@material-ui/icons/Cloud';
import SecurityIcon from '@material-ui/icons/Security';
import CachedIcon from '@material-ui/icons/Cached';
import TimerIcon from '@material-ui/icons/Timer';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import FlagIcon from '@material-ui/icons/Flag';
import InfoIcon from '@material-ui/icons/Info';

const typeToComponent = {

  Development: <CodeIcon style={{
    marginRight: '8px', backgroundColor: '#89ABE3', color: 'white', padding: '2px', width: '28px', height: '28px', borderRadius: '6px',
  }}
  />,
  None: <InfoIcon style={{
    marginRight: '8px', backgroundColor: 'grey', color: 'white', padding: '2px', width: '28px', height: '28px', borderRadius: '6px',
  }}
  />,
  Design: <BrushIcon style={{
    marginRight: '8px', backgroundColor: '#71B2C9', color: 'white', padding: '2px', width: '28px', height: '28px', borderRadius: '6px',
  }}
  />,
  Cybersecurity: <SecurityIcon style={{
    marginRight: '8px', backgroundColor: '#D59ED7', color: 'white', padding: '2px', width: '28px', height: '28px', borderRadius: '6px',
  }}
  />,
  Cloud: <CloudIcon style={{
    marginRight: '8px', backgroundColor: '#FFA3B5', color: 'white', padding: '2px', width: '28px', height: '28px', borderRadius: '6px',
  }}
  />,
  'Time and material': (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: '24px',
      marginRight: '8px',
      backgroundColor: 'grey',
      color: 'white',
      padding: '2px',
      width: '30px',
      height: '30px',
      borderRadius: '6px',
    }}
    >
      TM
    </div>),
  'Work Package': (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',

      marginRight: '8px',
      textAlign: 'center',
      backgroundColor: 'grey',
      color: 'white',
      padding: '2px',
      width: '30px',
      height: '30px',
      borderRadius: '6px',
    }}
    >
      TP
    </div>),
  'Not defined': (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',

      marginRight: '8px',
      textAlign: 'center',
      backgroundColor: 'grey',
      color: 'white',
      padding: '2px',
      width: '30px',
      height: '30px',
      borderRadius: '6px',
    }}
    >
      ND
    </div>),
  Euro: (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      // marginLeft: '24px',
      marginRight: '8px',
      backgroundColor: 'orange',
      color: 'white',
      padding: '2px',
      width: '30px',
      height: '30px',
      borderRadius: '6px',
    }}
    >
      €
    </div>),
  'Dollar US': (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      // marginLeft: '24px',
      marginRight: '8px',
      backgroundColor: 'orange',
      color: 'white',
      padding: '2px',
      width: '30px',
      height: '30px',
      borderRadius: '6px',
    }}
    >
      $
    </div>),
  'Pound Sterling': (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      // marginLeft: '24px',
      marginRight: '8px',
      backgroundColor: 'orange',
      color: 'white',
      padding: '2px',
      width: '30px',
      height: '30px',
      borderRadius: '6px',
    }}
    >
      £
    </div>),
  'Swiss Franc': (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      // marg: inLeft: '24px',
      marginRight: '8px',
      backgroundColor: 'orange',
      color: 'white',
      padding: '2px',
      width: '30px',
      height: '30px',
      borderRadius: '6px',
    }}
    >
      ₣
    </div>),
  'on going': <CachedIcon style={{
    marginRight: '8px', backgroundColor: '#373A36', color: 'white', padding: '2px', width: '24px', height: '24px', borderRadius: '6px',
  }}
  />,
  future: <TimerIcon style={{
    marginRight: '8px', backgroundColor: '#373A36', color: 'white', padding: '2px', width: '24px', height: '24px', borderRadius: '6px',
  }}
  />,
  past: <CheckCircleIcon style={{
    marginRight: '8px', backgroundColor: '#373A36', color: 'white', padding: '2px', width: '24px', height: '24px', borderRadius: '6px',
  }}
  />,
  timeRemaining: <FlagIcon style={{
    marginRight: '8px', backgroundColor: '#373A36', color: 'white', padding: '2px', width: '24px', height: '24px', borderRadius: '6px',
  }}
  />,
};

const typeToComponent2 = {
  Development: <CodeIcon style={{
    marginRight: '8px', backgroundColor: '#89ABE3', color: 'white', width: '24px', height: '24px', borderRadius: '6px',
  }}
  />,
  None: <InfoIcon style={{
    marginRight: '8px', backgroundColor: 'grey', color: 'white', width: '24px', height: '24px', borderRadius: '6px',
  }}
  />,
  Design: <BrushIcon style={{
    marginRight: '8px', backgroundColor: '#71B2C9', color: 'white', width: '24px', height: '24px', borderRadius: '6px',
  }}
  />,
  Cybersecurity: <SecurityIcon style={{
    marginRight: '8px', backgroundColor: '#D59ED7', color: 'white', width: '24px', height: '24px', borderRadius: '6px',
  }}
  />,
  Cloud: <CloudIcon style={{
    marginRight: '8px', backgroundColor: '#FFA3B5', color: 'white', width: '24px', height: '24px', borderRadius: '6px',
  }}
  />,
  'Time and material': (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginRight: '8px',
      backgroundColor: 'grey',
      color: 'white',
      padding: '2px',
      width: '22px',
      height: '22px',
      borderRadius: '6px',
      fontSize: '14px',
    }}
    >
      TM
    </div>),
  'Work Package': (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginRight: '8px',
      textAlign: 'center',
      backgroundColor: 'grey',
      color: 'white',
      padding: '2px',
      width: '22px',
      height: '22px',
      borderRadius: '6px',
      fontSize: '14px',
    }}
    >
      TP
    </div>),
  'Not defined': (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginRight: '8px',
      textAlign: 'center',
      backgroundColor: 'grey',
      color: 'white',
      padding: '2px',
      width: '22px',
      height: '22px',
      borderRadius: '6px',
      fontSize: '14px',
    }}
    >
      ND
    </div>),
  Euro: (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      // marginLeft: '24px',
      marginRight: '8px',
      backgroundColor: 'orange',
      color: 'white',
      padding: '2px',
      width: '22px',
      height: '22px',
      borderRadius: '6px',
    }}
    >
      €
    </div>),
  'Dollar US': (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      // marg: inLeft: '24px',
      marginRight: '8px',
      backgroundColor: 'orange',
      color: 'white',
      padding: '2px',
      width: '22px',
      height: '22px',
      borderRadius: '6px',
    }}
    >
      $
    </div>),
  'Pound Sterling': (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      // marginLeft: '24px',
      marginRight: '8px',
      backgroundColor: 'orange',
      color: 'white',
      padding: '2px',
      width: '22px',
      height: '22px',
      borderRadius: '6px',
    }}
    >
      £
    </div>),
  'Swiss Franc': (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      // marg: inLeft: '24px',
      marginRight: '8px',
      backgroundColor: 'orange',
      color: 'white',
      padding: '2px',
      width: '22px',
      height: '22px',
      borderRadius: '6px',
    }}
    >
      ₣
    </div>),

  'on going': <CachedIcon style={{
    marginRight: '8px', backgroundColor: '#373A36', color: 'white', padding: '2px', width: '24px', height: '24px', borderRadius: '6px',
  }}
  />,
  future: <TimerIcon style={{
    marginRight: '8px', backgroundColor: '#373A36', color: 'white', padding: '2px', width: '24px', height: '24px', borderRadius: '6px',
  }}
  />,
  past: <CheckCircleIcon style={{
    marginRight: '8px', backgroundColor: '#373A36', color: 'white', padding: '2px', width: '24px', height: '24px', borderRadius: '6px',
  }}
  />,
  timeRemaining: <FlagIcon style={{
    marginRight: '8px', backgroundColor: '#373A36', color: 'white', padding: '2px', width: '24px', height: '24px', borderRadius: '6px',
  }}
  />,
};

const IconType = (props) => {
  const { type, select } = props;

  return (
    <>
      { select === 'select' ? typeToComponent2[type] : typeToComponent[type]}
    </>
  );
};

IconType.defaultProps = {
  type: '',
  select: '',
};

IconType.propTypes = {
  type: PropTypes.string,
  select: PropTypes.string,
};

export default IconType;
