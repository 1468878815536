/* eslint-disable react/prop-types */
import React from 'react';
import { Field, useFormikContext } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import TodayIcon from '@material-ui/icons/Today';
import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

const useStyles = makeStyles(() => ({
  dateInput: {
  },
  dateLabel: {
    display: 'flex',
    gap: '4px',
  },
}));

const CustomDatePicker = ({
  field,
  ...props
}) => {
  const classes = useStyles();
  const { setFieldValue } = useFormikContext();

  const handleDateChange = (date) => {
    const isoDate = date ? date.toISOString() : null;
    setFieldValue(field.name, isoDate);
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        {...field}
        {...props}
        id="customDatePicker"
        name={field.name}
        value={field.value || null}
        placeholder="dd-mm-yyyy"
        autoOk
        fullWidth
        emptyLabel="dd-mm-yyyy"
        format="DD-MM-yyyy"
        size="small"
        inputVariant="outlined"
        InputAdornmentProps={{ position: 'start' }}
        onChange={handleDateChange}
        keyboardIcon={<TodayIcon className={classes.iconCalendar} />}
      />
    </MuiPickersUtilsProvider>
  );
};

const DatePickerFormik = ({
  name, placeholder, label, validate,
}) => (
  <Field
    name={name}
    component={CustomDatePicker}
    placeholder={placeholder}
    label={label}
    validate={validate}
  />
);

DatePickerFormik.defaultProps = {
  placeholder: '',
  label: '',
  validate: () => {},
};

DatePickerFormik.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  validate: PropTypes.func,
};

export default DatePickerFormik;
