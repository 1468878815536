/* eslint-disable no-shadow */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-fragments */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Delete from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import {
  Avatar, Chip, Grid, MenuItem, Typography, Select, Tooltip, Paper,
} from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment';
import { MoreHoriz } from '@material-ui/icons';
import { convertCurrency } from '../../../utils/convertCurrency';
import SortUpDownCustom from '../../../commonComponents/SortUpDownCustom';
import { api } from '../../../api/api';
import CellRisk from './CellRisk';
import { SelectFormik } from '../../../commonComponents/Fields/SelectFormik';
import ModalCustom from '../../../commonComponents/ModalCustom';
import ConfirmationComponent from '../../../commonComponents/ConfirmationComponent';
import DeleteRisk from './DeleteRisk';

const useRowStyles = makeStyles({
  root: {
    backgroundColor: 'white',
  },
  borderLeft: {
    border: '1px solid #CCCCCC',
    borderLeft: '4px solid grey',
    alignItems: 'center',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.5)',
  },
  border1: {
    height: 40,
  },
  rootDown: {
    backgroundColor: '#F4F9FE',
    borderLeft: '4px solid grey',
  },
  border: {
    border: '1px solid #CCCCCC',
    alignItems: 'center',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.5)',
  },
  insideTable: {
    border: '1px solid #CCCCCC',
    alignItems: 'center',
    textAlign: 'center',
  },
  tableCell: {
    border: '1px solid #CCCCCC',
    backgroundColor: '#F0F0F0',
    alignItems: 'center',
    textAlign: 'center',
    height: '40px',
    padding: '10px',
  },
  sortUpDownCustom: {
    position: 'relative',
    left: '10px',
  },
  tableItem: {
    fontSize: '12px',
    lineHeight: '1rem',
    padding: '15px',
  },
  tableLargeHeader: {
    background: 'none',
    fontSize: '13px',
    fontWeight: 'bold',
    lineHeight: '1rem',
    letterSpacing: '0.08333em',
    textTransform: 'uppercase',
    paddingLeft: '10px',
    height: '40px !important',
    padding: '10px',
  },
});

const getVariant = (criticity) => {
  switch (criticity) {
    case 'Negligible': {
      return '#68D47E';
    }
    case 'Significant': {
      return '#FFB81C';
    }
    case 'Critical': {
      return '#F28016';
    }
    case '-': {
      return 'rgba(242, 128, 22, 0.1)';
    }
    default: {
      return '#DA291C';
    }
  }
};

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 15,
  },
}))(Tooltip);

function Row(props) {
  const {
    edit,
    setUpdateRisks,
    updateRisks,
    handleDeleteRow, rows, row, currency, convertCurrencyValue, change, setChange, setRisks,
  } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    api
      .get('api/users?RolesIds=1,2')
      .then((res) => {
        setUserList(res.data);
      });
  }, []);

  function getOwnerInitials(firstName, lastName) {
    const initials = firstName.charAt(0) + lastName.charAt(0);
    return initials;
  }
  const onChangeImpact = (row, value) => {
    setRisks([...rows.filter((r) => r.id !== row.id),
      { ...rows.find((r) => r.id === row.id), impact: value }]);
    api.put(`api/risks/${row.id}`, row).then(() => setUpdateRisks(!updateRisks));
  };

  const onChangeProbability = (row, value) => {
    setRisks([...rows.filter((r) => r.id !== row.id),
      { ...rows.find((r) => r.id === row.id), probability: value }]);
    api.put(`api/risks/${row.id}`, row).then(() => setUpdateRisks(!updateRisks));
  };

  const onChangeStatus = (row, value) => {
    setRisks([...rows.filter((r) => r.id !== row.id),
      { ...rows.find((r) => r.id === row.id), status: value }]);
    api.put(`api/risks/${row.id}`, row).then(() => setUpdateRisks(!updateRisks));
  };

  const onChangeOwner = (row, value) => {
    setRisks([...rows.filter((r) => r.id !== row.id),
      { ...rows.find((r) => r.id === row.id), ownerId: value }]);
    api.put(`api/risks/${row.id}`, row).then(() => setUpdateRisks(!updateRisks));
  };

  return (
    <>
      <TableRow className={clsx(classes.root, { [classes.rootDown]: open === true })}>
        <TableCell className={classes.insideTable}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </TableCell>
        {edit ? (<CellRisk edit={edit} change={change} setChange={setChange} item={row.title} word="title" rowId={row.id} />) : (<TableCell className={classes.tableItem} align="left">{row.title}</TableCell>)}
        <TableCell className={classes.insideTable} align="left">
          {edit ? (
            <Select
              name="status"
              value={row.status}
              id="status"
              variant="outlined"
              className={classes.insideTable}
              onChange={(e) => onChangeStatus(row, e.target.value)}
              classes={{
                root: classes.rootSelect,
                select: classes.select,
                outlined: classes.outlined,
                selectMenu: classes.selectMenu,
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
            >
              <MenuItem value="Open">Open</MenuItem>
              <MenuItem value="Closed">Closed</MenuItem>
            </Select>
          ) : row.status}
        </TableCell>
        <TableCell className={classes.insideTable} align="left">
          {row.impact === null ? (
            <Typography
              variant="overline"
              style={{
                color: '#000000', background: 'rgba(242, 128, 22, 0.1)', borderRadius: '6px', padding: '5px',
              }}
            >
              N/A
            </Typography>
          ) : edit ? (
            <Select
              name="impact"
              value={row.impact}
              id="impact"
              variant="outlined"
              className={classes.insideTable}
              onChange={(e) => onChangeImpact(row, e.target.value)}
              classes={{
                root: classes.rootSelect,
                select: classes.select,
                outlined: classes.outlined,
                selectMenu: classes.selectMenu,
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
            >
              <MenuItem value="Low">Low</MenuItem>
              <MenuItem value="Moderate">Moderate</MenuItem>
              <MenuItem value="High">High</MenuItem>
              <MenuItem value="Very high">Very high</MenuItem>
            </Select>
          ) : row.impact}
        </TableCell>
        <TableCell className={classes.insideTable} align="left">
          {row.probability === null ? (
            <Typography
              variant="overline"
              style={{
                color: '#000000', background: 'rgba(242, 128, 22, 0.1)', borderRadius: '6px', padding: '5px',
              }}
            >
              N/A
            </Typography>
          ) : edit ? (
            <Select
              name="probability"
              value={row.probability}
              id="probability"
              variant="outlined"
              className={classes.insideTable}
              onChange={(e) => onChangeProbability(row, e.target.value)}
              classes={{
                root: classes.rootSelect,
                select: classes.select,
                outlined: classes.outlined,
                selectMenu: classes.selectMenu,
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
            >
              <MenuItem value="Unlikely">Unlikely</MenuItem>
              <MenuItem value="Possible">Possible</MenuItem>
              <MenuItem value="Probable">Probable</MenuItem>
              <MenuItem value="Very likely">Very likely</MenuItem>
            </Select>
          ) : row.probability}
        </TableCell>
        <TableCell
          align="center"
          colSpan={2}
          className={classes.insideTable}
        >
          <Typography
            variant="overline"
            style={{
              background: getVariant(row.criticity),
              color: row.criticity === '-' ? '#000000' : 'white',
              borderRadius: '6px',
              fontSize: '12px',
              textTransform: 'uppercase',
              padding: '5px',
            }}
          >
            {row.criticity === '-' ? 'N/A' : row.criticity}
          </Typography>
        </TableCell>
        <TableCell
          align="right"
          colSpan={2}
          className={classes.insideTable}
        >
          {`${row.potImpactType === 'hrs'
            ? row.potentialImpact : convertCurrencyValue(parseInt(row.potentialImpact, 10)).toFixed(0)} ${row.potImpactType === 'hrs'
            ? row.potImpactType : convertCurrency(currency)}`}

        </TableCell>
        <TableCell className={classes.insideTable} align="left">
          {edit ? (
            <SelectFormik
              name="ownerId"
              className={classes.fieldFormik}
              placeholder="Owner"
              renderValue={() => `${row.Owner.first_name}  ${''}  ${row.Owner.last_name}`}
              onChange={(e) => onChangeOwner(row, e.target.value)}
              elements={userList
                .map((ownerId) => ({
                  value: ownerId,
                  name: `${ownerId.first_name}  ${ownerId.last_name}`,
                }))}
            />
          ) : (
            <>
              <Chip
                style={{ background: 'white', border: '1px solid #9B9B9C' }}
                avatar={<Avatar style={{ color: 'white', background: '#89ABE3' }}>{getOwnerInitials(row.Owner.first_name, row.Owner.last_name)}</Avatar>}
                label={`${row.Owner.first_name}  ${''}  ${row.Owner.last_name}`}
              />
            </>
          )}
        </TableCell>
        {edit && (
        <TableCell className={classes.insideTable} align="left">
          <Delete
            fontSize="small"
            color="error"
            style={{ cursor: 'pointer' }}
            onClick={() => handleDeleteRow(row.id)}
          />
        </TableCell>
        )}
      </TableRow>
      <TableRow className={classes.borderLeft}>
        <TableCell
          className={clsx(classes.root, { [classes.rootDown]: open === true })}
          style={{
            width: '100%',
            height: '100%',
            paddingBottom: 0,
            paddingTop: 0,
            border: '1px solid #9B9B9C',
          }}
          colSpan={11}
        >
          <Collapse style={{ width: '100%' }} in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Grid container justifyContent="space-between">
                <Grid item xs={3}>
                  <h4 style={{ fontWeight: 'bold', marginBottom: '20px', marginTop: '0px' }}>
                    {row.type === 'Risk' ? 'Mitigation:' : 'Action:'}
                  </h4>
                  <p style={{ marginTop: '-10px', overflowWrap: 'break-word' }}>
                    {row.type === 'Risk' ? row.mitigation : row.action}
                  </p>
                </Grid>
                <Grid item xs={4}>
                  <h4 style={{ fontWeight: 'bold', marginBottom: '20px', marginTop: '0px' }}>Dependencies</h4>
                  <p style={{ marginTop: '-10px', overflowWrap: 'break-word' }}>{row.dependencies}</p>
                </Grid>
                <Grid item xs={3} style={{ marginTop: '0px' }}>
                  <h4 style={{ display: 'inline', marginTop: '30px' }}>Has it impacted the project?  </h4>
                  <p style={{ display: 'inline' }}>{row.hasImpacted}</p>
                </Grid>
                <Grid item xs={3} style={{ marginTop: '20px' }}>
                  <h4 style={{ display: 'inline' }}>Due date: </h4>
                  <p style={{ display: 'inline' }}>{ row.dueDate ? moment(row.dueDate).format('DD-MM-YYYY') : '-' }</p>
                </Grid>
                <Grid item xs={3} style={{ marginTop: '20px' }}>
                  <h4 style={{ display: 'inline' }}>Last updated on: </h4>
                  <p style={{ display: 'inline' }}>{moment(row.updatedAt).format('DD-MM-YYYY')}</p>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const RiskTable = (props) => {
  const {
    edit,
    rows,
    setUpdateRisks,
    updateRisks,
    currency, convertCurrencyValue, handleSortCriticityAsc, handleSortCriticityDesc, type, setRisks,
  } = props;
  const classes = useRowStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDelete, setOpenDelete] = useState(false);
  const [openDeleteRow, setOpenDeleteRow] = useState(false);
  const [change, setChange] = useState(false);
  const [riskToBeDeleted, setRiskToBeDeleted] = useState();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const riskIds = rows.map((risk) => risk.id);

  const handleDeleteTable = () => {
    api.delete('api/risks?hardDelete=true', { data: riskIds })
      .then(() => {
        // to not refresh the page
        setRisks([]);
        // re-render of the component
        setChange(!change);
      })
      .then(() => setOpenDelete(!openDelete))
      .then(() => setOpenDeleteRow(!openDeleteRow))
      .then(() => setUpdateRisks(!updateRisks));
  };

  const handleDeleteRow = (riskId) => {
    setRiskToBeDeleted(riskId);
    setOpenDeleteRow(!openDeleteRow);
    setUpdateRisks(!updateRisks);
  };

  return (

    <>
      <TableContainer
        component={Paper}
      >
        <TableRow>
          <TableCell className={classes.tableLargeHeader} align="left" colSpan={edit ? 8 : 11}>
            {edit ? (
              <IconButton aria-label="expand row" size="small">
                <LightTooltip title="Delete Table" placement="bottom-start">
                  <MoreHoriz
                    onClick={() => setOpenDelete(true)}
                  />
                </LightTooltip>
              </IconButton>
            ) : ''}
            <Typography variant="overline" className={classes.tableLargeHeader} align="left" colSpan={8}>
              {type}
            </Typography>
          </TableCell>
        </TableRow>
        <Table className={classes.tableCell} aria-label="collapsible table">
          <TableHead>
            <TableRow style={{ verticalAlign: 'middle' }}>
              <TableCell className={classes.tableCell} />
              <TableCell className={classes.border} align="left">Title</TableCell>
              <TableCell className={classes.border} align="left">Status</TableCell>
              <TableCell className={classes.border} align="left">Impact</TableCell>
              <TableCell className={classes.border} colSpan={2} align="left">Probability</TableCell>
              <TableCell className={classes.border} colSpan={2} align="left">
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  Criticity
                  <div className={classes.sortUpDownCustom}>
                    <SortUpDownCustom
                      handleClickUp={() => handleSortCriticityAsc(type)}
                      handleClickDown={() => handleSortCriticityDesc(type)}
                    />
                  </div>
                </div>
              </TableCell>
              <TableCell className={classes.border} width="20%" align="center">Impact value</TableCell>
              <TableCell className={classes.border} align="center">Owner</TableCell>
              {edit && (
                <TableCell className={classes.border} />
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            <DeleteRisk
              riskId={riskToBeDeleted}
              open={openDeleteRow}
              setOpen={setOpenDeleteRow}
              updateRisks={updateRisks}
              setUpdateRisks={setUpdateRisks}
              onRowDeleted={handleDeleteRow}
            />
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <Row
                rows={rows}
                setRisks={setRisks}
                change={change}
                setChange={setChange}
                edit={edit}
                key={row.id}
                row={row}
                currency={currency}
                convertCurrencyValue={convertCurrencyValue}
                handleDeleteRow={handleDeleteRow}
                updateRisks={updateRisks}
                setUpdateRisks={setUpdateRisks}
              />
            ))}
          </TableBody>
          {rows.length > 10 && (
          <TableFooter>
            <TableRow>
              <TableCell style={{ border: 'none' }} align="right" colSpan={11}>
                <TablePagination
                  rowsPerPageOptions={[10, 15, 25]}
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableCell>
            </TableRow>
          </TableFooter>
          ) }
        </Table>
      </TableContainer>
      <ModalCustom
        handleClose={() => setOpenDelete(false)}
        open={openDelete}
        width="676px"
        title="Delete the table?"
        child={(
          <ConfirmationComponent
            eventOne={() => handleDeleteTable(riskIds)}
            eventTwo={() => setOpenDelete(false)}
          />
        )}
      />
    </>

  );
};

RiskTable.defaultProps = {
  type: null,
};

RiskTable.propTypes = {
  currency: PropTypes.string.isRequired,
  edit: PropTypes.bool.isRequired,
  convertCurrencyValue: PropTypes.func.isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  handleSortCriticityAsc: PropTypes.func.isRequired,
  handleSortCriticityDesc: PropTypes.func.isRequired,
  type: PropTypes.string,
  setUpdateRisks: PropTypes.func.isRequired,
  updateRisks: PropTypes.bool.isRequired,
  setRisks: PropTypes.func.isRequired,
};
Row.propTypes = {
  setChange: PropTypes.func.isRequired,
  change: PropTypes.bool.isRequired,
  setUpdateRisks: PropTypes.func.isRequired,
  updateRisks: PropTypes.bool.isRequired,
  edit: PropTypes.bool.isRequired,
  row: PropTypes.shape().isRequired,
  currency: PropTypes.string.isRequired,
  convertCurrencyValue: PropTypes.func.isRequired,
  setRisks: PropTypes.func.isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  handleDeleteRow: PropTypes.func.isRequired,
};

export default RiskTable;
