/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { TramOutlined } from '@material-ui/icons';
import { api } from '../../api/api';
import FiltersForecasting from './FiltersForecasting';
import GridForecasting from './GridForecasting';
import { fetchForecastings } from '../../redux/ForecastingsSlice';

const CollaboratorsForecasting = (props) => {
  const {
    membersOf, projectsOneManager, all, setAll, workers,
  } = props;
  const [timeUnit, setTimeUnit] = useState('day');
  const [searchUser, setSearchUser] = useState('');
  const [updateForecasting, setUpdateForecasting] = useState(false);
  const [startDate, setStartDate] = useState(moment().subtract(6, `${timeUnit}s`).startOf(`${timeUnit}s`));
  const [endDate, setEndDate] = useState(moment().add(6, `${timeUnit}s`).endOf(`${timeUnit}s`));
  const ManagerId = 14; // To change when permissions set
  const dispatch = useDispatch();

  const onChangeTimeUnit = (newTimeUnit) => {
    setTimeUnit(newTimeUnit);
    setStartDate(moment().subtract(6, `${newTimeUnit}s`).startOf(`${newTimeUnit}s`));
    setEndDate(moment().add(6, `${newTimeUnit}s`).endOf(`${newTimeUnit}s`));
  };

  const forecastingsRedux = useSelector(
    (state) => state.forecastingsState?.forecastings
      .filter((forecasting) => moment(forecasting.date).isBetween(startDate, endDate, undefined, '[]')) || [],
  );

  useEffect(() => {
    const params = {
      membersOf,
      timeUnit,
      // searchUser: searchUser !== '' ? encodeURIComponent(searchUser) : undefined,
      // ManagerId,
      startDate: startDate.format(),
      endDate: endDate.format(),
    };

    dispatch(fetchForecastings(params));
  }, [membersOf, timeUnit, startDate, endDate, updateForecasting]);
  // }, [membersOf, timeUnit, searchUser,
  // ManagerId, startDate, endDate, updateForecasting]); REMOVE MANAGER FOR NOW
  useEffect(() => {
  }, [forecastingsRedux]);

  return (
    <div id="collaboratorsForecasting">
      <div>
        <FiltersForecasting
          setSearchUser={setSearchUser}
          timeUnit={timeUnit}
          setTimeUnit={onChangeTimeUnit}
          ManagerId={ManagerId}
          projectsOneManager={projectsOneManager}
          updateForecasting={updateForecasting}
          setUpdateForecasting={setUpdateForecasting}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      </div>
      <GridForecasting
        projectsOneManager={projectsOneManager}
        workers={workers}
        all={all}
        setAll={setAll}
        membersOf={membersOf}
        searchUser={searchUser}
        forecastings={forecastingsRedux}
        startDate={startDate}
        endDate={endDate}
        timeUnit={timeUnit}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setUpdateForecasting={setUpdateForecasting}
      />
    </div>
  );
};

CollaboratorsForecasting.defaultProps = {
  membersOf: undefined,
};

CollaboratorsForecasting.propTypes = {
  all: PropTypes.bool.isRequired,
  setAll: PropTypes.func.isRequired,
  membersOf: PropTypes.number,
  projectsOneManager: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  workers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default CollaboratorsForecasting;
