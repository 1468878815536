import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useFormikContext } from 'formik';

const useStyles = makeStyles(() => ({
  common: {
    fontSize: (props) => (props.variant === 'large' ? '14px' : '14px'),
    height: (props) => (props.variant === 'large' ? '48px !important' : '40px !important'),
    width: (props) => (props.variant === 'large' && '160px'),
    border: (props) => props.border,
    paddingLeft: '16px',
    paddingRight: '16px',
    fontFamily: 'Roboto',
    fontWeight: 600,
    textTransform: 'none',
    color: (props) => props.color,
    '&:focus': {
      backgroundColor: (props) => (props.color === '#DA291C' ? 'white' : 'rgba(0, 0, 0, 0.04)'),
    },
    '&:hover': {
      backgroundColor: (props) => (props.color === '#DA291C' ? 'white' : 'rgba(0, 0, 0, 0.04)'),
    },
  },
  button: {
    backgroundColor: '#001F33',
    color: 'white',
    borderRadius: '8px',
    '&:focus': {
      backgroundColor: 'rgb(255, 184, 28) !important',
      color: 'black',
    },
    '&:hover': {
      backgroundColor: 'rgb(255, 184, 28) !important',
      color: 'black',
    },
    '&.Mui-disabled': {
      backgroundColor: 'lightGrey',
      color: 'white',
    },
  },
}));

const ButtonCustom = (props) => {
  const {
    variant, icon, text, event, disabled,
  } = props;
  const classes = useStyles({
    variant,
  });

  return (
    <Button
      onClick={event}
      className={`${classes.common} ${classes.button}`}
      startIcon={icon}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};

const ButtonCustomFormik = (props) => {
  const {
    variant, icon, text, disabled,
  } = props;
  const classes = useStyles({
    variant,
  });

  const { submitForm } = useFormikContext();
  const handleSubmit = () => (submitForm());

  return (
    <Button
      onClick={handleSubmit}
      className={`${classes.common} ${classes.button}`}
      startIcon={icon}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};

const ButtonSimple = (props) => {
  const {
    // eslint-disable-next-line no-unused-vars
    text, variant, event, border, icon, color, disabled,
  } = props;

  const classes = useStyles({
    variant, text, border, color,
  });

  return (
    <Button
      startIcon={icon}
      onClick={event}
      className={classes.common}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};

ButtonCustom.defaultProps = {
  icon: <></>,
  // eslint-disable-next-line no-console
  event: () => {},
  disabled: false,
};

ButtonCustom.propTypes = {
  variant: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.element,
  event: PropTypes.func,
  disabled: PropTypes.bool,
};

ButtonCustomFormik.defaultProps = {
  icon: '',
  disabled: false,
};

ButtonCustomFormik.propTypes = {
  variant: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.element,
  disabled: PropTypes.bool,
};

ButtonSimple.defaultProps = {
  icon: <></>,
  border: 'none',
  event: () => {},
  color: 'black',
  disabled: false,
};

ButtonSimple.propTypes = {
  icon: PropTypes.element,
  border: PropTypes.string,
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
  variant: PropTypes.string.isRequired,
  event: PropTypes.func,
  disabled: PropTypes.bool,
};

export { ButtonSimple, ButtonCustom, ButtonCustomFormik };
