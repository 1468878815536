import React, { useEffect, useState } from 'react';
import {
  Box, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '../../../api/api';
import { ButtonCustom, ButtonSimple } from '../../../commonComponents/ButtonCustom';
import Filters from './Filters';
import { forecastingsUser, reset } from '../../../redux/ForecastingsUserSlice';
import GridForecastingProfile from './GridForecasting';
import datesRangeToForecastings from '../../../utils/datesRangeToFprecastings';

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 700,
    fontSize: '36px',
  },
}));

const Workload = () => {
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();
  const forecastingsUserUpdated = useSelector((state) => state
    .forecastingsUserState.forecastingsUserUpdated
   ?? []);
  const [timeUnit, setTimeUnit] = useState('day');
  const [startDate, setStartDate] = useState(moment().subtract(6, `${timeUnit}s`).startOf(`${timeUnit}s`));
  const [endDate, setEndDate] = useState(moment().add(6, `${timeUnit}s`).endOf(`${timeUnit}s`));
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    const params = {
      timeUnit, UserId: id, startDate: startDate.format(), endDate: endDate.format(),
    };
    api.get('api/forecasting', { params })
      .then((res) => {
        if (res.data.length > 0) {
          dispatch(forecastingsUser(datesRangeToForecastings(res.data)
            .filter((forecasting) => moment(forecasting.date).isBetween(startDate, endDate, undefined, '[]'))
            .map((f, i) => ({ ...f, calendarSelected: false, id: timeUnit === 'week' ? i : f.id }))));
        }
      });
  }, [timeUnit, startDate, endDate]);

  const saveWorkload = () => {
    // api.post('api/forecasting', data)
    //   .then(() => setEdit(false));
  };

  const onClose = () => {
    dispatch(reset());
    setEdit(false);
  };

  return (
    <Box>

      <Box>

        {/* HEADER */}
        <Box display="flex" justifyContent="space-between" paddingTop="24px" paddingLeft="32px" paddingRight="32px">
          <Typography className={classes.title} variant="h3">Workload</Typography>
          {edit ? (
            <Box>
              <ButtonCustom variant="small" text="Save and close" event={saveWorkload} />
              <ButtonSimple event={() => onClose()} variant="small" text="Cancel" />
            </Box>
          ) : <ButtonCustom variant="small" text="Change workload" event={() => setEdit(true)} />}
        </Box>

        {/* FILTERS */}
        <Filters
          timeUnit={timeUnit}
          setTimeUnit={setTimeUnit}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          edit={edit}
        />
      </Box>

      {/* WORKLOAD */}
      <Box>
        <GridForecastingProfile
          forecastings={forecastingsUserUpdated}
          startDate={startDate}
          endDate={endDate}
          timeUnit={timeUnit}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
        {/* </Box> */}
      </Box>
    </Box>
  );
};

export default Workload;
