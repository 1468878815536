import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import { api } from '../../api/api';

const useStyles = makeStyles(() => ({
  root: {
    color: 'black',
  },
  rootSecondary: {
    color: '#FDB71A',
  },
  select: {
    '&:focus': {
      background: 'none',
    },
  },
  selectCustom: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    borderRadius: '8px',
    // width: '164px',
    height: '36px',
  },
  colorSecondary: {
    color: 'black',
    '&:checked': {
      color: '#FDB71A',
    },
  },
}));
const CheckBoxFilterApi = (props) => {
  const {
    itemsFilters, setItemsFilters, word, fieldToUse,
  } = props;
  const classes = useStyles();
  const [elementsDB, setElementsDB] = useState([]);
  const [selectNone, setSelectNone] = useState(false);

  useEffect(() => {
    api
      .get(`api/${word}`)
      .then((res) => setElementsDB(res.data));
  }, []);

  const handleChange = (event) => {
    setItemsFilters(event.target.value);
    const item = event.target.value;
    if (item.includes('Select All')) {
      setSelectNone(true);
      setItemsFilters(itemsFilters.length === elementsDB.length
        ? [] : elementsDB.map((element) => element[fieldToUse]));
    } else if (item.includes('Select None')) {
      setItemsFilters([]);
    } else {
      setItemsFilters(event.target.value);
      setSelectNone(false);
    }
  };

  const isAllSelected = itemsFilters.length > 0 && itemsFilters.length === elementsDB.length;
  const isNoneSelected = itemsFilters.length === 0;

  return (
    <FormControl fullWidth>
      <Select
        multiple
        fullWidth
        value={itemsFilters}
        onChange={handleChange}
        input={<Input placeholder={word} />}
        renderValue={() => `${word.charAt(0).toUpperCase() + word.slice(1)}`}
        displayEmpty
        id="filtersSelect"
        classes={{ select: classes.select }}
        className={classes.selectCustom}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: '350px',
            },
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        <MenuItem key="Select All" value="Select All">
          <Checkbox
            color="primary"
            name="Select All"
            checked={isAllSelected}
            classes={{
              root: classes.root,
            }}
          />
          <ListItemText primary="Select All" />
        </MenuItem>
        {selectNone
          ? (
            <MenuItem key="Select None" value="Select None">
              <Checkbox
                color="primary"
                name="Select None"
                indeterminate={isNoneSelected}
                classes={{
                  root: isNoneSelected ? classes.rootSecondary : classes.root,
                }}
              />
              <ListItemText primary="Select None" />
            </MenuItem>
          ) : null}
        {elementsDB.map((element) => (

          <MenuItem key={element[fieldToUse]} value={element[fieldToUse]}>
            <Checkbox
              color="primary"
              checked={itemsFilters.indexOf(element[fieldToUse]) > -1}
              classes={{ root: classes.root }}
            />
            <ListItemText primary={element[fieldToUse]} />
          </MenuItem>
        ))}

      </Select>
    </FormControl>
  );
};

CheckBoxFilterApi.propTypes = {
  setItemsFilters: PropTypes.func.isRequired,
  word: PropTypes.string.isRequired,
  fieldToUse: PropTypes.string.isRequired,
  itemsFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default CheckBoxFilterApi;
