/* eslint-disable react/prop-types */
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const Period = (props) => {
  const {
    id,
    onChange,
    userId,
    hourlyRate,
  } = props;

  const [hourlyR, setHourlyR] = useState();

  // eslint-disable-next-line no-console
  console.log(Array(hourlyRate).length);
  useEffect(() => {
    if (Array(hourlyRate).length <= 1) {
      setHourlyR(0);
    } else {
      setHourlyR(hourlyRate[0].hourlyRate);
    }
  }, []);

  const handleChangeHourlyRate = (e) => {
    const newHourlyR = parseInt(e.target.value, 10) > 0 ? parseInt(e.target.value, 10) : 0;
    setHourlyR(newHourlyR);
    onChange({
      id,
      userId,
      hourlyRate: newHourlyR,
    });
  };

  return (
    <>
      <div style={{
        display: 'flex', alignItems: 'center', gap: '30px', justifyContent: 'center',
      }}
      >
        <div style={{
          display: 'flex', alignItems: 'center', justifyContent: 'center ',
        }}
        >
          <div style={{
            display: 'flex', alignItems: 'center', gap: '8px', marginLeft: '5px',
          }}
          >
            <TextField
              variant="outlined"
              defaultValue={hourlyR}
              id="textFieldHourlyRate"
              onChange={handleChangeHourlyRate}
              value={hourlyR}
            />
            <p> €/hr</p>
          </div>
        </div>
      </div>
    </>
  );
};

Period.defaultProps = {
  onChange: () => {},
};

Period.propTypes = {
  userId: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  id: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]).isRequired,
};

export default Period;
