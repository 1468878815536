/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import TotalIntensity from '../TotalIntensity';
import { mapUserRole } from '../../utils/mapUserRole';
import profileIcon from '../../ressources/profile-icon.png';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    marginTop: '16px',
  },
  avatar: {
    marginRight: '8px',
    width: '48px',
    height: '48px',
  },
  point: {
    width: '11px',
    height: '11px',
    marginRight: '5px',
    color: (props) => props.colorPoint,
  },
});

const CollaboratorProject = (props) => {
  const { coll, contactPerson } = props;
  const classes = useStyles();
  return (
    <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '24px' }}>
      {coll.forecast && (
      <>
        <Link to={`/employees/${coll.id}`}>
          <Avatar
            title={`${coll.first_name} ${coll.last_name}`}
            alt={coll.first_name + coll.last_name}
            className={classes.avatar}
            key={coll.first_name + coll.last_name}
            src={coll.profileImagePath ? `${process.env.REACT_APP_BACK_URL}/api/users/profileImage/${coll.profileImagePath}` : profileIcon}
          />
        </Link>
        <div style={{
          paddingTop: '0px', marginLeft: '11px', width: '220px', marginRight: '10%',
        }}
        >
          <p style={{ fontWeight: 700, marginBottom: '6px' }}>{`${coll.first_name} ${coll.last_name}`.substring(0, 22)}</p>
          <p style={{ fontWeight: 400, color: 'rgba(112, 121, 135, 1)', marginBottom: '8px' }}>{`${coll.position}`.substring(0, 22)}</p>
          <div>
            {(coll.forecast.roleInProject === '1'
          || coll.forecast.roleInProject === '2')
              ? (
                <button
                  type="button"
                  style={{
                    border: '0px solid rgba(255, 184, 28, 0.2)',
                    borderRadius: '4px',
                    height: '22px',
                    padding: '4px',
                    fontSize: '12px',
                    fontWeight: 700,
                    fontFamily: 'Roboto',
                    color: 'rgba(199, 142, 17, 1)',
                    backgroundColor: 'rgba(255, 184, 28, 0.2)',
                    marginRight: '8px',
                  }}
                >
                  {mapUserRole(coll.forecast.roleInProject).toUpperCase()}

                </button>
              ) : '' }
            {
            coll.id === contactPerson ? (
              <button
                type="button"
                style={{
                  border: 'rgba(81, 141, 238, 0.2)',
                  borderRadius: '4px',
                  height: '22px',
                  padding: '4px',
                  fontSize: '12px',
                  fontWeight: 700,
                  fontFamily: 'Roboto',
                  color: 'rgba(81, 141, 238, 1)',
                  backgroundColor: 'rgba(81, 141, 238, 0.2)',

                }}
              >
                CONTACT

              </button>
            )
              : ''
          }
          </div>
        </div>
        <TotalIntensity coll={coll} totalintensitydisplayed="yes" />

      </>
      )}

    </div>
  );
};

export default CollaboratorProject;
