/* eslint-disable react/prop-types */
import React from 'react';
import { MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Field } from 'formik';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  select: {
    display: 'flex',
    alignItems: 'center',
    '&:focus': {
      backgroundColor: 'white',
    },

  },
  outlined: {
    height: '40px',
  },
  selectMenu: {
    padding: '0 0 0 16px',
  },
}));

const CustomSelect = ({
  field,
  currentStatus, ...props
}) => {
  const classes = useStyles();
  return (
    <Select
      {...field}
      {...props}
      variant="outlined"
      displayEmpty
      fullWidth
      size="small"
      placeholder={props.placeholder}
      classes={{
        root: classes.rootSelect,
        select: classes.select,
        outlined: classes.outlined,
        selectMenu: classes.selectMenu,
      }}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
        label: 'toto',
      }}
    >
      <MenuItem
        value=""
        disabled
      >
        {props.placeholder}
      </MenuItem>
      {props.elements.map((item) => (
        <MenuItem value={item.value}>{item.name}</MenuItem>
      ))}
    </Select>
  );
};

const SelectFormik = ({
  name, placeholder, elements, validate, renderValue,
}) => (
  <Field
    elements={elements}
    name={name}
    component={CustomSelect}
    placeholder={placeholder}
    validate={validate}
    renderValue={renderValue}
  />
);

const SelectFormikCustom = ({
  name, placeholder, elements, validate, handleChange,
}) => (
  <Field
    elements={elements}
    name={name}
    component={CustomSelect}
    placeholder={placeholder}
    validate={validate}
    onChange={handleChange}
  />
);

SelectFormik.defaultProps = {
  placeholder: '',
  elements: [],
  validate: () => {},
  renderValue: '',
};

SelectFormik.propTypes = {
  placeholder: PropTypes.string,
  elements: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string.isRequired,
  validate: PropTypes.func,
  renderValue: PropTypes.func,
};

SelectFormikCustom.defaultProps = {
  placeholder: '',
  elements: [],
  validate: () => {},
  handleChange: () => {},
};

SelectFormikCustom.propTypes = {
  placeholder: PropTypes.string,
  elements: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string.isRequired,
  validate: PropTypes.func,
  handleChange: PropTypes.func,
};

export { SelectFormik, SelectFormikCustom };
