import React, { useEffect } from 'react';
import {
  Box, Button, makeStyles, Tooltip,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import Brightness1Icon from '@material-ui/icons/Brightness1';

const useStyles = makeStyles(() => ({
  point: {
    color: 'white',
    width: '15px',
    height: '15px',
  },
}));

const SenioritySelection = (props) => {
  const {
    // eslint-disable-next-line react/prop-types
    label, itemId, onChangeSeniority, removeElement, seniority, setLevel,
  } = props;
  const classes = useStyles();

  useEffect(() => {
    onChangeSeniority(seniority, itemId);
  }, [seniority, itemId]);

  return (
    <Box display="flex" alignItems="center" flexWrap="wrap" gridGap="14px" marginBottom="16px">
      <Button
        variant="contained"
        style={{ backgroundColor: '#4B4E4A', color: 'white', textTransform: 'none' }}
        disableRipple
        endIcon={<CloseIcon onClick={removeElement} />}
      >
        {label}
      </Button>
      <Tooltip title="Low Seniority">
        <Box
          onClick={() => setLevel(1)}
          style={{
            backgroundColor: '#FFE81C', width: '44px', height: '36px', borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'center',
          }}
        >
          {seniority === 1 ? <Brightness1Icon className={classes.point} /> : undefined}
        </Box>
      </Tooltip>
      <Tooltip title="Mid Seniority">
        <Box
          onClick={() => setLevel(2)}
          style={{
            backgroundColor: '#FFB81C', width: '44px', height: '36px', borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'center',
          }}
        >
          {seniority === 2 ? <Brightness1Icon className={classes.point} /> : undefined}
        </Box>
      </Tooltip>
      <Tooltip title="High Seniority">
        <Box
          onClick={() => setLevel(3)}
          style={{
            backgroundColor: '#68D47E', width: '44px', height: '36px', borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'center',
          }}
        >
          {seniority === 3 ? <Brightness1Icon className={classes.point} /> : undefined}
        </Box>
      </Tooltip>
    </Box>
  );
};

export default SenioritySelection;

SenioritySelection.defaultProps = {
  label: 'ND',
  seniority: 1,
  // eslint-disable-next-line no-console
  onChangeSeniority: () => console.log(''),
  itemId: undefined,
};

SenioritySelection.propTypes = {
  itemId: PropTypes.number,
  seniority: PropTypes.number,
  label: PropTypes.string,
  onChangeSeniority: PropTypes.func,
};
