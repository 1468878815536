import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import IconType from '../../IconType';

const useStyles = makeStyles(() => ({
  select: {
    display: 'flex',
    alignItems: 'center',
    width: '227px',
    marginLeft: 0,
    borderRadius: '8px',
    color: 'black',
    backgroundColor: 'white',
    background: '#FFFFFF',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.03)',
    '&:focus': {
      backgroundColor: 'white',
      borderRadius: '8px',
    },
  },
  outlined: {
    height: '40px',
  },
  selectSize: {
    height: '48px',
  },
  LabelType: {
    top: '-3px',
  },
}));

const ProjectCurrency = (props) => {
  const {
    edit, setFieldValue, originalCurrency,
  } = props;

  const classes = useStyles();

  const handleFieldChange = (event) => {
    setFieldValue('project_currency', event.target.value);
  };

  return (
    <>
      {edit
        ? (
          <>
            <FormControl variant="outlined">
              <InputLabel classes={{ root: classes.LabelType }}>Currency</InputLabel>
              <Select
                className={classes.select}
                label="Currency"
                name="project_currency"
                onChange={handleFieldChange}
                defaultValue={originalCurrency}
                classes={{
                  select: classes.select,
                  outlined: classes.outlined,
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                  PaperProps: { elevation: 0 },
                }}
                {...props}
              >
                <MenuItem name="Euro" value="Euro">
                  {' '}
                  <IconType type="Euro" select="select" />
                  Euro
                </MenuItem>
                <MenuItem type="Dollar US" value="Dollar US">
                  {' '}
                  <IconType type="Dollar US" select="select" />
                  Dollar US
                </MenuItem>
                <MenuItem type="Pound Sterling" value="Pound Sterling">
                  {' '}
                  <IconType type="Pound Sterling" select="select" />
                  Pound Sterling
                </MenuItem>
                <MenuItem name="Swiss Franc" value="Swiss Franc">
                  {' '}
                  <IconType type="Swiss Franc" select="select" />
                  Swiss Franc
                </MenuItem>
              </Select>
            </FormControl>
          </>
        )
        : (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconType type={originalCurrency} />
            <p>{originalCurrency}</p>
          </div>
        )}

    </>
  );
};

ProjectCurrency.propTypes = {
  edit: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  originalCurrency: PropTypes.string.isRequired,
};

export default ProjectCurrency;
