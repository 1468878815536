/* eslint-disable import/no-unresolved */
import moment from 'moment';
// import { strict as assert } from 'node:assert';
/*
    This function takes an array of forecastings and create an object
    (rangify) if the forecastings are following
    each other with the same intensity

    @param forecastings array of forecastings
    @param timeUnit "day", "week", "month"
*/

const groupForecastingsContinuity = (forecastings, timeUnit) => {
  const TIME_UNIT_ALLOWED = ['day', 'week', 'month'];

  if (!TIME_UNIT_ALLOWED.includes(timeUnit)) throw new Error(`Time unit should be day, week or month, received ${timeUnit}`);
  const ranges = forecastings.reduce((acc, cur, i, arr) => {
    if (i > 0 && (arr[i - 1].calendarSelected !== cur.calendarSelected)) {
      acc.push({
        intensity: cur.intensity,
        start: cur.date,
        end: cur.date,
        ProjectId: cur.ProjectId,
        absence: cur.absence,
        calendarSelected: cur.calendarSelected,
      });
    } else if (cur.intensity > 0 && (i > 0 && cur.intensity === arr[i - 1].intensity && (moment(cur.date).diff(moment(arr[i - 1].date), `${timeUnit}s`) === 1))) {
      acc[acc.length - 1].end = cur.date;
      acc[acc.length - 1].ProjectId = cur.ProjectId;
      acc[acc.length - 1].calendarSelected = cur.calendarSelected;
    } else if (i > 0 && cur.absence === arr[i - 1].absence && (moment(cur.date).diff(moment(arr[i - 1].date), 'days') === 1)) {
    // eslint-disable-next-line max-len
    // } else if (i > 0 && cur.absence === arr[i - 1].absence && (moment(cur.date).diff(moment(arr[i - 1].date), `${timeUnit}s`) === 1)) {
      acc[acc.length - 1].end = cur.date;
      acc[acc.length - 1].absence = cur.absence;
      acc[acc.length - 1].calendarSelected = cur.calendarSelected;
    } else {
      acc.push({
        intensity: cur.intensity,
        start: cur.date,
        end: cur.date,
        ProjectId: cur.ProjectId,
        absence: cur.absence,
        calendarSelected: cur.calendarSelected,
      });
    }
    return acc;
  }, []);

  return ranges;
};

export default groupForecastingsContinuity;
