import { Box, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

const useStyles = makeStyles({
  box: {
    backgroundColor: '#fff',
    boxShadow: '0 0 6px rgb(0 0 0 / 5%)',
    borderRadius: '13px',
  },
});

const ProfileLeftSideLoader = () => {
  const classes = useStyles();

  return (
    <Box style={{ width: '29%' }}>
      <Box
        height={650}
        className={classes.box}
        minHeight={532}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gridRowGap={16}
      >
        <Skeleton variant="circle" width={240} height={240} />
        <Skeleton variant="text" width={250} height={45} />
        <Skeleton variant="text" width={250} />
        <Skeleton variant="text" width={250} />
        <Skeleton variant="text" width={250} />
        <Skeleton variant="text" width={250} />
        <Box display="flex" gridColumnGap={32}>
          <Skeleton variant="text" width={100} />
          <Skeleton variant="text" width={100} />
        </Box>
        <Skeleton variant="text" width={150} />
        <Box display="flex" gridColumnGap={32}>
          <Skeleton variant="rect" width={120} height={40} />
          <Skeleton variant="rect" width={120} height={40} />
        </Box>
      </Box>
      <Box style={{ padding: '0 32px 0 32px', marginTop: '32px' }} className={classes.box} minHeight={402} display="flex" flexDirection="column" justifyContent="space-around">
        <Skeleton variant="text" width={150} />
        <Box display="flex" gridColumnGap={32}>
          <Skeleton variant="rect" width={120} height={40} />
          <Skeleton variant="rect" width={120} height={40} />
          <Skeleton variant="rect" width={120} height={40} />
        </Box>

        <Skeleton variant="text" height={10} />
        <Skeleton variant="text" width={150} />
        <Box display="flex" gridColumnGap={32}>
          <Skeleton variant="rect" width={120} height={40} />
          <Skeleton variant="rect" width={120} height={40} />
          <Skeleton variant="rect" width={120} height={40} />
        </Box>

        <Skeleton variant="text" height={10} />
        <Skeleton variant="text" width={150} />
        <Box display="flex" gridColumnGap={32}>
          <Skeleton variant="rect" width={120} height={40} />
          <Skeleton variant="rect" width={120} height={40} />
          <Skeleton variant="rect" width={120} height={40} />
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileLeftSideLoader;
