/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box, Button, Grid, Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { Alert } from '@material-ui/lab';
import moment from 'moment';
import { api } from '../../../api/api';
import RiskTable from './RiskTable';
import { risksAdded } from '../../../redux/RiskSlice';
import ModalCustom from '../../../commonComponents/ModalCustom';
import { ButtonSimple } from '../../../commonComponents/ButtonCustom';
import { orderCriticityUtility } from '../../../utils/orderCriticityUtility';
import CreateRisk from './CreateRisk';
import TableLoader from '../../loaders/TableLoader';

const useStyles = makeStyles(() => ({
  root: {
    color: 'black',
  },
  tableTitle: {
    marginBottom: '10px',
    float: 'left',
    fontSize: '24px',
  },
  dialog: {
    overflowX: 'hidden',
  },
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.4em',
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: '#474747',
      outline: '2px solid white',
      borderRadius: '50px',
    },
  },
  fieldFormik: {
    height: '38px',
    borderRadius: '8px',
    border: '0px',
    padding: '0',
    textIndent: '16px',
  },
  fieldFormik2: {
    width: '80%',
  },
  boxMessage: {
    padding: '15px',
    boxShadow: '1px 1px 3px 1px rgb(240 176 21 / 32%)',
    borderRadius: '5px',
    fontSize: '15px',
    color: 'gray',
  },
  inputFocused: {
    marginLeft: '16px',
  },
}));

const Risks = (props) => {
  const { id } = useParams();
  const [risks, setRisks] = useState([]);
  const [chances, setChances] = useState([]);
  const [risksLoader, setRisksLoader] = useState(true);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState('');
  const [trigger, setTrigger] = useState(false);
  const [userList, setUserList] = useState([]);
  const [order, setOrder] = useState(false);
  const [change, setChange] = useState(false);
  const [updateRisks, setUpdateRisks] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();

  const { currency, convertCurrencyValue, edit } = props;

  function addCriticityOrder(datas) {
    if (datas) {
      datas.reduce((acc, cur) => {
        if (!cur.criticityOrder) {
          const item = Object.assign(
            cur,
            {
              criticityOrder:
                {
                  criticity: cur.criticity,
                  order: orderCriticityUtility(cur.criticity),
                },
            },
          );
          acc.push(item);
        }
        return acc;
      }, []);
    }
  }

  const handleSortCriticityAsc = (type) => {
    switch (true) {
      case (type === 'Risks'):
        setRisks(
          risks.sort(
            (a, b) => b?.criticityOrder.order - a?.criticityOrder.order,
          ),
        );
        return order ? setOrder(false) : setOrder(true);
      case (type === 'Opportunities'):
        setChances(
          chances.sort(
            (a, b) => b?.criticityOrder.order - a?.criticityOrder.order,
          ),
        );
        return order ? setOrder(false) : setOrder(true);
      default:
        return order ? setOrder(false) : setOrder(true);
    }
  };

  const handleSortCriticityDesc = (type) => {
    switch (true) {
      case (type === 'Risks'):
        setRisks(
          risks.sort(
            (a, b) => a?.criticityOrder.order - b?.criticityOrder.order,
          ),
        );
        return order ? setOrder(false) : setOrder(true);
      case (type === 'Opportunities'):
        setChances(
          chances.sort(
            (a, b) => a?.criticityOrder.order - b?.criticityOrder.order,
          ),
        );
        return order ? setOrder(false) : setOrder(true);
      default:
        return order ? setOrder(false) : setOrder(true);
    }
  };

  useEffect(() => {
    setRisks(risks);
  }, [risks, change]);

  const handleAddRisk = () => {
    setType('Risk');
    setOpen(true);
  };

  const handleAddChance = () => {
    setType('Opportunity');
    setOpen(true);
  };

  useEffect(() => {
    setRisksLoader(true);
    api
      .get(`api/risks?ProjectId=${id}`)
      .then((res) => res.data)
      .then((data) => {
        setRisks(data.filter((item) => item.type === 'Risk' && (item.hasImpacted === 'Yes' || item.hasImpacted === 'No')).sort((a, b) => a.hasImpacted.localeCompare(b.hasImpacted)));
        setChances(data.filter((item) => item.type === 'Opportunity').sort((a, b) => a.hasImpacted.localeCompare(b.hasImpacted)));
        dispatch(risksAdded(data.filter((item) => item.type === 'Risk')));
        setRisksLoader(false);
      });
    setTrigger(false);
  }, [id, trigger, updateRisks]);
  useEffect(() => {
    let mounted = true;
    api
      .get('api/users?RolesIds=1,2')
      .then((res) => {
        if (mounted) {
          setUserList(res.data);
        }
      });
    return () => {
      mounted = false;
    };
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const modal = (
    <ModalCustom
      handleClose={handleClose}
      open={open}
      width="676px"
      height="80vh"
      title="Add risk or opportunity"
      child={(
        <CreateRisk
          currency={currency}
          ProjectId={id}
          setOpen={setOpen}
          type={type}
          userList={userList}
          setTrigger={setTrigger}
        />
)}
    />
  );

  if (risks.length > 0) addCriticityOrder(risks);
  if (chances.length > 0) addCriticityOrder(chances);

  return (
    <Box minHeight="calc(100vh - 370px)" margin="30px">
      {chances.length === 0 && risks.length === 0 && risksLoader === false && !edit && (
      <Alert severity="info">
        There are no risks or opportunities for now,
        go to the edit mode to register them right away!
      </Alert>
      )}
      {edit && chances.length === 0 && risks.length === 0 && risksLoader === false ? (
        <Box height="196px" border="1px dashed rgba(0, 0, 0, 0.5)" borderRadius="8px" display="flex" justifyContent="center" alignItems="center">
          <ButtonSimple text="Add Risk or Opportunity" event={handleAddRisk} border="1px solid black" icon={<AddIcon />} />
          {modal}
        </Box>
      ) : ''}
      {risks.length > 0 && (
        <Grid container spacing={2}>
          <Grid style={{ marginBottom: '1rem' }} item xs={12}>
            <RiskTable
              rows={risks}
              setRisks={setRisks}
              setChange={setChange}
              edit={edit}
              change={change}
              currency={currency}
              convertCurrencyValue={convertCurrencyValue}
              handleSortCriticityAsc={handleSortCriticityAsc}
              handleSortCriticityDesc={handleSortCriticityDesc}
              updateRisks={updateRisks}
              setUpdateRisks={setUpdateRisks}
              type="Risks"
            />
            {edit && (
            <>
              <Button style={{ marginTop: '10px', backgroundColor: 'rgba(55, 58, 54, 0.05)', textTransform: 'none' }}>
                <Typography onClick={handleAddRisk} variant="outlined">
                  + Add
                </Typography>
              </Button>
              {modal}
            </>
            )}
          </Grid>
        </Grid>
      )}
      {chances.length > 0
          && (
          <Grid container spacing={2}>

            <Grid item xs={12}>
              <RiskTable
                rows={chances}
                setChange={setChange}
                change={change}
                edit={edit}
                currency={currency}
                convertCurrencyValue={convertCurrencyValue}
                handleSortCriticityAsc={handleSortCriticityAsc}
                handleSortCriticityDesc={handleSortCriticityDesc}
                updateRisks={updateRisks}
                setUpdateRisks={setUpdateRisks}
                type="Opportunities"
              />
              {edit && (
              <>
                <Button style={{ backgroundColor: 'rgba(55, 58, 54, 0.05)', marginTop: '10px', textTransform: 'none' }}>
                  <Typography onClick={handleAddChance} variant="outlined">
                    + Add
                  </Typography>
                </Button>
                {modal}
              </>
              ) }
            </Grid>
          </Grid>
          ) }
    </Box>
  );
};
export default Risks;
