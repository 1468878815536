import React, { useState } from 'react';
import PropTypes from 'prop-types';
import BodyModal from './BodyModal';
import ModalCustom from '../../commonComponents/ModalCustom';

const HorizontalBarProject = (props) => {
  const {
    intensity, colorBorder, isDoubleProject, idProject, idManager, idUser, setUpdateForecasting,
  } = props;

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let goodToDisplay;

  if (isDoubleProject) {
    goodToDisplay = (
      <button onClick={handleOpen} type="button" label="hello" className="intensityForecastingBar intensityForecastingBarDouble" style={{ cursor: 'pointer' }} />

    );
  } else {
    goodToDisplay = (
      <button onClick={handleOpen} type="button" className="intensityForecastingBar intensityForecastingBarSimple" style={{ cursor: 'pointer', borderLeftColor: colorBorder }}>
        <p>
          {Math.round(intensity)}
          %
        </p>
      </button>
    );
  }

  return (
    <ModalCustom
      handleClose={handleClose}
      open={open}
      width="676px"
      height="80vh"
      title="Edit Forecasting(s)"
      childButtonOpenModal={goodToDisplay}
      overflowY="scroll"
      child={(
        <BodyModal
          idProject={idProject}
          idManager={idManager}
          idUser={idUser}
          setUpdateForecasting={setUpdateForecasting}
          onClose={handleClose}
        />
      )}
    />
  );
};

HorizontalBarProject.defaultProps = {
  colorBorder: undefined,
  idManager: undefined,

};

HorizontalBarProject.propTypes = {
  intensity: PropTypes.number.isRequired,
  idUser: PropTypes.number.isRequired,
  idManager: PropTypes.number,
  idProject: PropTypes.number.isRequired,
  colorBorder: PropTypes.string,
  isDoubleProject: PropTypes.bool.isRequired,
  setUpdateForecasting: PropTypes.func.isRequired,
};

export default HorizontalBarProject;
