/* eslint-disable no-console */
import {
  Box, makeStyles, Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

const useStyles = makeStyles(() => ({
  chip: {
    height: '36px',
    padding: '0px 8px',
    fontSize: '16px',
    color: '#707987',
    backgroundColor: 'rgba(240, 240, 240, 1)',
    fontWeight: 500,
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.05)',
  },
  seniority: {
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    height: '20px',
    background: (props) => props.seniorityBackground,
    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.05)',

  },
  delete: {
    marginLeft: '5px',
  },
}));

const ChipSeniority = (props) => {
  const {
    seniority, label, edit, removeElement, focusElementSeniority,
  } = props;

  let seniorityBackground;
  if (seniority === 1) {
    seniorityBackground = 'linear-gradient(to right, #FFE81C 35%, rgba(240, 240, 240, 1) 0%)';
  } else if (seniority === 2) {
    seniorityBackground = 'linear-gradient(to right, #FFB81C 67%, rgba(240, 240, 240, 1) 0%)';
  } else {
    seniorityBackground = 'linear-gradient(to right, #68D47E 100%, rgba(240, 240, 240, 1) 0%)';
  }

  const classes = useStyles({ seniorityBackground });

  return (
    <Box
      onClick={focusElementSeniority}
      className="chipHoveringProfile"
    >
      <Box className={classes.seniority} />
      <Box className={classes.chip} display="flex" justifyContent="center" alignItems="center">
        <Typography>{label}</Typography>
        {edit
          ? <CloseIcon onClick={removeElement} className={classes.delete} />
          : undefined}
      </Box>
    </Box>
  );
};

export default ChipSeniority;

ChipSeniority.defaultProps = {
  label: 'element',
  seniority: 1,
  edit: false,
  focusElementSeniority: () => {},
  removeElement: () => {},
};

ChipSeniority.propTypes = {
  seniority: PropTypes.number,
  label: PropTypes.string,
  edit: PropTypes.bool,
  focusElementSeniority: PropTypes.func,
  removeElement: PropTypes.func,
};
