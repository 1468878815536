/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import {
  Box, Button, makeStyles, Typography,
} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  pBox: {
    borderLeftColor: (props) => props.color,
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
    borderStyle: 'solid',
    borderLeftWidth: '6px',
    borderColor: 'rgb(255, 255, 255)',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    paddingLeft: '14px',
    backgroundColor: 'white',

  },
}));

const HorizontalBarIntensity = (props) => {
  const {
    value, color, key,
  } = props;

  const classes = useStyles({ color });

  return (
    <Box className={classes.pBox}>
      <Typography style={{ marginTop: `${key > 0 ? '4px' : undefined} ` }}>
        {value.toFixed()}
        %
      </Typography>
    </Box>
  );
};

export default HorizontalBarIntensity;

HorizontalBarIntensity.defaultProps = {
  color: 'grey',
  key: 3,
};

HorizontalBarIntensity.propTypes = {
  color: PropTypes.string,
  key: PropTypes.number,
};
