/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { api } from '../../api/api';
import { ButtonCustom, ButtonSimple } from '../../commonComponents/ButtonCustom';
import ShowOnRoles from '../../utils/ShowOnRoles';
import dataCurrency from '../../utils/currency.json';
import TabsProject from '../TabsProject';
import DeleteProject from './DeleteProject';
import ProjectBudget from './ElementsProjectDetails/ProjectBudget';
import ProjectCurrency from './ElementsProjectDetails/ProjectCurrency';
import ProjectName from './ElementsProjectDetails/ProjectName';
import ProjectType from './ElementsProjectDetails/ProjectType';
import OverviewProject from './OverviewProject';
import TimeLine from './TimeLine';
import Finance from './finance/Finance';
import Notes from './notes/Notes';
import Risks from './risks/Risks';
import TimeBudget from './timeBudget/TimeBudget';

const ProjectDetails = (props) => {
  const {
    project, edit, setEdit, setFieldValue, valueTab, setValueTab, setProject,
    toolsSelected, technosSelected, setToolsSelected, setTechnosSelected, usersSelected,
    colorSelected, setColorSelected, setDeleteSnapshots, handleSubmit, budgetType,
    setUsersSelected, clientId, clientContacts, setClientContacts, updateForecasting,
  } = props;

  const { values } = useFormikContext();

  const projectsNames = useSelector((state) => state.projectsState?.projects.reduce((acc, cur) => {
    if (acc.indexOf(cur.project_name) === -1) {
      acc.push({
        projectId: cur.id,
        projectName: cur.project_name,
      });
    }
    return acc;
  }, []));
  // const dispatch = useDispatch();

  // const dataCurrency = useSelector((state) => state.currenciesState.currencies.rates);

  // useEffect(() => {
  //   const startDate = moment(project.start_date).format('YYYY-MM-DD');
  //   const dateNow = moment(new Date()).format('YYYY-MM-DD');
  //   dispatch(fetchCurrencies(startDate, dateNow));
  // }, []);

  const onEdit = () => {
    const currentValueTab = valueTab;
    setValueTab(currentValueTab);
    setEdit(true);
  };
  const [title, setTitle] = useState(false);
  const [unique, setUnique] = useState(false);
  const [editValidation, setEditValidation] = useState(false);

  // TODO add date value dynamically
  const convertCurrencyValue = (value) => {
    const rate = Object.entries(dataCurrency.rates)
      .find((date) => date[0] === '2022-10-08'); // Fixed date because of limitation API requests
    // .find((date) => date[0] === moment(project.start_date).format('YYYY-MM-DD'));
    let currentRate = 1; // default value is EURO
    if (project.project_currency === 'Dollar US') {
      currentRate = rate[1].USD;
      return currentRate * value;
    }
    if (project.project_currency === 'Pound Sterling') {
      currentRate = rate[1].GBP;
      return currentRate * value;
    }
    if (project.project_currency === 'Swiss Franc') {
      currentRate = rate[1].CHF;
      return currentRate * value;
    }
    if (project.project_currency === 'Euro') {
      return currentRate * value;
    }
    return undefined;
  };

  const onRestore = () => {
    api.patch(`api/projects/restore/${project.id}`, { ...project, deletedAt: null })
      .then(() => setProject({ ...project, deletedAt: null }));
  };

  const uniqueProjectName = () => {
    const projectNotUnique = projectsNames.find(
      (item) => item.projectName.toLowerCase() === values.project_name.toLowerCase()
      && item.projectName.toLowerCase() !== project.project_name.toLowerCase(),
    );
    if (projectNotUnique) {
      setUnique(false);
    } else {
      setUnique(true);
    }
  };

  useEffect(() => {
    if (values.project_name.length > 19) {
      setTitle(true);
    } else if (values.project_name.length < 20) {
      setTitle(false);
    }
    uniqueProjectName();
  }, [values.project_name]);
  return (
    <>

      <div id={edit ? 'leftSection1Edit' : 'leftSection1'} className="sections">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box display="flex" flexDirection="column">
            <ProjectName edit={edit} projectName={project.project_name} />
            <Typography variant="caption" className="errorMessageCreateCollaborator">
              {!unique && ('Project name already exist')}
              {title && ('Title must be shorter than 20 characters')}
            </Typography>
          </Box>
          {edit
            ? (
              <ShowOnRoles allowedRoleIds={[1, 2, 4]}>
                <Box marginTop="16px" display="flex" alignItems="center" gridGap="0.5rem">
                  <ButtonSimple variant="small" text="Cancel" event={() => setEdit(false)} />
                  <ButtonCustom disabled={editValidation === true} variant="small" text="Save changes" event={() => handleSubmit()} />
                </Box>
              </ShowOnRoles>
            )
            : (
              <ShowOnRoles allowedRoleIds={[1, 2, 4]}>
                {project.deletedAt ? (
                  <Box display="flex" alignItems="center" gridGap="1.5rem">
                    <ButtonCustom variant="small" event={onRestore} text="Restore Project" />
                    <DeleteProject id={project.id} hardDeletion project={project} />
                  </Box>
                ) : (
                  <Box display="flex" alignItems="center" gridGap="1.5rem">
                    <ButtonCustom variant="small" text="Edit Project" event={onEdit} />
                    <DeleteProject id={project.id} hardDeletion={false} project={project} />
                  </Box>
                ) }

              </ShowOnRoles>
            )}
        </div>
        <div id={edit ? 'leftSection1-1Edit' : 'leftSection1-1'}>

          <ProjectType
            edit={edit}
            projectType={project.project_type}
          />
          <ProjectBudget
            edit={edit}
            originalBudget={project.time_budget_type}
            setFieldValue={setFieldValue}
            setDeleteSnapshots={setDeleteSnapshots}
            handleSubmit={handleSubmit}
          />
          <ProjectCurrency
            edit={edit}
            setFieldValue={setFieldValue}
            originalCurrency={project.project_currency}
          />
        </div>

      </div>

      <div id="leftSection2">
        <TabsProject valueTab={valueTab} setValueTab={setValueTab} />
      </div>
      <hr style={{ marginTop: '-1px', marginBlockEnd: '0', border: '1px solid rgba(55, 58, 54, 0.2)' }} />

      {valueTab === 0 ? (
        <OverviewProject
          project={project}
          setEditValidation={setEditValidation}
          edit={edit}
          setFieldValue={setFieldValue}
          toolsSelected={toolsSelected}
          technosSelected={technosSelected}
          setToolsSelected={setToolsSelected}
          setTechnosSelected={setTechnosSelected}
          setColorSelected={setColorSelected}
          colorSelected={colorSelected}
          setUsersSelected={setUsersSelected}
          usersSelected={usersSelected}
          clientId={clientId}
          clientContacts={clientContacts}
          setClientContacts={setClientContacts}
        />
      ) : ''}
      <>
        <ShowOnRoles allowedRoleIds={[1, 2, 4]}>
          {valueTab === 1 ? <TimeLine /> : ''}
          {valueTab === 2 ? <Finance ProjectId={project.id} edit={edit} currency={project.project_currency} convertCurrencyValue={convertCurrencyValue} updateForecasting={updateForecasting} /> : ''}
          {valueTab === 3 ? <Risks edit={edit} currency={project.project_currency} convertCurrencyValue={convertCurrencyValue} /> : ''}
          {valueTab === 4 ? <TimeBudget ProjectId={project.id} budgetType={budgetType} edit={edit} currency={project.project_currency} convertCurrencyValue={convertCurrencyValue} /> : ''}
          {valueTab === 5 ? <Notes ProjectId={project.id} /> : ''}
        </ShowOnRoles>
        <ShowOnRoles allowedRoleIds={[3]}>
          {valueTab === 1 ? <TimeLine /> : ''}
          {valueTab === 2 ? <Notes ProjectId={project.id} /> : ''}
        </ShowOnRoles>
      </>

    </>
  );
};

ProjectDetails.defaultProps = {
  usersSelected: [],
  toolsSelected: [],
  technosSelected: [],
  colorSelected: [],
  budgetType: 'Not defined',
};

ProjectDetails.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number.isRequired,
    project_type: PropTypes.string,
    project_name: PropTypes.string,
    time_budget_type: PropTypes.string,
    project_currency: PropTypes.string,
    start_date: PropTypes.string,
    deletedAt: PropTypes.string.isRequired,
  }).isRequired,
  budgetType: PropTypes.string,
  edit: PropTypes.bool.isRequired,
  setEdit: PropTypes.func.isRequired,
  valueTab: PropTypes.number.isRequired,
  setValueTab: PropTypes.func.isRequired,
  setUsersSelected: PropTypes.func.isRequired,
  usersSelected: PropTypes.arrayOf(PropTypes.shape()),
  setFieldValue: PropTypes.func.isRequired,
  setColorSelected: PropTypes.func.isRequired,
  setDeleteSnapshots: PropTypes.func.isRequired,
  setProject: PropTypes.func.isRequired,
  setTechnosSelected: PropTypes.func.isRequired,
  setToolsSelected: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  toolsSelected: PropTypes.arrayOf(PropTypes.shape()),
  technosSelected: PropTypes.arrayOf(PropTypes.shape()),
  colorSelected: PropTypes.arrayOf(PropTypes.shape()),
};

export default ProjectDetails;
