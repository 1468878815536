/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Field } from 'formik';

const CustomAutoComplete = ({
  field,
  ...props
}) => (
  <Autocomplete
    variant="outlined"
    // disablePortal
    fullWidth
    name={field.name}
    size="small"
    renderInput={(params) => <TextField placeholder={props.placeholder} variant="outlined" {...params} />}
    {...props}
  />

);

const AutoCompleteFormik = ({
  name, index, placeholder, label, validate, value, key, ...rest
}) => (
  <Field
    name={name}
    component={CustomAutoComplete}
    placeholder={placeholder}
    {...rest}
  />
);
AutoCompleteFormik.defaultProps = {
  options: [],
  validate: () => {},
};

AutoCompleteFormik.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape()),
  validate: PropTypes.func,
};

export default AutoCompleteFormik;
