import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import CreateWPContent from './CreateWPContent';
import ModalCustom from '../../../commonComponents/ModalCustom';
import { ButtonSimple } from '../../../commonComponents/ButtonCustom';

const BudgetTimeAndMaterial = (props) => {
  const {
    workPakagesData, change, setChange, ProjectId, budgetType, edit,
  } = props;

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let content;

  if (workPakagesData.lenght > 0) {
    content = <p>Hello la companie</p>;
  } else if (edit && !workPakagesData[0]) {
    content = (
      <div
        style={{
          display: 'flex', alignItems: 'center', justifyContent: 'center', height: '196px', flexDirection: 'column', border: '1px dashed rgba(0, 0, 0, 0.5)', borderRadius: '8px',
        }}
      >
        <ButtonSimple text="Forecast sprints" event={handleOpen} border="1px solid black" icon={<AddIcon />} />
        <ModalCustom
          handleClose={handleClose}
          open={open}
          width="505px"
          title="Time and material plan"
          child={(
            <CreateWPContent
              ProjectId={ProjectId}
              setOpen={setOpen}
              change={change}
              setChange={setChange}
              budgetType={budgetType}
            />
          )}
        />

      </div>
    );
  } else {
    content = <p />;
  }

  return edit && workPakagesData.lenght === 0 ? <p>blabla</p> : <p>{workPakagesData.lenght > 0 ? 'There is no Forecast created' : content}</p>;
};

BudgetTimeAndMaterial.defaultProps = {
  change: false,
  budgetType: undefined,
  workPakagesData: [],
};

BudgetTimeAndMaterial.propTypes = {
  workPakagesData: PropTypes.arrayOf(PropTypes.shape()),
  setChange: PropTypes.func.isRequired,
  change: PropTypes.bool,
  budgetType: PropTypes.string,
  ProjectId: PropTypes.number.isRequired,
  edit: PropTypes.bool.isRequired,

};

export default BudgetTimeAndMaterial;
