/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import groupBy from 'lodash/groupBy';
import uniq from 'lodash/uniq';
import moment from 'moment';
import Avatar from '@material-ui/core/Avatar';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { debounce } from 'lodash';
import { Box } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import TimeAxis from './TimeAxis';
import { api } from '../../../api/api';
import LoadingAnimation from '../../loaders/LoadingAnimation';
import GridPositioner from '../../Forecasting/GridPositioner';
import HorizontalBarProject from '../../Forecasting/HorizontalBarProject';
import HorizontalBarTotalIntensity from './HorizontalBarTotalIntensity';
import groupForecastingsContinuity from '../../../utils/groupForecastingsContinuity';
import ProjectNameBox from './ProjectNameBox';
import HorizontalBarIntensity from './HorizontalBarIntensity';
import { forecastingsUserUpdate } from '../../../redux/ForecastingsUserSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '75%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  panelSummary: {
    flexDirection: 'row-reverse',
  },
  accordion: {
    width: '100%',
    padding: '0px 16px',
    border: 'none',
    boxShadow: 'none',
  },
  rootAccordion: {
    paddingLeft: 0,
    margin: '0px 0px 16px 0px',
  },
  rootDetailsProjects: {
    marginTop: '0px',
    paddingTop: '0px',
  },

}));

const GridForecastingProfile = (props) => {
  const {
    forecastings, startDate, endDate, timeUnit, setStartDate, setEndDate,
  } = props;
  const classes = useStyles();

  //   const [collaborators, setCollaborators] = useState([]);
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  // console.log('forecastings', forecastings);
  const changeIntensity = (time, ProjectId, intensity, start, end) => {
    // console.log('time', time);
    // console.log('ProjectId', ProjectId);
    // console.log('intensity', intensity);
    // console.log('START', moment(start).startOf('week'));
    // console.log('ENDEND', moment(start).endOf('week'));

    dispatch(forecastingsUserUpdate({
      timeUnit,
      intensity,
      start,
      // start: timeUnit === 'week' ? moment(start).startOf('week') : start,
      end,
      // end: timeUnit === 'week' ? moment(end).endOf('week') : end,
      ProjectId,
    }));
  };

  const onChangeDebounced = useCallback(
    debounce(changeIntensity, 1000),
    // [change],
  );

  // We cluster the entries for each user in only one array
  //   const groupedByUserId = groupBy(forecastings, 'UserId');
  // console.log('forecastings', forecastings);

  //   useEffect(() => {
  //     // We create an array with all the UserId we have in the original request of forecastings
  //     const userIds = Object.keys(groupedByUserId);

  //     if (userIds.length > 0) {
  //       api
  //         .get(`api/users?UsersIds=${userIds.join(',')}`)
  //         .then((res) => res.data)
  //         .then((data) => setCollaborators(data));
  //     }
  //   }, [forecastings]);

  useEffect(async () => {
    // Lodash "uniq" removes duplicates from an array
    const projectIds = uniq(forecastings.map((item) => item.ProjectId)).filter((el) => el != null);
    if (projectIds.length > 0) {
      setIsLoading(true);
      await api.get(`api/projects?ProjectsIds=${projectIds.join(',')}`)
        .then((res) => setProjects(res.data));
      setIsLoading(false);
    }
  }, [forecastings]);

  let goodToDisplay;

  if (isLoading) {
    goodToDisplay = <LoadingAnimation />;
  } else if (forecastings?.length === 0) {
    goodToDisplay = (
      <Box display="flex" justifyContent="center">
        <Typography variant="h4" style={{ marginTop: '2rem' }}>No forecastings for this period</Typography>
      </Box>
    );
  } else {
    let goodFormat;
    if (timeUnit === 'day') {
      goodFormat = 'YYYY-MM-DD';
    } else if (timeUnit === 'week') {
      goodFormat = 'W';
    } else if (timeUnit === 'month') {
      goodFormat = 'MM';
    }

    const groupedByTimeUnit = groupBy(forecastings,
      (f) => moment(f.date).format(goodFormat));

    // console.log('groupedByTimeUnit', groupedByTimeUnit);

    const totalIntensitiesUser = Object.values(groupedByTimeUnit)
      .map((value) => value.reduce((acc, currentValue) => ({
        intensity: acc.intensity + currentValue.intensity,
        date: currentValue.date,
        startDate: currentValue.startDate,
        endDate: currentValue.endDate,
        UserId: currentValue.UserId,
        ProjectId: currentValue.ProjectId,
        ManagerId: currentValue.ManagerId,
        absence: currentValue.absence,
      }), { intensity: 0 }));

    const groupsTotalIntensity = groupForecastingsContinuity(totalIntensitiesUser, timeUnit);

    // console.log('totalIntensitiesUser', totalIntensitiesUser);
    // console.log(`repeat(${endDate.diff(startDate, `${timeUnit}s`) + 1}, 1fr)`);
    // console.log('hello', Object.values(intensityByProject).map((p) => groupForecastingsContinuity(p, timeUnit)));
    const intensityByProject = groupBy(forecastings, 'ProjectId');
    const hello = Object.values(intensityByProject).map((el) => el.map((item) => item)); // ICI ICI ICI ICI
    // if (timeUnit === 'day') {

    // }
    const groupsProjects = Object.values(intensityByProject).map((p) => groupForecastingsContinuity(p, timeUnit));
    // console.log('intensityByProject', intensityByProject);
    // group by week => valueok

    // const groupByWeek = groupBy(totalIntensitiesUser, (forecasting) => moment(forecasting.date).week());
    // const groups = totalIntensitiesUser.reduce((acc, cur, i, arr) => {
    //   if (i > 0 && cur.intensity === arr[i - 1].intensity && (moment(cur.date).diff(moment(arr[i - 1].date), `${timeUnit}s`) === 1)) {
    //     acc[acc.length - 1].end = cur.date;
    //   } else {
    //     acc.push({ intensity: cur.intensity, start: cur.date, end: cur.date });
    //   }
    //   return acc;
    // }, []);
    // console.log('1111', moment(startDate).toISOString());
    // console.log('2222', moment(endDate).toISOString());
    // console.log('Object.values(intensityByProject)', Object.values(intensityByProject));
    // console.log('groupsProjects', groupsProjects);
    // console.log('groupsTotalIntensity', groupsTotalIntensity);

    goodToDisplay = (
      <Box display="flex">
        <Box style={{
          width: '14.5%',
          gridTemplateColumns: 'repeat(1fr)',
          display: 'grid',
          alignContent: 'center',
          gridAutoRows: 'minmax(2rem, auto)',
          gridGap: '4px',
        }}
        >
          <Typography />
          {projects.map((p, i) => (
            <ProjectNameBox color={p.color} key={i} cle={i} projectName={p.project_name} />
          ))}
        </Box>
        <Box style={{
          width: '85.5%',
          paddingRight: '35px',
          gridTemplateColumns: `repeat(${endDate.diff(startDate, `${timeUnit}s`) + 1}, 1fr)`,
          display: 'grid',
          alignItems: 'center',
          gridAutoRows: 'minmax(2rem, auto)',
          gridGap: '4px',
          backgroundColor: '#F0F0F1',
        }}
        >
          {groupsTotalIntensity.map((f) => (
            <GridPositioner
              key={f.start}
              startDate={moment(f.start)}
              row={1}
              endDate={moment(f.end)}
              gridStartDate={startDate}
              timeUnit={timeUnit}
            >
              <HorizontalBarTotalIntensity forecasting={f} />
            </GridPositioner>
          ))}

          {/* console.log('edsdssdlll', el); */}
          {groupsProjects.map((f, i) => (
            f.map((el) => (el.ProjectId
              ? (
                <GridPositioner
                  key={el.start}
                  startDate={moment(el.start)}
                  row={i + 2}
                  endDate={moment(el.end)}
                  gridStartDate={startDate}
                  timeUnit={timeUnit}
                >
                  {el.calendarSelected
                    ? <TextField defaultValue={el.intensity || 0} variant="outlined" placeholder={el.intensity} onChange={(e) => onChangeDebounced(timeUnit, el.ProjectId, parseInt(e.target.value, 10), el.start, el.end)} />
                    : el.intensity !== 0 ? (
                      <HorizontalBarIntensity
                        value={el.intensity}
                        color={projects.find((p) => p.id === el.ProjectId)?.color}
                      />
                    ) : ''}
                </GridPositioner>
              ) : ''))

          ))}
          {/* {totalIntensitiesUser.map((f, i, a) => {
              let isDouble = false;

              let absent = false;

              if (f.absence === 1 && timeUnit === 'day') {
                absent = true;
                if (i > 0) {
                  const dateVeille = a[i - 1].date ? moment(a[i - 1].date) : undefined;
                  const dateJour = moment(f.date);
                  if (f.absence === a[i - 1].absence && (dateJour.diff(dateVeille, `${timeUnit}s`) === 1)) {
                    isDouble = true;
                  }
                }
                return (
                  <GridPositioner
                    key={f.date}
                    startDate={moment(f.date)}
                    row={1}
                    gridStartDate={startDate}
                    timeUnit={timeUnit}
                  >
                    <HorizontalBarTotalIntensity word="Vaca" absent={absent} isDouble={isDouble} />
                  </GridPositioner>
                );
              }

              if (f.absence === 2 && timeUnit === 'day') {
                absent = true;
                if (i > 0) {
                  const dateVeille = a[i - 1].date ? moment(a[i - 1].date) : undefined;
                  const dateJour = moment(f.date);
                  if (f.absence === a[i - 1].absence && (dateJour.diff(dateVeille, `${timeUnit}s`) === 1)) {
                    isDouble = true;
                  }
                }
                return (
                  <GridPositioner
                    key={f.date}
                    startDate={moment(f.date)}
                    row={1}
                    gridStartDate={startDate}
                    timeUnit={timeUnit}
                  >
                    <HorizontalBarTotalIntensity word="Sick" absent={absent} isDouble={isDouble} />
                  </GridPositioner>
                );
              }

              if (i > 0) {
                const dateVeille = a[i - 1].date ? moment(a[i - 1].date) : undefined;
                const dayBefore = moment(f.date).subtract(1, 'days').format('DD-MMM-yyyy');
                const searchPreviousDate = a.find((item) => moment(item.date).format('DD-MMM-yyyy') === dayBefore);
                const dateJour = moment(f.date);

                if (typeof searchPreviousDate !== 'undefined' && (dateJour.diff(dateVeille, `${timeUnit}s`) === 1) && f.intensity === a[i - 1].intensity) {
                  isDouble = true;
                }
              }
              return (
                <GridPositioner
                  key={f.date}
                  startDate={moment(f.date)}
                  row={1}
                  gridStartDate={startDate}
                  timeUnit={timeUnit}
                >
                  <HorizontalBarTotalIntensity
                    totalIntensity={f.intensity}
                    isDouble={isDouble}
                  />
                </GridPositioner>
              );
            })} */}

        </Box>
        {/* </Box> */}
      </Box>
    );

    // collaborators.map((user) => {
    //   const forecastingsForUser = groupedByUserId[user.id];
    //   const groupedByProject = groupBy(forecastingsForUser, 'ProjectId');

    //   let goodFormat;
    //   if (timeUnit === 'day') {
    //     goodFormat = 'YYYY-MM-DD';
    //   } else if (timeUnit === 'week') {
    //     goodFormat = 'W';
    //   } else if (timeUnit === 'month') {
    //     goodFormat = 'MM';
    //   }

    //   const groupedByTimeUnit = groupBy(forecastingsForUser,
    //     (f) => moment(f.date).format(goodFormat));

    //   console.log('groupedByTimeUnit', groupedByTimeUnit);

    //   const totalIntensitiesUser = Object.values(groupedByTimeUnit)
    //     .map((value) => value.reduce((acc, currentValue) => ({
    //       intensity: acc.intensity + currentValue.intensity,
    //       date: currentValue.date,
    //       UserId: currentValue.UserId,
    //       ProjectId: currentValue.ProjectId,
    //       ManagerId: currentValue.ManagerId,
    //       absence: currentValue.absence,
    //     }), { intensity: 0 }));
    //   console.log('totalIntensitiesUser', totalIntensitiesUser);

    //   return (
    //     <div key={user.id}>
    //       <div id="userForecasting">

    //         <Accordion className={classes.accordion} defaultExpanded>
    //           <AccordionSummary
    //             className={classes.panelSummary}
    //             expandIcon={<ExpandMoreIcon />}
    //             classes={{ root: classes.rootAccordion }}
    //           >
    //             <div id="userIdentityForecastings">
    //               {/* <Link to={`/employees/${user.id}`}>
    //                 <Avatar alt="Remy Sharp" src="https://static1.puretrend.com/articles/5/17/72/85/@/1947675-visage-fin-comment-appliquer-son-blush-624x600-3.jpg" />
    //               </Link>
    //               <div>
    //                 <p>
    //                   {user.first_name}
    //                   {' '}
    //                   {user.last_name}
    //                 </p>
    //                 <p>{user.position}</p>
    //               </div> */}
    //             </div>

    //           </AccordionSummary>
    //           <AccordionDetails classes={{ root: classes.rootDetailsProjects }}>

    //             <div style={{ width: '24%' }} />

    //             <div style={{
    //               gridTemplateColumns: `repeat(${endDate.diff(startDate, `${timeUnit}s`) + 1}, 1fr)`, width: '100%', display: 'grid', gridGap: '4px', gridAutoRows: 'minmax(2rem, auto)', alignItems: 'center',
    //             }}
    //             >
    //               { Object.values(groupedByProject)
    //                 .map((fcs, indexProject) => fcs.map((f, i, a) => {
    //                   let isDoubleProject = false;

    //                   if (i > 0) {
    //                     const dateVeille = a[i - 1].date ? moment(a[i - 1].date) : undefined;
    //                     const dateJour = moment(f.date);
    //                     if (f.intensity === a[i - 1].intensity && (dateJour.diff(dateVeille, `${timeUnit}s`) === 1)) {
    //                       isDoubleProject = true;
    //                     }
    //                   }

    //                   return f.absence === 0 ? (
    //                     <GridPositioner
    //                       key={f.id}
    //                       startDate={moment(f.date)}
    //                       row={indexProject + 1}
    //                       gridStartDate={startDate}
    //                       timeUnit={timeUnit}
    //                     >
    //                       <HorizontalBarProject
    //                         idProject={f.ProjectId}
    //                         idManager={f.ManagerId}
    //                         idUser={f.UserId}
    //                         intensity={f.intensity}
    //                         isDoubleProject={isDoubleProject}
    //                         colorBorder={projects.find((proj) => proj.id === f.ProjectId)?.color}
    //                         setUpdateForecasting={setUpdateForecasting}
    //                       />
    //                     </GridPositioner>
    //                   ) : '';
    //                 }))}

    //             </div>
    //           </AccordionDetails>
    //         </Accordion>

    //       </div>
    //       <hr />
    //     </div>
    //   );
    // });
  }

  return (
    <>
      { goodToDisplay}
    </>

  );
};

GridForecastingProfile.propTypes = {
  forecastings: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  timeUnit: PropTypes.oneOf(['day', 'week', 'month']).isRequired,
  startDate: PropTypes.shape().isRequired, // Moment object
  endDate: PropTypes.shape().isRequired, // Moment object
  setStartDate: PropTypes.func.isRequired,
  setEndDate: PropTypes.func.isRequired,
  // setUpdateForecasting: PropTypes.func.isRequired,
};

export default GridForecastingProfile;
