/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import ProjectsApi from '../api/ProjectsApi';

const ProjectSlice = createSlice({
  name: 'projects',
  initialState: { projects: [] },
  reducers: {
    projectsAdded: (state, action) => {
      // state.push(action.payload);
      state.projects = action.payload;
    },
  },
});

const { actions, reducer } = ProjectSlice;

export const { projectsAdded } = actions;

export const fetchProjects = () => async (dispatch) => {
  const projectsApi = new ProjectsApi();
  const response = await projectsApi.fetchAll();
  dispatch(projectsAdded(response));
};

export default reducer;
