/* eslint-disable import/prefer-default-export */
/* eslint-disable camelcase */

import moment from 'moment';
import publicHollidays from './publicHolidaysDE.json';

function dateRangeOverlaps(a_start, a_end, b_start, b_end) {
  if (moment(b_start).isBetween(a_start, a_end, undefined, '[]')
  || moment(b_end).isBetween(a_start, a_end, undefined, '[]')) return true; // b starts or ends in a

  if (moment(a_start).isBetween(b_start, b_end, undefined, '[]')
  || moment(a_end).isBetween(b_start, b_end, undefined, '[]')) return true; // b starts or ends in b

  if (moment(a_start).isSame(b_start, 'day')
  || moment(a_end).isSame(b_end, 'day')
  || moment(a_start).isSame(b_end, 'day')
  || moment(a_end).isSame(b_start, 'day')
  ) return true;

  if (moment(a_start).isBetween(b_start, b_end, undefined, '()')
  && moment(a_end).isBetween(b_start, b_end, undefined, '()')) return true; // a in b

  if (moment(b_start).isBetween(a_start, a_end, undefined, '()')
  && moment(b_end).isBetween(a_start, a_end, undefined, '()')) return true; // b in a

  return false;
}

export const multipleDateRangeOverlaps = (dateRanges) => {
  if (dateRanges.length % 2 !== 0) {
    throw new TypeError('Arguments length must be a multiple of 2');
  }
  for (let i = 0; i < dateRanges.length - 2; i += 2) {
    for (let j = i + 2; j < dateRanges.length; j += 2) {
      if (
        dateRangeOverlaps(
          dateRanges[i], dateRanges[i + 1],
          dateRanges[j], dateRanges[j + 1],
        )
      ) return true;
    }
  }
  return false;
};

// const currentYear = moment().format('YYYY');
export const selectPublicHollidays = publicHollidays.map(
  (item) => moment(item.startDate).format('YYYY-MM-DD'),
);
// console.log('selectPublicHollidays', selectPublicHollidays);
// console.log('publicHollidays', publicHollidays);
// console.log('currentYear', currentYear);
