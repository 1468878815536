import React from 'react';
import { Alert } from '@material-ui/lab';

const TimeLine = () => (
  <div id="leftSection3" className="sections">
    <Alert style={{ marginBottom: '1rem' }} severity="info">
      Unfortunately, there is no timeline to see yet.
    </Alert>
  </div>
);

export default TimeLine;
