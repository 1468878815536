/* eslint-disable max-len */
/* eslint-disable arrow-body-style */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import moment from 'moment/moment';
import {
  Formik, Form, FieldArray,
} from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { isEmpty } from 'lodash';
import { api } from '../../../api/api';
import NewForecastings from './NewForecastings';
import { ButtonCustomFormik, ButtonSimple } from '../../../commonComponents/ButtonCustom';
import { multipleDateRangeOverlaps } from '../../../utils/dateUtils';

const BodyNewForecasting = (props) => {
  const {
    onClose,
    // projectsOneManager',
    setUpdateForecasting,
    projectId,
    newUsers,
  } = props;
  const [overlaping, setOverlaping] = useState(false);
  const [arrOverlap, setArrOverlap] = useState([]);

  const handleSubmit = (values) => {
    const data = values.forecastings.map((f) => ({
      ...f, UserId: f.UserId.id, ProjectId: f.ProjectId, edit: false,
    }));
    api.post('api/forecasting', data)
      .then(onClose)
      .then(() => setUpdateForecasting(true))
      .then(() => setUpdateForecasting(false));
  };

  const validateDateRange = (values) => {
    let dateRanges = [];
    if (values.UserId && values.ProjectId) {
      api
        .get(`api/forecasting/groupedByRange?ManagerId=${undefined}
        &UserId=${values.UserId.id}&ProjectId=${values.ProjectId}
        &startDate=${moment().subtract(1, 'months')}&endDate=${moment().add(2, 'years')}`)
        .then((res) => res.data)
        .then((data) => (
          data.length > 0 ? data.forEach((elem) => elem.forEach(
            (item) => dateRanges.push({
              startDate: item[0].date,
              endDate: item[item.length - 1].date,
            }),
          )) : setOverlaping(false)
        ));
    }
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    const dateToCheck = [{ startDate: values.startDate, endDate: values.endDate }];
    dateRanges = [...dateRanges, ...dateToCheck];
    sleep(500).then(() => {
      setArrOverlap(dateRanges.reduce((acc, cur) => {
        acc.push(cur.startDate, cur.endDate);
        return acc;
      }, []));
    });
    return arrOverlap;
  };

  useEffect(() => {
    setOverlaping(multipleDateRangeOverlaps(arrOverlap));
  }, [arrOverlap]);

  const DEFAULT_FORECASTING = () => ({
    absence: '0',
    UserId: undefined,
    ProjectId: projectId,
    startDate: moment().format(),
    endDate: moment().format(),
    intensity: 0,
    roleInProject: '0',
    uuid: uuidv4(),
  });

  const initialValuesObject = {
    forecastings: [
      DEFAULT_FORECASTING(),
    ],
  };

  const validateOneForecasting = (forecasting) => {
    const errors = {};
    if (typeof forecasting?.UserId === 'undefined') {
      errors.UserId = 'Required field.';
    }
    if (forecasting?.absence === 0 && typeof forecasting?.ProjectId === 'undefined') {
      errors.ProjectId = 'Required field.';
    }
    if (forecasting?.absence === 0 && forecasting?.intensity < 5) {
      errors.intensity = 'Intensity must be over 5%';
    }
    if (forecasting?.absence === 0 && typeof forecasting?.intensity !== 'number') {
      errors.intensity = 'Intensity must be a number';
    }
    if (moment(forecasting?.endDate).isBefore(forecasting?.startDate)) {
      errors.startDate = 'End date must be equal or after start date';
    }

    // const projectConcerned = projectsOneManager.find((p) => p.id === forecasting?.ProjectId);
    // // validate that the selected start date, for the forecasting, is greater (or equal)
    // // than the starting date of the selected project (if any)
    // if ((typeof projectConcerned?.start_date !== 'undefined') && (typeof forecasting?.startDate !== 'undefined')
    //   && moment(forecasting?.startDate).isBefore(projectConcerned.start_date, 'day')) {
    //   const message = 'The start date of the forecasting must occur after (or the same day as the starting date of the selected project';
    //   errors.startDate = errors.startDate ? `${errors.startDate} and ${message}` : message;
    // }
    // if ((typeof projectConcerned?.end_date !== 'undefined') && (forecasting?.endDate !== 'undefined')
    //     && moment(forecasting?.endDate).isAfter(projectConcerned.end_date, 'day')) {
    //   const message = 'The end date of the forecasting must occur before (or the same day as the end date of the selected project';
    //   errors.startDate = errors.startDate ? `${errors.startDate} and ${message}` : message;
    // }

    if (forecasting?.UserId && forecasting?.ProjectId
      && multipleDateRangeOverlaps(arrOverlap)) {
      validateDateRange(forecasting);
      const message = 'No overlapping allowed, please check the date range';
      errors.startDate = errors.startDate ? `${errors.startDate} and ${message}` : message;
    }

    return errors;
  };

  const validate = (values) => {
    const errors = {};
    errors.forecastings = values.forecastings
      .map(validateOneForecasting);
    if (errors.forecastings.every((f) => isEmpty(f))) {
      return {};
    } return errors;
  };

  return (
    <Formik initialValues={initialValuesObject} onSubmit={handleSubmit} validate={validate}>
      {({ values }) => {
        return (
          <Form>
            <Box id="forecastingsModal">
              <FieldArray name="forecastings">
                {({ remove, push }) => {
                  return (
                    <Box>
                      {values.forecastings.length > 0
                    && values.forecastings.map((f, index) => (
                      <NewForecastings
                        key={f.uuid}
                        projectId={f.ProjectId}
                        // projectsOneManager={projectsOneManager}
                        remove={() => {
                          remove(f.uuid);
                        }}
                        onClose={onClose}
                        index={index}
                        validateDateRange={validateDateRange}
                        setOverlap={setOverlaping}
                        overlap={overlaping}
                        newUsers={newUsers}
                      />
                    ))}
                      <Box display="flex" justifyContent="flex-start" marginTop="40px">
                        <ButtonSimple
                          event={() => push(DEFAULT_FORECASTING())}
                          text=" + New Collaborator "
                          border="1px solid #001F33"
                        />
                      </Box>
                    </Box>
                  );
                }}
              </FieldArray>
            </Box>
            <Box display="flex" justifyContent="center" marginTop="40px" gridGap="1.5rem">
              <ButtonCustomFormik variant="large" text="Save" />
              <ButtonSimple variant="large" text="Cancel" border="1px solid #001F33" event={onClose} />
            </Box>

          </Form>
        );
      }}
    </Formik>
  );
};

BodyNewForecasting.defaultProps = {
  onClose: () => {},
  setUpdateForecasting: false,
};

BodyNewForecasting.propTypes = {
  onClose: PropTypes.func,
  // projectsOneManager: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setUpdateForecasting: PropTypes.bool,
  projectId: PropTypes.number.isRequired,
  newUsers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default BodyNewForecasting;
