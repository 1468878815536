/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { api } from '../../api/api';
import FormikAutoCompleteCreate from '../formik/FormikAutoCompleteCreate';

const Activity = (props) => {
  const {
    setActivitiesList,
    activitiesList,
    setNewActivityAdded,
  } = props;

  const handleCreate = (newValue) => api
    .post('api/activities', newValue)
    .then((response) => {
      setActivitiesList([...activitiesList, response.data]);
      setNewActivityAdded(true);
      setNewActivityAdded(false);
      return response.data;
    })
    .catch((err) => {
      throw new Error(err);
    });

  return (
    <>
      <>
        <label htmlFor="ActivityDemand" className="labelsFormCreate">
          Activity
          {' '}
          <span className="mandatoryInputs">*</span>
        </label>
        <p style={{ color: 'rgba(112, 121, 135, 1)', marginBottom: '8px', marginTop: '3px' }}>If the Activity you’re looking for doesn’t exist, press enter to add a new one</p>
      </>
      <FormikAutoCompleteCreate
        name="activity"
        options={activitiesList}
        // getOptionLabel={(option) => (option.activity_name || '')}
        disableClearable
        placehold="Enter your activity there"
        onCreate={handleCreate}
      />
    </>
  );
};

Activity.propTypes = {
  activitiesList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    activity_name: PropTypes.string,
  })).isRequired,
  setActivitiesList: PropTypes.func.isRequired,
  setNewActivityAdded: PropTypes.func.isRequired,
};

export default Activity;
