/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
// import UsersApi from '../../api/UsersApi';

const ActivitySlice = createSlice({
  name: 'activities',
  initialState: { activities: [] },
  reducers: {
    activitiesAdded: (state, action) => {
      // state.push(action.payload);
      state.activities = action.payload;
    },
  },
});

const { actions, reducer } = ActivitySlice;

export const { activitiesAdded } = actions;

export default reducer;
