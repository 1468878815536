import React from 'react';
import PropTypes from 'prop-types';
import GeneralInfosCard from './GeneralInfosCard';
import ProgressionChance from './ProgressionChance';
import Staffed from './Staffed';
import IconType from '../IconType';

const CardProject = (props) => {
  const {
    projectName, endDate, startDate, progression, projectType,
    status, fullyStaff, collaborators, stepSale, chanceWinning,
    budgettype, ProjectId, ManagerId, forecastings, clientName,
  } = props;
  return (
    <div style={{
      width: '332px',
      height: '280px',
      backgroundColor: '#FFFFFF',
      padding: '32px 28px 26px 32px',
      boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.08)',
      borderRadius: '13px',
    }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {projectType ? <IconType type={projectType} /> : <IconType type="None" />}
        <p style={{ fontSize: '24px', fontWeight: 500 }}>{projectName}</p>
      </div>

      <GeneralInfosCard
        clientName={clientName}
        projectType={projectType}
        startDate={startDate}
        endDate={endDate}
        stepSale={stepSale}
        budgettype={budgettype}
      />

      <ProgressionChance
        progression={progression}
        status={status}
        chanceWinning={chanceWinning}
        startDate={startDate}
        endDate={endDate}
        budgettype={budgettype}
      />

      <Staffed
        fullyStaff={fullyStaff}
        endDate={endDate}
        startDate={startDate}
        collaborators={collaborators}
        projectName={projectName}
        ProjectId={ProjectId}
        ManagerId={ManagerId}
        forecastings={forecastings}
      />
    </div>
  );
};

CardProject.defaultProps = {
  progression: null,
  chanceWinning: null,
  status: null,
  endDate: null,
  startDate: null,
  stepSale: null,
  projectType: null,
  budgettype: null,
  fullyStaff: undefined,
  ManagerId: undefined,
};

CardProject.propTypes = {
  clientName: PropTypes.string.isRequired,
  projectName: PropTypes.string.isRequired,
  fullyStaff: PropTypes.string,
  progression: PropTypes.number,
  budgettype: PropTypes.string,
  chanceWinning: PropTypes.number,
  status: PropTypes.string,
  projectType: PropTypes.string,
  endDate: PropTypes.string,
  startDate: PropTypes.string,
  stepSale: PropTypes.string,
  collaborators: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
  })).isRequired,
  forecastings: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
  })).isRequired,
  ProjectId: PropTypes.number.isRequired,
  ManagerId: PropTypes.number,
};

export default CardProject;
