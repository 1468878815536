/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import moment from 'moment';
import Activity from '../common/Activity';
import { api } from '../../api/api';
import { ButtonSimple, ButtonCustomFormik } from '../../commonComponents/ButtonCustom';
import { SelectFormik } from '../../commonComponents/Fields/SelectFormik';
import StartDateSelection from '../../commonComponents/StartDateCustom';
import EndDateSelection from '../../commonComponents/EndDateCustom';

const useStyles = makeStyles((theme) => ({
  collaborators: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '8px',
    boxShadow: theme.shadows[5],
  },
  datesContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
}));

const BodyModalAddActivity = (props) => {
  const {
    handleClose,
    currentProjects,
    userId,
    setActivityAdded,
    editcompletedactivity,
    // projectName,
    projectId,
    activityName,
    setActivityName,
    activitystartdate,
    setActivitiesList,
    activitiesList,
    activityId,
    completedActivityId,
    activity,
    workedOnProjectsByUser,
    setNewActivityAdded,
  } = props;
  const classes = useStyles();

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        // eslint-disable-next-line react/destructuring-assignment
        props.handleClose();
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, []);

  const handleUpdate = (values) => {
    const data = {
      activityId: values.activity.id,
      userId,
      projectId: parseInt(values.projectId, 10),
      startDate: values.startDate,
      endDate: values.endDate === '' ? null : values.endDate,
    };
    api.put(`api/userActivityProject/${completedActivityId}`, data)
      .then(() => handleClose())
      .then(() => setActivityAdded(true))
      .then(() => setActivityAdded(false))
      .catch((err) => {
      // eslint-disable-next-line no-console
        console.error('Error:', err);
      });
  };

  const validateAsync = (values) => {
    const errors = {};
    if (moment(values.endDate).isBefore(values.startDate)) {
      errors.startDate = 'End date cant be before Start date';
    }
    if (moment(values.endDate).isAfter(moment())) {
      errors.startDate = 'End Date cant be after today';
    }
    if (values.startDate === '') {
      errors.startDate = 'Start Date is Mandatory';
    }
    if (values.activity === '' || values.activity === undefined) {
      errors.activity = 'Activity is Mandatory';
    }
    if (values.projectId === '') {
      errors.projectId = 'Project is Mandatory';
    }
    return errors;
  };

  const submitting = (values) => {
    const data = {
      activityId: values?.activity?.id,
      userId,
      projectId: parseInt(values.projectId, 10),
      startDate: values.startDate,
      endDate: values.endDate === '' ? null : values.endDate,
    };

    api.post('api/userActivityProject', data)
      .then(() => handleClose())
      .then(() => setActivityAdded(true))
      .then(() => setActivityAdded(false))
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error('Error:', err);
      });
  };

  return (
    <div className={classes.collaborators}>
      <Formik
        initialValues={{
          userId: '',
          activity: editcompletedactivity ? activity[0] : undefined,
          // projectId: workedOnProjectsByUser[0] && workedOnProjectsByUser[0]?.projectId[0],
          projectId: editcompletedactivity ? projectId : '',
          startDate: editcompletedactivity ? activitystartdate.toString() : undefined,
          endDate: undefined,
        }}
        onSubmit={!editcompletedactivity ? submitting : handleUpdate}
        validate={validateAsync}
      >
        {({ values }) => (
          <Form className="formCreateCollaborator">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <label htmlFor="ActivityDemand" className="labelsFormCreate">
                Project
                {' '}
                <span className="mandatoryInputs">*</span>
              </label>

            </div>
            <SelectFormik
              name="projectId"
              elements={workedOnProjectsByUser[0] && workedOnProjectsByUser[0].projectId
                .map((el) => ({
                  value: el,
                  name: currentProjects
                    .find((current) => current.id === el).project_name,
                }))}
            />
            <ErrorMessage
              name="projectId"
              component="div"
              className="field-error"
            />
            <div style={{ marginTop: '15px', marginBottom: '15px' }}>
              <Activity
                edit={editcompletedactivity}
                activityId={activityId}
                activity={activityName}
                setActivityName={setActivityName}
                setActivitiesList={setActivitiesList}
                activitiesList={activitiesList}
                currentActivity={values.activity}
                setNewActivityAdded={setNewActivityAdded}
              />
              <ErrorMessage
                name="activity"
                component="div"
                className="field-error"
              />
            </div>
            <div className={classes.datesContainer}>
              <StartDateSelection
                mandatory
                title="Start Date"
                name="startDate"
                editcompletedactivity={editcompletedactivity.toString()}
                style={{ width: '300px' }}
              />
              <EndDateSelection
                name="endDate"
                title="End Date"
                editcompletedactivity={editcompletedactivity.toString()}
              />
            </div>
            <ErrorMessage
              name="startDate"
              component="div"
              className="field-error"
            />
            <Box display="flex" justifyContent="center" gridGap="1.5rem" marginTop="30px">
              <ButtonCustomFormik
                variant="large"
                text="Save"
              />
              <ButtonSimple event={handleClose} variant="large" text="Cancel" />
            </Box>
          </Form>
        )}
      </Formik>

    </div>
  );
};

BodyModalAddActivity.defaultProps = {
  currentProjects: [],
  activitystartdate: undefined,

};

BodyModalAddActivity.propTypes = {
  handleClose: PropTypes.func.isRequired,
  currentProjects: PropTypes.arrayOf(PropTypes.shape()),
  editcompletedactivity: PropTypes.bool.isRequired,
  // projectName: PropTypes.string.isRequired,
  // projectId: PropTypes.oneOfType([
  //   PropTypes.string,
  //   PropTypes.number,
  // ]).isRequired,
  activityName: PropTypes.string.isRequired,
  activitystartdate: PropTypes.string,
  setActivitiesList: PropTypes.func.isRequired,
  activitiesList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    activity_name: PropTypes.string,
  })).isRequired,
  activityId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  completedActivityId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  setNewActivityAdded: PropTypes.func.isRequired,

};

export default BodyModalAddActivity;
