/* eslint-disable no-console */
import { api } from './api';

export default function ForecastingsApi() {
  function fetchAll(params) {
    return api.get('api/forecasting', { params })
      .then((response) => response.data)
      .catch((e) => console.error(e));
  }

  function fetchByUserAndProjectId({
    idManager, idUser, idProject, startDate, endDate,
  }) {
    return api.get(`api/forecasting/groupedByRange?ManagerId=${idManager}&UserId=${idUser}&ProjectId=${idProject}&startDate=${startDate}&endDate=${endDate}`)
      .then((response) => response.data)
      .catch((e) => console.error(e));
  }

  return ({
    fetchAll,
    fetchByUserAndProjectId,
  });
}
