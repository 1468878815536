/* eslint-disable no-nested-ternary */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';

const useStyles = makeStyles({
  box: {
    width: '121px',
    height: '27px',
    borderRadius: '6px',
    paddingLeft: '6px',
    paddingRight: '6px',
    marginTop: '8px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: (props) => props.backgroundColor,
  },
  point: {
    width: '11px',
    height: '11px',
    marginRight: '5px',
    color: (props) => props.colorPoint,
  },
  typo: {
    color: '#707987',
  },
});

const ChipStaff = (props) => {
  const { fullyStaff } = props;

  let colorPoint;
  let backgroundColor;
  let label;
  if (fullyStaff === '0') {
    colorPoint = 'rgba(218, 41, 28, 0.7)';
    backgroundColor = 'rgba(218, 41, 28, 0.1)';
    label = 'Under Staffed';
  } else if (fullyStaff === '1') {
    colorPoint = '#FDB71A';
    backgroundColor = 'rgba(253, 183, 26, 0.1)';
    label = 'Over Staffed';
  } else {
    colorPoint = '#68D47E';
    backgroundColor = 'rgba(104, 212, 126, 0.1)';
    label = 'Fully Staffed';
  }

  const classes = useStyles({ colorPoint, backgroundColor });

  return (
    <Box
      className={classes.box}
    >
      <FiberManualRecord className={classes.point} />
      <Typography className={classes.typo}>
        {label}
      </Typography>
    </Box>
  );
};

ChipStaff.propTypes = {
  fullyStaff: PropTypes.string.isRequired,
};

export default ChipStaff;
