/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@material-ui/core';
import { api } from '../../api/api';
import Manager from '../common/Manager';
import TextFieldFormik from '../../commonComponents/Fields/TextFieldFormik';
import { SelectFormik } from '../../commonComponents/Fields/SelectFormik';
import DeliveryLead from '../common/DeliveryLead';

const Step1 = (props) => {
  const {
    setValidation, shallNotPass, setTitle,
  } = props;
  const { values } = useFormikContext();
  const [projects, setProjects] = useState([]);
  const [maxChar, setMaxChar] = useState(false);
  const [nameValidation, setNameValidation] = useState(false);
  const [managerValidation, setManagerValidation] = useState(false);
  const [typeNames, setTypeNames] = useState(false);

  useEffect(() => {
    setValidation(true);
  }, []);

  useEffect(() => {
    api
      .get('api/projects')
      .then((res) => setProjects(res.data));
  }, []);

  const projectNames = projects
    .map((item) => item.project_name);

  useEffect(() => {
    if (!typeNames && !nameValidation && !maxChar && !managerValidation) {
      setValidation(false);
    } else {
      setValidation(true);
    }
  }, [values.project_name, values.Client, values.Manager, projectNames]);

  useEffect(() => {
    if (values.project_name.length >= 20) {
      setMaxChar(true);
    } else {
      setMaxChar(false);
    }
  }, [values.project_name]);

  useEffect(() => {
    if (values.project_name.length <= 0) {
      setNameValidation(true);
    } else {
      setNameValidation(false);
    }
  }, [values.project_name]);

  useEffect(() => {
    if (typeof projectNames.find((item) => item.toLowerCase() === values.project_name.toLowerCase()) !== 'undefined') {
      setTypeNames(true);
    } else {
      setTypeNames(false);
      setTitle(values.project_name);
    }
  }, [projectNames, values.project_name]);

  useEffect(() => {
    if (typeof values.Manager === 'undefined') {
      setManagerValidation(true);
    } else {
      setManagerValidation(false);
    }
  }, [values.Manager]);

  return (
    <Grid
      container
      spacing={2}
      style={{
        maxHeight: '400px', marginBottom: '20px', width: '85%', margin: 'auto',
      }}
    >
      <Grid item xs={12}>
        <Box>
          <Box display="flex" className="formFieldLabel" gridGap="5px">
            <label htmlFor="project_name" className="labelsFormCreate">Project Name</label>
            <span className="mandatoryInputs">*</span>
          </Box>
          <TextFieldFormik name="project_name" placeholder="Your project name" maxLength={20} />
          <Typography variant="caption" className="errorMessageCreateCollaborator">
            {typeNames && ('Project already exists.')}
            {nameValidation && ('Required field.')}
            {maxChar && ('Project name cannot be more than 20 characters.')}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <Box display="flex" className="formFieldLabel" gridGap="5px">
            <label htmlFor="project_type" className="labelsFormCreate">Project Type</label>
          </Box>
          <SelectFormik
            name="project_type"
            elements={['Not defined', 'Development', 'Design', 'Cybersecurity', 'Cloud']
              .map((el) => ({ value: el, name: el }))}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Manager status="createProject" />
        <Typography variant="caption" className="errorMessageCreateCollaborator">
          {managerValidation && ('Required field.')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <DeliveryLead status="createProject" />
      </Grid>
    </Grid>
  // </Grid>
  );
};

Step1.propTypes = {
  setValidation: PropTypes.func.isRequired,
  shallNotPass: PropTypes.bool.isRequired,
  setTitle: PropTypes.func.isRequired,
};

export default Step1;
