/* eslint-disable react/prop-types */
import React, {
  useState, useEffect,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import MomentUtils from '@date-io/moment';
import TodayIcon from '@material-ui/icons/Today';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import { useFormikContext, ErrorMessage } from 'formik';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import {
  Box, Divider, Grid,
} from '@material-ui/core';
import { api } from '../../../api/api';
// import { SelectFormik } from '../../../commonComponents/Fields/SelectFormik';
import NumberFieldFormik from '../../../commonComponents/Fields/NumberFieldFormik';
import RadioFormik from '../../../commonComponents/Fields/RadioFormik';
import AutoCompleteFormik from '../../../commonComponents/Fields/AutoCompleteFormik';
// eslint-disable-next-line no-unused-vars
import { multipleDateRangeOverlaps } from '../../../utils/dateUtils';

const useStyles = makeStyles(() => ({
  iconCalendar: {
    color: 'black',
  },
  TrendingFlatIcon: {
    marginLeft: '9px',
    marginRight: '9px',
    margin: '8px 9px 0 9px',
  },
  labels: {
    marginBottom: '8px',
    fontWeight: 700,
  },
  inputFocused: {
    marginLeft: '16px',
  },
}));

const TextField = (props) => {
  const {
    children, name, label, mandatory,
  } = props;

  return (
    <Box>
      <Box display="flex" className="formFieldLabel" gridGap="5px">
        <label htmlFor={name} className="labelsFormCreate">{label}</label>
        {mandatory && (
          <span className="mandatoryInputs">*</span>
        )}
      </Box>
      {children}
      {mandatory && (
      <ErrorMessage
        name={name}
        component="div"
        className="errorMessageCreateCollaborator"
      />
      )}
    </Box>
  );
};

const NewForecastings = ((props) => {
  const {
    // eslint-disable-next-line no-unused-vars
    projectsOneManager,
    index, remove, key, validateDateRange, setOverlap, newUsers,
  } = props;

  const [collaboratorsList, setCollaboratorsList] = useState([]);

  const classes = useStyles();

  const { setFieldValue, values } = useFormikContext();

  useEffect(() => {
    api
      .get('api/users')
      .then((res) => {
        setCollaboratorsList(res.data);
      });
  }, [newUsers]);

  const filterUsers = newUsers
  && collaboratorsList.length > 0 && collaboratorsList.reduce((acc, cur) => {
    const item = newUsers.find((newUser) => newUser.id === cur.id);
    if (!item) {
      acc.push(cur);
    }
    return acc;
  }, []);

  useEffect(() => {
    setOverlap(multipleDateRangeOverlaps(validateDateRange(values.forecastings[index])));
  }, [values.forecastings[index]?.UserId?.id,
    values.forecastings[index].ProjectId,
    values.forecastings[index].startDate,
    values.forecastings[index].endDate]);
  return (
    <Grid className="oneNewForecasting" container spacing={2} style={{ marginTop: index > 0 ? '1rem' : 0 }}>
      {index > 0 && (
      <Grid
        className={classes.gridItem}
        container
        spacing={1}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={1}>
          <IconButton
            onClick={() => {
              remove();
            }}
            size="small"
          >
            <ClearIcon />
          </IconButton>
        </Grid>
      </Grid>
      )}
      <Grid item xs={12}>
        <TextField name={`forecastings.${index}.UserId`} label="Collaborator" mandatory>
          <AutoCompleteFormik
            options={filterUsers || collaboratorsList}
            name={`forecastings.${index}.UserId`}
            getOptionLabel={(option) => (option ? `${option.first_name} ${option.last_name}` : '')}
            disableClearable
            key={key}
            index={index}
            placeholder="Select a collaborator"
            classes={{
              inputFocused: classes.inputFocused,
            }}
            onChange={(e, v) => {
              const newValue = v.value ?? v;
              setFieldValue(`forecastings.${index}.UserId`, newValue);
            }}
          />
        </TextField>
      </Grid>
      <Grid item className={classes.gridItem} container spacing={1} alignItems="stretch">
        <Grid item xs={6}>
          <TextField name={`forecastings.${index}.absence`} label="Absence">
            <RadioFormik
              customChange={(e) => {
                const { value } = e.target;
                if (parseInt(value, 10) > 0) {
                  setFieldValue(`forecastings.${index}.absence`, value.toString());
                  setFieldValue(`forecastings.${index}.intensity`, 0);
                  // setFieldValue(`forecastings.${index}.ProjectId`, undefined);
                  setFieldValue(`forecastings.${index}.roleInProject`, '0');
                } else setFieldValue(`forecastings.${index}.absence`, '0');
              }}
              name={`forecastings.${index}.absence`}
              elements={[{ value: '0', name: 'None' }, { value: '1', name: 'Vacation' }, { value: '2', name: 'Sickness' }]}
                // .map((el) => ({ value: el.value, name: el.name }))}
            />
          </TextField>
        </Grid>
        {/* {values.forecastings[index].absence === 0 && (
          <Grid item xs={6}>
            <TextField name={`forecastings.${index}.ProjectId`} label="Project" mandatory>
              <AutoCompleteFormik
                options={projectsOneManager}
                name={`forecastings.${index}.ProjectId`}
                getOptionLabel={(option) => (option ? option.project_name : '')}
                disableClearable
                key={key}
                index={index}
                placeholder="Select a Project"
                classes={{
                  inputFocused: classes.inputFocused,
                }}
                onChange={(e, v) => {
                  const newValue = v.value ?? v;
                  setFieldValue(`forecastings.${index}.ProjectId`, newValue);
                }}
              />
            </TextField>
          </Grid>
        ) } */}
      </Grid>
      <Grid item justifyContent="center" className={classes.gridItem} container spacing={1} alignItems="stretch">
        <Grid item xs={6}>
          <TextField name={`forecastings.${index}.startDate`} label="Start date" mandatory>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                className="dateEditRange"
                autoOk
                style={{ width: '100%' }}
                size="small"
                inputVariant="outlined"
                name={`forecastings.${index}.startDate`}
                format="MMM DD, yyyy"
                value={values.forecastings[index].startDate}
                InputAdornmentProps={{ position: 'start' }}
                onChange={(value) => setFieldValue(`forecastings.${index}.startDate`, moment(value).add(2, 'h'))}
                keyboardIcon={<TodayIcon className={classes.iconCalendar} />}
              />
            </MuiPickersUtilsProvider>
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField name={`forecastings.${index}.startDate`} label="End date" mandatory>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                className="dateEditRange"
                autoOk
                style={{ width: '100%' }}
                size="small"
                inputVariant="outlined"
                format="MMM DD, yyyy"
                name={`forecastings.${index}.endDate`}
                value={values.forecastings[index].endDate}
                InputAdornmentProps={{ position: 'start', variant: 'standard' }}
                keyboardIcon={<TodayIcon className={classes.iconCalendar} />}
                onChange={(value) => setFieldValue(`forecastings.${index}.endDate`, moment(value).add(2, 'h'))}
              />
            </MuiPickersUtilsProvider>
          </TextField>
        </Grid>
      </Grid>
      {values.forecastings[index].absence === '0' && (
        <Grid item className={classes.gridItem} container spacing={1} alignItems="stretch">
          <Grid item xs={12}>
            <TextField name={`forecastings.${index}.intensity`} label="Intensity (%)" mandatory>
              <NumberFieldFormik
                name={`forecastings.${index}.intensity`}
              />
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField name={`forecastings.${index}.roleInProject`} label="Role">
              <RadioFormik
                name={`forecastings.${index}.roleInProject`}
                elements={[{ value: '1', name: 'Expert' }, { value: '2', name: 'Product Owner' }, { value: '3', name: 'Scrum Master' }]}
              />
            </TextField>
          </Grid>
        </Grid>
      )}
      <Divider />
    </Grid>
  );
});

NewForecastings.defaultProps = {
};

NewForecastings.propTypes = {
  projectsOneManager: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  index: PropTypes.number.isRequired,
  remove: PropTypes.func.isRequired,
  key: PropTypes.string.isRequired,
  newUsers: PropTypes.arrayOf(PropTypes.shape()).isRequired,

};

export default NewForecastings;
