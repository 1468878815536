import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles(() => ({
  root: {
    height: '26px',
    fontWeight: 700,
    color: '#707987',
    fontSize: '14px',
    marginRight: '4px',
    borderRadius: '4px',
    backgroundColor: '#F5F5F5',

  },
  label: {
    padding: '0 6px',
    backgroundColor: '#F5F5F5',
    textOverflow: 'ellipsis',
  },
}));

const SkillsToolsTechnos = (props) => {
  const { Technos, Tools, Skills } = props;

  const classes = useStyles();

  let toolPoints = false;
  let technoPoints = false;
  let skillPoints = false;

  if (Tools.length > 2) {
    toolPoints = true;
  }
  if (Technos.length > 2) {
    technoPoints = true;
  }
  if (Skills.length > 2) {
    skillPoints = true;
  }

  return (
    <>

      <div className="ToolsTechnosSkills">
        {Skills.slice(0, 2).map((skill) => (
          <Chip
            classes={{ root: classes.root, label: classes.label }}
            label={skill?.skill_name?.slice(0, 10)}
            key={skill.id}
            title={skill?.skill_name}
          />
        ))}
        {skillPoints ? (
          <Chip
            classes={{ root: classes.root, label: classes.label }}
            label={`+${Skills.length - 2}`}
          />
        ) : '' }
      </div>

      <div className="ToolsTechnosSkills">
        {Tools.slice(0, 2).map((tool) => (
          <Chip
            classes={{ root: classes.root, label: classes.label }}
            label={tool?.tool_name?.slice(0, 10)}
            title={tool?.tool_name}
            key={tool.id}
          />
        ))}
        {toolPoints ? (
          <Chip
            classes={{ root: classes.root, label: classes.label }}
            label={`+${Tools.length - 2}`}
          />
        ) : '' }
      </div>

      <div className="ToolsTechnosSkills">
        {Technos.slice(0, 2).map((techno) => (
          <Chip
            classes={{ root: classes.root, label: classes.label }}
            label={techno?.techno_name?.slice(0, 10)}
            key={techno.id}
            title={techno?.techno_name}
          />
        ))}
        {technoPoints ? (
          <Chip
            classes={{ root: classes.root, label: classes.label }}
            label={`+${Technos.length - 2}`}
          />
        ) : '' }
      </div>

    </>
  );
};

SkillsToolsTechnos.defaultProps = {
  Skills: [],
  Tools: [],
  Technos: [],
};

SkillsToolsTechnos.propTypes = {
  Skills: PropTypes.arrayOf(PropTypes.shape({
    skill_name: PropTypes.string,
  })),
  Tools: PropTypes.arrayOf(PropTypes.shape({
    tool_name: PropTypes.string,
  })),
  Technos: PropTypes.arrayOf(PropTypes.shape({
    techno_name: PropTypes.string,
  })),

};

export default SkillsToolsTechnos;
