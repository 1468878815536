import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

const CollaboratorInList = () => (
  <Box display="flex" alignItems="center" marginBottom="49px">
    <Box display="flex" width="13.55%" alignItems="center">
      <Skeleton variant="circle" width={40} height={40} />
      <Box display="flex" flexDirection="column" marginLeft="8px">
        <Skeleton variant="text" width={100} />
        <Skeleton variant="text" width={100} />
      </Box>
    </Box>
    <Box width="10.16%">
      <Skeleton variant="text" width={75} />
    </Box>
    <Box display="flex" width="13.55%" gridColumnGap="4px">
      <Skeleton variant="text" width={50} height={40} />
      <Skeleton variant="text" width={50} height={40} />
      <Skeleton variant="text" width={50} height={40} />
    </Box>
    <Box display="flex" width="13.55%" gridColumnGap="4px">
      <Skeleton variant="text" width={50} height={40} />
      <Skeleton variant="text" width={50} height={40} />
      <Skeleton variant="text" width={50} height={40} />
    </Box>
    <Box display="flex" width="13.55%" gridColumnGap="4px">
      <Skeleton variant="text" width={50} height={40} />
      <Skeleton variant="text" width={50} height={40} />
      <Skeleton variant="text" width={50} height={40} />
    </Box>
    <Box width="8.81%" display="flex" justifyContent="center">
      <Skeleton variant="circle" width={40} height={40} />
    </Box>

    <Box display="flex" width="13.55%" gridColumnGap="4px">
      <Skeleton variant="text" width={50} height={40} />
      <Skeleton variant="text" width={50} height={40} />
    </Box>

    <Box width="13.28%">
      <Skeleton variant="text" width={75} />
    </Box>
  </Box>
);

export default CollaboratorInList;
