import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { convertCurrency } from '../../../utils/convertCurrency';

const useRowStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'rgba(39, 130, 237, 0.05)',
    },
  },
  tableCellHead: {
    paddingTop: '8px',
    paddingBottom: '8px',
    color: 'rgba(0, 0, 0, 0.5)',
    height: '2rem',
  },
  tableCell: {
    border: '1px solid #CCCCCC',
    paddingTop: '7.5px',
    paddingBottom: '7.5px',
  },
  tableLargeHeader: {
    background: 'none',
    fontSize: '13px',
    fontWeight: 'bold',
    lineHeight: '1rem',
    letterSpacing: '0.08333em',
    textTransform: 'uppercase',
    borderBottom: 'none',
  },
  gridTable: {
    padding: '10px',
    boxShadow: '0px 1px 1px -1px rgb(0 0 0 / 20%), 0px 0px 3px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%)',
    borderRadius: '4px',
    border: '1px solid rgba(224, 224, 224, 1)',
  },
  tableItem: {
    fontSize: '12px',
    lineHeight: '1rem',
    padding: '15px',
  },

  tableHeader: {
    textTransform: 'uppercase',
    lineHeight: '1rem',
    fontSize: '10px',
    fontWeight: 'bold',
    color: '#373A36',
  },
  tableRow: {
    '& td:nth-last-child(1)': {
      borderRight: '1px solid rgba(224, 224, 224, 1)',
    },
    '& td:first-child': {
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
    },
  },
});

function Row(props) {
  const { name, value } = props;
  // const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell className={classes.tableItem} align="left">{name}</TableCell>
        <TableCell className={classes.tableItem} align="right">{value}</TableCell>
      </TableRow>
    </>
  );
}

const TotalExpensesTable = (props) => {
  const {
    totalCapex, totalOpex, impactRiskHours, impactRiskEuros, currency, convertCurrencyValue,
  } = props;
  const classes = useRowStyles();

  return (
    <>
      { impactRiskEuros > 0 || impactRiskHours > 0 || totalCapex > 0 || totalOpex > 0 ? (
        <TableContainer
          style={{
            display: 'inline-block',
            overflowX: 'hidden',
            padding: '7px 3px 7px 3px',
            fontSize: '12px',
          }}
          className={classes.totalExpensesTable}
          component={Paper}
        >
          <Table className={classes.gridTable} id="totalExpensesTable" aria-label="collapsible table">
            <TableHead>
              <TableRow style={{ backgroundColor: 'rgba(0, 0, 0, 0.03)' }}>
                <TableCell className={classes.tableHeader} align="left">Expenses</TableCell>
                <TableCell className={classes.tableHeader} align="right">
                  Total (
                  {convertCurrency(currency)}
                  )
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {impactRiskEuros > 0 ? (
                <>
                  <Row key="capex" name={`Impact (${convertCurrency(currency)})`} value={convertCurrencyValue(impactRiskEuros).toFixed(0)} />
                </>
              ) : <></>}
              {impactRiskHours > 0 ? (
                <>
                  <Row key="capex" name="Impact (hrs)" value={impactRiskHours.toFixed(0)} />
                </>
              ) : <></>}
              {totalCapex > 0 ? (
                <>
                  <Row key="capex" name="CAPEX" value={totalCapex.toFixed(0)} />
                </>
              ) : <></>}
              {totalOpex > 0 ? (
                <>
                  <Row key="capex" name="OPEX" value={totalOpex.toFixed(0)} />
                </>
              ) : <></>}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <></>
      ) }
    </>

  );
};

TotalExpensesTable.propTypes = {
  currency: PropTypes.string.isRequired,
  convertCurrencyValue: PropTypes.func.isRequired,
  totalCapex: PropTypes.number.isRequired,
  totalOpex: PropTypes.number.isRequired,
  impactRiskHours: PropTypes.number.isRequired,
  impactRiskEuros: PropTypes.number.isRequired,
};

Row.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default TotalExpensesTable;
