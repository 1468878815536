/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box, Button, Typography, makeStyles,
} from '@material-ui/core';
import axios from 'axios';
import { api } from '../../api/api';
import GeneralInfosProfile from './GeneralInfosProfile';
import profileIcon from '../../ressources/profile-icon.png';
import SkillsProfile from './SkillsProfile';
import CompletedActivities from './CompletedActivities';
import AbsencesProfile from './AbsencesProfile';
import SelectAddElementProfile from './SelectAddElementProfile';
import ProfileLeftSideLoader from '../loaders/ProfileLeftSideLoader';
import { activitiesAdded } from '../../redux/ActivitySlice';
import { fetchProjects } from '../../redux/ProjectSlice';
import Workload from './Workload/Workload';
import { ReactComponent as Oops } from '../../ressources/oops.svg';

const useStyles = makeStyles(() => ({
  text: {
    fontSize: '24px',
    fontFamily: 'Roboto',
    top: '283px',
    left: '922px',
    textAlign: 'center',
  },
  paper: {
    backgroundColor: 'white',
    width: '100%',
    height: 'calc(100vh - 74px)',
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  saveBtn: {
    textAlign: 'center',
    backgroundColor: '#373A36',
    color: 'white',
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 500,
    fontFamily: 'Roboto',
    '&:hover': {
      backgroundColor: 'rgb(255, 184, 28)',
      color: 'black',
    },
  },
}));
const ProfilePage = () => {
  const { id } = useParams();
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [profileImagePath, setProfileImagePath] = useState('');
  const [profileImageForm, setProfileImageForm] = useState(null);
  const [activityRemoved, setActivityRemoved] = useState(false);
  const [activtyAdded, setActivityAdded] = useState(false);
  const [toolsSelected, setToolsSelected] = useState([]);
  const [technosSelected, setTechnosSelected] = useState([]);
  const [skillsSelected, setSkillsSelected] = useState([]);
  const [currentProjects, setCurrentProjects] = useState([]);
  const [activitiesList, setActivitiesList] = useState([]);
  const [newActivityAdded, setNewActivityAdded] = useState(false);
  const [elementsTechnos, setElementsTechnos] = useState([]);
  const [elementsSkills, setElementsSkills] = useState([]);
  const [elementsTools, setElementsTools] = useState([]);
  const [leads, setLeads] = useState([]);
  const dispatch = useDispatch();
  const classes = useStyles();
  const projectsName = useSelector((state) => state.projectsState.projects);

  useEffect(async () => {
    api.get('api/users?RolesIds=1,2').then((res) => setLeads(res.data.reduce((acc, cur) => {
      acc.push({ id: cur.id, name: `${cur.first_name} ${cur.last_name}` });
      return acc;
    }, [])));
  }, []);

  useEffect(() => {
    api
      .get('api/tools')
      .then((res) => {
        setElementsTools(res.data);
      });
  }, []);
  useEffect(() => {
    api
      .get('api/technos')
      .then((res) => {
        setElementsTechnos(res.data);
      });
  }, []);
  useEffect(() => {
    api
      .get('api/skills')
      .then((res) => {
        setElementsSkills(res.data);
      });
  }, []);
  useEffect(() => {
    setIsLoading(true);
    const idIsValidInteger = /^-?\d+$/.test(id);
    if (idIsValidInteger) {
      api
        .get(`api/users?UserId=${id}`)
        .then((res) => res.data)
        .then((data) => {
          setUser({ ...data, leadId: data.leadId ? data.leadId : false });
          setToolsSelected(data.Tools);
          setTechnosSelected(data.Technos);
          setSkillsSelected(data.Skills);
          setProfileImagePath(data.profileImagePath ? `${process.env.REACT_APP_BACK_URL}/api/users/profileImage/${data.profileImagePath}` : profileIcon);
          dispatch(activitiesAdded(data.UserActivityProjects));
        })
        .catch((error) => {
          console.log(error);
          setUser(null);
        })
        .finally(() => setIsLoading(false));
    } else {
      setUser(null);
      setIsLoading(false);
    }
  }, [id, edit, activtyAdded, activityRemoved]);
  useEffect(() => {
    api
      .get('api/projectsWithIntensity')
      .then((res) => {
        setCurrentProjects(res.data);
      });
    if (projectsName.length === 0) dispatch(fetchProjects());
  }, []);
  useEffect(() => {
    api
      .get('api/activities')
      .then((res) => res.data)
      .then((data) => {
        setActivitiesList(data);
        // dispatch(activitiesListAdded(data));
      });
  }, [newActivityAdded]);

  const handleUpdate = () => {
    const body = {
      ...user,
      leadId: user.leadId ? user.leadId : null,
      Tools: toolsSelected.map((tool) => ({
        toolId: tool.toolId,
        userId: parseInt(id, 10),
        seniority: tool.seniority,
      })),
      Skills: skillsSelected.map((skill) => ({
        skillId: skill.skillId,
        userId: parseInt(id, 10),
        seniority: skill.seniority,
      })),
      Technos: technosSelected
        .map((techno) => ({
          technoId: techno.technoId,
          userId: parseInt(id, 10),
          seniority: techno.seniority,
          name: techno.techno_name,
        })),
    };
    if (profileImageForm) {
      api.patch('api/users/profileImage', profileImageForm)
        .catch((err) => {
          console.log(err);
        });
    }
    api.patch(`api/users/${id}`, body)
      .then(() => setEdit(false));
  };

  const filteredTechnos = elementsTechnos.reduce((acc, curr) => {
    const result = technosSelected.find((techno) => techno.technoId === curr.id);
    if (!result) {
      acc.push(curr);
    }
    return acc;
  }, []);

  const filteredSkills = elementsSkills.reduce((acc, curr) => {
    const result = skillsSelected.find((skills) => skills.skillId === curr.id);
    if (!result) {
      acc.push(curr);
    }
    return acc;
  }, []);

  const filteredTools = elementsTools.reduce((acc, curr) => {
    const result = toolsSelected.find((tools) => tools.toolId === curr.id);
    if (!result) {
      acc.push(curr);
    }
    return acc;
  }, []);
  return (
    <div id="boxProfilePage">
      {isLoading ? (
        <ProfileLeftSideLoader />
      ) : (
        <>
          {user ? (
            <>
              <div id="leftProfilePage">
                <GeneralInfosProfile
                  profileImagePath={profileImagePath}
                  setProfileImagePath={setProfileImagePath}
                  setProfileImageForm={setProfileImageForm}
                  firstName={user.first_name}
                  lastName={user.last_name}
                  email={user.email}
                  position={user.position}
                  location={user.location}
                  computer_used={user.computer_used}
                  hourlyRate={user.hourlyRate}
                  userId={user.id}
                  edit={edit}
                  setEdit={setEdit}
                  user={user}
                  setUser={setUser}
                  handleUpdate={handleUpdate}
                  skills={skillsSelected}
                  technos={technosSelected}
                  tools={toolsSelected}
                  leadsNames={leads}
                />
                <div id="skillsTechnosToolsProfile">
                  {edit ? (
                    <SelectAddElementProfile setElements={setElementsTechnos} options={filteredTechnos} elements={elementsTechnos} selected={technosSelected} setSelected={setTechnosSelected} word="Techno" title="no" keyRequired="techno_name" />
                  ) : <SkillsProfile listInfos={elementsTechnos} elements={technosSelected} keyRequired="techno_name" word="Technologies" />}
                  <hr />
                  {edit ? (
                    <SelectAddElementProfile setElements={setElementsSkills} options={filteredSkills} elements={elementsSkills} selected={skillsSelected} setSelected={setSkillsSelected} word="Skill" title="no" keyRequired="skill_name" />
                  ) : <SkillsProfile listInfos={elementsSkills} elements={skillsSelected} keyRequired="skill_name" word="Skills" />}
                  <hr />
                  {edit ? (
                    <SelectAddElementProfile setElements={setElementsTools} options={filteredTools} elements={elementsTools} selected={toolsSelected} setSelected={setToolsSelected} word="Tool" title="no" keyRequired="tool_name" />
                  ) : <SkillsProfile listInfos={elementsTools} elements={toolsSelected} keyRequired="tool_name" word="Tools" />}
                </div>
              </div>
              <div id="rightProfilePage">
                <Workload />
                <CompletedActivities
                  completedActivities={user.UserActivityProjects}
                  userId={user.id}
                  currentProjects={currentProjects}
                  activtyAdded={activtyAdded}
                  setActivityAdded={setActivityAdded}
                  activityRemoved={activityRemoved}
                  setActivityRemoved={setActivityRemoved}
                  activitiesList={activitiesList}
                  setActivitiesList={setActivitiesList}
                  setNewActivityAdded={setNewActivityAdded}
                />
                {/* <AbsencesProfile /> */}
              </div>
            </>
          ) : (
            <>
              <Box className={classes.paper}>
                <Typography className={classes.text}>
                  Ooops!
                </Typography>
                <Oops />
                <Typography className={classes.text}>
                  This user doesn´t exist,
                  check the collaborators list to find you :)
                </Typography>
                <br />
                <Link to="/employeesList">
                  <Button className={classes.saveBtn}>
                    Go back
                  </Button>
                </Link>
              </Box>
            </>
          )}
        </>
      )}
    </div>
  );
};
export default ProfilePage;
