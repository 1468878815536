/* eslint-disable linebreak-style */
import React from 'react';
import PropTypes from 'prop-types';
import { api } from '../../../api/api';
import ConfirmationComponent from '../../../commonComponents/ConfirmationComponent';
import ModalCustom from '../../../commonComponents/ModalCustom';

const DeleteRisk = (props) => {
  const {
    riskId, open, setOpen, updateRisks, setUpdateRisks, onRowDeleted,
  } = props;

  const handleDeleteRow = () => {
    api.delete(`api/risks/${riskId}`)
      .then(() => {
        setUpdateRisks(!updateRisks);
        setOpen(false);
        onRowDeleted();
      });
  };

  return (
    <ModalCustom
      handleClose={() => setOpen(false)}
      open={open}
      width="676px"
      title="Delete the row?"
      child={(
        <ConfirmationComponent
          eventOne={() => handleDeleteRow(riskId)}
          eventTwo={() => setOpen(false)}
        />
    )}
    />
  );
};

DeleteRisk.propTypes = {
  onRowDeleted: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  riskId: PropTypes.number.isRequired,
  updateRisks: PropTypes.bool.isRequired,
  setUpdateRisks: PropTypes.func.isRequired,
};

export default DeleteRisk;
