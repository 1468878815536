import React from 'react';
import PropTypes from 'prop-types';

const ProjectLayout = (props) => {
  const { leftSection, rightSectionList } = props;
  return (
    <div id="detailedProjectBox">
      <div id="leftSection">
        {leftSection}
      </div>
      <div id="rightSection">
        {rightSectionList}
      </div>
    </div>
  );
};

ProjectLayout.propTypes = {
  leftSection: PropTypes.element.isRequired,
  rightSectionList: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default ProjectLayout;
