/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { api } from '../api/api';

const filter = createFilterOptions();

const useStyles = makeStyles(() => ({
  tag: {
    display: 'none',
  },
  suppItem: {
    marginRight: '16px',
    color: 'rgba(17, 17, 17, 0.7)',
    height: '18px',
    padding: '3px 0',
    '&:hover': {
      backgroundColor: 'grey',
      color: 'white',
      boxShadow: 'none',
      borderRadius: '50%',
    },
  },

  chip: {
    height: '36px',
    padding: '0px 8px',
    fontSize: '16px',
    color: '#707987',
    backgroundColor: 'rgba(240, 240, 240, 1)',
    fontWeight: 500,
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.05)',
  },
  seniority: {
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    height: '20px',
    background: 'linear-gradient(to right, green 50%, rgba(240, 240, 240, 1) 0%)',
    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.05)',
    // borderBottom: '1px rgba(240, 240, 240, 1) solid',

  },

}));

const SelectAddElement = (props) => {
  const {
    selected, setSelected, word, title, profile,
  } = props;
  const goodVariable = `${word.toLowerCase()}_name`;
  const [elements, setElements] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    let mounted = true;
    api
      .get(`api/${word.toLowerCase()}s`)
      .then((res) => {
        if (mounted) {
          setElements(res.data);
        }
      });
    return () => {
      mounted = false;
    };
  }, []);

  const handleSelect = (event, newElements) => {
    const newSelected = { ...selected };

    // Try to find a new item ( = not existing in DB)
    const newItem = newElements
      .find((item) => !elements
        .map((e) => e[goodVariable]).includes(item[goodVariable]));

    const body = {};
    body[goodVariable] = newItem;

    const addElement = () => api
      .post(`api/${word.toLowerCase()}s`, body[goodVariable])
      .then((response) => {
        setElements([...elements, response.data]);
        setSelected([...selected, response.data]);
      })
      .catch((err) => {
        if (err.response.status === 409) {
          // eslint-disable-next-line no-console
          console.error(err);
        }
      });

    // If element did not exist, create it in DB
    if (newItem) {
      addElement();
    }

    if (typeof newValue === 'string') {
      newSelected[goodVariable] = newElements;
      setSelected(newSelected);
    } else if (newElements && newElements.inputValue) {
      newSelected[goodVariable] = newElements.inputValue;
      setSelected(newSelected);
    } else {
      setSelected(newElements);
    }
  };

  const removeElement = (idToRemove) => {
    const updateElementsSelected = selected
      .filter((id) => id.id !== idToRemove);
    setSelected(updateElementsSelected);
  };

  return (
    <div className="boxAutoCompletionTechnoTool">
      {title === 'no' ? '' : (
        <p className="labelsFormCreate">
          {word}
          s
          {' '}
          needed
        </p>
      ) }

      <Autocomplete
        id="autocompleteSelectAddElement"
        multiple
        classes={{ tag: classes.tag }}
        style={title === 'no' ? { marginBottom: selected.length > 0 ? '0.5em' : '0em', padding: '8px 0' } : {
          marginBottom: selected.length > 0 ? '0.5em' : '0em',
          padding: '8px 0',
        }}
        fullWidth
        autoComplete
        autoHighlight
        value={selected}
        onChange={handleSelect}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          // Suggest the creation of a new value
          if (params.inputValue !== '' && !elements.some((item) => item[goodVariable] === params.inputValue)) {
            const body = {};
            body.title = `Add "${params.inputValue}"`;
            body[goodVariable] = params.inputValue;
            filtered.push(body);
          }
          return filtered;
        }}
        selectOnFocus
        handleHomeEndKeys
        options={elements}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          return option[goodVariable];
        }}
        renderOption={(option) => option.title ?? option[goodVariable]}
        freeSolo
        renderInput={(params) => (
          <TextField placeholder={`Type to search or add a ${word.toLowerCase()}`} {...params} variant="outlined" />
        )}
      />
      <div style={{ display: 'flex', justifyContent: 'start', flexWrap: 'wrap' }}>

        {selected ? selected.map((item) => (
          profile
            ? (
              <Box>
                <Box className={classes.seniority} />
                <Box className={classes.chip} display="flex" justifyContent="center" alignItems="center">
                  <Typography>{item[goodVariable]}</Typography>
                </Box>
              </Box>
            )
            : (
              <div
                key={item.id}
                className="chipsSelectedToolsTechnos"
              >
                <p key={item[goodVariable]}>
                  {item[goodVariable]}
                </p>
                <CloseIcon onClick={() => removeElement(item.id)} className={classes.suppItem} />
              </div>
            )
        )) : ''}
      </div>
    </div>
  );
};

SelectAddElement.defaultProps = {
  title: 'yes',
  selected: [],
  profile: false,
};

SelectAddElement.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
  })),
  word: PropTypes.string.isRequired,
  setSelected: PropTypes.func.isRequired,
  title: PropTypes.string,
  profile: PropTypes.bool,
};

export default SelectAddElement;
